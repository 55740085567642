@import "../../../../node_modules/zsui/src/carousel/carousel.less";

// overriding opacity of carousel screen edge cards to make them look simillar 
.zs-carousel span.zs-carousel-sides-back, .zs-carousel span.zs-carousel-sides-forward{
    opacity: 0;
}

.zs-carousel{
    &:not([slider]) .zs-carousel-container{
        display: grid;
        // Override carousel.less to allow 5 parts in carousel
        grid-template-columns: ~"calc(100% - @{zs-carousel-sides-width}) calc(100% - @{zs-carousel-sides-width}*2) calc(100% - @{zs-carousel-sides-width}*2) calc(100% - @{zs-carousel-sides-width}*2) calc(100% - @{zs-carousel-sides-width})";
        display: -ms-grid;
        -ms-grid-columns: ~"calc(100% - @{zs-carousel-sides-width}) calc(100% - @{zs-carousel-sides-width}*2) calc(100% - @{zs-carousel-sides-width}*2) calc(100% - @{zs-carousel-sides-width}*2) calc(100% - @{zs-carousel-sides-width})";
        transition: -webkit-transform 0.5s ease 0s;
        transition: transform 0.5s ease 0s;
        transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
        >section{   
            overflow: hidden; 
            &:nth-of-type(1){
                grid-column: 1;
                -ms-grid-column: 1;
                -ms-grid-column-span: 1;
                margin-left: @zs-carousel-sides-width;

            }
            &:nth-of-type(2){
                grid-column: 2;
                -ms-grid-column: 2;
                -ms-grid-column-span: 1;
    
            }
            &:nth-of-type(3){
                grid-column: 3;
                -ms-grid-column: 3;
                -ms-grid-column-span: 1;
            }
            &:nth-of-type(4){
                grid-column: 4;
                -ms-grid-column: 4;
                -ms-grid-column-span: 1;
    
            }
    }
}
}
