/* You can add global styles to this file, and also import other style files */
/* ZSUI application styles bundle */
.zs-inverse .zs-link-action,
.zs-inverse.zs-link-action {
  color: #ffffff;
  background: transparent;
}
.zs-inverse .zs-link-action:visited,
.zs-inverse.zs-link-action:visited {
  color: #ffffff;
}
.zs-inverse .zs-link-action:focus,
.zs-inverse.zs-link-action:focus {
  color: #ffffff;
  background: rgba(39, 166, 164, 0.1);
  outline: 2px solid #27A6A4;
}
.zs-inverse .zs-link-action:hover,
.zs-inverse.zs-link-action:hover,
.zs-inverse .zs-link-action:hover:visited,
.zs-inverse.zs-link-action:hover:visited {
  color: #ffffff;
  background: rgba(39, 166, 164, 0.1);
}
.zs-inverse .zs-link-action:active,
.zs-inverse.zs-link-action:active,
.zs-inverse .zs-link-action:active:visited,
.zs-inverse.zs-link-action:active:visited {
  color: #ffffff;
  background: #2D8B93;
}
.zs-inverse .zs-link-action.zs-active,
.zs-inverse.zs-link-action.zs-active,
.zs-inverse .zs-link-action.zs-active:visited,
.zs-inverse.zs-link-action.zs-active:visited {
  color: #ffffff;
  background: #27A6A4;
}
.zs-inverse .zs-loader [loader],
.zs-loader.zs-inverse [loader] {
  border-color: rgba(222, 220, 222, 0.5);
}
.zs-inverse .zs-loader [loader]:before,
.zs-loader.zs-inverse [loader]:before,
.zs-inverse .zs-loader [loader]:after,
.zs-loader.zs-inverse [loader]:after,
.zs-inverse .zs-loader [loader] span::after,
.zs-loader.zs-inverse [loader] span::after,
.zs-inverse .zs-loader [loader] span::before,
.zs-loader.zs-inverse [loader] span::before {
  border-color: #5B5864;
  border-color: #5B5864 transparent transparent transparent;
}
.zs-inverse .zs-interactive-secondary,
.zs-inverse.zs-interactive-secondary,
.zs-inverse .zs-icon.zs-interactive-secondary,
.zs-inverse.zs-icon.zs-interactive-secondary {
  color: #ffffff;
}
.zs-inverse .zs-interactive-secondary:visited,
.zs-inverse.zs-interactive-secondary:visited,
.zs-inverse .zs-icon.zs-interactive-secondary:visited,
.zs-inverse.zs-icon.zs-interactive-secondary:visited {
  color: #ffffff;
}
.zs-inverse .zs-interactive-secondary:focus,
.zs-inverse.zs-interactive-secondary:focus,
.zs-inverse .zs-icon.zs-interactive-secondary:focus,
.zs-inverse.zs-icon.zs-interactive-secondary:focus {
  color: #9C9AA1;
}
.zs-inverse .zs-interactive-secondary:hover,
.zs-inverse.zs-interactive-secondary:hover,
.zs-inverse .zs-icon.zs-interactive-secondary:hover,
.zs-inverse.zs-icon.zs-interactive-secondary:hover {
  color: #9C9AA1;
}
.zs-inverse .zs-interactive-secondary:active,
.zs-inverse.zs-interactive-secondary:active,
.zs-inverse .zs-icon.zs-interactive-secondary:active,
.zs-inverse.zs-icon.zs-interactive-secondary:active {
  color: #2F2C3C;
}
.zs-inverse .zs-interactive-secondary.zs-danger,
.zs-inverse.zs-interactive-secondary.zs-danger,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-danger,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-danger,
.zs-inverse .zs-interactive-secondary.zs-warning,
.zs-inverse.zs-interactive-secondary.zs-warning,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-warning,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-warning,
.zs-inverse .zs-interactive-secondary.zs-success,
.zs-inverse.zs-interactive-secondary.zs-success,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-success,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-success,
.zs-inverse .zs-interactive-secondary.zs-info,
.zs-inverse.zs-interactive-secondary.zs-info,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-info,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-info {
  color: #ffffff;
}
.zs-inverse .zs-interactive-secondary.zs-danger:visited,
.zs-inverse.zs-interactive-secondary.zs-danger:visited,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-danger:visited,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-danger:visited,
.zs-inverse .zs-interactive-secondary.zs-warning:visited,
.zs-inverse.zs-interactive-secondary.zs-warning:visited,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-warning:visited,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-warning:visited,
.zs-inverse .zs-interactive-secondary.zs-success:visited,
.zs-inverse.zs-interactive-secondary.zs-success:visited,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-success:visited,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-success:visited,
.zs-inverse .zs-interactive-secondary.zs-info:visited,
.zs-inverse.zs-interactive-secondary.zs-info:visited,
.zs-inverse .zs-icon.zs-interactive-secondary.zs-info:visited,
.zs-inverse.zs-icon.zs-interactive-secondary.zs-info:visited {
  color: #ffffff;
}
.zs-inverse.zs-overlay,
.zs-inverse .zs-overlay {
  background: rgba(26, 22, 40, 0.9);
}
.zs-inverse.backdrop[for="zs-dialog"]:not([class*="zs-bg-overlay-"]),
.zs-inverse.zs-dialog ~ .backdrop:not([class*="zs-bg-overlay-"]),
.zs-inverse .backdrop[for="zs-dialog"]:not([class*="zs-bg-overlay-"]),
.zs-inverse .zs-dialog ~ .backdrop:not([class*="zs-bg-overlay-"]) {
  background: rgba(26, 22, 40, 0.9) !important;
}
.zs-inverse.zs-dialog::backdrop,
.zs-inverse .zs-dialog::backdrop {
  background: rgba(26, 22, 40, 0.9) !important;
}
dialog.zs-dialog.zs-native-dialog-inverse + div.backdrop {
  background: rgba(26, 22, 40, 0.9) !important;
}
dialog.zs-dialog.zs-native-dialog-inverse::backdrop {
  background: rgba(26, 22, 40, 0.9) !important;
}
.zs-inverse zs-tooltip,
zs-tooltip.zs-inverse {
  color: #2F2C3C;
  background: #FAFAFA;
}
.zs-inverse zs-tooltip.left > zs-tooltip-arrow:after,
zs-tooltip.zs-inverse.left > zs-tooltip-arrow:after {
  border-left-color: #FAFAFA;
}
.zs-inverse zs-tooltip.bottom > zs-tooltip-arrow:after,
zs-tooltip.zs-inverse.bottom > zs-tooltip-arrow:after {
  border-bottom-color: #FAFAFA;
}
.zs-inverse zs-tooltip.top > zs-tooltip-arrow:after,
zs-tooltip.zs-inverse.top > zs-tooltip-arrow:after {
  border-top-color: #FAFAFA;
}
.zs-inverse zs-tooltip.right > zs-tooltip-arrow:after,
zs-tooltip.zs-inverse.right > zs-tooltip-arrow:after {
  border-right-color: #FAFAFA;
}
.zs-inverse.zs-color-tile .zs-icon {
  color: #27A6A4;
}
.zs-inverse.zs-bg-overlay-90,
.zs-inverse .zs-bg-overlay-90 {
  background: rgba(26, 22, 40, 0.9) !important;
}
.zs-inverse.zs-bg-overlay-80,
.zs-inverse .zs-bg-overlay-80 {
  background: rgba(26, 22, 40, 0.8) !important;
}
.zs-inverse.zs-bg-overlay-70,
.zs-inverse .zs-bg-overlay-70 {
  background: rgba(26, 22, 40, 0.7) !important;
}
.zs-inverse.zs-bg-overlay-60,
.zs-inverse .zs-bg-overlay-60 {
  background: rgba(26, 22, 40, 0.6) !important;
}
.zs-inverse.zs-bg-overlay-50,
.zs-inverse .zs-bg-overlay-50 {
  background: rgba(26, 22, 40, 0.5) !important;
}
.zs-inverse.zs-bg-overlay-40,
.zs-inverse .zs-bg-overlay-40 {
  background: rgba(26, 22, 40, 0.4) !important;
}
.zs-inverse.zs-bg-overlay-30,
.zs-inverse .zs-bg-overlay-30 {
  background: rgba(26, 22, 40, 0.3) !important;
}
.zs-inverse.zs-bg-overlay-20,
.zs-inverse .zs-bg-overlay-20 {
  background: rgba(26, 22, 40, 0.2) !important;
}
.zs-inverse.zs-bg-overlay-10,
.zs-inverse .zs-bg-overlay-10 {
  background: rgba(26, 22, 40, 0.1) !important;
}
.zs-inverse.zs-text-inverse,
.zs-inverse .zs-text-inverse {
  color: #2F2C3C !important;
}
.zs-inverse.zs-text-default,
.zs-inverse .zs-text-default {
  color: #FAFAFA !important;
}
.zs-inverse .zs-menu,
.zs-inverse.zs-menu {
  background: inherit;
}
