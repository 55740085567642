/* You can add global styles to this file, and also import other style files */
/* ZSUI application styles bundle */
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-Light.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-Light-Italic.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-Light-Italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-Regular.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-Italic.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-SemiBold.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-SemiBold-Italic.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-SemiBold-Italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-Bold.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../../node_modules/zsui/src/core/OpenSans-Bold-Italic.woff') format('woff'), url('../../node_modules/zsui/src/core/OpenSans-Bold-Italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}
.zs-master-style .zs-danger-text {
  color: #B21111;
}
.zs-master-style .zs-danger-text:visited {
  color: #B21111;
}
.zs-master-style .zs-danger-text:focus {
  color: #892208;
}
.zs-master-style .zs-danger-text:hover {
  color: #F27755;
}
.zs-master-style .zs-danger-text:active {
  color: #5C1A0B;
}
.zs-master-style .zs-danger-border {
  border: 1px solid #B21111;
}
.zs-master-style .zs-danger-border:focus {
  border: 1px solid #892208;
}
.zs-master-style .zs-danger-border:hover {
  border: 1px solid #F27755;
}
.zs-master-style .zs-danger-border:active {
  border: 1px solid #5C1A0B;
}
.zs-master-style .zs-danger-background {
  background: #B21111;
}
.zs-master-style .zs-danger-background:focus {
  background: #892208;
}
.zs-master-style .zs-danger-background:hover {
  background: #F27755;
}
.zs-master-style .zs-danger-background:active {
  background: #5C1A0B;
}
.zs-master-style .zs-success-text {
  color: #00AA67;
}
.zs-master-style .zs-success-text:visited {
  color: #00AA67;
}
.zs-master-style .zs-success-text:focus {
  color: #0A6E5E;
}
.zs-master-style .zs-success-text:hover {
  color: #54D7AF;
}
.zs-master-style .zs-success-text:active {
  color: #0C4846;
}
.zs-master-style .zs-success-border {
  border: 1px solid #00AA67;
}
.zs-master-style .zs-success-border:focus {
  border: 1px solid #0A6E5E;
}
.zs-master-style .zs-success-border:hover {
  border: 1px solid #54D7AF;
}
.zs-master-style .zs-success-border:active {
  border: 1px solid #0C4846;
}
.zs-master-style .zs-success-background {
  background: #00AA67;
}
.zs-master-style .zs-success-background:focus {
  background: #0A6E5E;
}
.zs-master-style .zs-success-background:hover {
  background: #54D7AF;
}
.zs-master-style .zs-success-background:active {
  background: #0C4846;
}
.zs-master-style .zs-warning-text {
  color: #E3A900;
}
.zs-master-style .zs-warning-text:visited {
  color: #E3A900;
}
.zs-master-style .zs-warning-text:focus {
  color: #8A640C;
}
.zs-master-style .zs-warning-text:hover {
  color: #FDCA63;
}
.zs-master-style .zs-warning-text:active {
  color: #563F0E;
}
.zs-master-style .zs-warning-border {
  border: 1px solid #E3A900;
}
.zs-master-style .zs-warning-border:focus {
  border: 1px solid #8A640C;
}
.zs-master-style .zs-warning-border:hover {
  border: 1px solid #FDCA63;
}
.zs-master-style .zs-warning-border:active {
  border: 1px solid #563F0E;
}
.zs-master-style .zs-warning-background {
  background: #E3A900;
}
.zs-master-style .zs-warning-background:focus {
  background: #8A640C;
}
.zs-master-style .zs-warning-background:hover {
  background: #FDCA63;
}
.zs-master-style .zs-warning-background:active {
  background: #563F0E;
}
.zs-master-style .zs-info-text {
  color: #2B44C7;
}
.zs-master-style .zs-info-text:visited {
  color: #2B44C7;
}
.zs-master-style .zs-info-text:focus {
  color: #141187;
}
.zs-master-style .zs-info-text:hover {
  color: #7E92E9;
}
.zs-master-style .zs-info-text:active {
  color: #160C60;
}
.zs-master-style .zs-info-border {
  border: 1px solid #2B44C7;
}
.zs-master-style .zs-info-border:focus {
  border: 1px solid #141187;
}
.zs-master-style .zs-info-border:hover {
  border: 1px solid #7E92E9;
}
.zs-master-style .zs-info-border:active {
  border: 1px solid #160C60;
}
.zs-master-style .zs-info-background {
  background: #2B44C7;
}
.zs-master-style .zs-info-background:focus {
  background: #141187;
}
.zs-master-style .zs-info-background:hover {
  background: #7E92E9;
}
.zs-master-style .zs-info-background:active {
  background: #160C60;
}
.zs-icon + .zs-icon {
  margin: 0em 0em 0em 0.125em;
}
@font-face {
  font-family: 'zsIcons';
  src: url('../../node_modules/zsui/src/core/zsIcons.woff2?v5.4.0') format('woff2'), url('../../node_modules/zsui/src/core/zsIcons.woff?v5.4.0') format('woff');
  font-weight: normal;
  font-style: normal;
}
.zs-master-style .zs-icon {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.zs-master-style .zs-interactive-primary,
.zs-master-style .zs-interactive-secondary {
  display: inline-block;
}
.zs-master-style .zs-interactive-primary .zs-icon,
.zs-master-style .zs-interactive-secondary .zs-icon {
  pointer-events: none;
}
.zs-master-style a:hover > .zs-icon::before,
.zs-master-style a:hover > .zs-icon::after {
  color: inherit;
}
.zs-master-style a:hover > .zs-icon:before,
.zs-master-style a > .zs-icon:before {
  margin-right: 0;
}
.zs-master-style a.zs-icon:before {
  cursor: pointer;
}
.zs-master-style a.zs-icon,
.zs-master-style a.zs-icon:hover {
  cursor: pointer;
}
.zs-master-style .zs-icon-add::before {
  content: "\ea01";
}
.zs-master-style .zs-icon-add-circle::before {
  content: "\ea02";
}
.zs-master-style .zs-icon-add-circle-fill::before {
  content: "\ea03";
}
.zs-master-style .zs-icon-admin-log::before {
  content: "\ea04";
}
.zs-master-style .zs-icon-admin-log-fill::before {
  content: "\ea05";
}
.zs-master-style .zs-icon-ampersand::before {
  content: "\ea06";
}
.zs-master-style .zs-icon-app-nav::before {
  content: "\ea07";
}
.zs-master-style .zs-icon-arrow-down::before {
  content: "\ea08";
}
.zs-master-style .zs-icon-arrow-down-circle::before {
  content: "\ea09";
}
.zs-master-style .zs-icon-arrow-down-circle-fill::before {
  content: "\ea0a";
}
.zs-master-style .zs-icon-arrow-first::before {
  content: "\ea0b";
}
.zs-master-style .zs-icon-arrow-first-fill::before {
  content: "\ea0c";
}
.zs-master-style .zs-icon-arrow-last::before {
  content: "\ea0d";
}
.zs-master-style .zs-icon-arrow-last-fill::before {
  content: "\ea0e";
}
.zs-master-style .zs-icon-arrow-left::before {
  content: "\ea0f";
}
.zs-master-style .zs-icon-arrow-next-circle::before {
  content: "\ea10";
}
.zs-master-style .zs-icon-arrow-next-circle-fill::before {
  content: "\ea11";
}
.zs-master-style .zs-icon-arrow-prev-circle::before {
  content: "\ea12";
}
.zs-master-style .zs-icon-arrow-prev-circle-fill::before {
  content: "\ea13";
}
.zs-master-style .zs-icon-arrow-redo::before {
  content: "\ea14";
}
.zs-master-style .zs-icon-arrow-reply::before {
  content: "\ea15";
}
.zs-master-style .zs-icon-arrow-reply-fill::before {
  content: "\ea16";
}
.zs-master-style .zs-icon-arrow-return::before {
  content: "\ea17";
}
.zs-master-style .zs-icon-arrow-revise::before {
  content: "\ea18";
}
.zs-master-style .zs-icon-arrow-right::before {
  content: "\ea19";
}
.zs-master-style .zs-icon-arrow-send::before {
  content: "\ea1a";
}
.zs-master-style .zs-icon-arrow-up::before {
  content: "\ea1b";
}
.zs-master-style .zs-icon-arrow-up-circle::before {
  content: "\ea1c";
}
.zs-master-style .zs-icon-arrow-up-circle-fill::before {
  content: "\ea1d";
}
.zs-master-style .zs-icon-arrow-up-down::before {
  content: "\ea1e";
}
.zs-master-style .zs-icon-bookmark::before {
  content: "\ea1f";
}
.zs-master-style .zs-icon-bookmark-fill::before {
  content: "\ea20";
}
.zs-master-style .zs-icon-brackets::before {
  content: "\ea21";
}
.zs-master-style .zs-icon-bullet::before {
  content: "\ea22";
}
.zs-master-style .zs-icon-bullet-left::before {
  content: "\ea23";
}
.zs-master-style .zs-icon-business-rule::before {
  content: "\ea24";
}
.zs-master-style .zs-icon-calendar::before {
  content: "\ea25";
}
.zs-master-style .zs-icon-carat-down::before {
  content: "\ea26";
}
.zs-master-style .zs-icon-carat-left::before {
  content: "\ea27";
}
.zs-master-style .zs-icon-carat-right::before {
  content: "\ea28";
}
.zs-master-style .zs-icon-carat-up::before {
  content: "\ea29";
}
.zs-master-style .zs-icon-card::before {
  content: "\ea2a";
}
.zs-master-style .zs-icon-card-flip::before {
  content: "\ea2b";
}
.zs-master-style .zs-icon-card-view::before {
  content: "\ea2c";
}
.zs-master-style .zs-icon-card-view-fill::before {
  content: "\ea2d";
}
.zs-master-style .zs-icon-check::before {
  content: "\ea2e";
}
.zs-master-style .zs-icon-check-circle::before {
  content: "\ea2f";
}
.zs-master-style .zs-icon-check-circle-fill::before {
  content: "\ea30";
}
.zs-master-style .zs-icon-circle-fill::before {
  content: "\ea31";
}
.zs-master-style .zs-icon-circle-outline::before {
  content: "\ea32";
}
.zs-master-style .zs-icon-clock-pending::before {
  content: "\ea33";
}
.zs-master-style .zs-icon-clock-pending-fill::before {
  content: "\ea34";
}
.zs-master-style .zs-icon-close::before {
  content: "\ea35";
}
.zs-master-style .zs-icon-close-circle::before {
  content: "\ea36";
}
.zs-master-style .zs-icon-close-circle-fill::before {
  content: "\ea37";
}
.zs-master-style .zs-icon-config-ab::before {
  content: "\ea38";
}
.zs-master-style .zs-icon-config-summary::before {
  content: "\ea39";
}
.zs-master-style .zs-icon-config-summary-fill::before {
  content: "\ea3a";
}
.zs-master-style .zs-icon-content-guide::before {
  content: "\ea3b";
}
.zs-master-style .zs-icon-content-guide-fill::before {
  content: "\ea3c";
}
.zs-master-style .zs-icon-delete::before {
  content: "\ea3d";
}
.zs-master-style .zs-icon-delete-fill::before {
  content: "\ea3e";
}
.zs-master-style .zs-icon-dislike::before {
  content: "\ea3f";
}
.zs-master-style .zs-icon-dislike-fill::before {
  content: "\ea40";
}
.zs-master-style .zs-icon-divide::before {
  content: "\ea41";
}
.zs-master-style .zs-icon-download::before {
  content: "\ea42";
}
.zs-master-style .zs-icon-edit::before {
  content: "\ea43";
}
.zs-master-style .zs-icon-edit-fill::before {
  content: "\ea44";
}
.zs-master-style .zs-icon-event::before {
  content: "\ea45";
}
.zs-master-style .zs-icon-event-fill::before {
  content: "\ea46";
}
.zs-master-style .zs-icon-eye-close::before {
  content: "\ea47";
}
.zs-master-style .zs-icon-eye-open::before {
  content: "\ea48";
}
.zs-master-style .zs-icon-filter::before {
  content: "\ea49";
}
.zs-master-style .zs-icon-flag::before {
  content: "\ea4a";
}
.zs-master-style .zs-icon-flag-fill::before {
  content: "\ea4b";
}
.zs-master-style .zs-icon-font-size::before {
  content: "\ea4c";
}
.zs-master-style .zs-icon-item-drag::before {
  content: "\ea4d";
}
.zs-master-style .zs-icon-item-group::before {
  content: "\ea4e";
}
.zs-master-style .zs-icon-item-ungroup::before {
  content: "\ea4f";
}
.zs-master-style .zs-icon-labels::before {
  content: "\ea50";
}
.zs-master-style .zs-icon-labels-rotate::before {
  content: "\ea51";
}
.zs-master-style .zs-icon-layers::before {
  content: "\ea52";
}
.zs-master-style .zs-icon-layers-fill::before {
  content: "\ea53";
}
.zs-master-style .zs-icon-like::before {
  content: "\ea54";
}
.zs-master-style .zs-icon-like-fill::before {
  content: "\ea55";
}
.zs-master-style .zs-icon-link::before {
  content: "\ea56";
}
.zs-master-style .zs-icon-list-view::before {
  content: "\ea57";
}
.zs-master-style .zs-icon-list-view-fill::before {
  content: "\ea58";
}
.zs-master-style .zs-icon-list-view-generous::before {
  content: "\ea59";
}
.zs-master-style .zs-icon-loader::before {
  content: "\ea5a";
}
.zs-master-style .zs-icon-lock::before {
  content: "\ea5b";
}
.zs-master-style .zs-icon-lock-fill::before {
  content: "\ea5c";
}
.zs-master-style .zs-icon-lock-unlock::before {
  content: "\ea5d";
}
.zs-master-style .zs-icon-lock-unlock-fill::before {
  content: "\ea5e";
}
.zs-master-style .zs-icon-login::before {
  content: "\ea5f";
}
.zs-master-style .zs-icon-logout::before {
  content: "\ea60";
}
.zs-master-style .zs-icon-machine-learn::before {
  content: "\ea61";
}
.zs-master-style .zs-icon-mail::before {
  content: "\ea62";
}
.zs-master-style .zs-icon-mail-fill::before {
  content: "\ea63";
}
.zs-master-style .zs-icon-mail-settings::before {
  content: "\ea64";
}
.zs-master-style .zs-icon-mail-settings-fill::before {
  content: "\ea65";
}
.zs-master-style .zs-icon-mobile-nav::before {
  content: "\ea66";
}
.zs-master-style .zs-icon-more::before {
  content: "\ea67";
}
.zs-master-style .zs-icon-multiply::before {
  content: "\ea68";
}
.zs-master-style .zs-icon-object-group::before {
  content: "\ea69";
}
.zs-master-style .zs-icon-palette::before {
  content: "\ea6a";
}
.zs-master-style .zs-icon-palette-fill::before {
  content: "\ea6b";
}
.zs-master-style .zs-icon-parens-left::before {
  content: "\ea6c";
}
.zs-master-style .zs-icon-parens-right::before {
  content: "\ea6d";
}
.zs-master-style .zs-icon-pause::before {
  content: "\ea6e";
}
.zs-master-style .zs-icon-pause-fill::before {
  content: "\ea6f";
}
.zs-master-style .zs-icon-pin::before {
  content: "\ea70";
}
.zs-master-style .zs-icon-pin-fill::before {
  content: "\ea71";
}
.zs-master-style .zs-icon-pin-undo::before {
  content: "\ea72";
}
.zs-master-style .zs-icon-pin-undo-fill::before {
  content: "\ea73";
}
.zs-master-style .zs-icon-play-circle::before {
  content: "\ea74";
}
.zs-master-style .zs-icon-play-circle-fill::before {
  content: "\ea75";
}
.zs-master-style .zs-icon-print::before {
  content: "\ea76";
}
.zs-master-style .zs-icon-priority::before {
  content: "\ea77";
}
.zs-master-style .zs-icon-product-weight::before {
  content: "\ea78";
}
.zs-master-style .zs-icon-product-weight-fill::before {
  content: "\ea79";
}
.zs-master-style .zs-icon-refresh::before {
  content: "\ea7a";
}
.zs-master-style .zs-icon-reminder::before {
  content: "\ea7b";
}
.zs-master-style .zs-icon-save::before {
  content: "\ea7c";
}
.zs-master-style .zs-icon-save-fill::before {
  content: "\ea7d";
}
.zs-master-style .zs-icon-scissors::before {
  content: "\ea7e";
}
.zs-master-style .zs-icon-search::before {
  content: "\ea7f";
}
.zs-master-style .zs-icon-sequence::before {
  content: "\ea80";
}
.zs-master-style .zs-icon-sequence-fill::before {
  content: "\ea81";
}
.zs-master-style .zs-icon-settings::before {
  content: "\ea82";
}
.zs-master-style .zs-icon-settings-fill::before {
  content: "\ea83";
}
.zs-master-style .zs-icon-share::before {
  content: "\ea84";
}
.zs-master-style .zs-icon-share-fill::before {
  content: "\ea85";
}
.zs-master-style .zs-icon-sort::before {
  content: "\ea86";
}
.zs-master-style .zs-icon-sort-ascend::before {
  content: "\ea87";
}
.zs-master-style .zs-icon-sort-descend::before {
  content: "\ea88";
}
.zs-master-style .zs-icon-star::before {
  content: "\ea89";
}
.zs-master-style .zs-icon-star-fill::before {
  content: "\ea8a";
}
.zs-master-style .zs-icon-stop-circle::before {
  content: "\ea8b";
}
.zs-master-style .zs-icon-stop-circle-fill::before {
  content: "\ea8c";
}
.zs-master-style .zs-icon-subtract::before {
  content: "\ea8d";
}
.zs-master-style .zs-icon-subtract-circle::before {
  content: "\ea8e";
}
.zs-master-style .zs-icon-subtract-circle-fill::before {
  content: "\ea8f";
}
.zs-master-style .zs-icon-tag::before {
  content: "\ea90";
}
.zs-master-style .zs-icon-tag-fill::before {
  content: "\ea91";
}
.zs-master-style .zs-icon-timer::before {
  content: "\ea92";
}
.zs-master-style .zs-icon-timer-fill::before {
  content: "\ea93";
}
.zs-master-style .zs-icon-touchpoint-min::before {
  content: "\ea94";
}
.zs-master-style .zs-icon-touchpoint-min-gap::before {
  content: "\ea95";
}
.zs-master-style .zs-icon-touchpoint-qty::before {
  content: "\ea96";
}
.zs-master-style .zs-icon-upload::before {
  content: "\ea97";
}
.zs-master-style .zs-icon-upload-cloud::before {
  content: "\ea98";
}
.zs-master-style .zs-icon-upload-cloud-fill::before {
  content: "\ea99";
}
.zs-master-style .zs-icon-video::before {
  content: "\ea9a";
}
.zs-master-style .zs-icon-video-fill::before {
  content: "\ea9b";
}
.zs-master-style .zs-icon-visualization::before {
  content: "\ea9c";
}
.zs-master-style .zs-icon-alarm::before {
  content: "\ea9d";
}
.zs-master-style .zs-icon-alarm-fill::before {
  content: "\ea9e";
}
.zs-master-style .zs-icon-announce::before {
  content: "\ea9f";
}
.zs-master-style .zs-icon-announce-fill::before {
  content: "\eaa0";
}
.zs-master-style .zs-icon-error-circle::before {
  content: "\eaa1";
}
.zs-master-style .zs-icon-error-circle-fill::before {
  content: "\eaa2";
}
.zs-master-style .zs-icon-error-diamond::before {
  content: "\eaa3";
}
.zs-master-style .zs-icon-error-diamond-fill::before {
  content: "\eaa4";
}
.zs-master-style .zs-icon-error-square::before {
  content: "\eaa5";
}
.zs-master-style .zs-icon-error-square-fill::before {
  content: "\eaa6";
}
.zs-master-style .zs-icon-error-triangle::before {
  content: "\eaa7";
}
.zs-master-style .zs-icon-error-triangle-fill::before {
  content: "\eaa8";
}
.zs-master-style .zs-icon-notification::before {
  content: "\eaa9";
}
.zs-master-style .zs-icon-notification-fill::before {
  content: "\eaaa";
}
.zs-master-style .zs-icon-at-line::before {
  content: "\eaab";
}
.zs-master-style .zs-icon-briefcase::before {
  content: "\eaac";
}
.zs-master-style .zs-icon-briefcase-fill::before {
  content: "\eaad";
}
.zs-master-style .zs-icon-chat::before {
  content: "\eaae";
}
.zs-master-style .zs-icon-chat-fill::before {
  content: "\eaaf";
}
.zs-master-style .zs-icon-field-unit::before {
  content: "\eab0";
}
.zs-master-style .zs-icon-field-unit-fill::before {
  content: "\eab1";
}
.zs-master-style .zs-icon-globe::before {
  content: "\eab2";
}
.zs-master-style .zs-icon-hcp::before {
  content: "\eab3";
}
.zs-master-style .zs-icon-help::before {
  content: "\eab4";
}
.zs-master-style .zs-icon-help-fill::before {
  content: "\eab5";
}
.zs-master-style .zs-icon-home-office::before {
  content: "\eab6";
}
.zs-master-style .zs-icon-home-office-fill::before {
  content: "\eab7";
}
.zs-master-style .zs-icon-hospital::before {
  content: "\eab8";
}
.zs-master-style .zs-icon-hospital-fill::before {
  content: "\eab9";
}
.zs-master-style .zs-icon-info::before {
  content: "\eaba";
}
.zs-master-style .zs-icon-info-fill::before {
  content: "\eabb";
}
.zs-master-style .zs-icon-local-team::before {
  content: "\eabc";
}
.zs-master-style .zs-icon-local-team-fill::before {
  content: "\eabd";
}
.zs-master-style .zs-icon-product::before {
  content: "\eabe";
}
.zs-master-style .zs-icon-product-fill::before {
  content: "\eabf";
}
.zs-master-style .zs-icon-rep-call::before {
  content: "\eac0";
}
.zs-master-style .zs-icon-rep-call-fill::before {
  content: "\eac1";
}
.zs-master-style .zs-icon-team::before {
  content: "\eac2";
}
.zs-master-style .zs-icon-team-fill::before {
  content: "\eac3";
}
.zs-master-style .zs-icon-team-settings::before {
  content: "\eac4";
}
.zs-master-style .zs-icon-team-settings-fill::before {
  content: "\eac5";
}
.zs-master-style .zs-icon-user::before {
  content: "\eac6";
}
.zs-master-style .zs-icon-user-access::before {
  content: "\eac7";
}
.zs-master-style .zs-icon-user-access-fill::before {
  content: "\eac8";
}
.zs-master-style .zs-icon-user-approval::before {
  content: "\eac9";
}
.zs-master-style .zs-icon-user-approval-fill::before {
  content: "\eaca";
}
.zs-master-style .zs-icon-user-fill::before {
  content: "\eacb";
}
.zs-master-style .zs-icon-user-leader::before {
  content: "\eacc";
}
.zs-master-style .zs-icon-user-leader-fill::before {
  content: "\eacd";
}
.zs-master-style .zs-icon-user-permissions::before {
  content: "\eace";
}
.zs-master-style .zs-icon-user-permissions-fill::before {
  content: "\eacf";
}
.zs-master-style .zs-icon-user-profile::before {
  content: "\ead0";
}
.zs-master-style .zs-icon-user-rep::before {
  content: "\ead1";
}
.zs-master-style .zs-icon-user-rep-fill::before {
  content: "\ead2";
}
.zs-master-style .zs-icon-user-role::before {
  content: "\ead3";
}
.zs-master-style .zs-icon-user-role-fill::before {
  content: "\ead4";
}
.zs-master-style .zs-icon-user-selected::before {
  content: "\ead5";
}
.zs-master-style .zs-icon-user-selected-fill::before {
  content: "\ead6";
}
.zs-master-style .zs-icon-user-settings::before {
  content: "\ead7";
}
.zs-master-style .zs-icon-user-settings-fill::before {
  content: "\ead8";
}
.zs-master-style .zs-icon-frame-expand::before {
  content: "\ead9";
}
.zs-master-style .zs-icon-frame-resize::before {
  content: "\eada";
}
.zs-master-style .zs-icon-frame-restore::before {
  content: "\eadb";
}
.zs-master-style .zs-icon-mirror::before {
  content: "\eadc";
}
.zs-master-style .zs-icon-mirror-fill::before {
  content: "\eadd";
}
.zs-master-style .zs-icon-resize-corner::before {
  content: "\eade";
}
.zs-master-style .zs-icon-site::before {
  content: "\eadf";
}
.zs-master-style .zs-icon-site-fill::before {
  content: "\eae0";
}
.zs-master-style .zs-icon-window-new::before {
  content: "\eae1";
}
.zs-master-style .zs-icon-doc::before {
  content: "\eae2";
}
.zs-master-style .zs-icon-doc-fill::before {
  content: "\eae3";
}
.zs-master-style .zs-icon-doc-generic::before {
  content: "\eae4";
}
.zs-master-style .zs-icon-file-attach::before {
  content: "\eae5";
}
.zs-master-style .zs-icon-file-copy::before {
  content: "\eae6";
}
.zs-master-style .zs-icon-file-doc::before {
  content: "\eae7";
}
.zs-master-style .zs-icon-file-edit::before {
  content: "\eae8";
}
.zs-master-style .zs-icon-file-pdf::before {
  content: "\eae9";
}
.zs-master-style .zs-icon-file-ppt::before {
  content: "\eaea";
}
.zs-master-style .zs-icon-file-sql::before {
  content: "\eaeb";
}
.zs-master-style .zs-icon-file-txt::before {
  content: "\eaec";
}
.zs-master-style .zs-icon-file-xls::before {
  content: "\eaed";
}
.zs-master-style .zs-icon-file-xml::before {
  content: "\eaee";
}
.zs-master-style .zs-icon-file-zip::before {
  content: "\eaef";
}
.zs-master-style .zs-icon-folder-closed::before {
  content: "\eaf0";
}
.zs-master-style .zs-icon-folder-closed-fill::before {
  content: "\eaf1";
}
.zs-master-style .zs-icon-folder-open::before {
  content: "\eaf2";
}
.zs-master-style .zs-icon-folder-open-fill::before {
  content: "\eaf3";
}
.zs-master-style .zs-icon-folder-shortcut::before {
  content: "\eaf4";
}
.zs-master-style .zs-icon-folder-shortcut-fill::before {
  content: "\eaf5";
}
.zs-master-style .zs-icon-glossary::before {
  content: "\eaf6";
}
.zs-master-style .zs-icon-glossary-fill::before {
  content: "\eaf7";
}
.zs-master-style .zs-icon-img::before {
  content: "\eaf8";
}
.zs-master-style .zs-icon-note::before {
  content: "\eaf9";
}
.zs-master-style .zs-icon-preview::before {
  content: "\eafa";
}
.zs-master-style .zs-icon-template::before {
  content: "\eafb";
}
.zs-master-style .zs-icon-template-fill::before {
  content: "\eafc";
}
.zs-master-style .zs-icon-upload-file::before {
  content: "\eafd";
}
.zs-master-style .zs-icon-upload-file-fill::before {
  content: "\eafe";
}
.zs-master-style .zs-icon-upload-folder::before {
  content: "\eaff";
}
.zs-master-style .zs-icon-upload-folder-fill::before {
  content: "\eb00";
}
.zs-master-style .zs-icon-diagram-view::before {
  content: "\eb01";
}
.zs-master-style .zs-icon-geo-detail::before {
  content: "\eb02";
}
.zs-master-style .zs-icon-geo-detail-fill::before {
  content: "\eb03";
}
.zs-master-style .zs-icon-geo-level::before {
  content: "\eb04";
}
.zs-master-style .zs-icon-legend-diamond::before {
  content: "\eb05";
}
.zs-master-style .zs-icon-legend-ellipse::before {
  content: "\eb06";
}
.zs-master-style .zs-icon-legend-hexagon::before {
  content: "\eb07";
}
.zs-master-style .zs-icon-legend-pentagon::before {
  content: "\eb08";
}
.zs-master-style .zs-icon-legend-square::before {
  content: "\eb09";
}
.zs-master-style .zs-icon-legend-star::before {
  content: "\eb0a";
}
.zs-master-style .zs-icon-legend-triangle::before {
  content: "\eb0b";
}
.zs-master-style .zs-icon-locate::before {
  content: "\eb0c";
}
.zs-master-style .zs-icon-locate-fill::before {
  content: "\eb0d";
}
.zs-master-style .zs-icon-map-align::before {
  content: "\eb0e";
}
.zs-master-style .zs-icon-map-border-dashed::before {
  content: "\eb0f";
}
.zs-master-style .zs-icon-map-border-dotted::before {
  content: "\eb10";
}
.zs-master-style .zs-icon-map-border-solid::before {
  content: "\eb11";
}
.zs-master-style .zs-icon-map-expand::before {
  content: "\eb12";
}
.zs-master-style .zs-icon-map-focus::before {
  content: "\eb13";
}
.zs-master-style .zs-icon-map-graph::before {
  content: "\eb14";
}
.zs-master-style .zs-icon-map-pin::before {
  content: "\eb15";
}
.zs-master-style .zs-icon-map-pin-fill::before {
  content: "\eb16";
}
.zs-master-style .zs-icon-map-route::before {
  content: "\eb17";
}
.zs-master-style .zs-icon-map-select::before {
  content: "\eb18";
}
.zs-master-style .zs-icon-map-select-lasso::before {
  content: "\eb19";
}
.zs-master-style .zs-icon-map-select-rectangle::before {
  content: "\eb1a";
}
.zs-master-style .zs-icon-map-view::before {
  content: "\eb1b";
}
.zs-master-style .zs-icon-map-view-affiliations::before {
  content: "\eb1c";
}
.zs-master-style .zs-icon-map-view-alignments::before {
  content: "\eb1d";
}
.zs-master-style .zs-icon-map-view-split::before {
  content: "\eb1e";
}
.zs-master-style .zs-icon-map-view-split-fill::before {
  content: "\eb1f";
}
.zs-master-style .zs-icon-map-zoom-adjust::before {
  content: "\eb20";
}
.zs-master-style .zs-icon-map-zoom-rectangle::before {
  content: "\eb21";
}
.zs-master-style .zs-icon-mapsettings::before {
  content: "\eb22";
}
.zs-master-style .zs-icon-measure::before {
  content: "\eb23";
}
.zs-master-style .zs-icon-navigation::before {
  content: "\eb24";
}
.zs-master-style .zs-icon-navigation-fill::before {
  content: "\eb25";
}
.zs-master-style .zs-icon-route::before {
  content: "\eb26";
}
.zs-master-style .zs-icon-territory::before {
  content: "\eb27";
}
.zs-master-style .zs-icon-zoom-to-actual::before {
  content: "\eb28";
}
.zs-master-style .zs-icon-zoom-to-fit::before {
  content: "\eb29";
}
.zs-master-style .zs-icon-data::before {
  content: "\eb2a";
}
.zs-master-style .zs-icon-field-insights::before {
  content: "\eb2b";
}
.zs-master-style .zs-icon-field-insights-fill::before {
  content: "\eb2c";
}
.zs-master-style .zs-icon-home::before {
  content: "\eb2d";
}
.zs-master-style .zs-icon-home-fill::before {
  content: "\eb2e";
}
.zs-master-style .zs-icon-incentives::before {
  content: "\eb2f";
}
.zs-master-style .zs-icon-incentives-fill::before {
  content: "\eb30";
}
.zs-master-style .zs-icon-planning::before {
  content: "\eb31";
}
.zs-master-style .zs-icon-planning-fill::before {
  content: "\eb32";
}
.zs-master-style .zs-icon-reports::before {
  content: "\eb33";
}
.zs-master-style .zs-icon-reports-fill::before {
  content: "\eb34";
}
.zs-master-style .zs-icon-roster::before {
  content: "\eb35";
}
.zs-master-style .zs-icon-roster-fill::before {
  content: "\eb36";
}
.zs-master-style .zs-icon-award-trip::before {
  content: "\eb37";
}
.zs-master-style .zs-icon-chart-pie::before {
  content: "\eb38";
}
.zs-master-style .zs-icon-chart-pie-fill::before {
  content: "\eb39";
}
.zs-master-style .zs-icon-contest::before {
  content: "\eb3a";
}
.zs-master-style .zs-icon-contest-fill::before {
  content: "\eb3b";
}
.zs-master-style .zs-icon-equals::before {
  content: "\eb3c";
}
.zs-master-style .zs-icon-forecast::before {
  content: "\eb3d";
}
.zs-master-style .zs-icon-goal::before {
  content: "\eb3e";
}
.zs-master-style .zs-icon-goal-fill::before {
  content: "\eb3f";
}
.zs-master-style .zs-icon-incentive-dollar::before {
  content: "\eb40";
}
.zs-master-style .zs-icon-incentive-euro::before {
  content: "\eb41";
}
.zs-master-style .zs-icon-incentive-pound::before {
  content: "\eb42";
}
.zs-master-style .zs-icon-incentive-yen::before {
  content: "\eb43";
}
.zs-master-style .zs-icon-product-metric::before {
  content: "\eb44";
}
.zs-master-style .zs-icon-report-calc::before {
  content: "\eb45";
}
.zs-master-style .zs-icon-report-dashboard::before {
  content: "\eb46";
}
.zs-master-style .zs-icon-report-derived::before {
  content: "\eb47";
}
.zs-master-style .zs-icon-report-derived-fill::before {
  content: "\eb48";
}
.zs-master-style .zs-icon-report-generated::before {
  content: "\eb49";
}
.zs-master-style .zs-icon-report-goal::before {
  content: "\eb4a";
}
.zs-master-style .zs-icon-report-preview::before {
  content: "\eb4b";
}
.zs-master-style .zs-icon-report-publish::before {
  content: "\eb4c";
}
.zs-master-style .zs-icon-report-task::before {
  content: "\eb4d";
}
.zs-master-style .zs-icon-report-template::before {
  content: "\eb4e";
}
.zs-master-style .zs-icon-report-trend::before {
  content: "\eb4f";
}
.zs-master-style .zs-icon-report-unpublish::before {
  content: "\eb50";
}
.zs-master-style .zs-icon-report-valid::before {
  content: "\eb51";
}
.zs-master-style .zs-icon-revenue::before {
  content: "\eb52";
}
.zs-master-style .zs-icon-spend::before {
  content: "\eb53";
}
.zs-master-style .zs-icon-columns::before {
  content: "\eb54";
}
.zs-master-style .zs-icon-columns-fill::before {
  content: "\eb55";
}
.zs-master-style .zs-icon-data-arrow-down::before {
  content: "\eb56";
}
.zs-master-style .zs-icon-data-arrow-up::before {
  content: "\eb57";
}
.zs-master-style .zs-icon-data-flow::before {
  content: "\eb58";
}
.zs-master-style .zs-icon-data-flow-fill::before {
  content: "\eb59";
}
.zs-master-style .zs-icon-data-list::before {
  content: "\eb5a";
}
.zs-master-style .zs-icon-data-publish::before {
  content: "\eb5b";
}
.zs-master-style .zs-icon-data-publish-done::before {
  content: "\eb5c";
}
.zs-master-style .zs-icon-data-set::before {
  content: "\eb5d";
}
.zs-master-style .zs-icon-data-set-fill::before {
  content: "\eb5e";
}
.zs-master-style .zs-icon-data-zero-negative::before {
  content: "\eb5f";
}
.zs-master-style .zs-icon-data-zero-positive::before {
  content: "\eb60";
}
.zs-master-style .zs-icon-table::before {
  content: "\eb61";
}
.zs-master-style .zs-icon-table-id::before {
  content: "\eb62";
}
.zs-master-style .zs-icon-table-id-fill::before {
  content: "\eb63";
}
.zs-master-style .zs-icon-table-key::before {
  content: "\eb64";
}
.zs-master-style .zs-icon-table-preview::before {
  content: "\eb65";
}
.zs-master-style .zs-icon-logo-apache-spark::before {
  content: "\eb66";
}
.zs-master-style .zs-icon-logo-codepen::before {
  content: "\eb67";
}
.zs-master-style .zs-icon-control::before {
  content: "\eb68";
}
.zs-master-style .zs-icon-legacy::before {
  content: "\eb69";
}
/* 
** Modern Typography styles
*/
.zs-master-style {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #2F2C3C;
}
.zs-master-style .zs-h1,
.zs-master-style h1 {
  font-size: 2em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: -0.046em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-h2,
.zs-master-style h2 {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33;
  letter-spacing: -0.021em;
  margin: 1.33333333em 0em 1.33333333em 0em;
}
.zs-master-style .zs-h3,
.zs-master-style h3 {
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.008em;
  margin: 1.2em 0em 1.2em 0em;
}
.zs-master-style .zs-h4,
.zs-master-style h4 {
  font-size: 1em;
  font-weight: 700;
  font-style: normal;
  opacity: 1;
  line-height: 1.5;
  letter-spacing: -0.009em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-h5,
.zs-master-style h5 {
  font-size: 0.875em;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: 0.011em;
  margin: 0.57142857em 0em 0.57142857em 0em;
}
.zs-master-style .zs-h6,
.zs-master-style h6 {
  font-size: 0.75em;
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: -0.013em;
  margin: 0.33333333em 0em 0.33333333em 0em;
}
.zs-master-style .zs-super-hero {
  font-size: 4em;
  line-height: 1.5;
  letter-spacing: -0.047em;
}
.zs-master-style .zs-hero {
  font-size: 3em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.003em;
  margin: 0em 0em 1em 0em;
}
.zs-master-style .zs-subtitle-1 {
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: 0.013em;
}
.zs-master-style .zs-subtitle-2 {
  font-size: 0.875em;
  line-height: 1.44;
  letter-spacing: 0.014em;
}
.zs-master-style .zs-body {
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: -0.009em;
  margin: 1em 0em 1em 0em;
}
.zs-master-style .zs-body-small {
  font-size: 0.875em;
  line-height: 1.4;
  letter-spacing: -0.011em;
  margin: 1.14285714em 0em 1.14285714em 0em;
}
.zs-master-style .zs-body-extra-small {
  font-size: 0.75em;
  line-height: 1.63;
  letter-spacing: -0.03em;
}
.zs-master-style .zs-caption-1 {
  font-size: 0.75em;
  line-height: 1.5;
  letter-spacing: -0.013em;
  margin: -1em 0em 0.66666667em 0em;
}
.zs-master-style .zs-caption-2 {
  font-size: 0.625em;
  line-height: 1.5;
  letter-spacing: -0.015em;
  margin: -0.8em 0em 0.8em 0em;
}
.zs-master-style .zs-caption-2 + .zs-caption-2 {
  margin: 0.8em 0em 0.8em 0em;
}
.zs-master-style .zs-overline {
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5;
  text-transform: uppercase;
  letter-spacing: 0.4em;
}
.zs-master-style .zs-label-1 {
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.011em;
}
.zs-master-style .zs-label-2 {
  font-size: 0.625em;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.013em;
}
.zs-master-style .zs-label {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 2.5px;
}
.zs-master-style .zs-input-label {
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.15px;
}
.zs-master-style .zs-header-block {
  margin: 2em 0em 2em 0em;
}
.zs-master-style .zs-header-block .zs-overline {
  margin: 0em 0em 0.4em 0em;
}
.zs-master-style .zs-header-block .zs-h1,
.zs-master-style .zs-header-block h1 {
  margin: 0em 0em 0.125em 0em;
}
.zs-master-style .zs-header-block .zs-h2,
.zs-master-style .zs-header-block h2 {
  margin: 0em 0em 0.16666667em 0em;
}
.zs-master-style .zs-header-block .zs-subtitle-1 {
  margin: 0em 0em 0.25em 0em;
}
.zs-master-style a {
  color: #27A6A4;
  text-decoration: none;
  letter-spacing: -0.013em;
}
.zs-master-style a:visited {
  color: #2D535F;
}
.zs-master-style a:focus {
  color: #27A6A4;
  outline-offset: -2px;
  outline: 2px solid;
  outline-color: #27A6A4;
  z-index: 1;
}
.zs-master-style a:hover {
  color: #27A6A4;
  text-decoration: none;
}
.zs-master-style a:active {
  color: #2F6F7B;
}
.zs-master-style a.zs-disabled,
.zs-master-style a[disabled],
.zs-master-style a.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: #B2B0B6;
}
.zs-master-style .zs-link-action {
  color: #2F2C3C;
  background: transparent;
}
.zs-master-style .zs-link-action:visited {
  color: inherit;
  background: inherit;
}
.zs-master-style .zs-link-action:focus {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-master-style .zs-link-action:hover,
.zs-master-style .zs-link-action:hover:visited {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-master-style .zs-link-action:active,
.zs-master-style .zs-link-action:active:visited {
  color: #ffffff;
  background: #2D8B93;
}
.zs-master-style .zs-link-action.zs-active,
.zs-master-style .zs-link-action.zs-active:visited {
  color: #ffffff;
  background: #27A6A4;
}
.zs-master-style .zs-link-action.zs-disabled,
.zs-master-style .zs-link-action[disabled],
.zs-master-style .zs-link-action.zs-calendar > table td[disabled] {
  color: #B2B0B6;
}
.zs-master-style ul.zs-list,
.zs-master-style ol.zs-list {
  margin: 0em 0em 0em 2em;
}
.zs-master-style ul.zs-list > li,
.zs-master-style ol.zs-list > li {
  margin: 0em 0em 0.25em 0em;
  padding: 0em 0em 0em 0.5em;
  line-height: 1.44;
  letter-spacing: -0.013em;
}
.zs-master-style ul.zs-list > li:first-child,
.zs-master-style ol.zs-list > li:first-child {
  margin: 0.5em 0em 0.25em 0em;
}
.zs-master-style ul.zs-list h4,
.zs-master-style ol.zs-list h4 {
  margin: 1.5em 0em 0em 0.5em;
}
.zs-master-style ul.zs-list ul,
.zs-master-style ol.zs-list ul,
.zs-master-style ul.zs-list ol,
.zs-master-style ol.zs-list ol {
  margin: 0em 0em 0em 2.5em;
}
.zs-master-style ul.zs-bullet-list {
  list-style-type: none;
  margin: 0;
}
.zs-master-style ul.zs-bullet-list > li {
  display: flex;
  align-items: center;
  padding-left: 0;
}
.zs-master-style ul.zs-bullet-list > li:before {
  font-family: 'zsIcons';
  font-size: 1.5em;
  content: "\ea23";
  padding: 0em 0em 0em 0.66666667em;
  line-height: 1;
}
.zs-master-style ul.zs-bullet-list h4 {
  margin: 1.5em 0em 0em 2.5em;
}
.zs-master-style ul.zs-bullet-list ul {
  margin: 0em 0em 0em 2.5em;
}
.zs-master-style em,
.zs-master-style b {
  font-weight: 700;
}
.zs-master-style i {
  font-style: italic;
}
.zs-master-style p,
.zs-master-style .zs-paragraph {
  margin: 0.75em 0em 0.5em 0em;
}
.zs-master-style hr {
  border-bottom: 1px solid #DEDCDE;
  *zoom: 1;
  display: block !important;
}
.zs-master-style hr:before,
.zs-master-style hr:after {
  content: "";
  display: table;
}
.zs-master-style hr:after {
  clear: both;
}
.zs-master-style h1:first-child,
.zs-master-style h2:first-child,
.zs-master-style h3:first-child,
.zs-master-style h4:first-child,
.zs-master-style .zs-h1:first-child,
.zs-master-style .zs-h2:first-child,
.zs-master-style .zs-h3:first-child,
.zs-master-style .zs-h4:first-child,
.zs-master-style p:first-child,
.zs-master-style .zs-paragraph:first-child,
.zs-master-style table:first-child {
  margin-top: 0;
}
.zs-master-style > h1:last-child,
.zs-master-style > h2:last-child,
.zs-master-style > h3:last-child,
.zs-master-style > h4:last-child,
.zs-master-style > .zs-h1:last-child,
.zs-master-style > .zs-h2:last-child,
.zs-master-style > .zs-h3:last-child,
.zs-master-style > .zs-h4:last-child,
.zs-master-style > p:last-child,
.zs-master-style > .zs-paragraph:last-child,
.zs-master-style > table:last-child {
  margin-bottom: 0;
}
.zs-master-style,
.zs-master-style *,
.zs-master-style:before,
.zs-master-style:after,
.zs-master-style *:before,
.zs-master-style *:after {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
}
.zs-master-style,
.zs-master-style * {
  font-family: "Open Sans", sans-serif;
}
.zs-custom-scrollbar {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.zs-custom-scrollbar ::-webkit-scrollbar,
.zs-custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
.zs-custom-scrollbar ::-webkit-scrollbar-track,
.zs-custom-scrollbar::-webkit-scrollbar-track {
  background: #F4F3F3;
}
.zs-custom-scrollbar ::-webkit-scrollbar-thumb,
.zs-custom-scrollbar::-webkit-scrollbar-thumb {
  background: #B2B0B6;
  border-radius: 8px;
  border: 2px solid #F4F3F3;
}
.zs-custom-scrollbar ::-webkit-scrollbar-thumb:hover,
.zs-custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #87848D;
}
.zs-master-style .zs-interactive-primary,
.zs-master-style .zs-icon.zs-interactive-primary {
  color: #27A6A4;
}
.zs-master-style .zs-interactive-primary:visited,
.zs-master-style .zs-icon.zs-interactive-primary:visited {
  color: #27A6A4;
}
.zs-master-style .zs-interactive-primary:focus,
.zs-master-style .zs-icon.zs-interactive-primary:focus {
  color: #2F6F7B;
  outline: 2px solid #27A6A4;
}
.zs-master-style .zs-interactive-primary:hover,
.zs-master-style .zs-icon.zs-interactive-primary:hover {
  color: #62D2D1;
}
.zs-master-style .zs-interactive-primary:active,
.zs-master-style .zs-icon.zs-interactive-primary:active {
  color: #2D535F;
}
.zs-master-style .zs-interactive-secondary,
.zs-master-style .zs-icon.zs-interactive-secondary {
  color: #716E79;
}
.zs-master-style .zs-interactive-secondary:visited,
.zs-master-style .zs-icon.zs-interactive-secondary:visited {
  color: #716E79;
}
.zs-master-style .zs-interactive-secondary:focus,
.zs-master-style .zs-icon.zs-interactive-secondary:focus {
  color: #454250;
}
.zs-master-style .zs-interactive-secondary:hover,
.zs-master-style .zs-icon.zs-interactive-secondary:hover {
  color: #9C9AA1;
}
.zs-master-style .zs-interactive-secondary:active,
.zs-master-style .zs-icon.zs-interactive-secondary:active {
  color: #2F2C3C;
}
.zs-master-style .zs-interactive-primary.zs-disabled,
.zs-master-style .zs-interactive-secondary.zs-disabled,
.zs-master-style .zs-interactive-primary.zs-calendar > table td[disabled],
.zs-master-style .zs-interactive-secondary.zs-calendar > table td[disabled] {
  color: #B2B0B6;
}
.zs-master-style .zs-interactive-primary.zs-danger,
.zs-master-style .zs-interactive-secondary.zs-danger {
  color: #B21111;
}
.zs-master-style .zs-interactive-primary.zs-danger:visited,
.zs-master-style .zs-interactive-secondary.zs-danger:visited {
  color: #B21111;
}
.zs-master-style .zs-interactive-primary.zs-danger:focus,
.zs-master-style .zs-interactive-secondary.zs-danger:focus {
  color: #892208;
}
.zs-master-style .zs-interactive-primary.zs-danger:hover,
.zs-master-style .zs-interactive-secondary.zs-danger:hover {
  color: #F27755;
}
.zs-master-style .zs-interactive-primary.zs-danger:active,
.zs-master-style .zs-interactive-secondary.zs-danger:active {
  color: #5C1A0B;
}
.zs-master-style .zs-interactive-primary.zs-success,
.zs-master-style .zs-interactive-secondary.zs-success {
  color: #00AA67;
}
.zs-master-style .zs-interactive-primary.zs-success:visited,
.zs-master-style .zs-interactive-secondary.zs-success:visited {
  color: #00AA67;
}
.zs-master-style .zs-interactive-primary.zs-success:focus,
.zs-master-style .zs-interactive-secondary.zs-success:focus {
  color: #0A6E5E;
}
.zs-master-style .zs-interactive-primary.zs-success:hover,
.zs-master-style .zs-interactive-secondary.zs-success:hover {
  color: #54D7AF;
}
.zs-master-style .zs-interactive-primary.zs-success:active,
.zs-master-style .zs-interactive-secondary.zs-success:active {
  color: #0C4846;
}
.zs-master-style .zs-interactive-primary.zs-warning,
.zs-master-style .zs-interactive-secondary.zs-warning {
  color: #E3A900;
}
.zs-master-style .zs-interactive-primary.zs-warning:visited,
.zs-master-style .zs-interactive-secondary.zs-warning:visited {
  color: #E3A900;
}
.zs-master-style .zs-interactive-primary.zs-warning:focus,
.zs-master-style .zs-interactive-secondary.zs-warning:focus {
  color: #8A640C;
}
.zs-master-style .zs-interactive-primary.zs-warning:hover,
.zs-master-style .zs-interactive-secondary.zs-warning:hover {
  color: #FDCA63;
}
.zs-master-style .zs-interactive-primary.zs-warning:active,
.zs-master-style .zs-interactive-secondary.zs-warning:active {
  color: #563F0E;
}
.zs-master-style .zs-interactive-primary.zs-info,
.zs-master-style .zs-interactive-secondary.zs-info {
  color: #2B44C7;
}
.zs-master-style .zs-interactive-primary.zs-info:visited,
.zs-master-style .zs-interactive-secondary.zs-info:visited {
  color: #2B44C7;
}
.zs-master-style .zs-interactive-primary.zs-info:focus,
.zs-master-style .zs-interactive-secondary.zs-info:focus {
  color: #141187;
}
.zs-master-style .zs-interactive-primary.zs-info:hover,
.zs-master-style .zs-interactive-secondary.zs-info:hover {
  color: #7E92E9;
}
.zs-master-style .zs-interactive-primary.zs-info:active,
.zs-master-style .zs-interactive-secondary.zs-info:active {
  color: #160C60;
}
.zs-clear {
  *zoom: 1;
  display: block !important;
}
.zs-clear:before,
.zs-clear:after {
  content: "";
  display: table;
}
.zs-clear:after {
  clear: both;
}
.zs-master-style .zs-disabled,
.zs-master-style [disabled],
.zs-master-style .zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}
.zs-highlight,
mark {
  background-color: #FFD680;
  color: inherit;
}
/* IE11 scroll fix */
body {
  -ms-overflow-style: scrollbar;
}
.zs-master-style input::-ms-clear {
  display: none;
}
.zs-display-inline-grid {
  display: inline-grid !important;
}
.zs-display-grid {
  display: grid !important;
}
.zs-display-inline-flex {
  display: inline-flex !important;
}
.zs-display-flex {
  display: flex !important;
}
.zs-display-table-cell {
  display: table-cell !important;
}
.zs-display-table-row {
  display: table-row !important;
}
.zs-display-table {
  display: table !important;
}
.zs-display-none {
  display: none !important;
}
.zs-display-inline-block {
  display: inline-block !important;
}
.zs-display-inline {
  display: inline !important;
}
.zs-display-block {
  display: block !important;
}
.zs-accordion > [expandable-header] {
  cursor: pointer;
}
.zs-accordion > [expandable-body] {
  overflow: hidden;
}
.zs-accordion[animate] [expandable-body] {
  transition: max-height 0.5s;
}
.zs-accordion[animate] > [expandable-header] [expandable-icon] {
  transition: transform 0.5s;
}
.zs-accordion[expanded] [expandable-header-text] {
  color: #1A1628;
}
.zs-accordion[expanded] > [expandable-header] [expandable-icon] {
  transform: rotate(-180deg);
}
.zs-accordion {
  margin: 0em 0em 0.125em 0em;
}
.zs-accordion .zs-accordion-bar {
  border: solid 0.0625em #DEDCDE;
  box-shadow: 0.125em 0.125em 0.125em #DEDCDE;
  cursor: pointer;
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.zs-accordion .zs-accordion-panel {
  background: #F4F3F3;
  border: solid 0.0625em #DEDCDE;
  padding: 1em 1em 1em 1em;
  margin: 0em 1em 0em 1em;
}
.zs-bg-navigation-overlay-horizontal {
  background: linear-gradient(89.99deg, #1A1628 -0.86%, #1A1628 -0.85%, rgba(2, 45, 66, 0.1) 6.5%, rgba(113, 110, 121, 0.4) 45.89%, #2F2C3C 99.99%) !important;
}
.zs-bg-navigation-overlay-vertical {
  background: linear-gradient(180deg, #1A1628 -1.83%, #1A1628 -1.82%, rgba(2, 45, 66, 0.1) 6.39%, rgba(113, 110, 121, 0.4) 50.47%, #2F2C3C 111%) !important;
}
.zs-bg-navigation {
  background: #1A1628 !important;
}
.zs-bg-neutral-overlay-90 {
  background: rgba(26, 22, 40, 0.9) !important;
}
.zs-bg-neutral-overlay-80 {
  background: rgba(26, 22, 40, 0.8) !important;
}
.zs-bg-neutral-overlay-70 {
  background: rgba(26, 22, 40, 0.7) !important;
}
.zs-bg-neutral-overlay-60 {
  background: rgba(26, 22, 40, 0.6) !important;
}
.zs-bg-neutral-overlay-50 {
  background: rgba(26, 22, 40, 0.5) !important;
}
.zs-bg-neutral-overlay-40 {
  background: rgba(26, 22, 40, 0.4) !important;
}
.zs-bg-neutral-overlay-30 {
  background: rgba(26, 22, 40, 0.3) !important;
}
.zs-bg-neutral-overlay-20 {
  background: rgba(26, 22, 40, 0.2) !important;
}
.zs-bg-neutral-overlay-10 {
  background: rgba(26, 22, 40, 0.1) !important;
}
.zs-bg-tertiary-overlay-90 {
  background: rgba(2, 45, 66, 0.9) !important;
}
.zs-bg-tertiary-overlay-80 {
  background: rgba(2, 45, 66, 0.8) !important;
}
.zs-bg-tertiary-overlay-70 {
  background: rgba(2, 45, 66, 0.7) !important;
}
.zs-bg-tertiary-overlay-60 {
  background: rgba(2, 45, 66, 0.6) !important;
}
.zs-bg-tertiary-overlay-50 {
  background: rgba(2, 45, 66, 0.5) !important;
}
.zs-bg-tertiary-overlay-40 {
  background: rgba(2, 45, 66, 0.4) !important;
}
.zs-bg-tertiary-overlay-30 {
  background: rgba(2, 45, 66, 0.3) !important;
}
.zs-bg-tertiary-overlay-20 {
  background: rgba(2, 45, 66, 0.2) !important;
}
.zs-bg-tertiary-overlay-10 {
  background: rgba(2, 45, 66, 0.1) !important;
}
.zs-bg-secondary-overlay-90 {
  background: rgba(236, 114, 0, 0.9) !important;
}
.zs-bg-secondary-overlay-80 {
  background: rgba(236, 114, 0, 0.8) !important;
}
.zs-bg-secondary-overlay-70 {
  background: rgba(236, 114, 0, 0.7) !important;
}
.zs-bg-secondary-overlay-60 {
  background: rgba(236, 114, 0, 0.6) !important;
}
.zs-bg-secondary-overlay-50 {
  background: rgba(236, 114, 0, 0.5) !important;
}
.zs-bg-secondary-overlay-40 {
  background: rgba(236, 114, 0, 0.4) !important;
}
.zs-bg-secondary-overlay-30 {
  background: rgba(236, 114, 0, 0.3) !important;
}
.zs-bg-secondary-overlay-20 {
  background: rgba(236, 114, 0, 0.2) !important;
}
.zs-bg-secondary-overlay-10 {
  background: rgba(236, 114, 0, 0.1) !important;
}
.zs-bg-primary-overlay-90 {
  background: rgba(39, 166, 164, 0.9) !important;
}
.zs-bg-primary-overlay-80 {
  background: rgba(39, 166, 164, 0.8) !important;
}
.zs-bg-primary-overlay-70 {
  background: rgba(39, 166, 164, 0.7) !important;
}
.zs-bg-primary-overlay-60 {
  background: rgba(39, 166, 164, 0.6) !important;
}
.zs-bg-primary-overlay-50 {
  background: rgba(39, 166, 164, 0.5) !important;
}
.zs-bg-primary-overlay-40 {
  background: rgba(39, 166, 164, 0.4) !important;
}
.zs-bg-primary-overlay-30 {
  background: rgba(39, 166, 164, 0.3) !important;
}
.zs-bg-primary-overlay-20 {
  background: rgba(39, 166, 164, 0.2) !important;
}
.zs-bg-primary-overlay-10 {
  background: rgba(39, 166, 164, 0.1) !important;
}
.zs-bg-overlay-90 {
  background: rgba(255, 255, 255, 0.9) !important;
}
.zs-bg-overlay-80 {
  background: rgba(255, 255, 255, 0.8) !important;
}
.zs-bg-overlay-70 {
  background: rgba(255, 255, 255, 0.7) !important;
}
.zs-bg-overlay-60 {
  background: rgba(255, 255, 255, 0.6) !important;
}
.zs-bg-overlay-50 {
  background: rgba(255, 255, 255, 0.5) !important;
}
.zs-bg-overlay-40 {
  background: rgba(255, 255, 255, 0.4) !important;
}
.zs-bg-overlay-30 {
  background: rgba(255, 255, 255, 0.3) !important;
}
.zs-bg-overlay-20 {
  background: rgba(255, 255, 255, 0.2) !important;
}
.zs-bg-overlay-10 {
  background: rgba(255, 255, 255, 0.1) !important;
}
.zs-bg-table-zebra-odd {
  background: #FAFAFA !important;
}
.zs-bg-table-zebra-even {
  background: #F4F3F3 !important;
}
.zs-bg-table-data-color-12 {
  background: #FFFFA9 !important;
}
.zs-bg-table-data-color-11 {
  background: #FECDCF !important;
}
.zs-bg-table-data-color-10 {
  background: #8BFFF5 !important;
}
.zs-bg-table-data-color-9 {
  background: #FE9766 !important;
}
.zs-bg-table-data-color-8 {
  background: #E9FFAC !important;
}
.zs-bg-table-data-color-7 {
  background: #A8C6FF !important;
}
.zs-bg-table-data-color-6 {
  background: #FFF6C7 !important;
}
.zs-bg-table-data-color-5 {
  background: #FDBEF0 !important;
}
.zs-bg-table-data-color-4 {
  background: #D4F5D7 !important;
}
.zs-bg-table-data-color-3 {
  background: #E0C6FF !important;
}
.zs-bg-table-data-color-2 {
  background: #AFDCFC !important;
}
.zs-bg-table-data-color-1 {
  background: #FFE0C2 !important;
}
.zs-bg-transparent {
  background: transparent !important;
}
.zs-bg-surface-5 {
  background: #1A1628 !important;
}
.zs-bg-surface-4 {
  background: #454250 !important;
}
.zs-bg-surface-3 {
  background: #716E79 !important;
}
.zs-bg-surface-2 {
  background: #F4F3F3 !important;
}
.zs-bg-surface-1 {
  background: #FAFAFA !important;
}
.zs-bg-data-color-12 {
  background: #E1F65D !important;
}
.zs-bg-data-color-11 {
  background: #5AD767 !important;
}
.zs-bg-data-color-10 {
  background: #18F3CC !important;
}
.zs-bg-data-color-9 {
  background: #70C2FE !important;
}
.zs-bg-data-color-8 {
  background: #5648FB !important;
}
.zs-bg-data-color-7 {
  background: #6328B8 !important;
}
.zs-bg-data-color-6 {
  background: #ED39DB !important;
}
.zs-bg-data-color-5 {
  background: #FD595F !important;
}
.zs-bg-data-color-4 {
  background: #EB6620 !important;
}
.zs-bg-data-color-3 {
  background: #FF9836 !important;
}
.zs-bg-data-color-2 {
  background: #FFC62B !important;
}
.zs-bg-data-color-1 {
  background: #FFE949 !important;
}
.zs-bg-border {
  background: #DEDCDE !important;
}
.zs-bg-shadow {
  background: #2F2C3C !important;
}
.zs-bg-selected {
  background: #027AFF !important;
}
.zs-bg-neutral-inverse {
  background: #FAFAFA !important;
}
.zs-bg-info-inverse {
  background: #EEEEFF !important;
}
.zs-bg-warning-inverse {
  background: #FFF9F1 !important;
}
.zs-bg-error-inverse {
  background: #FFEDE9 !important;
}
.zs-bg-success-inverse {
  background: #F1FEFF !important;
}
.zs-bg-info-secondary {
  background: #A6B2F3 !important;
}
.zs-bg-warning-secondary {
  background: #E3A900 !important;
}
.zs-bg-error-secondary {
  background: #FFB6A5 !important;
}
.zs-bg-success-secondary {
  background: #00AA67 !important;
}
.zs-bg-neutral {
  background: #FAFAFA !important;
}
.zs-bg-info {
  background: #2B44C7 !important;
}
.zs-bg-warning {
  background: #E3A900 !important;
}
.zs-bg-error {
  background: #B21111 !important;
}
.zs-bg-success {
  background: #00AA67 !important;
}
.zs-bg-link-hover {
  background: #27A6A4 !important;
}
.zs-bg-link {
  background: #27A6A4 !important;
}
.zs-bg-disabled {
  background: #B2B0B6 !important;
}
.zs-bg-standard {
  background: #ffffff !important;
}
.zs-text-disabled {
  color: #B2B0B6 !important;
}
.zs-text-medium {
  color: #454250 !important;
}
.zs-text-caption {
  color: #716E79 !important;
}
.zs-text-headline {
  color: #1A1628 !important;
}
.zs-text-inverse {
  color: #FAFAFA !important;
}
.zs-text-data-color-12 {
  color: #E1F65D !important;
}
.zs-text-data-color-11 {
  color: #5AD767 !important;
}
.zs-text-data-color-10 {
  color: #18F3CC !important;
}
.zs-text-data-color-9 {
  color: #70C2FE !important;
}
.zs-text-data-color-8 {
  color: #5648FB !important;
}
.zs-text-data-color-7 {
  color: #6328B8 !important;
}
.zs-text-data-color-6 {
  color: #ED39DB !important;
}
.zs-text-data-color-5 {
  color: #FD595F !important;
}
.zs-text-data-color-4 {
  color: #EB6620 !important;
}
.zs-text-data-color-3 {
  color: #FF9836 !important;
}
.zs-text-data-color-2 {
  color: #FFC62B !important;
}
.zs-text-data-color-1 {
  color: #FFE949 !important;
}
.zs-text-nav {
  color: #27A6A4 !important;
}
.zs-text-neutral {
  color: #1A1628 !important;
}
.zs-text-info {
  color: #2B44C7 !important;
}
.zs-text-warning {
  color: #E3A900 !important;
}
.zs-text-success {
  color: #00AA67 !important;
}
.zs-text-error {
  color: #B21111 !important;
}
.zs-text-default {
  color: #DEDCDE !important;
}
.zs-position-fixed {
  position: fixed !important;
}
.zs-position-absolute {
  position: absolute !important;
}
.zs-position-relative {
  position: relative !important;
}
.zs-position-static {
  position: static !important;
}
[class*="zs-grid-"] > .zs-col-1:nth-of-type(n) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
.zs-col-span-1 {
  grid-column-end: span 1;
  -ms-grid-column-span: 1;
}
[class*="zs-grid-"] > .zs-col-2:nth-of-type(n) {
  grid-column-start: 2;
  -ms-grid-column: 2;
}
.zs-col-span-2 {
  grid-column-end: span 2;
  -ms-grid-column-span: 2;
}
[class*="zs-grid-"] > .zs-col-3:nth-of-type(n) {
  grid-column-start: 3;
  -ms-grid-column: 3;
}
.zs-col-span-3 {
  grid-column-end: span 3;
  -ms-grid-column-span: 3;
}
[class*="zs-grid-"] > .zs-col-4:nth-of-type(n) {
  grid-column-start: 4;
  -ms-grid-column: 4;
}
.zs-col-span-4 {
  grid-column-end: span 4;
  -ms-grid-column-span: 4;
}
[class*="zs-grid-"] > .zs-col-5:nth-of-type(n) {
  grid-column-start: 5;
  -ms-grid-column: 5;
}
.zs-col-span-5 {
  grid-column-end: span 5;
  -ms-grid-column-span: 5;
}
[class*="zs-grid-"] > .zs-col-6:nth-of-type(n) {
  grid-column-start: 6;
  -ms-grid-column: 6;
}
.zs-col-span-6 {
  grid-column-end: span 6;
  -ms-grid-column-span: 6;
}
[class*="zs-grid-"] > .zs-row-1:nth-of-type(n) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
.zs-row-span-1 {
  grid-row-end: span 1;
  -ms-grid-row-span: 1;
}
[class*="zs-grid-"] > .zs-row-2:nth-of-type(n) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
.zs-row-span-2 {
  grid-row-end: span 2;
  -ms-grid-row-span: 2;
}
[class*="zs-grid-"] > .zs-row-3:nth-of-type(n) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
.zs-row-span-3 {
  grid-row-end: span 3;
  -ms-grid-row-span: 3;
}
[class*="zs-grid-"] > .zs-row-4:nth-of-type(n) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
.zs-row-span-4 {
  grid-row-end: span 4;
  -ms-grid-row-span: 4;
}
[class*="zs-grid-"] > .zs-row-5:nth-of-type(n) {
  grid-row-start: 5;
  -ms-grid-row: 5;
}
.zs-row-span-5 {
  grid-row-end: span 5;
  -ms-grid-row-span: 5;
}
[class*="zs-grid-"] > .zs-row-6:nth-of-type(n) {
  grid-row-start: 6;
  -ms-grid-row: 6;
}
.zs-row-span-6 {
  grid-row-end: span 6;
  -ms-grid-row-span: 6;
}
.zs-grid-1cols-auto {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto ;
  -ms-grid-columns: auto ;
}
.zs-grid-1cols-equal {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr ;
  -ms-grid-columns: 1fr ;
}
.zs-grid-1cols {
  display: -ms-grid;
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) ;
  -ms-grid-columns: minmax(min-content, 1fr) ;
}
.zs-grid-2cols-auto {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto auto ;
  -ms-grid-columns: auto auto ;
}
.zs-grid-2cols-equal {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr ;
  -ms-grid-columns: 1fr 1fr ;
}
.zs-grid-2cols {
  display: -ms-grid;
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-3cols-auto {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto auto auto ;
  -ms-grid-columns: auto auto auto ;
}
.zs-grid-3cols-equal {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr ;
  -ms-grid-columns: 1fr 1fr 1fr ;
}
.zs-grid-3cols {
  display: -ms-grid;
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-4cols-auto {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto auto auto auto ;
  -ms-grid-columns: auto auto auto auto ;
}
.zs-grid-4cols-equal {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr ;
  -ms-grid-columns: 1fr 1fr 1fr 1fr ;
}
.zs-grid-4cols {
  display: -ms-grid;
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-5cols-auto {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto auto auto auto auto ;
  -ms-grid-columns: auto auto auto auto auto ;
}
.zs-grid-5cols-equal {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr ;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr ;
}
.zs-grid-5cols {
  display: -ms-grid;
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-6cols-auto {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto ;
  -ms-grid-columns: auto auto auto auto auto auto ;
}
.zs-grid-6cols-equal {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr ;
}
.zs-grid-6cols {
  display: -ms-grid;
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-columns: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-1rows-auto {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto ;
  -ms-grid-rows: auto ;
}
.zs-grid-1rows-equal {
  display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr ;
  -ms-grid-rows: 1fr ;
}
.zs-grid-1rows {
  display: -ms-grid;
  display: grid;
  grid-template-rows: minmax(min-content, 1fr) ;
  -ms-grid-rows: minmax(min-content, 1fr) ;
}
.zs-grid-2rows-auto {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto auto ;
  -ms-grid-rows: auto auto ;
}
.zs-grid-2rows-equal {
  display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr 1fr ;
  -ms-grid-rows: 1fr 1fr ;
}
.zs-grid-2rows {
  display: -ms-grid;
  display: grid;
  grid-template-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-3rows-auto {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto auto auto ;
  -ms-grid-rows: auto auto auto ;
}
.zs-grid-3rows-equal {
  display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr ;
  -ms-grid-rows: 1fr 1fr 1fr ;
}
.zs-grid-3rows {
  display: -ms-grid;
  display: grid;
  grid-template-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-4rows-auto {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto auto auto auto ;
  -ms-grid-rows: auto auto auto auto ;
}
.zs-grid-4rows-equal {
  display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr ;
  -ms-grid-rows: 1fr 1fr 1fr 1fr ;
}
.zs-grid-4rows {
  display: -ms-grid;
  display: grid;
  grid-template-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-5rows-auto {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto auto auto auto auto ;
  -ms-grid-rows: auto auto auto auto auto ;
}
.zs-grid-5rows-equal {
  display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr ;
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr ;
}
.zs-grid-5rows {
  display: -ms-grid;
  display: grid;
  grid-template-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
.zs-grid-6rows-auto {
  display: -ms-grid;
  display: grid;
  grid-template-rows: auto auto auto auto auto auto ;
  -ms-grid-rows: auto auto auto auto auto auto ;
}
.zs-grid-6rows-equal {
  display: -ms-grid;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr ;
  -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr ;
}
.zs-grid-6rows {
  display: -ms-grid;
  display: grid;
  grid-template-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
  -ms-grid-rows: minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) minmax(min-content, 1fr) ;
}
[class*="zs-grid-1cols"] :nth-of-type(1n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 2) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 3) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 4) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 5) {
  grid-row-start: 5;
  -ms-grid-row: 5;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 6) {
  grid-row-start: 6;
  -ms-grid-row: 6;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 7) {
  grid-row-start: 7;
  -ms-grid-row: 7;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 8) {
  grid-row-start: 8;
  -ms-grid-row: 8;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 9) {
  grid-row-start: 9;
  -ms-grid-row: 9;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 10) {
  grid-row-start: 10;
  -ms-grid-row: 10;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 11) {
  grid-row-start: 11;
  -ms-grid-row: 11;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 12) {
  grid-row-start: 12;
  -ms-grid-row: 12;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 13) {
  grid-row-start: 13;
  -ms-grid-row: 13;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 14) {
  grid-row-start: 14;
  -ms-grid-row: 14;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 15) {
  grid-row-start: 15;
  -ms-grid-row: 15;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 16) {
  grid-row-start: 16;
  -ms-grid-row: 16;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 17) {
  grid-row-start: 17;
  -ms-grid-row: 17;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 18) {
  grid-row-start: 18;
  -ms-grid-row: 18;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 19) {
  grid-row-start: 19;
  -ms-grid-row: 19;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 20) {
  grid-row-start: 20;
  -ms-grid-row: 20;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 21) {
  grid-row-start: 21;
  -ms-grid-row: 21;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 22) {
  grid-row-start: 22;
  -ms-grid-row: 22;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 23) {
  grid-row-start: 23;
  -ms-grid-row: 23;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 24) {
  grid-row-start: 24;
  -ms-grid-row: 24;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 25) {
  grid-row-start: 25;
  -ms-grid-row: 25;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 26) {
  grid-row-start: 26;
  -ms-grid-row: 26;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 27) {
  grid-row-start: 27;
  -ms-grid-row: 27;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 28) {
  grid-row-start: 28;
  -ms-grid-row: 28;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 29) {
  grid-row-start: 29;
  -ms-grid-row: 29;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 30) {
  grid-row-start: 30;
  -ms-grid-row: 30;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 31) {
  grid-row-start: 31;
  -ms-grid-row: 31;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 32) {
  grid-row-start: 32;
  -ms-grid-row: 32;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 33) {
  grid-row-start: 33;
  -ms-grid-row: 33;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 34) {
  grid-row-start: 34;
  -ms-grid-row: 34;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 35) {
  grid-row-start: 35;
  -ms-grid-row: 35;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 36) {
  grid-row-start: 36;
  -ms-grid-row: 36;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 37) {
  grid-row-start: 37;
  -ms-grid-row: 37;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 38) {
  grid-row-start: 38;
  -ms-grid-row: 38;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 39) {
  grid-row-start: 39;
  -ms-grid-row: 39;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 40) {
  grid-row-start: 40;
  -ms-grid-row: 40;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 41) {
  grid-row-start: 41;
  -ms-grid-row: 41;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 42) {
  grid-row-start: 42;
  -ms-grid-row: 42;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 43) {
  grid-row-start: 43;
  -ms-grid-row: 43;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 44) {
  grid-row-start: 44;
  -ms-grid-row: 44;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 45) {
  grid-row-start: 45;
  -ms-grid-row: 45;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 46) {
  grid-row-start: 46;
  -ms-grid-row: 46;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 47) {
  grid-row-start: 47;
  -ms-grid-row: 47;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 48) {
  grid-row-start: 48;
  -ms-grid-row: 48;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 49) {
  grid-row-start: 49;
  -ms-grid-row: 49;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 50) {
  grid-row-start: 50;
  -ms-grid-row: 50;
}
[class*="zs-grid-1cols"] :nth-of-type(n + 51) {
  grid-row-start: 51;
  -ms-grid-row: 51;
}
[class*="zs-grid-2cols"] :nth-of-type(2n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
[class*="zs-grid-2cols"] :nth-of-type(2n+2) {
  grid-column-start: 2;
  -ms-grid-column: 2;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 3) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 5) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 7) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 9) {
  grid-row-start: 5;
  -ms-grid-row: 5;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 11) {
  grid-row-start: 6;
  -ms-grid-row: 6;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 13) {
  grid-row-start: 7;
  -ms-grid-row: 7;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 15) {
  grid-row-start: 8;
  -ms-grid-row: 8;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 17) {
  grid-row-start: 9;
  -ms-grid-row: 9;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 19) {
  grid-row-start: 10;
  -ms-grid-row: 10;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 21) {
  grid-row-start: 11;
  -ms-grid-row: 11;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 23) {
  grid-row-start: 12;
  -ms-grid-row: 12;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 25) {
  grid-row-start: 13;
  -ms-grid-row: 13;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 27) {
  grid-row-start: 14;
  -ms-grid-row: 14;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 29) {
  grid-row-start: 15;
  -ms-grid-row: 15;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 31) {
  grid-row-start: 16;
  -ms-grid-row: 16;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 33) {
  grid-row-start: 17;
  -ms-grid-row: 17;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 35) {
  grid-row-start: 18;
  -ms-grid-row: 18;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 37) {
  grid-row-start: 19;
  -ms-grid-row: 19;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 39) {
  grid-row-start: 20;
  -ms-grid-row: 20;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 41) {
  grid-row-start: 21;
  -ms-grid-row: 21;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 43) {
  grid-row-start: 22;
  -ms-grid-row: 22;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 45) {
  grid-row-start: 23;
  -ms-grid-row: 23;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 47) {
  grid-row-start: 24;
  -ms-grid-row: 24;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 49) {
  grid-row-start: 25;
  -ms-grid-row: 25;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 51) {
  grid-row-start: 26;
  -ms-grid-row: 26;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 53) {
  grid-row-start: 27;
  -ms-grid-row: 27;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 55) {
  grid-row-start: 28;
  -ms-grid-row: 28;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 57) {
  grid-row-start: 29;
  -ms-grid-row: 29;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 59) {
  grid-row-start: 30;
  -ms-grid-row: 30;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 61) {
  grid-row-start: 31;
  -ms-grid-row: 31;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 63) {
  grid-row-start: 32;
  -ms-grid-row: 32;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 65) {
  grid-row-start: 33;
  -ms-grid-row: 33;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 67) {
  grid-row-start: 34;
  -ms-grid-row: 34;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 69) {
  grid-row-start: 35;
  -ms-grid-row: 35;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 71) {
  grid-row-start: 36;
  -ms-grid-row: 36;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 73) {
  grid-row-start: 37;
  -ms-grid-row: 37;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 75) {
  grid-row-start: 38;
  -ms-grid-row: 38;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 77) {
  grid-row-start: 39;
  -ms-grid-row: 39;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 79) {
  grid-row-start: 40;
  -ms-grid-row: 40;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 81) {
  grid-row-start: 41;
  -ms-grid-row: 41;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 83) {
  grid-row-start: 42;
  -ms-grid-row: 42;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 85) {
  grid-row-start: 43;
  -ms-grid-row: 43;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 87) {
  grid-row-start: 44;
  -ms-grid-row: 44;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 89) {
  grid-row-start: 45;
  -ms-grid-row: 45;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 91) {
  grid-row-start: 46;
  -ms-grid-row: 46;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 93) {
  grid-row-start: 47;
  -ms-grid-row: 47;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 95) {
  grid-row-start: 48;
  -ms-grid-row: 48;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 97) {
  grid-row-start: 49;
  -ms-grid-row: 49;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 99) {
  grid-row-start: 50;
  -ms-grid-row: 50;
}
[class*="zs-grid-2cols"] :nth-of-type(n + 101) {
  grid-row-start: 51;
  -ms-grid-row: 51;
}
[class*="zs-grid-3cols"] :nth-of-type(3n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
[class*="zs-grid-3cols"] :nth-of-type(3n+2) {
  grid-column-start: 2;
  -ms-grid-column: 2;
}
[class*="zs-grid-3cols"] :nth-of-type(3n+3) {
  grid-column-start: 3;
  -ms-grid-column: 3;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 4) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 7) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 10) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 13) {
  grid-row-start: 5;
  -ms-grid-row: 5;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 16) {
  grid-row-start: 6;
  -ms-grid-row: 6;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 19) {
  grid-row-start: 7;
  -ms-grid-row: 7;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 22) {
  grid-row-start: 8;
  -ms-grid-row: 8;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 25) {
  grid-row-start: 9;
  -ms-grid-row: 9;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 28) {
  grid-row-start: 10;
  -ms-grid-row: 10;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 31) {
  grid-row-start: 11;
  -ms-grid-row: 11;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 34) {
  grid-row-start: 12;
  -ms-grid-row: 12;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 37) {
  grid-row-start: 13;
  -ms-grid-row: 13;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 40) {
  grid-row-start: 14;
  -ms-grid-row: 14;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 43) {
  grid-row-start: 15;
  -ms-grid-row: 15;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 46) {
  grid-row-start: 16;
  -ms-grid-row: 16;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 49) {
  grid-row-start: 17;
  -ms-grid-row: 17;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 52) {
  grid-row-start: 18;
  -ms-grid-row: 18;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 55) {
  grid-row-start: 19;
  -ms-grid-row: 19;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 58) {
  grid-row-start: 20;
  -ms-grid-row: 20;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 61) {
  grid-row-start: 21;
  -ms-grid-row: 21;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 64) {
  grid-row-start: 22;
  -ms-grid-row: 22;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 67) {
  grid-row-start: 23;
  -ms-grid-row: 23;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 70) {
  grid-row-start: 24;
  -ms-grid-row: 24;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 73) {
  grid-row-start: 25;
  -ms-grid-row: 25;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 76) {
  grid-row-start: 26;
  -ms-grid-row: 26;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 79) {
  grid-row-start: 27;
  -ms-grid-row: 27;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 82) {
  grid-row-start: 28;
  -ms-grid-row: 28;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 85) {
  grid-row-start: 29;
  -ms-grid-row: 29;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 88) {
  grid-row-start: 30;
  -ms-grid-row: 30;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 91) {
  grid-row-start: 31;
  -ms-grid-row: 31;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 94) {
  grid-row-start: 32;
  -ms-grid-row: 32;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 97) {
  grid-row-start: 33;
  -ms-grid-row: 33;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 100) {
  grid-row-start: 34;
  -ms-grid-row: 34;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 103) {
  grid-row-start: 35;
  -ms-grid-row: 35;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 106) {
  grid-row-start: 36;
  -ms-grid-row: 36;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 109) {
  grid-row-start: 37;
  -ms-grid-row: 37;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 112) {
  grid-row-start: 38;
  -ms-grid-row: 38;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 115) {
  grid-row-start: 39;
  -ms-grid-row: 39;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 118) {
  grid-row-start: 40;
  -ms-grid-row: 40;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 121) {
  grid-row-start: 41;
  -ms-grid-row: 41;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 124) {
  grid-row-start: 42;
  -ms-grid-row: 42;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 127) {
  grid-row-start: 43;
  -ms-grid-row: 43;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 130) {
  grid-row-start: 44;
  -ms-grid-row: 44;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 133) {
  grid-row-start: 45;
  -ms-grid-row: 45;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 136) {
  grid-row-start: 46;
  -ms-grid-row: 46;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 139) {
  grid-row-start: 47;
  -ms-grid-row: 47;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 142) {
  grid-row-start: 48;
  -ms-grid-row: 48;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 145) {
  grid-row-start: 49;
  -ms-grid-row: 49;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 148) {
  grid-row-start: 50;
  -ms-grid-row: 50;
}
[class*="zs-grid-3cols"] :nth-of-type(n + 151) {
  grid-row-start: 51;
  -ms-grid-row: 51;
}
[class*="zs-grid-4cols"] :nth-of-type(4n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
[class*="zs-grid-4cols"] :nth-of-type(4n+2) {
  grid-column-start: 2;
  -ms-grid-column: 2;
}
[class*="zs-grid-4cols"] :nth-of-type(4n+3) {
  grid-column-start: 3;
  -ms-grid-column: 3;
}
[class*="zs-grid-4cols"] :nth-of-type(4n+4) {
  grid-column-start: 4;
  -ms-grid-column: 4;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 5) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 9) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 13) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 17) {
  grid-row-start: 5;
  -ms-grid-row: 5;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 21) {
  grid-row-start: 6;
  -ms-grid-row: 6;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 25) {
  grid-row-start: 7;
  -ms-grid-row: 7;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 29) {
  grid-row-start: 8;
  -ms-grid-row: 8;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 33) {
  grid-row-start: 9;
  -ms-grid-row: 9;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 37) {
  grid-row-start: 10;
  -ms-grid-row: 10;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 41) {
  grid-row-start: 11;
  -ms-grid-row: 11;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 45) {
  grid-row-start: 12;
  -ms-grid-row: 12;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 49) {
  grid-row-start: 13;
  -ms-grid-row: 13;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 53) {
  grid-row-start: 14;
  -ms-grid-row: 14;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 57) {
  grid-row-start: 15;
  -ms-grid-row: 15;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 61) {
  grid-row-start: 16;
  -ms-grid-row: 16;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 65) {
  grid-row-start: 17;
  -ms-grid-row: 17;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 69) {
  grid-row-start: 18;
  -ms-grid-row: 18;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 73) {
  grid-row-start: 19;
  -ms-grid-row: 19;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 77) {
  grid-row-start: 20;
  -ms-grid-row: 20;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 81) {
  grid-row-start: 21;
  -ms-grid-row: 21;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 85) {
  grid-row-start: 22;
  -ms-grid-row: 22;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 89) {
  grid-row-start: 23;
  -ms-grid-row: 23;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 93) {
  grid-row-start: 24;
  -ms-grid-row: 24;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 97) {
  grid-row-start: 25;
  -ms-grid-row: 25;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 101) {
  grid-row-start: 26;
  -ms-grid-row: 26;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 105) {
  grid-row-start: 27;
  -ms-grid-row: 27;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 109) {
  grid-row-start: 28;
  -ms-grid-row: 28;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 113) {
  grid-row-start: 29;
  -ms-grid-row: 29;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 117) {
  grid-row-start: 30;
  -ms-grid-row: 30;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 121) {
  grid-row-start: 31;
  -ms-grid-row: 31;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 125) {
  grid-row-start: 32;
  -ms-grid-row: 32;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 129) {
  grid-row-start: 33;
  -ms-grid-row: 33;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 133) {
  grid-row-start: 34;
  -ms-grid-row: 34;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 137) {
  grid-row-start: 35;
  -ms-grid-row: 35;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 141) {
  grid-row-start: 36;
  -ms-grid-row: 36;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 145) {
  grid-row-start: 37;
  -ms-grid-row: 37;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 149) {
  grid-row-start: 38;
  -ms-grid-row: 38;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 153) {
  grid-row-start: 39;
  -ms-grid-row: 39;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 157) {
  grid-row-start: 40;
  -ms-grid-row: 40;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 161) {
  grid-row-start: 41;
  -ms-grid-row: 41;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 165) {
  grid-row-start: 42;
  -ms-grid-row: 42;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 169) {
  grid-row-start: 43;
  -ms-grid-row: 43;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 173) {
  grid-row-start: 44;
  -ms-grid-row: 44;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 177) {
  grid-row-start: 45;
  -ms-grid-row: 45;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 181) {
  grid-row-start: 46;
  -ms-grid-row: 46;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 185) {
  grid-row-start: 47;
  -ms-grid-row: 47;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 189) {
  grid-row-start: 48;
  -ms-grid-row: 48;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 193) {
  grid-row-start: 49;
  -ms-grid-row: 49;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 197) {
  grid-row-start: 50;
  -ms-grid-row: 50;
}
[class*="zs-grid-4cols"] :nth-of-type(n + 201) {
  grid-row-start: 51;
  -ms-grid-row: 51;
}
[class*="zs-grid-5cols"] :nth-of-type(5n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
[class*="zs-grid-5cols"] :nth-of-type(5n+2) {
  grid-column-start: 2;
  -ms-grid-column: 2;
}
[class*="zs-grid-5cols"] :nth-of-type(5n+3) {
  grid-column-start: 3;
  -ms-grid-column: 3;
}
[class*="zs-grid-5cols"] :nth-of-type(5n+4) {
  grid-column-start: 4;
  -ms-grid-column: 4;
}
[class*="zs-grid-5cols"] :nth-of-type(5n+5) {
  grid-column-start: 5;
  -ms-grid-column: 5;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 6) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 11) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 16) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 21) {
  grid-row-start: 5;
  -ms-grid-row: 5;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 26) {
  grid-row-start: 6;
  -ms-grid-row: 6;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 31) {
  grid-row-start: 7;
  -ms-grid-row: 7;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 36) {
  grid-row-start: 8;
  -ms-grid-row: 8;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 41) {
  grid-row-start: 9;
  -ms-grid-row: 9;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 46) {
  grid-row-start: 10;
  -ms-grid-row: 10;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 51) {
  grid-row-start: 11;
  -ms-grid-row: 11;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 56) {
  grid-row-start: 12;
  -ms-grid-row: 12;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 61) {
  grid-row-start: 13;
  -ms-grid-row: 13;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 66) {
  grid-row-start: 14;
  -ms-grid-row: 14;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 71) {
  grid-row-start: 15;
  -ms-grid-row: 15;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 76) {
  grid-row-start: 16;
  -ms-grid-row: 16;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 81) {
  grid-row-start: 17;
  -ms-grid-row: 17;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 86) {
  grid-row-start: 18;
  -ms-grid-row: 18;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 91) {
  grid-row-start: 19;
  -ms-grid-row: 19;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 96) {
  grid-row-start: 20;
  -ms-grid-row: 20;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 101) {
  grid-row-start: 21;
  -ms-grid-row: 21;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 106) {
  grid-row-start: 22;
  -ms-grid-row: 22;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 111) {
  grid-row-start: 23;
  -ms-grid-row: 23;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 116) {
  grid-row-start: 24;
  -ms-grid-row: 24;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 121) {
  grid-row-start: 25;
  -ms-grid-row: 25;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 126) {
  grid-row-start: 26;
  -ms-grid-row: 26;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 131) {
  grid-row-start: 27;
  -ms-grid-row: 27;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 136) {
  grid-row-start: 28;
  -ms-grid-row: 28;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 141) {
  grid-row-start: 29;
  -ms-grid-row: 29;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 146) {
  grid-row-start: 30;
  -ms-grid-row: 30;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 151) {
  grid-row-start: 31;
  -ms-grid-row: 31;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 156) {
  grid-row-start: 32;
  -ms-grid-row: 32;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 161) {
  grid-row-start: 33;
  -ms-grid-row: 33;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 166) {
  grid-row-start: 34;
  -ms-grid-row: 34;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 171) {
  grid-row-start: 35;
  -ms-grid-row: 35;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 176) {
  grid-row-start: 36;
  -ms-grid-row: 36;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 181) {
  grid-row-start: 37;
  -ms-grid-row: 37;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 186) {
  grid-row-start: 38;
  -ms-grid-row: 38;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 191) {
  grid-row-start: 39;
  -ms-grid-row: 39;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 196) {
  grid-row-start: 40;
  -ms-grid-row: 40;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 201) {
  grid-row-start: 41;
  -ms-grid-row: 41;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 206) {
  grid-row-start: 42;
  -ms-grid-row: 42;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 211) {
  grid-row-start: 43;
  -ms-grid-row: 43;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 216) {
  grid-row-start: 44;
  -ms-grid-row: 44;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 221) {
  grid-row-start: 45;
  -ms-grid-row: 45;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 226) {
  grid-row-start: 46;
  -ms-grid-row: 46;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 231) {
  grid-row-start: 47;
  -ms-grid-row: 47;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 236) {
  grid-row-start: 48;
  -ms-grid-row: 48;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 241) {
  grid-row-start: 49;
  -ms-grid-row: 49;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 246) {
  grid-row-start: 50;
  -ms-grid-row: 50;
}
[class*="zs-grid-5cols"] :nth-of-type(n + 251) {
  grid-row-start: 51;
  -ms-grid-row: 51;
}
[class*="zs-grid-6cols"] :nth-of-type(6n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
[class*="zs-grid-6cols"] :nth-of-type(6n+2) {
  grid-column-start: 2;
  -ms-grid-column: 2;
}
[class*="zs-grid-6cols"] :nth-of-type(6n+3) {
  grid-column-start: 3;
  -ms-grid-column: 3;
}
[class*="zs-grid-6cols"] :nth-of-type(6n+4) {
  grid-column-start: 4;
  -ms-grid-column: 4;
}
[class*="zs-grid-6cols"] :nth-of-type(6n+5) {
  grid-column-start: 5;
  -ms-grid-column: 5;
}
[class*="zs-grid-6cols"] :nth-of-type(6n+6) {
  grid-column-start: 6;
  -ms-grid-column: 6;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 7) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 13) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 19) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 25) {
  grid-row-start: 5;
  -ms-grid-row: 5;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 31) {
  grid-row-start: 6;
  -ms-grid-row: 6;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 37) {
  grid-row-start: 7;
  -ms-grid-row: 7;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 43) {
  grid-row-start: 8;
  -ms-grid-row: 8;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 49) {
  grid-row-start: 9;
  -ms-grid-row: 9;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 55) {
  grid-row-start: 10;
  -ms-grid-row: 10;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 61) {
  grid-row-start: 11;
  -ms-grid-row: 11;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 67) {
  grid-row-start: 12;
  -ms-grid-row: 12;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 73) {
  grid-row-start: 13;
  -ms-grid-row: 13;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 79) {
  grid-row-start: 14;
  -ms-grid-row: 14;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 85) {
  grid-row-start: 15;
  -ms-grid-row: 15;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 91) {
  grid-row-start: 16;
  -ms-grid-row: 16;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 97) {
  grid-row-start: 17;
  -ms-grid-row: 17;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 103) {
  grid-row-start: 18;
  -ms-grid-row: 18;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 109) {
  grid-row-start: 19;
  -ms-grid-row: 19;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 115) {
  grid-row-start: 20;
  -ms-grid-row: 20;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 121) {
  grid-row-start: 21;
  -ms-grid-row: 21;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 127) {
  grid-row-start: 22;
  -ms-grid-row: 22;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 133) {
  grid-row-start: 23;
  -ms-grid-row: 23;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 139) {
  grid-row-start: 24;
  -ms-grid-row: 24;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 145) {
  grid-row-start: 25;
  -ms-grid-row: 25;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 151) {
  grid-row-start: 26;
  -ms-grid-row: 26;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 157) {
  grid-row-start: 27;
  -ms-grid-row: 27;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 163) {
  grid-row-start: 28;
  -ms-grid-row: 28;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 169) {
  grid-row-start: 29;
  -ms-grid-row: 29;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 175) {
  grid-row-start: 30;
  -ms-grid-row: 30;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 181) {
  grid-row-start: 31;
  -ms-grid-row: 31;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 187) {
  grid-row-start: 32;
  -ms-grid-row: 32;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 193) {
  grid-row-start: 33;
  -ms-grid-row: 33;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 199) {
  grid-row-start: 34;
  -ms-grid-row: 34;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 205) {
  grid-row-start: 35;
  -ms-grid-row: 35;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 211) {
  grid-row-start: 36;
  -ms-grid-row: 36;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 217) {
  grid-row-start: 37;
  -ms-grid-row: 37;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 223) {
  grid-row-start: 38;
  -ms-grid-row: 38;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 229) {
  grid-row-start: 39;
  -ms-grid-row: 39;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 235) {
  grid-row-start: 40;
  -ms-grid-row: 40;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 241) {
  grid-row-start: 41;
  -ms-grid-row: 41;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 247) {
  grid-row-start: 42;
  -ms-grid-row: 42;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 253) {
  grid-row-start: 43;
  -ms-grid-row: 43;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 259) {
  grid-row-start: 44;
  -ms-grid-row: 44;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 265) {
  grid-row-start: 45;
  -ms-grid-row: 45;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 271) {
  grid-row-start: 46;
  -ms-grid-row: 46;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 277) {
  grid-row-start: 47;
  -ms-grid-row: 47;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 283) {
  grid-row-start: 48;
  -ms-grid-row: 48;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 289) {
  grid-row-start: 49;
  -ms-grid-row: 49;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 295) {
  grid-row-start: 50;
  -ms-grid-row: 50;
}
[class*="zs-grid-6cols"] :nth-of-type(n + 301) {
  grid-row-start: 51;
  -ms-grid-row: 51;
}
.zs-justify-end > * {
  justify-self: end;
  -ms-grid-column-align: end;
}
.zs-justify-self-end {
  justify-self: end;
  -ms-grid-column-align: end;
}
.zs-align-end > * {
  align-self: end;
  -ms-grid-row-align: end;
}
.zs-align-self-end {
  align-self: end;
  -ms-grid-row-align: end;
}
.zs-justify-start > * {
  justify-self: start;
  -ms-grid-column-align: start;
}
.zs-justify-self-start {
  justify-self: start;
  -ms-grid-column-align: start;
}
.zs-align-self-start {
  align-self: start;
  -ms-grid-row-align: start;
}
.zs-align-start > * {
  align-self: start;
  -ms-grid-row-align: start;
}
.zs-justify-center > * {
  justify-self: center;
  -ms-grid-column-align: center;
}
.zs-justify-self-center {
  justify-self: center;
  -ms-grid-column-align: center;
}
.zs-align-center > * {
  align-self: center;
  -ms-grid-row-align: center;
}
.zs-align-self-center {
  align-self: center;
  -ms-grid-row-align: center;
}
.zs-justify-stretch > * {
  justify-self: stretch;
  -ms-grid-column-align: stretch;
}
.zs-justify-self-stretch {
  justify-self: stretch;
  -ms-grid-column-align: stretch;
}
.zs-align-stretch > * {
  align-self: stretch;
  -ms-grid-row-align: stretch;
}
.zs-align-self-stretch {
  align-self: stretch;
  -ms-grid-row-align: stretch;
}
/** Sw start and end column**/
/** mw start and end column**/
/** Lw start and end column**/
@media (min-width: 768px) {
  [class*="zs-grid-"] > .zs-col-sw-1:nth-of-type(n) {
    grid-column-start: 1;
    -ms-grid-column: 1;
  }
  .zs-col-sw-span-1 {
    grid-column-end: span 1;
    -ms-grid-column-span: 1;
  }
  [class*="zs-grid-"] > .zs-col-sw-2:nth-of-type(n) {
    grid-column-start: 2;
    -ms-grid-column: 2;
  }
  .zs-col-sw-span-2 {
    grid-column-end: span 2;
    -ms-grid-column-span: 2;
  }
  [class*="zs-grid-"] > .zs-col-sw-3:nth-of-type(n) {
    grid-column-start: 3;
    -ms-grid-column: 3;
  }
  .zs-col-sw-span-3 {
    grid-column-end: span 3;
    -ms-grid-column-span: 3;
  }
  [class*="zs-grid-"] > .zs-col-sw-4:nth-of-type(n) {
    grid-column-start: 4;
    -ms-grid-column: 4;
  }
  .zs-col-sw-span-4 {
    grid-column-end: span 4;
    -ms-grid-column-span: 4;
  }
  [class*="zs-grid-"] > .zs-col-sw-5:nth-of-type(n) {
    grid-column-start: 5;
    -ms-grid-column: 5;
  }
  .zs-col-sw-span-5 {
    grid-column-end: span 5;
    -ms-grid-column-span: 5;
  }
  [class*="zs-grid-"] > .zs-col-sw-6:nth-of-type(n) {
    grid-column-start: 6;
    -ms-grid-column: 6;
  }
  .zs-col-sw-span-6 {
    grid-column-end: span 6;
    -ms-grid-column-span: 6;
  }
  [class*="zs-grid-"] > .zs-row-sw-1:nth-of-type(n) {
    grid-row-start: 1;
    -ms-grid-row: 1;
  }
  .zs-row-sw-span-1 {
    grid-row-end: span 1;
    -ms-grid-row-span: 1;
  }
  [class*="zs-grid-"] > .zs-row-sw-2:nth-of-type(n) {
    grid-row-start: 2;
    -ms-grid-row: 2;
  }
  .zs-row-sw-span-2 {
    grid-row-end: span 2;
    -ms-grid-row-span: 2;
  }
  [class*="zs-grid-"] > .zs-row-sw-3:nth-of-type(n) {
    grid-row-start: 3;
    -ms-grid-row: 3;
  }
  .zs-row-sw-span-3 {
    grid-row-end: span 3;
    -ms-grid-row-span: 3;
  }
  [class*="zs-grid-"] > .zs-row-sw-4:nth-of-type(n) {
    grid-row-start: 4;
    -ms-grid-row: 4;
  }
  .zs-row-sw-span-4 {
    grid-row-end: span 4;
    -ms-grid-row-span: 4;
  }
  [class*="zs-grid-"] > .zs-row-sw-5:nth-of-type(n) {
    grid-row-start: 5;
    -ms-grid-row: 5;
  }
  .zs-row-sw-span-5 {
    grid-row-end: span 5;
    -ms-grid-row-span: 5;
  }
  [class*="zs-grid-"] > .zs-row-sw-6:nth-of-type(n) {
    grid-row-start: 6;
    -ms-grid-row: 6;
  }
  .zs-row-sw-span-6 {
    grid-row-end: span 6;
    -ms-grid-row-span: 6;
  }
}
@media (min-width: 992px) {
  [class*="zs-grid-"] > .zs-col-mw-1:nth-of-type(n) {
    grid-column-start: 1;
    -ms-grid-column: 1;
  }
  .zs-col-mw-span-1 {
    grid-column-end: span 1;
    -ms-grid-column-span: 1;
  }
  [class*="zs-grid-"] > .zs-col-mw-2:nth-of-type(n) {
    grid-column-start: 2;
    -ms-grid-column: 2;
  }
  .zs-col-mw-span-2 {
    grid-column-end: span 2;
    -ms-grid-column-span: 2;
  }
  [class*="zs-grid-"] > .zs-col-mw-3:nth-of-type(n) {
    grid-column-start: 3;
    -ms-grid-column: 3;
  }
  .zs-col-mw-span-3 {
    grid-column-end: span 3;
    -ms-grid-column-span: 3;
  }
  [class*="zs-grid-"] > .zs-col-mw-4:nth-of-type(n) {
    grid-column-start: 4;
    -ms-grid-column: 4;
  }
  .zs-col-mw-span-4 {
    grid-column-end: span 4;
    -ms-grid-column-span: 4;
  }
  [class*="zs-grid-"] > .zs-col-mw-5:nth-of-type(n) {
    grid-column-start: 5;
    -ms-grid-column: 5;
  }
  .zs-col-mw-span-5 {
    grid-column-end: span 5;
    -ms-grid-column-span: 5;
  }
  [class*="zs-grid-"] > .zs-col-mw-6:nth-of-type(n) {
    grid-column-start: 6;
    -ms-grid-column: 6;
  }
  .zs-col-mw-span-6 {
    grid-column-end: span 6;
    -ms-grid-column-span: 6;
  }
  [class*="zs-grid-"] > .zs-row-mw-1:nth-of-type(n) {
    grid-row-start: 1;
    -ms-grid-row: 1;
  }
  .zs-row-mw-span1 {
    grid-row-end: span 1;
    -ms-grid-row-span: 1;
  }
  [class*="zs-grid-"] > .zs-row-mw-2:nth-of-type(n) {
    grid-row-start: 2;
    -ms-grid-row: 2;
  }
  .zs-row-mw-span2 {
    grid-row-end: span 2;
    -ms-grid-row-span: 2;
  }
  [class*="zs-grid-"] > .zs-row-mw-3:nth-of-type(n) {
    grid-row-start: 3;
    -ms-grid-row: 3;
  }
  .zs-row-mw-span3 {
    grid-row-end: span 3;
    -ms-grid-row-span: 3;
  }
  [class*="zs-grid-"] > .zs-row-mw-4:nth-of-type(n) {
    grid-row-start: 4;
    -ms-grid-row: 4;
  }
  .zs-row-mw-span4 {
    grid-row-end: span 4;
    -ms-grid-row-span: 4;
  }
  [class*="zs-grid-"] > .zs-row-mw-5:nth-of-type(n) {
    grid-row-start: 5;
    -ms-grid-row: 5;
  }
  .zs-row-mw-span5 {
    grid-row-end: span 5;
    -ms-grid-row-span: 5;
  }
  [class*="zs-grid-"] > .zs-row-mw-6:nth-of-type(n) {
    grid-row-start: 6;
    -ms-grid-row: 6;
  }
  .zs-row-mw-span6 {
    grid-row-end: span 6;
    -ms-grid-row-span: 6;
  }
}
@media (min-width: 1200px) {
  [class*="zs-grid-"] > .zs-col-lw-1:nth-of-type(n) {
    grid-column-start: 1;
    -ms-grid-column: 1;
  }
  .zs-col-lw-span-1 {
    grid-column-end: span 1;
    -ms-grid-column-span: 1;
  }
  [class*="zs-grid-"] > .zs-col-lw-2:nth-of-type(n) {
    grid-column-start: 2;
    -ms-grid-column: 2;
  }
  .zs-col-lw-span-2 {
    grid-column-end: span 2;
    -ms-grid-column-span: 2;
  }
  [class*="zs-grid-"] > .zs-col-lw-3:nth-of-type(n) {
    grid-column-start: 3;
    -ms-grid-column: 3;
  }
  .zs-col-lw-span-3 {
    grid-column-end: span 3;
    -ms-grid-column-span: 3;
  }
  [class*="zs-grid-"] > .zs-col-lw-4:nth-of-type(n) {
    grid-column-start: 4;
    -ms-grid-column: 4;
  }
  .zs-col-lw-span-4 {
    grid-column-end: span 4;
    -ms-grid-column-span: 4;
  }
  [class*="zs-grid-"] > .zs-col-lw-5:nth-of-type(n) {
    grid-column-start: 5;
    -ms-grid-column: 5;
  }
  .zs-col-lw-span-5 {
    grid-column-end: span 5;
    -ms-grid-column-span: 5;
  }
  [class*="zs-grid-"] > .zs-col-lw-6:nth-of-type(n) {
    grid-column-start: 6;
    -ms-grid-column: 6;
  }
  .zs-col-lw-span-6 {
    grid-column-end: span 6;
    -ms-grid-column-span: 6;
  }
  [class*="zs-grid-"] > .zs-row-lw-1:nth-of-type(n) {
    grid-row-start: 1;
    -ms-grid-row: 1;
  }
  .zs-row-lw-span-1 {
    grid-row-end: span 1;
    -ms-grid-row-span: 1;
  }
  [class*="zs-grid-"] > .zs-row-lw-2:nth-of-type(n) {
    grid-row-start: 2;
    -ms-grid-row: 2;
  }
  .zs-row-lw-span-2 {
    grid-row-end: span 2;
    -ms-grid-row-span: 2;
  }
  [class*="zs-grid-"] > .zs-row-lw-3:nth-of-type(n) {
    grid-row-start: 3;
    -ms-grid-row: 3;
  }
  .zs-row-lw-span-3 {
    grid-row-end: span 3;
    -ms-grid-row-span: 3;
  }
  [class*="zs-grid-"] > .zs-row-lw-4:nth-of-type(n) {
    grid-row-start: 4;
    -ms-grid-row: 4;
  }
  .zs-row-lw-span-4 {
    grid-row-end: span 4;
    -ms-grid-row-span: 4;
  }
  [class*="zs-grid-"] > .zs-row-lw-5:nth-of-type(n) {
    grid-row-start: 5;
    -ms-grid-row: 5;
  }
  .zs-row-lw-span-5 {
    grid-row-end: span 5;
    -ms-grid-row-span: 5;
  }
  [class*="zs-grid-"] > .zs-row-lw-6:nth-of-type(n) {
    grid-row-start: 6;
    -ms-grid-row: 6;
  }
  .zs-row-lw-span-6 {
    grid-row-end: span 6;
    -ms-grid-row-span: 6;
  }
}
@media screen and (orientation: portrait) {
  [class*="zs-grid-"] > .zs-col-p-1:nth-of-type(n) {
    grid-column-start: 1;
    -ms-grid-column: 1;
  }
  .zs-col-p-span-1 {
    grid-column-end: span 1;
    -ms-grid-column-span: 1;
  }
  [class*="zs-grid-"] > .zs-col-p-2:nth-of-type(n) {
    grid-column-start: 2;
    -ms-grid-column: 2;
  }
  .zs-col-p-span-2 {
    grid-column-end: span 2;
    -ms-grid-column-span: 2;
  }
  [class*="zs-grid-"] > .zs-col-p-3:nth-of-type(n) {
    grid-column-start: 3;
    -ms-grid-column: 3;
  }
  .zs-col-p-span-3 {
    grid-column-end: span 3;
    -ms-grid-column-span: 3;
  }
  [class*="zs-grid-"] > .zs-col-p-4:nth-of-type(n) {
    grid-column-start: 4;
    -ms-grid-column: 4;
  }
  .zs-col-p-span-4 {
    grid-column-end: span 4;
    -ms-grid-column-span: 4;
  }
  [class*="zs-grid-"] > .zs-col-p-5:nth-of-type(n) {
    grid-column-start: 5;
    -ms-grid-column: 5;
  }
  .zs-col-p-span-5 {
    grid-column-end: span 5;
    -ms-grid-column-span: 5;
  }
  [class*="zs-grid-"] > .zs-col-p-6:nth-of-type(n) {
    grid-column-start: 6;
    -ms-grid-column: 6;
  }
  .zs-col-p-span-6 {
    grid-column-end: span 6;
    -ms-grid-column-span: 6;
  }
  [class*="zs-grid-"] > .zs-row-p-1:nth-of-type(n) {
    grid-row-start: 1;
    -ms-grid-row: 1;
  }
  .zs-row-p-span-1 {
    grid-row-end: span 1;
    -ms-grid-row-span: 1;
  }
  [class*="zs-grid-"] > .zs-row-p-2:nth-of-type(n) {
    grid-row-start: 2;
    -ms-grid-row: 2;
  }
  .zs-row-p-span-2 {
    grid-row-end: span 2;
    -ms-grid-row-span: 2;
  }
  [class*="zs-grid-"] > .zs-row-p-3:nth-of-type(n) {
    grid-row-start: 3;
    -ms-grid-row: 3;
  }
  .zs-row-p-span-3 {
    grid-row-end: span 3;
    -ms-grid-row-span: 3;
  }
  [class*="zs-grid-"] > .zs-row-p-4:nth-of-type(n) {
    grid-row-start: 4;
    -ms-grid-row: 4;
  }
  .zs-row-p-span-4 {
    grid-row-end: span 4;
    -ms-grid-row-span: 4;
  }
  [class*="zs-grid-"] > .zs-row-p-5:nth-of-type(n) {
    grid-row-start: 5;
    -ms-grid-row: 5;
  }
  .zs-row-p-span-5 {
    grid-row-end: span 5;
    -ms-grid-row-span: 5;
  }
  [class*="zs-grid-"] > .zs-row-p-6:nth-of-type(n) {
    grid-row-start: 6;
    -ms-grid-row: 6;
  }
  .zs-row-p-span-6 {
    grid-row-end: span 6;
    -ms-grid-row-span: 6;
  }
}
@media (min-width: 768px) and (orientation: portrait) {
  [class*="zs-grid-"] > .zs-col-sp-1:nth-of-type(n) {
    grid-column-start: 1;
    -ms-grid-column: 1;
  }
  .zs-col-sp-span-1 {
    grid-column-end: span 1;
    -ms-grid-column-span: 1;
  }
  [class*="zs-grid-"] > .zs-col-sp-2:nth-of-type(n) {
    grid-column-start: 2;
    -ms-grid-column: 2;
  }
  .zs-col-sp-span-2 {
    grid-column-end: span 2;
    -ms-grid-column-span: 2;
  }
  [class*="zs-grid-"] > .zs-col-sp-3:nth-of-type(n) {
    grid-column-start: 3;
    -ms-grid-column: 3;
  }
  .zs-col-sp-span-3 {
    grid-column-end: span 3;
    -ms-grid-column-span: 3;
  }
  [class*="zs-grid-"] > .zs-col-sp-4:nth-of-type(n) {
    grid-column-start: 4;
    -ms-grid-column: 4;
  }
  .zs-col-sp-span-4 {
    grid-column-end: span 4;
    -ms-grid-column-span: 4;
  }
  [class*="zs-grid-"] > .zs-col-sp-5:nth-of-type(n) {
    grid-column-start: 5;
    -ms-grid-column: 5;
  }
  .zs-col-sp-span-5 {
    grid-column-end: span 5;
    -ms-grid-column-span: 5;
  }
  [class*="zs-grid-"] > .zs-col-sp-6:nth-of-type(n) {
    grid-column-start: 6;
    -ms-grid-column: 6;
  }
  .zs-col-sp-span-6 {
    grid-column-end: span 6;
    -ms-grid-column-span: 6;
  }
  [class*="zs-grid-"] > .zs-row-sp-1:nth-of-type(n) {
    grid-row-start: 1;
    -ms-grid-row: 1;
  }
  .zs-row-sp-span-1 {
    grid-row-end: span 1;
    -ms-grid-row-span: 1;
  }
  [class*="zs-grid-"] > .zs-row-sp-2:nth-of-type(n) {
    grid-row-start: 2;
    -ms-grid-row: 2;
  }
  .zs-row-sp-span-2 {
    grid-row-end: span 2;
    -ms-grid-row-span: 2;
  }
  [class*="zs-grid-"] > .zs-row-sp-3:nth-of-type(n) {
    grid-row-start: 3;
    -ms-grid-row: 3;
  }
  .zs-row-sp-span-3 {
    grid-row-end: span 3;
    -ms-grid-row-span: 3;
  }
  [class*="zs-grid-"] > .zs-row-sp-4:nth-of-type(n) {
    grid-row-start: 4;
    -ms-grid-row: 4;
  }
  .zs-row-sp-span-4 {
    grid-row-end: span 4;
    -ms-grid-row-span: 4;
  }
  [class*="zs-grid-"] > .zs-row-sp-5:nth-of-type(n) {
    grid-row-start: 5;
    -ms-grid-row: 5;
  }
  .zs-row-sp-span-5 {
    grid-row-end: span 5;
    -ms-grid-row-span: 5;
  }
  [class*="zs-grid-"] > .zs-row-sp-6:nth-of-type(n) {
    grid-row-start: 6;
    -ms-grid-row: 6;
  }
  .zs-row-sp-span-6 {
    grid-row-end: span 6;
    -ms-grid-row-span: 6;
  }
}
@media (min-width: 992px) and (orientation: portrait) {
  [class*="zs-grid-"] > .zs-col-mp-1:nth-of-type(n) {
    grid-column-start: 1;
    -ms-grid-column: 1;
  }
  .zs-col-mp-span-1 {
    grid-column-end: span 1;
    -ms-grid-column-span: 1;
  }
  [class*="zs-grid-"] > .zs-col-mp-2:nth-of-type(n) {
    grid-column-start: 2;
    -ms-grid-column: 2;
  }
  .zs-col-mp-span-2 {
    grid-column-end: span 2;
    -ms-grid-column-span: 2;
  }
  [class*="zs-grid-"] > .zs-col-mp-3:nth-of-type(n) {
    grid-column-start: 3;
    -ms-grid-column: 3;
  }
  .zs-col-mp-span-3 {
    grid-column-end: span 3;
    -ms-grid-column-span: 3;
  }
  [class*="zs-grid-"] > .zs-col-mp-4:nth-of-type(n) {
    grid-column-start: 4;
    -ms-grid-column: 4;
  }
  .zs-col-mp-span-4 {
    grid-column-end: span 4;
    -ms-grid-column-span: 4;
  }
  [class*="zs-grid-"] > .zs-col-mp-5:nth-of-type(n) {
    grid-column-start: 5;
    -ms-grid-column: 5;
  }
  .zs-col-mp-span-5 {
    grid-column-end: span 5;
    -ms-grid-column-span: 5;
  }
  [class*="zs-grid-"] > .zs-col-mp-6:nth-of-type(n) {
    grid-column-start: 6;
    -ms-grid-column: 6;
  }
  .zs-col-mp-span-6 {
    grid-column-end: span 6;
    -ms-grid-column-span: 6;
  }
  [class*="zs-grid-"] > .zs-row-mp-1:nth-of-type(n) {
    grid-row-start: 1;
    -ms-grid-row: 1;
  }
  .zs-row-mp-span1 {
    grid-row-end: span 1;
    -ms-grid-row-span: 1;
  }
  [class*="zs-grid-"] > .zs-row-mp-2:nth-of-type(n) {
    grid-row-start: 2;
    -ms-grid-row: 2;
  }
  .zs-row-mp-span2 {
    grid-row-end: span 2;
    -ms-grid-row-span: 2;
  }
  [class*="zs-grid-"] > .zs-row-mp-3:nth-of-type(n) {
    grid-row-start: 3;
    -ms-grid-row: 3;
  }
  .zs-row-mp-span3 {
    grid-row-end: span 3;
    -ms-grid-row-span: 3;
  }
  [class*="zs-grid-"] > .zs-row-mp-4:nth-of-type(n) {
    grid-row-start: 4;
    -ms-grid-row: 4;
  }
  .zs-row-mp-span4 {
    grid-row-end: span 4;
    -ms-grid-row-span: 4;
  }
  [class*="zs-grid-"] > .zs-row-mp-5:nth-of-type(n) {
    grid-row-start: 5;
    -ms-grid-row: 5;
  }
  .zs-row-mp-span5 {
    grid-row-end: span 5;
    -ms-grid-row-span: 5;
  }
  [class*="zs-grid-"] > .zs-row-mp-6:nth-of-type(n) {
    grid-row-start: 6;
    -ms-grid-row: 6;
  }
  .zs-row-mp-span6 {
    grid-row-end: span 6;
    -ms-grid-row-span: 6;
  }
}
@media (min-width: 1200px) and (orientation: portrait) {
  [class*="zs-grid-"] > .zs-col-lp-1:nth-of-type(n) {
    grid-column-start: 1;
    -ms-grid-column: 1;
  }
  .zs-col-lp-span-1 {
    grid-column-end: span 1;
    -ms-grid-column-span: 1;
  }
  [class*="zs-grid-"] > .zs-col-lp-2:nth-of-type(n) {
    grid-column-start: 2;
    -ms-grid-column: 2;
  }
  .zs-col-lp-span-2 {
    grid-column-end: span 2;
    -ms-grid-column-span: 2;
  }
  [class*="zs-grid-"] > .zs-col-lp-3:nth-of-type(n) {
    grid-column-start: 3;
    -ms-grid-column: 3;
  }
  .zs-col-lp-span-3 {
    grid-column-end: span 3;
    -ms-grid-column-span: 3;
  }
  [class*="zs-grid-"] > .zs-col-lp-4:nth-of-type(n) {
    grid-column-start: 4;
    -ms-grid-column: 4;
  }
  .zs-col-lp-span-4 {
    grid-column-end: span 4;
    -ms-grid-column-span: 4;
  }
  [class*="zs-grid-"] > .zs-col-lp-5:nth-of-type(n) {
    grid-column-start: 5;
    -ms-grid-column: 5;
  }
  .zs-col-lp-span-5 {
    grid-column-end: span 5;
    -ms-grid-column-span: 5;
  }
  [class*="zs-grid-"] > .zs-col-lp-6:nth-of-type(n) {
    grid-column-start: 6;
    -ms-grid-column: 6;
  }
  .zs-col-lp-span-6 {
    grid-column-end: span 6;
    -ms-grid-column-span: 6;
  }
  [class*="zs-grid-"] > .zs-row-lp-1:nth-of-type(n) {
    grid-row-start: 1;
    -ms-grid-row: 1;
  }
  .zs-row-lp-span-1 {
    grid-row-end: span 1;
    -ms-grid-row-span: 1;
  }
  [class*="zs-grid-"] > .zs-row-lp-2:nth-of-type(n) {
    grid-row-start: 2;
    -ms-grid-row: 2;
  }
  .zs-row-lp-span-2 {
    grid-row-end: span 2;
    -ms-grid-row-span: 2;
  }
  [class*="zs-grid-"] > .zs-row-lp-3:nth-of-type(n) {
    grid-row-start: 3;
    -ms-grid-row: 3;
  }
  .zs-row-lp-span-3 {
    grid-row-end: span 3;
    -ms-grid-row-span: 3;
  }
  [class*="zs-grid-"] > .zs-row-lp-4:nth-of-type(n) {
    grid-row-start: 4;
    -ms-grid-row: 4;
  }
  .zs-row-lp-span-4 {
    grid-row-end: span 4;
    -ms-grid-row-span: 4;
  }
  [class*="zs-grid-"] > .zs-row-lp-5:nth-of-type(n) {
    grid-row-start: 5;
    -ms-grid-row: 5;
  }
  .zs-row-lp-span-5 {
    grid-row-end: span 5;
    -ms-grid-row-span: 5;
  }
  [class*="zs-grid-"] > .zs-row-lp-6:nth-of-type(n) {
    grid-row-start: 6;
    -ms-grid-row: 6;
  }
  .zs-row-lp-span-6 {
    grid-row-end: span 6;
    -ms-grid-row-span: 6;
  }
}
.zs-height-0 {
  height: 0% !important;
}
.zs-width-0 {
  width: 0% !important;
}
.zs-height-10 {
  height: 10% !important;
}
.zs-width-10 {
  width: 10% !important;
}
.zs-height-20 {
  height: 20% !important;
}
.zs-width-20 {
  width: 20% !important;
}
.zs-height-25 {
  height: 25% !important;
}
.zs-width-25 {
  width: 25% !important;
}
.zs-height-30 {
  height: 30% !important;
}
.zs-width-30 {
  width: 30% !important;
}
.zs-height-40 {
  height: 40% !important;
}
.zs-width-40 {
  width: 40% !important;
}
.zs-height-50 {
  height: 50% !important;
}
.zs-width-50 {
  width: 50% !important;
}
.zs-height-60 {
  height: 60% !important;
}
.zs-width-60 {
  width: 60% !important;
}
.zs-height-70 {
  height: 70% !important;
}
.zs-width-70 {
  width: 70% !important;
}
.zs-height-75 {
  height: 75% !important;
}
.zs-width-75 {
  width: 75% !important;
}
.zs-height-80 {
  height: 80% !important;
}
.zs-width-80 {
  width: 80% !important;
}
.zs-height-90 {
  height: 90% !important;
}
.zs-width-90 {
  width: 90% !important;
}
.zs-height-100 {
  height: 100% !important;
}
.zs-width-100 {
  width: 100% !important;
}
.zs-height-auto {
  height: auto !important;
}
.zs-width-auto {
  width: auto !important;
}
.zs-border {
  border: 1px solid #DEDCDE !important;
}
.zs-border-top {
  border-top: 1px solid #DEDCDE !important;
}
.zs-border-right {
  border-right: 1px solid #DEDCDE !important;
}
.zs-border-bottom {
  border-bottom: 1px solid #DEDCDE !important;
}
.zs-border-left {
  border-left: 1px solid #DEDCDE !important;
}
.zs-border-0 {
  border: 0 !important;
}
.zs-border-top-0 {
  border-top: 0 !important;
}
.zs-border-right-0 {
  border-right: 0 !important;
}
.zs-border-bottom-0 {
  border-bottom: 0 !important;
}
.zs-border-left-0 {
  border-left: 0 !important;
}
.zs-border-rounded {
  border-radius: 0.25em !important;
}
.zs-border-rounded-top {
  border-top-left-radius: 0.25em !important;
  border-top-right-radius: 0.25em !important;
}
.zs-border-rounded-right {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}
.zs-border-rounded-bottom {
  border-bottom-right-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}
.zs-border-rounded-left {
  border-top-left-radius: 0.25em !important;
  border-bottom-left-radius: 0.25em !important;
}
.zs-border-circle {
  border-radius: 50% !important;
}
.zs-border-rounded-0 {
  border-radius: 0 !important;
}
.zs-border-color-link {
  border-color: #27A6A4 !important;
}
.zs-border-color-disabled {
  border-color: #B2B0B6 !important;
}
.zs-border-color-info-secondary {
  border-color: #A6B2F3 !important;
}
.zs-border-color-success-secondary {
  border-color: #00AA67 !important;
}
.zs-border-color-warning-secondary {
  border-color: #E3A900 !important;
}
.zs-border-color-error-secondary {
  border-color: #FFB6A5 !important;
}
.zs-border-color-info {
  border-color: #2B44C7 !important;
}
.zs-border-color-success {
  border-color: #00AA67 !important;
}
.zs-border-color-warning {
  border-color: #E3A900 !important;
}
.zs-border-color-error {
  border-color: #B21111 !important;
}
.zs-border-color-default {
  border-color: #DEDCDE !important;
}
[class~="zs-padding-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2"] {
  margin: 1em 1em 1em 1em !important;
}
[class~="zs-padding-0-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0-0.5"] {
  padding: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0-0.5"] {
  margin: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0-1"] {
  padding: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0-1"] {
  margin: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0-1.5"] {
  padding: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0-1.5"] {
  margin: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0-2"] {
  padding: 0em 1em 0em 1em !important;
}
[class~="zs-margin-0-2"] {
  margin: 0em 1em 0em 1em !important;
}
[class~="zs-padding-0.5-0"] {
  padding: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0"] {
  margin: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1"] {
  padding: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1"] {
  margin: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1.5"] {
  padding: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1.5"] {
  margin: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-2"] {
  padding: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-margin-0.5-2"] {
  margin: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-padding-1-0"] {
  padding: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-margin-1-0"] {
  margin: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-padding-1-0.5"] {
  padding: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0.5"] {
  margin: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1.5"] {
  padding: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1.5"] {
  margin: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1-2"] {
  padding: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-margin-1-2"] {
  margin: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0"] {
  padding: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0"] {
  margin: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0.5"] {
  padding: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0.5"] {
  margin: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1"] {
  padding: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1"] {
  margin: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-2"] {
  padding: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-margin-1.5-2"] {
  margin: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-padding-2-0"] {
  padding: 1em 0em 1em 0em !important;
}
[class~="zs-margin-2-0"] {
  margin: 1em 0em 1em 0em !important;
}
[class~="zs-padding-2-0.5"] {
  padding: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-2-0.5"] {
  margin: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-2-1"] {
  padding: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-2-1"] {
  margin: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-2-1.5"] {
  padding: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-2-1.5"] {
  margin: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-2-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2-2"] {
  margin: 1em 1em 1em 1em !important;
}
[class~="zs-padding-0-0-0-0"] {
  padding: 0em 0em 0em 0em !important;
}
[class~="zs-margin-0-0-0-0"] {
  margin: 0em 0em 0em 0em !important;
}
[class~="zs-padding-0-0-0-0.5"] {
  padding: 0em 0em 0em 0.25em !important;
}
[class~="zs-margin-0-0-0-0.5"] {
  margin: 0em 0em 0em 0.25em !important;
}
[class~="zs-padding-0-0-0-1"] {
  padding: 0em 0em 0em 0.5em !important;
}
[class~="zs-margin-0-0-0-1"] {
  margin: 0em 0em 0em 0.5em !important;
}
[class~="zs-padding-0-0-0-1.5"] {
  padding: 0em 0em 0em 0.75em !important;
}
[class~="zs-margin-0-0-0-1.5"] {
  margin: 0em 0em 0em 0.75em !important;
}
[class~="zs-padding-0-0-0-2"] {
  padding: 0em 0em 0em 1em !important;
}
[class~="zs-margin-0-0-0-2"] {
  margin: 0em 0em 0em 1em !important;
}
[class~="zs-padding-0-0-0.5-0"] {
  padding: 0em 0em 0.25em 0em !important;
}
[class~="zs-margin-0-0-0.5-0"] {
  margin: 0em 0em 0.25em 0em !important;
}
[class~="zs-padding-0-0-0.5-0.5"] {
  padding: 0em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-0-0-0.5-0.5"] {
  margin: 0em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-0-0-0.5-1"] {
  padding: 0em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-0-0-0.5-1"] {
  margin: 0em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-0-0-0.5-1.5"] {
  padding: 0em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-0-0-0.5-1.5"] {
  margin: 0em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-0-0-0.5-2"] {
  padding: 0em 0em 0.25em 1em !important;
}
[class~="zs-margin-0-0-0.5-2"] {
  margin: 0em 0em 0.25em 1em !important;
}
[class~="zs-padding-0-0-1-0"] {
  padding: 0em 0em 0.5em 0em !important;
}
[class~="zs-margin-0-0-1-0"] {
  margin: 0em 0em 0.5em 0em !important;
}
[class~="zs-padding-0-0-1-0.5"] {
  padding: 0em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-0-0-1-0.5"] {
  margin: 0em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-0-0-1-1"] {
  padding: 0em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-0-0-1-1"] {
  margin: 0em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-0-0-1-1.5"] {
  padding: 0em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-0-0-1-1.5"] {
  margin: 0em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-0-0-1-2"] {
  padding: 0em 0em 0.5em 1em !important;
}
[class~="zs-margin-0-0-1-2"] {
  margin: 0em 0em 0.5em 1em !important;
}
[class~="zs-padding-0-0-1.5-0"] {
  padding: 0em 0em 0.75em 0em !important;
}
[class~="zs-margin-0-0-1.5-0"] {
  margin: 0em 0em 0.75em 0em !important;
}
[class~="zs-padding-0-0-1.5-0.5"] {
  padding: 0em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-0-0-1.5-0.5"] {
  margin: 0em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-0-0-1.5-1"] {
  padding: 0em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-0-0-1.5-1"] {
  margin: 0em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-0-0-1.5-1.5"] {
  padding: 0em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-0-0-1.5-1.5"] {
  margin: 0em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-0-0-1.5-2"] {
  padding: 0em 0em 0.75em 1em !important;
}
[class~="zs-margin-0-0-1.5-2"] {
  margin: 0em 0em 0.75em 1em !important;
}
[class~="zs-padding-0-0-2-0"] {
  padding: 0em 0em 1em 0em !important;
}
[class~="zs-margin-0-0-2-0"] {
  margin: 0em 0em 1em 0em !important;
}
[class~="zs-padding-0-0-2-0.5"] {
  padding: 0em 0em 1em 0.25em !important;
}
[class~="zs-margin-0-0-2-0.5"] {
  margin: 0em 0em 1em 0.25em !important;
}
[class~="zs-padding-0-0-2-1"] {
  padding: 0em 0em 1em 0.5em !important;
}
[class~="zs-margin-0-0-2-1"] {
  margin: 0em 0em 1em 0.5em !important;
}
[class~="zs-padding-0-0-2-1.5"] {
  padding: 0em 0em 1em 0.75em !important;
}
[class~="zs-margin-0-0-2-1.5"] {
  margin: 0em 0em 1em 0.75em !important;
}
[class~="zs-padding-0-0-2-2"] {
  padding: 0em 0em 1em 1em !important;
}
[class~="zs-margin-0-0-2-2"] {
  margin: 0em 0em 1em 1em !important;
}
[class~="zs-padding-0-0.5-0-0"] {
  padding: 0em 0.25em 0em 0em !important;
}
[class~="zs-margin-0-0.5-0-0"] {
  margin: 0em 0.25em 0em 0em !important;
}
[class~="zs-padding-0-0.5-0-0.5"] {
  padding: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0-0.5-0-0.5"] {
  margin: 0em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0-0.5-0-1"] {
  padding: 0em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-0-0.5-0-1"] {
  margin: 0em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-0-0.5-0-1.5"] {
  padding: 0em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-0-0.5-0-1.5"] {
  margin: 0em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-0-0.5-0-2"] {
  padding: 0em 0.25em 0em 1em !important;
}
[class~="zs-margin-0-0.5-0-2"] {
  margin: 0em 0.25em 0em 1em !important;
}
[class~="zs-padding-0-0.5-0.5-0"] {
  padding: 0em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-0-0.5-0.5-0"] {
  margin: 0em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-0-0.5-0.5-0.5"] {
  padding: 0em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0-0.5-0.5-0.5"] {
  margin: 0em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0-0.5-0.5-1"] {
  padding: 0em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-0-0.5-0.5-1"] {
  margin: 0em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-0-0.5-0.5-1.5"] {
  padding: 0em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-0-0.5-0.5-1.5"] {
  margin: 0em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-0-0.5-0.5-2"] {
  padding: 0em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-0-0.5-0.5-2"] {
  margin: 0em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-0-0.5-1-0"] {
  padding: 0em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-0-0.5-1-0"] {
  margin: 0em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-0-0.5-1-0.5"] {
  padding: 0em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-0-0.5-1-0.5"] {
  margin: 0em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-0-0.5-1-1"] {
  padding: 0em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-0-0.5-1-1"] {
  margin: 0em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-0-0.5-1-1.5"] {
  padding: 0em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-0-0.5-1-1.5"] {
  margin: 0em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-0-0.5-1-2"] {
  padding: 0em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-0-0.5-1-2"] {
  margin: 0em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-0-0.5-1.5-0"] {
  padding: 0em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-0-0.5-1.5-0"] {
  margin: 0em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-0-0.5-1.5-0.5"] {
  padding: 0em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-0-0.5-1.5-0.5"] {
  margin: 0em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-0-0.5-1.5-1"] {
  padding: 0em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-0-0.5-1.5-1"] {
  margin: 0em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-0-0.5-1.5-1.5"] {
  padding: 0em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-0-0.5-1.5-1.5"] {
  margin: 0em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-0-0.5-1.5-2"] {
  padding: 0em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-0-0.5-1.5-2"] {
  margin: 0em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-0-0.5-2-0"] {
  padding: 0em 0.25em 1em 0em !important;
}
[class~="zs-margin-0-0.5-2-0"] {
  margin: 0em 0.25em 1em 0em !important;
}
[class~="zs-padding-0-0.5-2-0.5"] {
  padding: 0em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-0-0.5-2-0.5"] {
  margin: 0em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-0-0.5-2-1"] {
  padding: 0em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-0-0.5-2-1"] {
  margin: 0em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-0-0.5-2-1.5"] {
  padding: 0em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-0-0.5-2-1.5"] {
  margin: 0em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-0-0.5-2-2"] {
  padding: 0em 0.25em 1em 1em !important;
}
[class~="zs-margin-0-0.5-2-2"] {
  margin: 0em 0.25em 1em 1em !important;
}
[class~="zs-padding-0-1-0-0"] {
  padding: 0em 0.5em 0em 0em !important;
}
[class~="zs-margin-0-1-0-0"] {
  margin: 0em 0.5em 0em 0em !important;
}
[class~="zs-padding-0-1-0-0.5"] {
  padding: 0em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-0-1-0-0.5"] {
  margin: 0em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-0-1-0-1"] {
  padding: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0-1-0-1"] {
  margin: 0em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0-1-0-1.5"] {
  padding: 0em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-0-1-0-1.5"] {
  margin: 0em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-0-1-0-2"] {
  padding: 0em 0.5em 0em 1em !important;
}
[class~="zs-margin-0-1-0-2"] {
  margin: 0em 0.5em 0em 1em !important;
}
[class~="zs-padding-0-1-0.5-0"] {
  padding: 0em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-0-1-0.5-0"] {
  margin: 0em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-0-1-0.5-0.5"] {
  padding: 0em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-0-1-0.5-0.5"] {
  margin: 0em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-0-1-0.5-1"] {
  padding: 0em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0-1-0.5-1"] {
  margin: 0em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0-1-0.5-1.5"] {
  padding: 0em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-0-1-0.5-1.5"] {
  margin: 0em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-0-1-0.5-2"] {
  padding: 0em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-0-1-0.5-2"] {
  margin: 0em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-0-1-1-0"] {
  padding: 0em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-0-1-1-0"] {
  margin: 0em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-0-1-1-0.5"] {
  padding: 0em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-0-1-1-0.5"] {
  margin: 0em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-0-1-1-1"] {
  padding: 0em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-0-1-1-1"] {
  margin: 0em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-0-1-1-1.5"] {
  padding: 0em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-0-1-1-1.5"] {
  margin: 0em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-0-1-1-2"] {
  padding: 0em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-0-1-1-2"] {
  margin: 0em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-0-1-1.5-0"] {
  padding: 0em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-0-1-1.5-0"] {
  margin: 0em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-0-1-1.5-0.5"] {
  padding: 0em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-0-1-1.5-0.5"] {
  margin: 0em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-0-1-1.5-1"] {
  padding: 0em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-0-1-1.5-1"] {
  margin: 0em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-0-1-1.5-1.5"] {
  padding: 0em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-0-1-1.5-1.5"] {
  margin: 0em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-0-1-1.5-2"] {
  padding: 0em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-0-1-1.5-2"] {
  margin: 0em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-0-1-2-0"] {
  padding: 0em 0.5em 1em 0em !important;
}
[class~="zs-margin-0-1-2-0"] {
  margin: 0em 0.5em 1em 0em !important;
}
[class~="zs-padding-0-1-2-0.5"] {
  padding: 0em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-0-1-2-0.5"] {
  margin: 0em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-0-1-2-1"] {
  padding: 0em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-0-1-2-1"] {
  margin: 0em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-0-1-2-1.5"] {
  padding: 0em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-0-1-2-1.5"] {
  margin: 0em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-0-1-2-2"] {
  padding: 0em 0.5em 1em 1em !important;
}
[class~="zs-margin-0-1-2-2"] {
  margin: 0em 0.5em 1em 1em !important;
}
[class~="zs-padding-0-1.5-0-0"] {
  padding: 0em 0.75em 0em 0em !important;
}
[class~="zs-margin-0-1.5-0-0"] {
  margin: 0em 0.75em 0em 0em !important;
}
[class~="zs-padding-0-1.5-0-0.5"] {
  padding: 0em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-0-1.5-0-0.5"] {
  margin: 0em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-0-1.5-0-1"] {
  padding: 0em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-0-1.5-0-1"] {
  margin: 0em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-0-1.5-0-1.5"] {
  padding: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0-1.5-0-1.5"] {
  margin: 0em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0-1.5-0-2"] {
  padding: 0em 0.75em 0em 1em !important;
}
[class~="zs-margin-0-1.5-0-2"] {
  margin: 0em 0.75em 0em 1em !important;
}
[class~="zs-padding-0-1.5-0.5-0"] {
  padding: 0em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-0-1.5-0.5-0"] {
  margin: 0em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-0-1.5-0.5-0.5"] {
  padding: 0em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-0-1.5-0.5-0.5"] {
  margin: 0em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-0-1.5-0.5-1"] {
  padding: 0em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-0-1.5-0.5-1"] {
  margin: 0em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-0-1.5-0.5-1.5"] {
  padding: 0em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0-1.5-0.5-1.5"] {
  margin: 0em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0-1.5-0.5-2"] {
  padding: 0em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-0-1.5-0.5-2"] {
  margin: 0em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-0-1.5-1-0"] {
  padding: 0em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-0-1.5-1-0"] {
  margin: 0em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-0-1.5-1-0.5"] {
  padding: 0em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-0-1.5-1-0.5"] {
  margin: 0em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-0-1.5-1-1"] {
  padding: 0em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-0-1.5-1-1"] {
  margin: 0em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-0-1.5-1-1.5"] {
  padding: 0em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-0-1.5-1-1.5"] {
  margin: 0em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-0-1.5-1-2"] {
  padding: 0em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-0-1.5-1-2"] {
  margin: 0em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-0-1.5-1.5-0"] {
  padding: 0em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-0-1.5-1.5-0"] {
  margin: 0em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-0-1.5-1.5-0.5"] {
  padding: 0em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-0-1.5-1.5-0.5"] {
  margin: 0em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-0-1.5-1.5-1"] {
  padding: 0em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-0-1.5-1.5-1"] {
  margin: 0em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-0-1.5-1.5-1.5"] {
  padding: 0em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-0-1.5-1.5-1.5"] {
  margin: 0em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-0-1.5-1.5-2"] {
  padding: 0em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-0-1.5-1.5-2"] {
  margin: 0em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-0-1.5-2-0"] {
  padding: 0em 0.75em 1em 0em !important;
}
[class~="zs-margin-0-1.5-2-0"] {
  margin: 0em 0.75em 1em 0em !important;
}
[class~="zs-padding-0-1.5-2-0.5"] {
  padding: 0em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-0-1.5-2-0.5"] {
  margin: 0em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-0-1.5-2-1"] {
  padding: 0em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-0-1.5-2-1"] {
  margin: 0em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-0-1.5-2-1.5"] {
  padding: 0em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-0-1.5-2-1.5"] {
  margin: 0em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-0-1.5-2-2"] {
  padding: 0em 0.75em 1em 1em !important;
}
[class~="zs-margin-0-1.5-2-2"] {
  margin: 0em 0.75em 1em 1em !important;
}
[class~="zs-padding-0-2-0-0"] {
  padding: 0em 1em 0em 0em !important;
}
[class~="zs-margin-0-2-0-0"] {
  margin: 0em 1em 0em 0em !important;
}
[class~="zs-padding-0-2-0-0.5"] {
  padding: 0em 1em 0em 0.25em !important;
}
[class~="zs-margin-0-2-0-0.5"] {
  margin: 0em 1em 0em 0.25em !important;
}
[class~="zs-padding-0-2-0-1"] {
  padding: 0em 1em 0em 0.5em !important;
}
[class~="zs-margin-0-2-0-1"] {
  margin: 0em 1em 0em 0.5em !important;
}
[class~="zs-padding-0-2-0-1.5"] {
  padding: 0em 1em 0em 0.75em !important;
}
[class~="zs-margin-0-2-0-1.5"] {
  margin: 0em 1em 0em 0.75em !important;
}
[class~="zs-padding-0-2-0-2"] {
  padding: 0em 1em 0em 1em !important;
}
[class~="zs-margin-0-2-0-2"] {
  margin: 0em 1em 0em 1em !important;
}
[class~="zs-padding-0-2-0.5-0"] {
  padding: 0em 1em 0.25em 0em !important;
}
[class~="zs-margin-0-2-0.5-0"] {
  margin: 0em 1em 0.25em 0em !important;
}
[class~="zs-padding-0-2-0.5-0.5"] {
  padding: 0em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-0-2-0.5-0.5"] {
  margin: 0em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-0-2-0.5-1"] {
  padding: 0em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-0-2-0.5-1"] {
  margin: 0em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-0-2-0.5-1.5"] {
  padding: 0em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-0-2-0.5-1.5"] {
  margin: 0em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-0-2-0.5-2"] {
  padding: 0em 1em 0.25em 1em !important;
}
[class~="zs-margin-0-2-0.5-2"] {
  margin: 0em 1em 0.25em 1em !important;
}
[class~="zs-padding-0-2-1-0"] {
  padding: 0em 1em 0.5em 0em !important;
}
[class~="zs-margin-0-2-1-0"] {
  margin: 0em 1em 0.5em 0em !important;
}
[class~="zs-padding-0-2-1-0.5"] {
  padding: 0em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-0-2-1-0.5"] {
  margin: 0em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-0-2-1-1"] {
  padding: 0em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-0-2-1-1"] {
  margin: 0em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-0-2-1-1.5"] {
  padding: 0em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-0-2-1-1.5"] {
  margin: 0em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-0-2-1-2"] {
  padding: 0em 1em 0.5em 1em !important;
}
[class~="zs-margin-0-2-1-2"] {
  margin: 0em 1em 0.5em 1em !important;
}
[class~="zs-padding-0-2-1.5-0"] {
  padding: 0em 1em 0.75em 0em !important;
}
[class~="zs-margin-0-2-1.5-0"] {
  margin: 0em 1em 0.75em 0em !important;
}
[class~="zs-padding-0-2-1.5-0.5"] {
  padding: 0em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-0-2-1.5-0.5"] {
  margin: 0em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-0-2-1.5-1"] {
  padding: 0em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-0-2-1.5-1"] {
  margin: 0em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-0-2-1.5-1.5"] {
  padding: 0em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-0-2-1.5-1.5"] {
  margin: 0em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-0-2-1.5-2"] {
  padding: 0em 1em 0.75em 1em !important;
}
[class~="zs-margin-0-2-1.5-2"] {
  margin: 0em 1em 0.75em 1em !important;
}
[class~="zs-padding-0-2-2-0"] {
  padding: 0em 1em 1em 0em !important;
}
[class~="zs-margin-0-2-2-0"] {
  margin: 0em 1em 1em 0em !important;
}
[class~="zs-padding-0-2-2-0.5"] {
  padding: 0em 1em 1em 0.25em !important;
}
[class~="zs-margin-0-2-2-0.5"] {
  margin: 0em 1em 1em 0.25em !important;
}
[class~="zs-padding-0-2-2-1"] {
  padding: 0em 1em 1em 0.5em !important;
}
[class~="zs-margin-0-2-2-1"] {
  margin: 0em 1em 1em 0.5em !important;
}
[class~="zs-padding-0-2-2-1.5"] {
  padding: 0em 1em 1em 0.75em !important;
}
[class~="zs-margin-0-2-2-1.5"] {
  margin: 0em 1em 1em 0.75em !important;
}
[class~="zs-padding-0-2-2-2"] {
  padding: 0em 1em 1em 1em !important;
}
[class~="zs-margin-0-2-2-2"] {
  margin: 0em 1em 1em 1em !important;
}
[class~="zs-padding-0.5-0-0-0"] {
  padding: 0.25em 0em 0em 0em !important;
}
[class~="zs-margin-0.5-0-0-0"] {
  margin: 0.25em 0em 0em 0em !important;
}
[class~="zs-padding-0.5-0-0-0.5"] {
  padding: 0.25em 0em 0em 0.25em !important;
}
[class~="zs-margin-0.5-0-0-0.5"] {
  margin: 0.25em 0em 0em 0.25em !important;
}
[class~="zs-padding-0.5-0-0-1"] {
  padding: 0.25em 0em 0em 0.5em !important;
}
[class~="zs-margin-0.5-0-0-1"] {
  margin: 0.25em 0em 0em 0.5em !important;
}
[class~="zs-padding-0.5-0-0-1.5"] {
  padding: 0.25em 0em 0em 0.75em !important;
}
[class~="zs-margin-0.5-0-0-1.5"] {
  margin: 0.25em 0em 0em 0.75em !important;
}
[class~="zs-padding-0.5-0-0-2"] {
  padding: 0.25em 0em 0em 1em !important;
}
[class~="zs-margin-0.5-0-0-2"] {
  margin: 0.25em 0em 0em 1em !important;
}
[class~="zs-padding-0.5-0-0.5-0"] {
  padding: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0-0.5-0"] {
  margin: 0.25em 0em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0-0.5-0.5"] {
  padding: 0.25em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0-0.5-0.5"] {
  margin: 0.25em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-0-0.5-1"] {
  padding: 0.25em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-0-0.5-1"] {
  margin: 0.25em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-0-0.5-1.5"] {
  padding: 0.25em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-0-0.5-1.5"] {
  margin: 0.25em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-0-0.5-2"] {
  padding: 0.25em 0em 0.25em 1em !important;
}
[class~="zs-margin-0.5-0-0.5-2"] {
  margin: 0.25em 0em 0.25em 1em !important;
}
[class~="zs-padding-0.5-0-1-0"] {
  padding: 0.25em 0em 0.5em 0em !important;
}
[class~="zs-margin-0.5-0-1-0"] {
  margin: 0.25em 0em 0.5em 0em !important;
}
[class~="zs-padding-0.5-0-1-0.5"] {
  padding: 0.25em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-0-1-0.5"] {
  margin: 0.25em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-0-1-1"] {
  padding: 0.25em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-0-1-1"] {
  margin: 0.25em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-0-1-1.5"] {
  padding: 0.25em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-0-1-1.5"] {
  margin: 0.25em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-0-1-2"] {
  padding: 0.25em 0em 0.5em 1em !important;
}
[class~="zs-margin-0.5-0-1-2"] {
  margin: 0.25em 0em 0.5em 1em !important;
}
[class~="zs-padding-0.5-0-1.5-0"] {
  padding: 0.25em 0em 0.75em 0em !important;
}
[class~="zs-margin-0.5-0-1.5-0"] {
  margin: 0.25em 0em 0.75em 0em !important;
}
[class~="zs-padding-0.5-0-1.5-0.5"] {
  padding: 0.25em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-0-1.5-0.5"] {
  margin: 0.25em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-0-1.5-1"] {
  padding: 0.25em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-0-1.5-1"] {
  margin: 0.25em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-0-1.5-1.5"] {
  padding: 0.25em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-0-1.5-1.5"] {
  margin: 0.25em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-0-1.5-2"] {
  padding: 0.25em 0em 0.75em 1em !important;
}
[class~="zs-margin-0.5-0-1.5-2"] {
  margin: 0.25em 0em 0.75em 1em !important;
}
[class~="zs-padding-0.5-0-2-0"] {
  padding: 0.25em 0em 1em 0em !important;
}
[class~="zs-margin-0.5-0-2-0"] {
  margin: 0.25em 0em 1em 0em !important;
}
[class~="zs-padding-0.5-0-2-0.5"] {
  padding: 0.25em 0em 1em 0.25em !important;
}
[class~="zs-margin-0.5-0-2-0.5"] {
  margin: 0.25em 0em 1em 0.25em !important;
}
[class~="zs-padding-0.5-0-2-1"] {
  padding: 0.25em 0em 1em 0.5em !important;
}
[class~="zs-margin-0.5-0-2-1"] {
  margin: 0.25em 0em 1em 0.5em !important;
}
[class~="zs-padding-0.5-0-2-1.5"] {
  padding: 0.25em 0em 1em 0.75em !important;
}
[class~="zs-margin-0.5-0-2-1.5"] {
  margin: 0.25em 0em 1em 0.75em !important;
}
[class~="zs-padding-0.5-0-2-2"] {
  padding: 0.25em 0em 1em 1em !important;
}
[class~="zs-margin-0.5-0-2-2"] {
  margin: 0.25em 0em 1em 1em !important;
}
[class~="zs-padding-0.5-0.5-0-0"] {
  padding: 0.25em 0.25em 0em 0em !important;
}
[class~="zs-margin-0.5-0.5-0-0"] {
  margin: 0.25em 0.25em 0em 0em !important;
}
[class~="zs-padding-0.5-0.5-0-0.5"] {
  padding: 0.25em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-0-0.5"] {
  margin: 0.25em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-0-1"] {
  padding: 0.25em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-0-1"] {
  margin: 0.25em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-0-1.5"] {
  padding: 0.25em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-0-1.5"] {
  margin: 0.25em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-0-2"] {
  padding: 0.25em 0.25em 0em 1em !important;
}
[class~="zs-margin-0.5-0.5-0-2"] {
  margin: 0.25em 0.25em 0em 1em !important;
}
[class~="zs-padding-0.5-0.5-0.5-0"] {
  padding: 0.25em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-0.5-0.5-0.5-0"] {
  margin: 0.25em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-0.5-0.5-0.5-0.5"] {
  padding: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-0.5-0.5"] {
  margin: 0.25em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-0.5-1"] {
  padding: 0.25em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-0.5-1"] {
  margin: 0.25em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-0.5-1.5"] {
  padding: 0.25em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-0.5-1.5"] {
  margin: 0.25em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-0.5-2"] {
  padding: 0.25em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-0.5-0.5-0.5-2"] {
  margin: 0.25em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-0.5-0.5-1-0"] {
  padding: 0.25em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-0.5-0.5-1-0"] {
  margin: 0.25em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-0.5-0.5-1-0.5"] {
  padding: 0.25em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-1-0.5"] {
  margin: 0.25em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-1-1"] {
  padding: 0.25em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-1-1"] {
  margin: 0.25em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-1-1.5"] {
  padding: 0.25em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-1-1.5"] {
  margin: 0.25em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-1-2"] {
  padding: 0.25em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-0.5-0.5-1-2"] {
  margin: 0.25em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-0.5-0.5-1.5-0"] {
  padding: 0.25em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-0.5-0.5-1.5-0"] {
  margin: 0.25em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-0.5-0.5-1.5-0.5"] {
  padding: 0.25em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-1.5-0.5"] {
  margin: 0.25em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-1.5-1"] {
  padding: 0.25em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-1.5-1"] {
  margin: 0.25em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-1.5-1.5"] {
  padding: 0.25em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-1.5-1.5"] {
  margin: 0.25em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-1.5-2"] {
  padding: 0.25em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-0.5-0.5-1.5-2"] {
  margin: 0.25em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-0.5-0.5-2-0"] {
  padding: 0.25em 0.25em 1em 0em !important;
}
[class~="zs-margin-0.5-0.5-2-0"] {
  margin: 0.25em 0.25em 1em 0em !important;
}
[class~="zs-padding-0.5-0.5-2-0.5"] {
  padding: 0.25em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-0.5-0.5-2-0.5"] {
  margin: 0.25em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-0.5-0.5-2-1"] {
  padding: 0.25em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-0.5-0.5-2-1"] {
  margin: 0.25em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-0.5-0.5-2-1.5"] {
  padding: 0.25em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-0.5-0.5-2-1.5"] {
  margin: 0.25em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-0.5-0.5-2-2"] {
  padding: 0.25em 0.25em 1em 1em !important;
}
[class~="zs-margin-0.5-0.5-2-2"] {
  margin: 0.25em 0.25em 1em 1em !important;
}
[class~="zs-padding-0.5-1-0-0"] {
  padding: 0.25em 0.5em 0em 0em !important;
}
[class~="zs-margin-0.5-1-0-0"] {
  margin: 0.25em 0.5em 0em 0em !important;
}
[class~="zs-padding-0.5-1-0-0.5"] {
  padding: 0.25em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-0.5-1-0-0.5"] {
  margin: 0.25em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-0.5-1-0-1"] {
  padding: 0.25em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-0.5-1-0-1"] {
  margin: 0.25em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-0.5-1-0-1.5"] {
  padding: 0.25em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-0.5-1-0-1.5"] {
  margin: 0.25em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-0.5-1-0-2"] {
  padding: 0.25em 0.5em 0em 1em !important;
}
[class~="zs-margin-0.5-1-0-2"] {
  margin: 0.25em 0.5em 0em 1em !important;
}
[class~="zs-padding-0.5-1-0.5-0"] {
  padding: 0.25em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-0.5-1-0.5-0"] {
  margin: 0.25em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-0.5-1-0.5-0.5"] {
  padding: 0.25em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-1-0.5-0.5"] {
  margin: 0.25em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1-0.5-1"] {
  padding: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1-0.5-1"] {
  margin: 0.25em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1-0.5-1.5"] {
  padding: 0.25em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1-0.5-1.5"] {
  margin: 0.25em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-1-0.5-2"] {
  padding: 0.25em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-0.5-1-0.5-2"] {
  margin: 0.25em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-0.5-1-1-0"] {
  padding: 0.25em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-0.5-1-1-0"] {
  margin: 0.25em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-0.5-1-1-0.5"] {
  padding: 0.25em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-1-1-0.5"] {
  margin: 0.25em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-1-1-1"] {
  padding: 0.25em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-1-1-1"] {
  margin: 0.25em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-1-1-1.5"] {
  padding: 0.25em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-1-1-1.5"] {
  margin: 0.25em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-1-1-2"] {
  padding: 0.25em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-0.5-1-1-2"] {
  margin: 0.25em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-0.5-1-1.5-0"] {
  padding: 0.25em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-0.5-1-1.5-0"] {
  margin: 0.25em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-0.5-1-1.5-0.5"] {
  padding: 0.25em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-1-1.5-0.5"] {
  margin: 0.25em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-1-1.5-1"] {
  padding: 0.25em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-1-1.5-1"] {
  margin: 0.25em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-1-1.5-1.5"] {
  padding: 0.25em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-1-1.5-1.5"] {
  margin: 0.25em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-1-1.5-2"] {
  padding: 0.25em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-0.5-1-1.5-2"] {
  margin: 0.25em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-0.5-1-2-0"] {
  padding: 0.25em 0.5em 1em 0em !important;
}
[class~="zs-margin-0.5-1-2-0"] {
  margin: 0.25em 0.5em 1em 0em !important;
}
[class~="zs-padding-0.5-1-2-0.5"] {
  padding: 0.25em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-0.5-1-2-0.5"] {
  margin: 0.25em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-0.5-1-2-1"] {
  padding: 0.25em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-0.5-1-2-1"] {
  margin: 0.25em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-0.5-1-2-1.5"] {
  padding: 0.25em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-0.5-1-2-1.5"] {
  margin: 0.25em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-0.5-1-2-2"] {
  padding: 0.25em 0.5em 1em 1em !important;
}
[class~="zs-margin-0.5-1-2-2"] {
  margin: 0.25em 0.5em 1em 1em !important;
}
[class~="zs-padding-0.5-1.5-0-0"] {
  padding: 0.25em 0.75em 0em 0em !important;
}
[class~="zs-margin-0.5-1.5-0-0"] {
  margin: 0.25em 0.75em 0em 0em !important;
}
[class~="zs-padding-0.5-1.5-0-0.5"] {
  padding: 0.25em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-0-0.5"] {
  margin: 0.25em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-0-1"] {
  padding: 0.25em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-0-1"] {
  margin: 0.25em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-0-1.5"] {
  padding: 0.25em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-0-1.5"] {
  margin: 0.25em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-0-2"] {
  padding: 0.25em 0.75em 0em 1em !important;
}
[class~="zs-margin-0.5-1.5-0-2"] {
  margin: 0.25em 0.75em 0em 1em !important;
}
[class~="zs-padding-0.5-1.5-0.5-0"] {
  padding: 0.25em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-0.5-1.5-0.5-0"] {
  margin: 0.25em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-0.5-1.5-0.5-0.5"] {
  padding: 0.25em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-0.5-0.5"] {
  margin: 0.25em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-0.5-1"] {
  padding: 0.25em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-0.5-1"] {
  margin: 0.25em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-0.5-1.5"] {
  padding: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-0.5-1.5"] {
  margin: 0.25em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-0.5-2"] {
  padding: 0.25em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-0.5-1.5-0.5-2"] {
  margin: 0.25em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-0.5-1.5-1-0"] {
  padding: 0.25em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-0.5-1.5-1-0"] {
  margin: 0.25em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-0.5-1.5-1-0.5"] {
  padding: 0.25em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-1-0.5"] {
  margin: 0.25em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-1-1"] {
  padding: 0.25em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-1-1"] {
  margin: 0.25em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-1-1.5"] {
  padding: 0.25em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-1-1.5"] {
  margin: 0.25em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-1-2"] {
  padding: 0.25em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-0.5-1.5-1-2"] {
  margin: 0.25em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-0.5-1.5-1.5-0"] {
  padding: 0.25em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-0.5-1.5-1.5-0"] {
  margin: 0.25em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-0.5-1.5-1.5-0.5"] {
  padding: 0.25em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-1.5-0.5"] {
  margin: 0.25em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-1.5-1"] {
  padding: 0.25em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-1.5-1"] {
  margin: 0.25em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-1.5-1.5"] {
  padding: 0.25em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-1.5-1.5"] {
  margin: 0.25em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-1.5-2"] {
  padding: 0.25em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-0.5-1.5-1.5-2"] {
  margin: 0.25em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-0.5-1.5-2-0"] {
  padding: 0.25em 0.75em 1em 0em !important;
}
[class~="zs-margin-0.5-1.5-2-0"] {
  margin: 0.25em 0.75em 1em 0em !important;
}
[class~="zs-padding-0.5-1.5-2-0.5"] {
  padding: 0.25em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-0.5-1.5-2-0.5"] {
  margin: 0.25em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-0.5-1.5-2-1"] {
  padding: 0.25em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-0.5-1.5-2-1"] {
  margin: 0.25em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-0.5-1.5-2-1.5"] {
  padding: 0.25em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-0.5-1.5-2-1.5"] {
  margin: 0.25em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-0.5-1.5-2-2"] {
  padding: 0.25em 0.75em 1em 1em !important;
}
[class~="zs-margin-0.5-1.5-2-2"] {
  margin: 0.25em 0.75em 1em 1em !important;
}
[class~="zs-padding-0.5-2-0-0"] {
  padding: 0.25em 1em 0em 0em !important;
}
[class~="zs-margin-0.5-2-0-0"] {
  margin: 0.25em 1em 0em 0em !important;
}
[class~="zs-padding-0.5-2-0-0.5"] {
  padding: 0.25em 1em 0em 0.25em !important;
}
[class~="zs-margin-0.5-2-0-0.5"] {
  margin: 0.25em 1em 0em 0.25em !important;
}
[class~="zs-padding-0.5-2-0-1"] {
  padding: 0.25em 1em 0em 0.5em !important;
}
[class~="zs-margin-0.5-2-0-1"] {
  margin: 0.25em 1em 0em 0.5em !important;
}
[class~="zs-padding-0.5-2-0-1.5"] {
  padding: 0.25em 1em 0em 0.75em !important;
}
[class~="zs-margin-0.5-2-0-1.5"] {
  margin: 0.25em 1em 0em 0.75em !important;
}
[class~="zs-padding-0.5-2-0-2"] {
  padding: 0.25em 1em 0em 1em !important;
}
[class~="zs-margin-0.5-2-0-2"] {
  margin: 0.25em 1em 0em 1em !important;
}
[class~="zs-padding-0.5-2-0.5-0"] {
  padding: 0.25em 1em 0.25em 0em !important;
}
[class~="zs-margin-0.5-2-0.5-0"] {
  margin: 0.25em 1em 0.25em 0em !important;
}
[class~="zs-padding-0.5-2-0.5-0.5"] {
  padding: 0.25em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-0.5-2-0.5-0.5"] {
  margin: 0.25em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-0.5-2-0.5-1"] {
  padding: 0.25em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-0.5-2-0.5-1"] {
  margin: 0.25em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-0.5-2-0.5-1.5"] {
  padding: 0.25em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-0.5-2-0.5-1.5"] {
  margin: 0.25em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-0.5-2-0.5-2"] {
  padding: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-margin-0.5-2-0.5-2"] {
  margin: 0.25em 1em 0.25em 1em !important;
}
[class~="zs-padding-0.5-2-1-0"] {
  padding: 0.25em 1em 0.5em 0em !important;
}
[class~="zs-margin-0.5-2-1-0"] {
  margin: 0.25em 1em 0.5em 0em !important;
}
[class~="zs-padding-0.5-2-1-0.5"] {
  padding: 0.25em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-0.5-2-1-0.5"] {
  margin: 0.25em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-0.5-2-1-1"] {
  padding: 0.25em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-0.5-2-1-1"] {
  margin: 0.25em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-0.5-2-1-1.5"] {
  padding: 0.25em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-0.5-2-1-1.5"] {
  margin: 0.25em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-0.5-2-1-2"] {
  padding: 0.25em 1em 0.5em 1em !important;
}
[class~="zs-margin-0.5-2-1-2"] {
  margin: 0.25em 1em 0.5em 1em !important;
}
[class~="zs-padding-0.5-2-1.5-0"] {
  padding: 0.25em 1em 0.75em 0em !important;
}
[class~="zs-margin-0.5-2-1.5-0"] {
  margin: 0.25em 1em 0.75em 0em !important;
}
[class~="zs-padding-0.5-2-1.5-0.5"] {
  padding: 0.25em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-0.5-2-1.5-0.5"] {
  margin: 0.25em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-0.5-2-1.5-1"] {
  padding: 0.25em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-0.5-2-1.5-1"] {
  margin: 0.25em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-0.5-2-1.5-1.5"] {
  padding: 0.25em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-0.5-2-1.5-1.5"] {
  margin: 0.25em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-0.5-2-1.5-2"] {
  padding: 0.25em 1em 0.75em 1em !important;
}
[class~="zs-margin-0.5-2-1.5-2"] {
  margin: 0.25em 1em 0.75em 1em !important;
}
[class~="zs-padding-0.5-2-2-0"] {
  padding: 0.25em 1em 1em 0em !important;
}
[class~="zs-margin-0.5-2-2-0"] {
  margin: 0.25em 1em 1em 0em !important;
}
[class~="zs-padding-0.5-2-2-0.5"] {
  padding: 0.25em 1em 1em 0.25em !important;
}
[class~="zs-margin-0.5-2-2-0.5"] {
  margin: 0.25em 1em 1em 0.25em !important;
}
[class~="zs-padding-0.5-2-2-1"] {
  padding: 0.25em 1em 1em 0.5em !important;
}
[class~="zs-margin-0.5-2-2-1"] {
  margin: 0.25em 1em 1em 0.5em !important;
}
[class~="zs-padding-0.5-2-2-1.5"] {
  padding: 0.25em 1em 1em 0.75em !important;
}
[class~="zs-margin-0.5-2-2-1.5"] {
  margin: 0.25em 1em 1em 0.75em !important;
}
[class~="zs-padding-0.5-2-2-2"] {
  padding: 0.25em 1em 1em 1em !important;
}
[class~="zs-margin-0.5-2-2-2"] {
  margin: 0.25em 1em 1em 1em !important;
}
[class~="zs-padding-1-0-0-0"] {
  padding: 0.5em 0em 0em 0em !important;
}
[class~="zs-margin-1-0-0-0"] {
  margin: 0.5em 0em 0em 0em !important;
}
[class~="zs-padding-1-0-0-0.5"] {
  padding: 0.5em 0em 0em 0.25em !important;
}
[class~="zs-margin-1-0-0-0.5"] {
  margin: 0.5em 0em 0em 0.25em !important;
}
[class~="zs-padding-1-0-0-1"] {
  padding: 0.5em 0em 0em 0.5em !important;
}
[class~="zs-margin-1-0-0-1"] {
  margin: 0.5em 0em 0em 0.5em !important;
}
[class~="zs-padding-1-0-0-1.5"] {
  padding: 0.5em 0em 0em 0.75em !important;
}
[class~="zs-margin-1-0-0-1.5"] {
  margin: 0.5em 0em 0em 0.75em !important;
}
[class~="zs-padding-1-0-0-2"] {
  padding: 0.5em 0em 0em 1em !important;
}
[class~="zs-margin-1-0-0-2"] {
  margin: 0.5em 0em 0em 1em !important;
}
[class~="zs-padding-1-0-0.5-0"] {
  padding: 0.5em 0em 0.25em 0em !important;
}
[class~="zs-margin-1-0-0.5-0"] {
  margin: 0.5em 0em 0.25em 0em !important;
}
[class~="zs-padding-1-0-0.5-0.5"] {
  padding: 0.5em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-1-0-0.5-0.5"] {
  margin: 0.5em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-1-0-0.5-1"] {
  padding: 0.5em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-1-0-0.5-1"] {
  margin: 0.5em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-1-0-0.5-1.5"] {
  padding: 0.5em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-1-0-0.5-1.5"] {
  margin: 0.5em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-1-0-0.5-2"] {
  padding: 0.5em 0em 0.25em 1em !important;
}
[class~="zs-margin-1-0-0.5-2"] {
  margin: 0.5em 0em 0.25em 1em !important;
}
[class~="zs-padding-1-0-1-0"] {
  padding: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-margin-1-0-1-0"] {
  margin: 0.5em 0em 0.5em 0em !important;
}
[class~="zs-padding-1-0-1-0.5"] {
  padding: 0.5em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0-1-0.5"] {
  margin: 0.5em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-1-0-1-1"] {
  padding: 0.5em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-1-0-1-1"] {
  margin: 0.5em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-1-0-1-1.5"] {
  padding: 0.5em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-1-0-1-1.5"] {
  margin: 0.5em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-1-0-1-2"] {
  padding: 0.5em 0em 0.5em 1em !important;
}
[class~="zs-margin-1-0-1-2"] {
  margin: 0.5em 0em 0.5em 1em !important;
}
[class~="zs-padding-1-0-1.5-0"] {
  padding: 0.5em 0em 0.75em 0em !important;
}
[class~="zs-margin-1-0-1.5-0"] {
  margin: 0.5em 0em 0.75em 0em !important;
}
[class~="zs-padding-1-0-1.5-0.5"] {
  padding: 0.5em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-1-0-1.5-0.5"] {
  margin: 0.5em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-1-0-1.5-1"] {
  padding: 0.5em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-1-0-1.5-1"] {
  margin: 0.5em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-1-0-1.5-1.5"] {
  padding: 0.5em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-1-0-1.5-1.5"] {
  margin: 0.5em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-1-0-1.5-2"] {
  padding: 0.5em 0em 0.75em 1em !important;
}
[class~="zs-margin-1-0-1.5-2"] {
  margin: 0.5em 0em 0.75em 1em !important;
}
[class~="zs-padding-1-0-2-0"] {
  padding: 0.5em 0em 1em 0em !important;
}
[class~="zs-margin-1-0-2-0"] {
  margin: 0.5em 0em 1em 0em !important;
}
[class~="zs-padding-1-0-2-0.5"] {
  padding: 0.5em 0em 1em 0.25em !important;
}
[class~="zs-margin-1-0-2-0.5"] {
  margin: 0.5em 0em 1em 0.25em !important;
}
[class~="zs-padding-1-0-2-1"] {
  padding: 0.5em 0em 1em 0.5em !important;
}
[class~="zs-margin-1-0-2-1"] {
  margin: 0.5em 0em 1em 0.5em !important;
}
[class~="zs-padding-1-0-2-1.5"] {
  padding: 0.5em 0em 1em 0.75em !important;
}
[class~="zs-margin-1-0-2-1.5"] {
  margin: 0.5em 0em 1em 0.75em !important;
}
[class~="zs-padding-1-0-2-2"] {
  padding: 0.5em 0em 1em 1em !important;
}
[class~="zs-margin-1-0-2-2"] {
  margin: 0.5em 0em 1em 1em !important;
}
[class~="zs-padding-1-0.5-0-0"] {
  padding: 0.5em 0.25em 0em 0em !important;
}
[class~="zs-margin-1-0.5-0-0"] {
  margin: 0.5em 0.25em 0em 0em !important;
}
[class~="zs-padding-1-0.5-0-0.5"] {
  padding: 0.5em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-1-0.5-0-0.5"] {
  margin: 0.5em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-1-0.5-0-1"] {
  padding: 0.5em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-1-0.5-0-1"] {
  margin: 0.5em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-1-0.5-0-1.5"] {
  padding: 0.5em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-1-0.5-0-1.5"] {
  margin: 0.5em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-1-0.5-0-2"] {
  padding: 0.5em 0.25em 0em 1em !important;
}
[class~="zs-margin-1-0.5-0-2"] {
  margin: 0.5em 0.25em 0em 1em !important;
}
[class~="zs-padding-1-0.5-0.5-0"] {
  padding: 0.5em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-1-0.5-0.5-0"] {
  margin: 0.5em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-1-0.5-0.5-0.5"] {
  padding: 0.5em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-1-0.5-0.5-0.5"] {
  margin: 0.5em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1-0.5-0.5-1"] {
  padding: 0.5em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-1-0.5-0.5-1"] {
  margin: 0.5em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-1-0.5-0.5-1.5"] {
  padding: 0.5em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-1-0.5-0.5-1.5"] {
  margin: 0.5em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-1-0.5-0.5-2"] {
  padding: 0.5em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-1-0.5-0.5-2"] {
  margin: 0.5em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-1-0.5-1-0"] {
  padding: 0.5em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-1-0.5-1-0"] {
  margin: 0.5em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-1-0.5-1-0.5"] {
  padding: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1-0.5-1-0.5"] {
  margin: 0.5em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1-0.5-1-1"] {
  padding: 0.5em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-1-0.5-1-1"] {
  margin: 0.5em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-1-0.5-1-1.5"] {
  padding: 0.5em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-1-0.5-1-1.5"] {
  margin: 0.5em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-1-0.5-1-2"] {
  padding: 0.5em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-1-0.5-1-2"] {
  margin: 0.5em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-1-0.5-1.5-0"] {
  padding: 0.5em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-1-0.5-1.5-0"] {
  margin: 0.5em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-1-0.5-1.5-0.5"] {
  padding: 0.5em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1-0.5-1.5-0.5"] {
  margin: 0.5em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1-0.5-1.5-1"] {
  padding: 0.5em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-1-0.5-1.5-1"] {
  margin: 0.5em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-1-0.5-1.5-1.5"] {
  padding: 0.5em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-1-0.5-1.5-1.5"] {
  margin: 0.5em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-1-0.5-1.5-2"] {
  padding: 0.5em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-1-0.5-1.5-2"] {
  margin: 0.5em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-1-0.5-2-0"] {
  padding: 0.5em 0.25em 1em 0em !important;
}
[class~="zs-margin-1-0.5-2-0"] {
  margin: 0.5em 0.25em 1em 0em !important;
}
[class~="zs-padding-1-0.5-2-0.5"] {
  padding: 0.5em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-1-0.5-2-0.5"] {
  margin: 0.5em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-1-0.5-2-1"] {
  padding: 0.5em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-1-0.5-2-1"] {
  margin: 0.5em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-1-0.5-2-1.5"] {
  padding: 0.5em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-1-0.5-2-1.5"] {
  margin: 0.5em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-1-0.5-2-2"] {
  padding: 0.5em 0.25em 1em 1em !important;
}
[class~="zs-margin-1-0.5-2-2"] {
  margin: 0.5em 0.25em 1em 1em !important;
}
[class~="zs-padding-1-1-0-0"] {
  padding: 0.5em 0.5em 0em 0em !important;
}
[class~="zs-margin-1-1-0-0"] {
  margin: 0.5em 0.5em 0em 0em !important;
}
[class~="zs-padding-1-1-0-0.5"] {
  padding: 0.5em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-1-1-0-0.5"] {
  margin: 0.5em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-1-1-0-1"] {
  padding: 0.5em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-1-1-0-1"] {
  margin: 0.5em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-1-1-0-1.5"] {
  padding: 0.5em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-1-1-0-1.5"] {
  margin: 0.5em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-1-1-0-2"] {
  padding: 0.5em 0.5em 0em 1em !important;
}
[class~="zs-margin-1-1-0-2"] {
  margin: 0.5em 0.5em 0em 1em !important;
}
[class~="zs-padding-1-1-0.5-0"] {
  padding: 0.5em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-1-1-0.5-0"] {
  margin: 0.5em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-1-1-0.5-0.5"] {
  padding: 0.5em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-1-1-0.5-0.5"] {
  margin: 0.5em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-1-1-0.5-1"] {
  padding: 0.5em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-1-1-0.5-1"] {
  margin: 0.5em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-1-1-0.5-1.5"] {
  padding: 0.5em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-1-1-0.5-1.5"] {
  margin: 0.5em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-1-1-0.5-2"] {
  padding: 0.5em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-1-1-0.5-2"] {
  margin: 0.5em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-1-1-1-0"] {
  padding: 0.5em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-1-1-1-0"] {
  margin: 0.5em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-1-1-1-0.5"] {
  padding: 0.5em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-1-1-1-0.5"] {
  margin: 0.5em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1-1-1"] {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1-1-1"] {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1-1-1.5"] {
  padding: 0.5em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1-1-1.5"] {
  margin: 0.5em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-1-1-1-2"] {
  padding: 0.5em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-1-1-1-2"] {
  margin: 0.5em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-1-1-1.5-0"] {
  padding: 0.5em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-1-1-1.5-0"] {
  margin: 0.5em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-1-1-1.5-0.5"] {
  padding: 0.5em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-1-1-1.5-0.5"] {
  margin: 0.5em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-1-1-1.5-1"] {
  padding: 0.5em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1-1-1.5-1"] {
  margin: 0.5em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1-1-1.5-1.5"] {
  padding: 0.5em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-1-1-1.5-1.5"] {
  margin: 0.5em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-1-1-1.5-2"] {
  padding: 0.5em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-1-1-1.5-2"] {
  margin: 0.5em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-1-1-2-0"] {
  padding: 0.5em 0.5em 1em 0em !important;
}
[class~="zs-margin-1-1-2-0"] {
  margin: 0.5em 0.5em 1em 0em !important;
}
[class~="zs-padding-1-1-2-0.5"] {
  padding: 0.5em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-1-1-2-0.5"] {
  margin: 0.5em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-1-1-2-1"] {
  padding: 0.5em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-1-1-2-1"] {
  margin: 0.5em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-1-1-2-1.5"] {
  padding: 0.5em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-1-1-2-1.5"] {
  margin: 0.5em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-1-1-2-2"] {
  padding: 0.5em 0.5em 1em 1em !important;
}
[class~="zs-margin-1-1-2-2"] {
  margin: 0.5em 0.5em 1em 1em !important;
}
[class~="zs-padding-1-1.5-0-0"] {
  padding: 0.5em 0.75em 0em 0em !important;
}
[class~="zs-margin-1-1.5-0-0"] {
  margin: 0.5em 0.75em 0em 0em !important;
}
[class~="zs-padding-1-1.5-0-0.5"] {
  padding: 0.5em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-1-1.5-0-0.5"] {
  margin: 0.5em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-1-1.5-0-1"] {
  padding: 0.5em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-1-1.5-0-1"] {
  margin: 0.5em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-1-1.5-0-1.5"] {
  padding: 0.5em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-1-1.5-0-1.5"] {
  margin: 0.5em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-1-1.5-0-2"] {
  padding: 0.5em 0.75em 0em 1em !important;
}
[class~="zs-margin-1-1.5-0-2"] {
  margin: 0.5em 0.75em 0em 1em !important;
}
[class~="zs-padding-1-1.5-0.5-0"] {
  padding: 0.5em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-1-1.5-0.5-0"] {
  margin: 0.5em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-1-1.5-0.5-0.5"] {
  padding: 0.5em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-1-1.5-0.5-0.5"] {
  margin: 0.5em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-1-1.5-0.5-1"] {
  padding: 0.5em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-1-1.5-0.5-1"] {
  margin: 0.5em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-1-1.5-0.5-1.5"] {
  padding: 0.5em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-1-1.5-0.5-1.5"] {
  margin: 0.5em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-1-1.5-0.5-2"] {
  padding: 0.5em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-1-1.5-0.5-2"] {
  margin: 0.5em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-1-1.5-1-0"] {
  padding: 0.5em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-1-1.5-1-0"] {
  margin: 0.5em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-1-1.5-1-0.5"] {
  padding: 0.5em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-1-1.5-1-0.5"] {
  margin: 0.5em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-1-1.5-1-1"] {
  padding: 0.5em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-1-1.5-1-1"] {
  margin: 0.5em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-1-1.5-1-1.5"] {
  padding: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1-1.5-1-1.5"] {
  margin: 0.5em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1-1.5-1-2"] {
  padding: 0.5em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-1-1.5-1-2"] {
  margin: 0.5em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-1-1.5-1.5-0"] {
  padding: 0.5em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-1-1.5-1.5-0"] {
  margin: 0.5em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-1-1.5-1.5-0.5"] {
  padding: 0.5em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-1-1.5-1.5-0.5"] {
  margin: 0.5em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-1-1.5-1.5-1"] {
  padding: 0.5em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-1-1.5-1.5-1"] {
  margin: 0.5em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-1-1.5-1.5-1.5"] {
  padding: 0.5em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1-1.5-1.5-1.5"] {
  margin: 0.5em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1-1.5-1.5-2"] {
  padding: 0.5em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-1-1.5-1.5-2"] {
  margin: 0.5em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-1-1.5-2-0"] {
  padding: 0.5em 0.75em 1em 0em !important;
}
[class~="zs-margin-1-1.5-2-0"] {
  margin: 0.5em 0.75em 1em 0em !important;
}
[class~="zs-padding-1-1.5-2-0.5"] {
  padding: 0.5em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-1-1.5-2-0.5"] {
  margin: 0.5em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-1-1.5-2-1"] {
  padding: 0.5em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-1-1.5-2-1"] {
  margin: 0.5em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-1-1.5-2-1.5"] {
  padding: 0.5em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-1-1.5-2-1.5"] {
  margin: 0.5em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-1-1.5-2-2"] {
  padding: 0.5em 0.75em 1em 1em !important;
}
[class~="zs-margin-1-1.5-2-2"] {
  margin: 0.5em 0.75em 1em 1em !important;
}
[class~="zs-padding-1-2-0-0"] {
  padding: 0.5em 1em 0em 0em !important;
}
[class~="zs-margin-1-2-0-0"] {
  margin: 0.5em 1em 0em 0em !important;
}
[class~="zs-padding-1-2-0-0.5"] {
  padding: 0.5em 1em 0em 0.25em !important;
}
[class~="zs-margin-1-2-0-0.5"] {
  margin: 0.5em 1em 0em 0.25em !important;
}
[class~="zs-padding-1-2-0-1"] {
  padding: 0.5em 1em 0em 0.5em !important;
}
[class~="zs-margin-1-2-0-1"] {
  margin: 0.5em 1em 0em 0.5em !important;
}
[class~="zs-padding-1-2-0-1.5"] {
  padding: 0.5em 1em 0em 0.75em !important;
}
[class~="zs-margin-1-2-0-1.5"] {
  margin: 0.5em 1em 0em 0.75em !important;
}
[class~="zs-padding-1-2-0-2"] {
  padding: 0.5em 1em 0em 1em !important;
}
[class~="zs-margin-1-2-0-2"] {
  margin: 0.5em 1em 0em 1em !important;
}
[class~="zs-padding-1-2-0.5-0"] {
  padding: 0.5em 1em 0.25em 0em !important;
}
[class~="zs-margin-1-2-0.5-0"] {
  margin: 0.5em 1em 0.25em 0em !important;
}
[class~="zs-padding-1-2-0.5-0.5"] {
  padding: 0.5em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-1-2-0.5-0.5"] {
  margin: 0.5em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-1-2-0.5-1"] {
  padding: 0.5em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-1-2-0.5-1"] {
  margin: 0.5em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-1-2-0.5-1.5"] {
  padding: 0.5em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-1-2-0.5-1.5"] {
  margin: 0.5em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-1-2-0.5-2"] {
  padding: 0.5em 1em 0.25em 1em !important;
}
[class~="zs-margin-1-2-0.5-2"] {
  margin: 0.5em 1em 0.25em 1em !important;
}
[class~="zs-padding-1-2-1-0"] {
  padding: 0.5em 1em 0.5em 0em !important;
}
[class~="zs-margin-1-2-1-0"] {
  margin: 0.5em 1em 0.5em 0em !important;
}
[class~="zs-padding-1-2-1-0.5"] {
  padding: 0.5em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-1-2-1-0.5"] {
  margin: 0.5em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-1-2-1-1"] {
  padding: 0.5em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-1-2-1-1"] {
  margin: 0.5em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-1-2-1-1.5"] {
  padding: 0.5em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-1-2-1-1.5"] {
  margin: 0.5em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-1-2-1-2"] {
  padding: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-margin-1-2-1-2"] {
  margin: 0.5em 1em 0.5em 1em !important;
}
[class~="zs-padding-1-2-1.5-0"] {
  padding: 0.5em 1em 0.75em 0em !important;
}
[class~="zs-margin-1-2-1.5-0"] {
  margin: 0.5em 1em 0.75em 0em !important;
}
[class~="zs-padding-1-2-1.5-0.5"] {
  padding: 0.5em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-1-2-1.5-0.5"] {
  margin: 0.5em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-1-2-1.5-1"] {
  padding: 0.5em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-1-2-1.5-1"] {
  margin: 0.5em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-1-2-1.5-1.5"] {
  padding: 0.5em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-1-2-1.5-1.5"] {
  margin: 0.5em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-1-2-1.5-2"] {
  padding: 0.5em 1em 0.75em 1em !important;
}
[class~="zs-margin-1-2-1.5-2"] {
  margin: 0.5em 1em 0.75em 1em !important;
}
[class~="zs-padding-1-2-2-0"] {
  padding: 0.5em 1em 1em 0em !important;
}
[class~="zs-margin-1-2-2-0"] {
  margin: 0.5em 1em 1em 0em !important;
}
[class~="zs-padding-1-2-2-0.5"] {
  padding: 0.5em 1em 1em 0.25em !important;
}
[class~="zs-margin-1-2-2-0.5"] {
  margin: 0.5em 1em 1em 0.25em !important;
}
[class~="zs-padding-1-2-2-1"] {
  padding: 0.5em 1em 1em 0.5em !important;
}
[class~="zs-margin-1-2-2-1"] {
  margin: 0.5em 1em 1em 0.5em !important;
}
[class~="zs-padding-1-2-2-1.5"] {
  padding: 0.5em 1em 1em 0.75em !important;
}
[class~="zs-margin-1-2-2-1.5"] {
  margin: 0.5em 1em 1em 0.75em !important;
}
[class~="zs-padding-1-2-2-2"] {
  padding: 0.5em 1em 1em 1em !important;
}
[class~="zs-margin-1-2-2-2"] {
  margin: 0.5em 1em 1em 1em !important;
}
[class~="zs-padding-1.5-0-0-0"] {
  padding: 0.75em 0em 0em 0em !important;
}
[class~="zs-margin-1.5-0-0-0"] {
  margin: 0.75em 0em 0em 0em !important;
}
[class~="zs-padding-1.5-0-0-0.5"] {
  padding: 0.75em 0em 0em 0.25em !important;
}
[class~="zs-margin-1.5-0-0-0.5"] {
  margin: 0.75em 0em 0em 0.25em !important;
}
[class~="zs-padding-1.5-0-0-1"] {
  padding: 0.75em 0em 0em 0.5em !important;
}
[class~="zs-margin-1.5-0-0-1"] {
  margin: 0.75em 0em 0em 0.5em !important;
}
[class~="zs-padding-1.5-0-0-1.5"] {
  padding: 0.75em 0em 0em 0.75em !important;
}
[class~="zs-margin-1.5-0-0-1.5"] {
  margin: 0.75em 0em 0em 0.75em !important;
}
[class~="zs-padding-1.5-0-0-2"] {
  padding: 0.75em 0em 0em 1em !important;
}
[class~="zs-margin-1.5-0-0-2"] {
  margin: 0.75em 0em 0em 1em !important;
}
[class~="zs-padding-1.5-0-0.5-0"] {
  padding: 0.75em 0em 0.25em 0em !important;
}
[class~="zs-margin-1.5-0-0.5-0"] {
  margin: 0.75em 0em 0.25em 0em !important;
}
[class~="zs-padding-1.5-0-0.5-0.5"] {
  padding: 0.75em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-0-0.5-0.5"] {
  margin: 0.75em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-0-0.5-1"] {
  padding: 0.75em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-0-0.5-1"] {
  margin: 0.75em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-0-0.5-1.5"] {
  padding: 0.75em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-0-0.5-1.5"] {
  margin: 0.75em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-0-0.5-2"] {
  padding: 0.75em 0em 0.25em 1em !important;
}
[class~="zs-margin-1.5-0-0.5-2"] {
  margin: 0.75em 0em 0.25em 1em !important;
}
[class~="zs-padding-1.5-0-1-0"] {
  padding: 0.75em 0em 0.5em 0em !important;
}
[class~="zs-margin-1.5-0-1-0"] {
  margin: 0.75em 0em 0.5em 0em !important;
}
[class~="zs-padding-1.5-0-1-0.5"] {
  padding: 0.75em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-0-1-0.5"] {
  margin: 0.75em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-0-1-1"] {
  padding: 0.75em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-0-1-1"] {
  margin: 0.75em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-0-1-1.5"] {
  padding: 0.75em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-0-1-1.5"] {
  margin: 0.75em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-0-1-2"] {
  padding: 0.75em 0em 0.5em 1em !important;
}
[class~="zs-margin-1.5-0-1-2"] {
  margin: 0.75em 0em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0-1.5-0"] {
  padding: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0-1.5-0"] {
  margin: 0.75em 0em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0-1.5-0.5"] {
  padding: 0.75em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0-1.5-0.5"] {
  margin: 0.75em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-0-1.5-1"] {
  padding: 0.75em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-0-1.5-1"] {
  margin: 0.75em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-0-1.5-1.5"] {
  padding: 0.75em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-0-1.5-1.5"] {
  margin: 0.75em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-0-1.5-2"] {
  padding: 0.75em 0em 0.75em 1em !important;
}
[class~="zs-margin-1.5-0-1.5-2"] {
  margin: 0.75em 0em 0.75em 1em !important;
}
[class~="zs-padding-1.5-0-2-0"] {
  padding: 0.75em 0em 1em 0em !important;
}
[class~="zs-margin-1.5-0-2-0"] {
  margin: 0.75em 0em 1em 0em !important;
}
[class~="zs-padding-1.5-0-2-0.5"] {
  padding: 0.75em 0em 1em 0.25em !important;
}
[class~="zs-margin-1.5-0-2-0.5"] {
  margin: 0.75em 0em 1em 0.25em !important;
}
[class~="zs-padding-1.5-0-2-1"] {
  padding: 0.75em 0em 1em 0.5em !important;
}
[class~="zs-margin-1.5-0-2-1"] {
  margin: 0.75em 0em 1em 0.5em !important;
}
[class~="zs-padding-1.5-0-2-1.5"] {
  padding: 0.75em 0em 1em 0.75em !important;
}
[class~="zs-margin-1.5-0-2-1.5"] {
  margin: 0.75em 0em 1em 0.75em !important;
}
[class~="zs-padding-1.5-0-2-2"] {
  padding: 0.75em 0em 1em 1em !important;
}
[class~="zs-margin-1.5-0-2-2"] {
  margin: 0.75em 0em 1em 1em !important;
}
[class~="zs-padding-1.5-0.5-0-0"] {
  padding: 0.75em 0.25em 0em 0em !important;
}
[class~="zs-margin-1.5-0.5-0-0"] {
  margin: 0.75em 0.25em 0em 0em !important;
}
[class~="zs-padding-1.5-0.5-0-0.5"] {
  padding: 0.75em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-0-0.5"] {
  margin: 0.75em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-0-1"] {
  padding: 0.75em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-0-1"] {
  margin: 0.75em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-0-1.5"] {
  padding: 0.75em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-0-1.5"] {
  margin: 0.75em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-0-2"] {
  padding: 0.75em 0.25em 0em 1em !important;
}
[class~="zs-margin-1.5-0.5-0-2"] {
  margin: 0.75em 0.25em 0em 1em !important;
}
[class~="zs-padding-1.5-0.5-0.5-0"] {
  padding: 0.75em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-1.5-0.5-0.5-0"] {
  margin: 0.75em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-1.5-0.5-0.5-0.5"] {
  padding: 0.75em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-0.5-0.5"] {
  margin: 0.75em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-0.5-1"] {
  padding: 0.75em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-0.5-1"] {
  margin: 0.75em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-0.5-1.5"] {
  padding: 0.75em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-0.5-1.5"] {
  margin: 0.75em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-0.5-2"] {
  padding: 0.75em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-1.5-0.5-0.5-2"] {
  margin: 0.75em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-1.5-0.5-1-0"] {
  padding: 0.75em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-1.5-0.5-1-0"] {
  margin: 0.75em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-1.5-0.5-1-0.5"] {
  padding: 0.75em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-1-0.5"] {
  margin: 0.75em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-1-1"] {
  padding: 0.75em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-1-1"] {
  margin: 0.75em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-1-1.5"] {
  padding: 0.75em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-1-1.5"] {
  margin: 0.75em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-1-2"] {
  padding: 0.75em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-1.5-0.5-1-2"] {
  margin: 0.75em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-1.5-0.5-1.5-0"] {
  padding: 0.75em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-1.5-0.5-1.5-0"] {
  margin: 0.75em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-1.5-0.5-1.5-0.5"] {
  padding: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-1.5-0.5"] {
  margin: 0.75em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-1.5-1"] {
  padding: 0.75em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-1.5-1"] {
  margin: 0.75em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-1.5-1.5"] {
  padding: 0.75em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-1.5-1.5"] {
  margin: 0.75em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-1.5-2"] {
  padding: 0.75em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-1.5-0.5-1.5-2"] {
  margin: 0.75em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-1.5-0.5-2-0"] {
  padding: 0.75em 0.25em 1em 0em !important;
}
[class~="zs-margin-1.5-0.5-2-0"] {
  margin: 0.75em 0.25em 1em 0em !important;
}
[class~="zs-padding-1.5-0.5-2-0.5"] {
  padding: 0.75em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-1.5-0.5-2-0.5"] {
  margin: 0.75em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-1.5-0.5-2-1"] {
  padding: 0.75em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-1.5-0.5-2-1"] {
  margin: 0.75em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-1.5-0.5-2-1.5"] {
  padding: 0.75em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-1.5-0.5-2-1.5"] {
  margin: 0.75em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-1.5-0.5-2-2"] {
  padding: 0.75em 0.25em 1em 1em !important;
}
[class~="zs-margin-1.5-0.5-2-2"] {
  margin: 0.75em 0.25em 1em 1em !important;
}
[class~="zs-padding-1.5-1-0-0"] {
  padding: 0.75em 0.5em 0em 0em !important;
}
[class~="zs-margin-1.5-1-0-0"] {
  margin: 0.75em 0.5em 0em 0em !important;
}
[class~="zs-padding-1.5-1-0-0.5"] {
  padding: 0.75em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-1.5-1-0-0.5"] {
  margin: 0.75em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-1.5-1-0-1"] {
  padding: 0.75em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-1.5-1-0-1"] {
  margin: 0.75em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-1.5-1-0-1.5"] {
  padding: 0.75em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-1.5-1-0-1.5"] {
  margin: 0.75em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-1.5-1-0-2"] {
  padding: 0.75em 0.5em 0em 1em !important;
}
[class~="zs-margin-1.5-1-0-2"] {
  margin: 0.75em 0.5em 0em 1em !important;
}
[class~="zs-padding-1.5-1-0.5-0"] {
  padding: 0.75em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-1.5-1-0.5-0"] {
  margin: 0.75em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-1.5-1-0.5-0.5"] {
  padding: 0.75em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-1-0.5-0.5"] {
  margin: 0.75em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-1-0.5-1"] {
  padding: 0.75em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-1-0.5-1"] {
  margin: 0.75em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-1-0.5-1.5"] {
  padding: 0.75em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-1-0.5-1.5"] {
  margin: 0.75em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-1-0.5-2"] {
  padding: 0.75em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-1.5-1-0.5-2"] {
  margin: 0.75em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-1.5-1-1-0"] {
  padding: 0.75em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-1.5-1-1-0"] {
  margin: 0.75em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-1.5-1-1-0.5"] {
  padding: 0.75em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-1-1-0.5"] {
  margin: 0.75em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-1-1-1"] {
  padding: 0.75em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-1-1-1"] {
  margin: 0.75em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-1-1-1.5"] {
  padding: 0.75em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-1-1-1.5"] {
  margin: 0.75em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-1-1-2"] {
  padding: 0.75em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-1.5-1-1-2"] {
  margin: 0.75em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-1.5-1-1.5-0"] {
  padding: 0.75em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-1.5-1-1.5-0"] {
  margin: 0.75em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-1.5-1-1.5-0.5"] {
  padding: 0.75em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-1-1.5-0.5"] {
  margin: 0.75em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1-1.5-1"] {
  padding: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1-1.5-1"] {
  margin: 0.75em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1-1.5-1.5"] {
  padding: 0.75em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1-1.5-1.5"] {
  margin: 0.75em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-1-1.5-2"] {
  padding: 0.75em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-1.5-1-1.5-2"] {
  margin: 0.75em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-1.5-1-2-0"] {
  padding: 0.75em 0.5em 1em 0em !important;
}
[class~="zs-margin-1.5-1-2-0"] {
  margin: 0.75em 0.5em 1em 0em !important;
}
[class~="zs-padding-1.5-1-2-0.5"] {
  padding: 0.75em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-1.5-1-2-0.5"] {
  margin: 0.75em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-1.5-1-2-1"] {
  padding: 0.75em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-1.5-1-2-1"] {
  margin: 0.75em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-1.5-1-2-1.5"] {
  padding: 0.75em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-1.5-1-2-1.5"] {
  margin: 0.75em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-1.5-1-2-2"] {
  padding: 0.75em 0.5em 1em 1em !important;
}
[class~="zs-margin-1.5-1-2-2"] {
  margin: 0.75em 0.5em 1em 1em !important;
}
[class~="zs-padding-1.5-1.5-0-0"] {
  padding: 0.75em 0.75em 0em 0em !important;
}
[class~="zs-margin-1.5-1.5-0-0"] {
  margin: 0.75em 0.75em 0em 0em !important;
}
[class~="zs-padding-1.5-1.5-0-0.5"] {
  padding: 0.75em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-0-0.5"] {
  margin: 0.75em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-0-1"] {
  padding: 0.75em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-0-1"] {
  margin: 0.75em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-0-1.5"] {
  padding: 0.75em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-0-1.5"] {
  margin: 0.75em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-0-2"] {
  padding: 0.75em 0.75em 0em 1em !important;
}
[class~="zs-margin-1.5-1.5-0-2"] {
  margin: 0.75em 0.75em 0em 1em !important;
}
[class~="zs-padding-1.5-1.5-0.5-0"] {
  padding: 0.75em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-1.5-1.5-0.5-0"] {
  margin: 0.75em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-1.5-1.5-0.5-0.5"] {
  padding: 0.75em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-0.5-0.5"] {
  margin: 0.75em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-0.5-1"] {
  padding: 0.75em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-0.5-1"] {
  margin: 0.75em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-0.5-1.5"] {
  padding: 0.75em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-0.5-1.5"] {
  margin: 0.75em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-0.5-2"] {
  padding: 0.75em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-1.5-1.5-0.5-2"] {
  margin: 0.75em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-1.5-1.5-1-0"] {
  padding: 0.75em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-1.5-1.5-1-0"] {
  margin: 0.75em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-1.5-1.5-1-0.5"] {
  padding: 0.75em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-1-0.5"] {
  margin: 0.75em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-1-1"] {
  padding: 0.75em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-1-1"] {
  margin: 0.75em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-1-1.5"] {
  padding: 0.75em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-1-1.5"] {
  margin: 0.75em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-1-2"] {
  padding: 0.75em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-1.5-1.5-1-2"] {
  margin: 0.75em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-1.5-1.5-1.5-0"] {
  padding: 0.75em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-1.5-1.5-1.5-0"] {
  margin: 0.75em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-1.5-1.5-1.5-0.5"] {
  padding: 0.75em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-1.5-0.5"] {
  margin: 0.75em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-1.5-1"] {
  padding: 0.75em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-1.5-1"] {
  margin: 0.75em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-1.5-1.5"] {
  padding: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-1.5-1.5"] {
  margin: 0.75em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-1.5-2"] {
  padding: 0.75em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-1.5-1.5-1.5-2"] {
  margin: 0.75em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-1.5-1.5-2-0"] {
  padding: 0.75em 0.75em 1em 0em !important;
}
[class~="zs-margin-1.5-1.5-2-0"] {
  margin: 0.75em 0.75em 1em 0em !important;
}
[class~="zs-padding-1.5-1.5-2-0.5"] {
  padding: 0.75em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-1.5-1.5-2-0.5"] {
  margin: 0.75em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-1.5-1.5-2-1"] {
  padding: 0.75em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-1.5-1.5-2-1"] {
  margin: 0.75em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-1.5-1.5-2-1.5"] {
  padding: 0.75em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-1.5-1.5-2-1.5"] {
  margin: 0.75em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-1.5-1.5-2-2"] {
  padding: 0.75em 0.75em 1em 1em !important;
}
[class~="zs-margin-1.5-1.5-2-2"] {
  margin: 0.75em 0.75em 1em 1em !important;
}
[class~="zs-padding-1.5-2-0-0"] {
  padding: 0.75em 1em 0em 0em !important;
}
[class~="zs-margin-1.5-2-0-0"] {
  margin: 0.75em 1em 0em 0em !important;
}
[class~="zs-padding-1.5-2-0-0.5"] {
  padding: 0.75em 1em 0em 0.25em !important;
}
[class~="zs-margin-1.5-2-0-0.5"] {
  margin: 0.75em 1em 0em 0.25em !important;
}
[class~="zs-padding-1.5-2-0-1"] {
  padding: 0.75em 1em 0em 0.5em !important;
}
[class~="zs-margin-1.5-2-0-1"] {
  margin: 0.75em 1em 0em 0.5em !important;
}
[class~="zs-padding-1.5-2-0-1.5"] {
  padding: 0.75em 1em 0em 0.75em !important;
}
[class~="zs-margin-1.5-2-0-1.5"] {
  margin: 0.75em 1em 0em 0.75em !important;
}
[class~="zs-padding-1.5-2-0-2"] {
  padding: 0.75em 1em 0em 1em !important;
}
[class~="zs-margin-1.5-2-0-2"] {
  margin: 0.75em 1em 0em 1em !important;
}
[class~="zs-padding-1.5-2-0.5-0"] {
  padding: 0.75em 1em 0.25em 0em !important;
}
[class~="zs-margin-1.5-2-0.5-0"] {
  margin: 0.75em 1em 0.25em 0em !important;
}
[class~="zs-padding-1.5-2-0.5-0.5"] {
  padding: 0.75em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-1.5-2-0.5-0.5"] {
  margin: 0.75em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-1.5-2-0.5-1"] {
  padding: 0.75em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-1.5-2-0.5-1"] {
  margin: 0.75em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-1.5-2-0.5-1.5"] {
  padding: 0.75em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-1.5-2-0.5-1.5"] {
  margin: 0.75em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-1.5-2-0.5-2"] {
  padding: 0.75em 1em 0.25em 1em !important;
}
[class~="zs-margin-1.5-2-0.5-2"] {
  margin: 0.75em 1em 0.25em 1em !important;
}
[class~="zs-padding-1.5-2-1-0"] {
  padding: 0.75em 1em 0.5em 0em !important;
}
[class~="zs-margin-1.5-2-1-0"] {
  margin: 0.75em 1em 0.5em 0em !important;
}
[class~="zs-padding-1.5-2-1-0.5"] {
  padding: 0.75em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-1.5-2-1-0.5"] {
  margin: 0.75em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-1.5-2-1-1"] {
  padding: 0.75em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-1.5-2-1-1"] {
  margin: 0.75em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-1.5-2-1-1.5"] {
  padding: 0.75em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-1.5-2-1-1.5"] {
  margin: 0.75em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-1.5-2-1-2"] {
  padding: 0.75em 1em 0.5em 1em !important;
}
[class~="zs-margin-1.5-2-1-2"] {
  margin: 0.75em 1em 0.5em 1em !important;
}
[class~="zs-padding-1.5-2-1.5-0"] {
  padding: 0.75em 1em 0.75em 0em !important;
}
[class~="zs-margin-1.5-2-1.5-0"] {
  margin: 0.75em 1em 0.75em 0em !important;
}
[class~="zs-padding-1.5-2-1.5-0.5"] {
  padding: 0.75em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-1.5-2-1.5-0.5"] {
  margin: 0.75em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-1.5-2-1.5-1"] {
  padding: 0.75em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-1.5-2-1.5-1"] {
  margin: 0.75em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-1.5-2-1.5-1.5"] {
  padding: 0.75em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-1.5-2-1.5-1.5"] {
  margin: 0.75em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-1.5-2-1.5-2"] {
  padding: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-margin-1.5-2-1.5-2"] {
  margin: 0.75em 1em 0.75em 1em !important;
}
[class~="zs-padding-1.5-2-2-0"] {
  padding: 0.75em 1em 1em 0em !important;
}
[class~="zs-margin-1.5-2-2-0"] {
  margin: 0.75em 1em 1em 0em !important;
}
[class~="zs-padding-1.5-2-2-0.5"] {
  padding: 0.75em 1em 1em 0.25em !important;
}
[class~="zs-margin-1.5-2-2-0.5"] {
  margin: 0.75em 1em 1em 0.25em !important;
}
[class~="zs-padding-1.5-2-2-1"] {
  padding: 0.75em 1em 1em 0.5em !important;
}
[class~="zs-margin-1.5-2-2-1"] {
  margin: 0.75em 1em 1em 0.5em !important;
}
[class~="zs-padding-1.5-2-2-1.5"] {
  padding: 0.75em 1em 1em 0.75em !important;
}
[class~="zs-margin-1.5-2-2-1.5"] {
  margin: 0.75em 1em 1em 0.75em !important;
}
[class~="zs-padding-1.5-2-2-2"] {
  padding: 0.75em 1em 1em 1em !important;
}
[class~="zs-margin-1.5-2-2-2"] {
  margin: 0.75em 1em 1em 1em !important;
}
[class~="zs-padding-2-0-0-0"] {
  padding: 1em 0em 0em 0em !important;
}
[class~="zs-margin-2-0-0-0"] {
  margin: 1em 0em 0em 0em !important;
}
[class~="zs-padding-2-0-0-0.5"] {
  padding: 1em 0em 0em 0.25em !important;
}
[class~="zs-margin-2-0-0-0.5"] {
  margin: 1em 0em 0em 0.25em !important;
}
[class~="zs-padding-2-0-0-1"] {
  padding: 1em 0em 0em 0.5em !important;
}
[class~="zs-margin-2-0-0-1"] {
  margin: 1em 0em 0em 0.5em !important;
}
[class~="zs-padding-2-0-0-1.5"] {
  padding: 1em 0em 0em 0.75em !important;
}
[class~="zs-margin-2-0-0-1.5"] {
  margin: 1em 0em 0em 0.75em !important;
}
[class~="zs-padding-2-0-0-2"] {
  padding: 1em 0em 0em 1em !important;
}
[class~="zs-margin-2-0-0-2"] {
  margin: 1em 0em 0em 1em !important;
}
[class~="zs-padding-2-0-0.5-0"] {
  padding: 1em 0em 0.25em 0em !important;
}
[class~="zs-margin-2-0-0.5-0"] {
  margin: 1em 0em 0.25em 0em !important;
}
[class~="zs-padding-2-0-0.5-0.5"] {
  padding: 1em 0em 0.25em 0.25em !important;
}
[class~="zs-margin-2-0-0.5-0.5"] {
  margin: 1em 0em 0.25em 0.25em !important;
}
[class~="zs-padding-2-0-0.5-1"] {
  padding: 1em 0em 0.25em 0.5em !important;
}
[class~="zs-margin-2-0-0.5-1"] {
  margin: 1em 0em 0.25em 0.5em !important;
}
[class~="zs-padding-2-0-0.5-1.5"] {
  padding: 1em 0em 0.25em 0.75em !important;
}
[class~="zs-margin-2-0-0.5-1.5"] {
  margin: 1em 0em 0.25em 0.75em !important;
}
[class~="zs-padding-2-0-0.5-2"] {
  padding: 1em 0em 0.25em 1em !important;
}
[class~="zs-margin-2-0-0.5-2"] {
  margin: 1em 0em 0.25em 1em !important;
}
[class~="zs-padding-2-0-1-0"] {
  padding: 1em 0em 0.5em 0em !important;
}
[class~="zs-margin-2-0-1-0"] {
  margin: 1em 0em 0.5em 0em !important;
}
[class~="zs-padding-2-0-1-0.5"] {
  padding: 1em 0em 0.5em 0.25em !important;
}
[class~="zs-margin-2-0-1-0.5"] {
  margin: 1em 0em 0.5em 0.25em !important;
}
[class~="zs-padding-2-0-1-1"] {
  padding: 1em 0em 0.5em 0.5em !important;
}
[class~="zs-margin-2-0-1-1"] {
  margin: 1em 0em 0.5em 0.5em !important;
}
[class~="zs-padding-2-0-1-1.5"] {
  padding: 1em 0em 0.5em 0.75em !important;
}
[class~="zs-margin-2-0-1-1.5"] {
  margin: 1em 0em 0.5em 0.75em !important;
}
[class~="zs-padding-2-0-1-2"] {
  padding: 1em 0em 0.5em 1em !important;
}
[class~="zs-margin-2-0-1-2"] {
  margin: 1em 0em 0.5em 1em !important;
}
[class~="zs-padding-2-0-1.5-0"] {
  padding: 1em 0em 0.75em 0em !important;
}
[class~="zs-margin-2-0-1.5-0"] {
  margin: 1em 0em 0.75em 0em !important;
}
[class~="zs-padding-2-0-1.5-0.5"] {
  padding: 1em 0em 0.75em 0.25em !important;
}
[class~="zs-margin-2-0-1.5-0.5"] {
  margin: 1em 0em 0.75em 0.25em !important;
}
[class~="zs-padding-2-0-1.5-1"] {
  padding: 1em 0em 0.75em 0.5em !important;
}
[class~="zs-margin-2-0-1.5-1"] {
  margin: 1em 0em 0.75em 0.5em !important;
}
[class~="zs-padding-2-0-1.5-1.5"] {
  padding: 1em 0em 0.75em 0.75em !important;
}
[class~="zs-margin-2-0-1.5-1.5"] {
  margin: 1em 0em 0.75em 0.75em !important;
}
[class~="zs-padding-2-0-1.5-2"] {
  padding: 1em 0em 0.75em 1em !important;
}
[class~="zs-margin-2-0-1.5-2"] {
  margin: 1em 0em 0.75em 1em !important;
}
[class~="zs-padding-2-0-2-0"] {
  padding: 1em 0em 1em 0em !important;
}
[class~="zs-margin-2-0-2-0"] {
  margin: 1em 0em 1em 0em !important;
}
[class~="zs-padding-2-0-2-0.5"] {
  padding: 1em 0em 1em 0.25em !important;
}
[class~="zs-margin-2-0-2-0.5"] {
  margin: 1em 0em 1em 0.25em !important;
}
[class~="zs-padding-2-0-2-1"] {
  padding: 1em 0em 1em 0.5em !important;
}
[class~="zs-margin-2-0-2-1"] {
  margin: 1em 0em 1em 0.5em !important;
}
[class~="zs-padding-2-0-2-1.5"] {
  padding: 1em 0em 1em 0.75em !important;
}
[class~="zs-margin-2-0-2-1.5"] {
  margin: 1em 0em 1em 0.75em !important;
}
[class~="zs-padding-2-0-2-2"] {
  padding: 1em 0em 1em 1em !important;
}
[class~="zs-margin-2-0-2-2"] {
  margin: 1em 0em 1em 1em !important;
}
[class~="zs-padding-2-0.5-0-0"] {
  padding: 1em 0.25em 0em 0em !important;
}
[class~="zs-margin-2-0.5-0-0"] {
  margin: 1em 0.25em 0em 0em !important;
}
[class~="zs-padding-2-0.5-0-0.5"] {
  padding: 1em 0.25em 0em 0.25em !important;
}
[class~="zs-margin-2-0.5-0-0.5"] {
  margin: 1em 0.25em 0em 0.25em !important;
}
[class~="zs-padding-2-0.5-0-1"] {
  padding: 1em 0.25em 0em 0.5em !important;
}
[class~="zs-margin-2-0.5-0-1"] {
  margin: 1em 0.25em 0em 0.5em !important;
}
[class~="zs-padding-2-0.5-0-1.5"] {
  padding: 1em 0.25em 0em 0.75em !important;
}
[class~="zs-margin-2-0.5-0-1.5"] {
  margin: 1em 0.25em 0em 0.75em !important;
}
[class~="zs-padding-2-0.5-0-2"] {
  padding: 1em 0.25em 0em 1em !important;
}
[class~="zs-margin-2-0.5-0-2"] {
  margin: 1em 0.25em 0em 1em !important;
}
[class~="zs-padding-2-0.5-0.5-0"] {
  padding: 1em 0.25em 0.25em 0em !important;
}
[class~="zs-margin-2-0.5-0.5-0"] {
  margin: 1em 0.25em 0.25em 0em !important;
}
[class~="zs-padding-2-0.5-0.5-0.5"] {
  padding: 1em 0.25em 0.25em 0.25em !important;
}
[class~="zs-margin-2-0.5-0.5-0.5"] {
  margin: 1em 0.25em 0.25em 0.25em !important;
}
[class~="zs-padding-2-0.5-0.5-1"] {
  padding: 1em 0.25em 0.25em 0.5em !important;
}
[class~="zs-margin-2-0.5-0.5-1"] {
  margin: 1em 0.25em 0.25em 0.5em !important;
}
[class~="zs-padding-2-0.5-0.5-1.5"] {
  padding: 1em 0.25em 0.25em 0.75em !important;
}
[class~="zs-margin-2-0.5-0.5-1.5"] {
  margin: 1em 0.25em 0.25em 0.75em !important;
}
[class~="zs-padding-2-0.5-0.5-2"] {
  padding: 1em 0.25em 0.25em 1em !important;
}
[class~="zs-margin-2-0.5-0.5-2"] {
  margin: 1em 0.25em 0.25em 1em !important;
}
[class~="zs-padding-2-0.5-1-0"] {
  padding: 1em 0.25em 0.5em 0em !important;
}
[class~="zs-margin-2-0.5-1-0"] {
  margin: 1em 0.25em 0.5em 0em !important;
}
[class~="zs-padding-2-0.5-1-0.5"] {
  padding: 1em 0.25em 0.5em 0.25em !important;
}
[class~="zs-margin-2-0.5-1-0.5"] {
  margin: 1em 0.25em 0.5em 0.25em !important;
}
[class~="zs-padding-2-0.5-1-1"] {
  padding: 1em 0.25em 0.5em 0.5em !important;
}
[class~="zs-margin-2-0.5-1-1"] {
  margin: 1em 0.25em 0.5em 0.5em !important;
}
[class~="zs-padding-2-0.5-1-1.5"] {
  padding: 1em 0.25em 0.5em 0.75em !important;
}
[class~="zs-margin-2-0.5-1-1.5"] {
  margin: 1em 0.25em 0.5em 0.75em !important;
}
[class~="zs-padding-2-0.5-1-2"] {
  padding: 1em 0.25em 0.5em 1em !important;
}
[class~="zs-margin-2-0.5-1-2"] {
  margin: 1em 0.25em 0.5em 1em !important;
}
[class~="zs-padding-2-0.5-1.5-0"] {
  padding: 1em 0.25em 0.75em 0em !important;
}
[class~="zs-margin-2-0.5-1.5-0"] {
  margin: 1em 0.25em 0.75em 0em !important;
}
[class~="zs-padding-2-0.5-1.5-0.5"] {
  padding: 1em 0.25em 0.75em 0.25em !important;
}
[class~="zs-margin-2-0.5-1.5-0.5"] {
  margin: 1em 0.25em 0.75em 0.25em !important;
}
[class~="zs-padding-2-0.5-1.5-1"] {
  padding: 1em 0.25em 0.75em 0.5em !important;
}
[class~="zs-margin-2-0.5-1.5-1"] {
  margin: 1em 0.25em 0.75em 0.5em !important;
}
[class~="zs-padding-2-0.5-1.5-1.5"] {
  padding: 1em 0.25em 0.75em 0.75em !important;
}
[class~="zs-margin-2-0.5-1.5-1.5"] {
  margin: 1em 0.25em 0.75em 0.75em !important;
}
[class~="zs-padding-2-0.5-1.5-2"] {
  padding: 1em 0.25em 0.75em 1em !important;
}
[class~="zs-margin-2-0.5-1.5-2"] {
  margin: 1em 0.25em 0.75em 1em !important;
}
[class~="zs-padding-2-0.5-2-0"] {
  padding: 1em 0.25em 1em 0em !important;
}
[class~="zs-margin-2-0.5-2-0"] {
  margin: 1em 0.25em 1em 0em !important;
}
[class~="zs-padding-2-0.5-2-0.5"] {
  padding: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-margin-2-0.5-2-0.5"] {
  margin: 1em 0.25em 1em 0.25em !important;
}
[class~="zs-padding-2-0.5-2-1"] {
  padding: 1em 0.25em 1em 0.5em !important;
}
[class~="zs-margin-2-0.5-2-1"] {
  margin: 1em 0.25em 1em 0.5em !important;
}
[class~="zs-padding-2-0.5-2-1.5"] {
  padding: 1em 0.25em 1em 0.75em !important;
}
[class~="zs-margin-2-0.5-2-1.5"] {
  margin: 1em 0.25em 1em 0.75em !important;
}
[class~="zs-padding-2-0.5-2-2"] {
  padding: 1em 0.25em 1em 1em !important;
}
[class~="zs-margin-2-0.5-2-2"] {
  margin: 1em 0.25em 1em 1em !important;
}
[class~="zs-padding-2-1-0-0"] {
  padding: 1em 0.5em 0em 0em !important;
}
[class~="zs-margin-2-1-0-0"] {
  margin: 1em 0.5em 0em 0em !important;
}
[class~="zs-padding-2-1-0-0.5"] {
  padding: 1em 0.5em 0em 0.25em !important;
}
[class~="zs-margin-2-1-0-0.5"] {
  margin: 1em 0.5em 0em 0.25em !important;
}
[class~="zs-padding-2-1-0-1"] {
  padding: 1em 0.5em 0em 0.5em !important;
}
[class~="zs-margin-2-1-0-1"] {
  margin: 1em 0.5em 0em 0.5em !important;
}
[class~="zs-padding-2-1-0-1.5"] {
  padding: 1em 0.5em 0em 0.75em !important;
}
[class~="zs-margin-2-1-0-1.5"] {
  margin: 1em 0.5em 0em 0.75em !important;
}
[class~="zs-padding-2-1-0-2"] {
  padding: 1em 0.5em 0em 1em !important;
}
[class~="zs-margin-2-1-0-2"] {
  margin: 1em 0.5em 0em 1em !important;
}
[class~="zs-padding-2-1-0.5-0"] {
  padding: 1em 0.5em 0.25em 0em !important;
}
[class~="zs-margin-2-1-0.5-0"] {
  margin: 1em 0.5em 0.25em 0em !important;
}
[class~="zs-padding-2-1-0.5-0.5"] {
  padding: 1em 0.5em 0.25em 0.25em !important;
}
[class~="zs-margin-2-1-0.5-0.5"] {
  margin: 1em 0.5em 0.25em 0.25em !important;
}
[class~="zs-padding-2-1-0.5-1"] {
  padding: 1em 0.5em 0.25em 0.5em !important;
}
[class~="zs-margin-2-1-0.5-1"] {
  margin: 1em 0.5em 0.25em 0.5em !important;
}
[class~="zs-padding-2-1-0.5-1.5"] {
  padding: 1em 0.5em 0.25em 0.75em !important;
}
[class~="zs-margin-2-1-0.5-1.5"] {
  margin: 1em 0.5em 0.25em 0.75em !important;
}
[class~="zs-padding-2-1-0.5-2"] {
  padding: 1em 0.5em 0.25em 1em !important;
}
[class~="zs-margin-2-1-0.5-2"] {
  margin: 1em 0.5em 0.25em 1em !important;
}
[class~="zs-padding-2-1-1-0"] {
  padding: 1em 0.5em 0.5em 0em !important;
}
[class~="zs-margin-2-1-1-0"] {
  margin: 1em 0.5em 0.5em 0em !important;
}
[class~="zs-padding-2-1-1-0.5"] {
  padding: 1em 0.5em 0.5em 0.25em !important;
}
[class~="zs-margin-2-1-1-0.5"] {
  margin: 1em 0.5em 0.5em 0.25em !important;
}
[class~="zs-padding-2-1-1-1"] {
  padding: 1em 0.5em 0.5em 0.5em !important;
}
[class~="zs-margin-2-1-1-1"] {
  margin: 1em 0.5em 0.5em 0.5em !important;
}
[class~="zs-padding-2-1-1-1.5"] {
  padding: 1em 0.5em 0.5em 0.75em !important;
}
[class~="zs-margin-2-1-1-1.5"] {
  margin: 1em 0.5em 0.5em 0.75em !important;
}
[class~="zs-padding-2-1-1-2"] {
  padding: 1em 0.5em 0.5em 1em !important;
}
[class~="zs-margin-2-1-1-2"] {
  margin: 1em 0.5em 0.5em 1em !important;
}
[class~="zs-padding-2-1-1.5-0"] {
  padding: 1em 0.5em 0.75em 0em !important;
}
[class~="zs-margin-2-1-1.5-0"] {
  margin: 1em 0.5em 0.75em 0em !important;
}
[class~="zs-padding-2-1-1.5-0.5"] {
  padding: 1em 0.5em 0.75em 0.25em !important;
}
[class~="zs-margin-2-1-1.5-0.5"] {
  margin: 1em 0.5em 0.75em 0.25em !important;
}
[class~="zs-padding-2-1-1.5-1"] {
  padding: 1em 0.5em 0.75em 0.5em !important;
}
[class~="zs-margin-2-1-1.5-1"] {
  margin: 1em 0.5em 0.75em 0.5em !important;
}
[class~="zs-padding-2-1-1.5-1.5"] {
  padding: 1em 0.5em 0.75em 0.75em !important;
}
[class~="zs-margin-2-1-1.5-1.5"] {
  margin: 1em 0.5em 0.75em 0.75em !important;
}
[class~="zs-padding-2-1-1.5-2"] {
  padding: 1em 0.5em 0.75em 1em !important;
}
[class~="zs-margin-2-1-1.5-2"] {
  margin: 1em 0.5em 0.75em 1em !important;
}
[class~="zs-padding-2-1-2-0"] {
  padding: 1em 0.5em 1em 0em !important;
}
[class~="zs-margin-2-1-2-0"] {
  margin: 1em 0.5em 1em 0em !important;
}
[class~="zs-padding-2-1-2-0.5"] {
  padding: 1em 0.5em 1em 0.25em !important;
}
[class~="zs-margin-2-1-2-0.5"] {
  margin: 1em 0.5em 1em 0.25em !important;
}
[class~="zs-padding-2-1-2-1"] {
  padding: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-margin-2-1-2-1"] {
  margin: 1em 0.5em 1em 0.5em !important;
}
[class~="zs-padding-2-1-2-1.5"] {
  padding: 1em 0.5em 1em 0.75em !important;
}
[class~="zs-margin-2-1-2-1.5"] {
  margin: 1em 0.5em 1em 0.75em !important;
}
[class~="zs-padding-2-1-2-2"] {
  padding: 1em 0.5em 1em 1em !important;
}
[class~="zs-margin-2-1-2-2"] {
  margin: 1em 0.5em 1em 1em !important;
}
[class~="zs-padding-2-1.5-0-0"] {
  padding: 1em 0.75em 0em 0em !important;
}
[class~="zs-margin-2-1.5-0-0"] {
  margin: 1em 0.75em 0em 0em !important;
}
[class~="zs-padding-2-1.5-0-0.5"] {
  padding: 1em 0.75em 0em 0.25em !important;
}
[class~="zs-margin-2-1.5-0-0.5"] {
  margin: 1em 0.75em 0em 0.25em !important;
}
[class~="zs-padding-2-1.5-0-1"] {
  padding: 1em 0.75em 0em 0.5em !important;
}
[class~="zs-margin-2-1.5-0-1"] {
  margin: 1em 0.75em 0em 0.5em !important;
}
[class~="zs-padding-2-1.5-0-1.5"] {
  padding: 1em 0.75em 0em 0.75em !important;
}
[class~="zs-margin-2-1.5-0-1.5"] {
  margin: 1em 0.75em 0em 0.75em !important;
}
[class~="zs-padding-2-1.5-0-2"] {
  padding: 1em 0.75em 0em 1em !important;
}
[class~="zs-margin-2-1.5-0-2"] {
  margin: 1em 0.75em 0em 1em !important;
}
[class~="zs-padding-2-1.5-0.5-0"] {
  padding: 1em 0.75em 0.25em 0em !important;
}
[class~="zs-margin-2-1.5-0.5-0"] {
  margin: 1em 0.75em 0.25em 0em !important;
}
[class~="zs-padding-2-1.5-0.5-0.5"] {
  padding: 1em 0.75em 0.25em 0.25em !important;
}
[class~="zs-margin-2-1.5-0.5-0.5"] {
  margin: 1em 0.75em 0.25em 0.25em !important;
}
[class~="zs-padding-2-1.5-0.5-1"] {
  padding: 1em 0.75em 0.25em 0.5em !important;
}
[class~="zs-margin-2-1.5-0.5-1"] {
  margin: 1em 0.75em 0.25em 0.5em !important;
}
[class~="zs-padding-2-1.5-0.5-1.5"] {
  padding: 1em 0.75em 0.25em 0.75em !important;
}
[class~="zs-margin-2-1.5-0.5-1.5"] {
  margin: 1em 0.75em 0.25em 0.75em !important;
}
[class~="zs-padding-2-1.5-0.5-2"] {
  padding: 1em 0.75em 0.25em 1em !important;
}
[class~="zs-margin-2-1.5-0.5-2"] {
  margin: 1em 0.75em 0.25em 1em !important;
}
[class~="zs-padding-2-1.5-1-0"] {
  padding: 1em 0.75em 0.5em 0em !important;
}
[class~="zs-margin-2-1.5-1-0"] {
  margin: 1em 0.75em 0.5em 0em !important;
}
[class~="zs-padding-2-1.5-1-0.5"] {
  padding: 1em 0.75em 0.5em 0.25em !important;
}
[class~="zs-margin-2-1.5-1-0.5"] {
  margin: 1em 0.75em 0.5em 0.25em !important;
}
[class~="zs-padding-2-1.5-1-1"] {
  padding: 1em 0.75em 0.5em 0.5em !important;
}
[class~="zs-margin-2-1.5-1-1"] {
  margin: 1em 0.75em 0.5em 0.5em !important;
}
[class~="zs-padding-2-1.5-1-1.5"] {
  padding: 1em 0.75em 0.5em 0.75em !important;
}
[class~="zs-margin-2-1.5-1-1.5"] {
  margin: 1em 0.75em 0.5em 0.75em !important;
}
[class~="zs-padding-2-1.5-1-2"] {
  padding: 1em 0.75em 0.5em 1em !important;
}
[class~="zs-margin-2-1.5-1-2"] {
  margin: 1em 0.75em 0.5em 1em !important;
}
[class~="zs-padding-2-1.5-1.5-0"] {
  padding: 1em 0.75em 0.75em 0em !important;
}
[class~="zs-margin-2-1.5-1.5-0"] {
  margin: 1em 0.75em 0.75em 0em !important;
}
[class~="zs-padding-2-1.5-1.5-0.5"] {
  padding: 1em 0.75em 0.75em 0.25em !important;
}
[class~="zs-margin-2-1.5-1.5-0.5"] {
  margin: 1em 0.75em 0.75em 0.25em !important;
}
[class~="zs-padding-2-1.5-1.5-1"] {
  padding: 1em 0.75em 0.75em 0.5em !important;
}
[class~="zs-margin-2-1.5-1.5-1"] {
  margin: 1em 0.75em 0.75em 0.5em !important;
}
[class~="zs-padding-2-1.5-1.5-1.5"] {
  padding: 1em 0.75em 0.75em 0.75em !important;
}
[class~="zs-margin-2-1.5-1.5-1.5"] {
  margin: 1em 0.75em 0.75em 0.75em !important;
}
[class~="zs-padding-2-1.5-1.5-2"] {
  padding: 1em 0.75em 0.75em 1em !important;
}
[class~="zs-margin-2-1.5-1.5-2"] {
  margin: 1em 0.75em 0.75em 1em !important;
}
[class~="zs-padding-2-1.5-2-0"] {
  padding: 1em 0.75em 1em 0em !important;
}
[class~="zs-margin-2-1.5-2-0"] {
  margin: 1em 0.75em 1em 0em !important;
}
[class~="zs-padding-2-1.5-2-0.5"] {
  padding: 1em 0.75em 1em 0.25em !important;
}
[class~="zs-margin-2-1.5-2-0.5"] {
  margin: 1em 0.75em 1em 0.25em !important;
}
[class~="zs-padding-2-1.5-2-1"] {
  padding: 1em 0.75em 1em 0.5em !important;
}
[class~="zs-margin-2-1.5-2-1"] {
  margin: 1em 0.75em 1em 0.5em !important;
}
[class~="zs-padding-2-1.5-2-1.5"] {
  padding: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-margin-2-1.5-2-1.5"] {
  margin: 1em 0.75em 1em 0.75em !important;
}
[class~="zs-padding-2-1.5-2-2"] {
  padding: 1em 0.75em 1em 1em !important;
}
[class~="zs-margin-2-1.5-2-2"] {
  margin: 1em 0.75em 1em 1em !important;
}
[class~="zs-padding-2-2-0-0"] {
  padding: 1em 1em 0em 0em !important;
}
[class~="zs-margin-2-2-0-0"] {
  margin: 1em 1em 0em 0em !important;
}
[class~="zs-padding-2-2-0-0.5"] {
  padding: 1em 1em 0em 0.25em !important;
}
[class~="zs-margin-2-2-0-0.5"] {
  margin: 1em 1em 0em 0.25em !important;
}
[class~="zs-padding-2-2-0-1"] {
  padding: 1em 1em 0em 0.5em !important;
}
[class~="zs-margin-2-2-0-1"] {
  margin: 1em 1em 0em 0.5em !important;
}
[class~="zs-padding-2-2-0-1.5"] {
  padding: 1em 1em 0em 0.75em !important;
}
[class~="zs-margin-2-2-0-1.5"] {
  margin: 1em 1em 0em 0.75em !important;
}
[class~="zs-padding-2-2-0-2"] {
  padding: 1em 1em 0em 1em !important;
}
[class~="zs-margin-2-2-0-2"] {
  margin: 1em 1em 0em 1em !important;
}
[class~="zs-padding-2-2-0.5-0"] {
  padding: 1em 1em 0.25em 0em !important;
}
[class~="zs-margin-2-2-0.5-0"] {
  margin: 1em 1em 0.25em 0em !important;
}
[class~="zs-padding-2-2-0.5-0.5"] {
  padding: 1em 1em 0.25em 0.25em !important;
}
[class~="zs-margin-2-2-0.5-0.5"] {
  margin: 1em 1em 0.25em 0.25em !important;
}
[class~="zs-padding-2-2-0.5-1"] {
  padding: 1em 1em 0.25em 0.5em !important;
}
[class~="zs-margin-2-2-0.5-1"] {
  margin: 1em 1em 0.25em 0.5em !important;
}
[class~="zs-padding-2-2-0.5-1.5"] {
  padding: 1em 1em 0.25em 0.75em !important;
}
[class~="zs-margin-2-2-0.5-1.5"] {
  margin: 1em 1em 0.25em 0.75em !important;
}
[class~="zs-padding-2-2-0.5-2"] {
  padding: 1em 1em 0.25em 1em !important;
}
[class~="zs-margin-2-2-0.5-2"] {
  margin: 1em 1em 0.25em 1em !important;
}
[class~="zs-padding-2-2-1-0"] {
  padding: 1em 1em 0.5em 0em !important;
}
[class~="zs-margin-2-2-1-0"] {
  margin: 1em 1em 0.5em 0em !important;
}
[class~="zs-padding-2-2-1-0.5"] {
  padding: 1em 1em 0.5em 0.25em !important;
}
[class~="zs-margin-2-2-1-0.5"] {
  margin: 1em 1em 0.5em 0.25em !important;
}
[class~="zs-padding-2-2-1-1"] {
  padding: 1em 1em 0.5em 0.5em !important;
}
[class~="zs-margin-2-2-1-1"] {
  margin: 1em 1em 0.5em 0.5em !important;
}
[class~="zs-padding-2-2-1-1.5"] {
  padding: 1em 1em 0.5em 0.75em !important;
}
[class~="zs-margin-2-2-1-1.5"] {
  margin: 1em 1em 0.5em 0.75em !important;
}
[class~="zs-padding-2-2-1-2"] {
  padding: 1em 1em 0.5em 1em !important;
}
[class~="zs-margin-2-2-1-2"] {
  margin: 1em 1em 0.5em 1em !important;
}
[class~="zs-padding-2-2-1.5-0"] {
  padding: 1em 1em 0.75em 0em !important;
}
[class~="zs-margin-2-2-1.5-0"] {
  margin: 1em 1em 0.75em 0em !important;
}
[class~="zs-padding-2-2-1.5-0.5"] {
  padding: 1em 1em 0.75em 0.25em !important;
}
[class~="zs-margin-2-2-1.5-0.5"] {
  margin: 1em 1em 0.75em 0.25em !important;
}
[class~="zs-padding-2-2-1.5-1"] {
  padding: 1em 1em 0.75em 0.5em !important;
}
[class~="zs-margin-2-2-1.5-1"] {
  margin: 1em 1em 0.75em 0.5em !important;
}
[class~="zs-padding-2-2-1.5-1.5"] {
  padding: 1em 1em 0.75em 0.75em !important;
}
[class~="zs-margin-2-2-1.5-1.5"] {
  margin: 1em 1em 0.75em 0.75em !important;
}
[class~="zs-padding-2-2-1.5-2"] {
  padding: 1em 1em 0.75em 1em !important;
}
[class~="zs-margin-2-2-1.5-2"] {
  margin: 1em 1em 0.75em 1em !important;
}
[class~="zs-padding-2-2-2-0"] {
  padding: 1em 1em 1em 0em !important;
}
[class~="zs-margin-2-2-2-0"] {
  margin: 1em 1em 1em 0em !important;
}
[class~="zs-padding-2-2-2-0.5"] {
  padding: 1em 1em 1em 0.25em !important;
}
[class~="zs-margin-2-2-2-0.5"] {
  margin: 1em 1em 1em 0.25em !important;
}
[class~="zs-padding-2-2-2-1"] {
  padding: 1em 1em 1em 0.5em !important;
}
[class~="zs-margin-2-2-2-1"] {
  margin: 1em 1em 1em 0.5em !important;
}
[class~="zs-padding-2-2-2-1.5"] {
  padding: 1em 1em 1em 0.75em !important;
}
[class~="zs-margin-2-2-2-1.5"] {
  margin: 1em 1em 1em 0.75em !important;
}
[class~="zs-padding-2-2-2-2"] {
  padding: 1em 1em 1em 1em !important;
}
[class~="zs-margin-2-2-2-2"] {
  margin: 1em 1em 1em 1em !important;
}
.zs-size-tiny,
.zs-size-t {
  font-size: 0.5em !important;
}
.zs-size-extra-extra-small,
.zs-size-xxs {
  font-size: 0.625em !important;
}
.zs-size-extra-small,
.zs-size-xs {
  font-size: 0.75em !important;
}
.zs-size-small,
.zs-size-s {
  font-size: 0.875em !important;
}
.zs-size-normal,
.zs-size-n {
  font-size: 1em !important;
}
.zs-size-large,
.zs-size-l {
  font-size: 1.25em !important;
}
.zs-size-extra-large,
.zs-size-xl {
  font-size: 1.5em !important;
}
.zs-size-extra-extra-large,
.zs-size-xxl {
  font-size: 2em !important;
}
.zs-size-super {
  font-size: 4em !important;
}
.zs-size-hero {
  font-size: 3em !important;
}
.zs-font-weight-light {
  font-weight: 300 !important;
}
.zs-font-weight-semi-bold {
  font-weight: 600 !important;
}
.zs-font-weight-normal {
  font-weight: normal !important;
}
.zs-font-weight-bold {
  font-weight: 700 !important;
}
.zs-font-style-oblique {
  font-style: oblique !important;
}
.zs-font-style-normal {
  font-style: normal !important;
}
.zs-font-style-italic {
  font-style: italic !important;
}
.zs-text-uppercase {
  text-transform: uppercase !important;
}
[class~="zs-shadow-flat-bottom-12"] {
  box-shadow: 0 12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-bottom-8"] {
  box-shadow: 0 8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-bottom-4"] {
  box-shadow: 0 4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-bottom-2"] {
  box-shadow: 0 2px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-12"] {
  box-shadow: 0 -12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-8"] {
  box-shadow: 0 -8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-4"] {
  box-shadow: 0 -4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-top-2"] {
  box-shadow: 0 -2px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-12"] {
  box-shadow: 12px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-8"] {
  box-shadow: 8px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-4"] {
  box-shadow: 4px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-right-2"] {
  box-shadow: 2px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-12"] {
  box-shadow: -12px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-8"] {
  box-shadow: -8px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-4"] {
  box-shadow: -4px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-left-2"] {
  box-shadow: -2px 0 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-12"] {
  box-shadow: 12px 12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-8"] {
  box-shadow: 8px 8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-4"] {
  box-shadow: 4px 4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-right-2"] {
  box-shadow: 2px 2px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-12"] {
  box-shadow: -12px 12px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-8"] {
  box-shadow: -8px 8px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-4"] {
  box-shadow: -4px 4px 0 rgba(26, 22, 40, 0.1) !important;
}
[class~="zs-shadow-flat-angle-left-2"] {
  box-shadow: -2px 2px 0 rgba(26, 22, 40, 0.1) !important;
}
.zs-shadow-none {
  box-shadow: none !important;
}
.zs-shadow-blur-1 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(26, 22, 40, 0.12), 0px 2px 4px rgba(26, 22, 40, 0.12) !important;
}
.zs-shadow-blur-2 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(26, 22, 40, 0.12), 0px 4px 8px rgba(26, 22, 40, 0.18) !important;
}
.zs-shadow-blur-3 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18) !important;
}
.zs-shadow-blur-4 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 16px 24px rgba(26, 22, 40, 0.2) !important;
}
.zs-shadow-blur-5 {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.08), 0px 16px 24px rgba(26, 22, 40, 0.1), 0px 24px 32px rgba(26, 22, 40, 0.14) !important;
}
.zs-layer-5 {
  z-index: 5000 !important;
}
.zs-layer-4 {
  z-index: 4000 !important;
}
.zs-layer-3 {
  z-index: 3000 !important;
}
.zs-layer-2 {
  z-index: 2000 !important;
}
.zs-layer-1 {
  z-index: 1000 !important;
}
.zs-layer-0 {
  z-index: 0 !important;
}
.zs-layout-flex {
  display: flex !important;
}
.zs-flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}
.zs-flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}
.zs-flex-direction-column {
  flex-direction: column !important;
}
.zs-flex-direction-row {
  flex-direction: row !important;
}
.zs-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.zs-flex-nowrap {
  flex-wrap: nowrap !important;
}
.zs-flex-wrap {
  flex-wrap: wrap !important;
}
.zs-flex-justify-space-evenly {
  justify-content: space-evenly !important;
}
.zs-flex-justify-space-around {
  justify-content: space-around !important;
}
.zs-flex-justify-space-between {
  justify-content: space-between !important;
}
.zs-flex-justify-right {
  justify-content: right !important;
}
.zs-flex-justify-left {
  justify-content: left !important;
}
.zs-flex-justify-center {
  justify-content: center !important;
}
.zs-flex-justify-end {
  justify-content: end !important;
}
.zs-flex-justify-start {
  justify-content: start !important;
}
.zs-flex-justify-flex-end {
  justify-content: flex-end !important;
}
.zs-flex-justify-flex-start {
  justify-content: flex-start !important;
}
.zs-flex-align-stretch {
  align-items: stretch !important;
}
.zs-flex-align-baseline {
  align-items: baseline !important;
}
.zs-flex-align-center {
  align-items: center !important;
}
.zs-flex-align-end {
  align-items: end !important;
}
.zs-flex-align-start {
  align-items: start !important;
}
.zs-flex-align-flex-end {
  align-items: flex-end !important;
}
.zs-flex-align-flex-start {
  align-items: flex-start !important;
}
.zs-flex-align-self-stretch {
  align-self: stretch !important;
}
.zs-flex-align-self-baseline {
  align-self: baseline !important;
}
.zs-flex-align-self-center {
  align-self: center !important;
}
.zs-flex-align-self-end {
  align-self: end !important;
}
.zs-flex-align-self-start {
  align-self: start !important;
}
.zs-flex-align-self-flex-end {
  align-self: flex-end !important;
}
.zs-flex-align-self-flex-start {
  align-self: flex-start !important;
}
.zs-flex-align-content-space-between {
  align-content: space-between !important;
}
.zs-flex-align-content-space-evenly {
  align-content: space-evenly !important;
}
.zs-flex-align-content-space-around {
  align-content: space-around !important;
}
.zs-flex-align-content-stretch {
  align-content: stretch !important;
}
.zs-flex-align-content-center {
  align-content: center !important;
}
.zs-flex-align-content-end {
  align-content: end !important;
}
.zs-flex-align-content-start {
  align-content: start !important;
}
.zs-flex-align-content-flex-end {
  align-content: flex-end !important;
}
.zs-flex-align-content-flex-start {
  align-content: flex-start !important;
}
.zs-loader {
  display: inline-block;
  line-height: 1;
}
.zs-loader [loader] {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  border: 0.125em solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}
.zs-loader [loader]:before,
.zs-loader [loader]:after,
.zs-loader [loader] span::after,
.zs-loader [loader] span::before {
  content: '';
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -0.125em;
  left: -0.125em;
  width: 1em;
  height: 1em;
  border: 0.125em solid #ffffff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffffff transparent transparent transparent;
}
.zs-loader [loader]::before {
  animation-delay: -0.45s;
}
.zs-loader [loader]::after {
  animation-delay: -0.3s;
}
.zs-loader [loader] span::before {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.zs-radio {
  display: block;
  position: relative;
  font-size: 1em;
  cursor: pointer;
}
.zs-radio input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: inherit;
}
.zs-radio input ~ [radio] {
  position: relative;
  padding: 0em 0em 0em 1.5em;
  font-size: 1em;
  line-height: 1.5;
  display: inline;
  letter-spacing: -0.013em;
}
.zs-radio input ~ [radio]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background: #FAFAFA;
  border: 1px solid #DEDCDE;
  transform: translateY(-50%);
}
.zs-radio input:hover ~ [radio]::before {
  border-color: #27A6A4;
}
.zs-radio input:checked ~ [radio]::before {
  border-color: #27A6A4;
}
.zs-radio input:checked ~ [radio]::after {
  position: absolute;
  top: 50%;
  left: 0.25em;
  content: '';
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background: #27A6A4;
  transform: translateY(-50%);
}
.zs-radio input:focus ~ [radio]::before {
  border-color: #2F6F7B;
  background: #F3FCFE;
}
.zs-radio input:focus:checked ~ [radio]::before {
  background: #FAFAFA;
}
.zs-radio input:focus:checked ~ [radio]::after {
  background: #2F6F7B;
}
.zs-radio[disabled],
.zs-radio.zs-disabled,
.zs-radio.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-radio[disabled] input ~ [radio],
.zs-radio.zs-disabled input ~ [radio],
.zs-radio.zs-calendar > table td[disabled] input ~ [radio] {
  color: #B2B0B6;
}
.zs-radio[disabled] input ~ [radio]::before,
.zs-radio.zs-disabled input ~ [radio]::before,
.zs-radio.zs-calendar > table td[disabled] input ~ [radio]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-radio[disabled] input:checked ~ [radio]::before,
.zs-radio.zs-disabled input:checked ~ [radio]::before,
.zs-radio.zs-calendar > table td[disabled] input:checked ~ [radio]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-radio[disabled] input:checked ~ [radio]::after,
.zs-radio.zs-disabled input:checked ~ [radio]::after,
.zs-radio.zs-calendar > table td[disabled] input:checked ~ [radio]::after {
  background: #B2B0B6;
}
.zs-checkbox {
  display: block;
  position: relative;
  font-size: 1em;
  cursor: pointer;
}
.zs-checkbox input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  font-size: inherit;
}
.zs-checkbox input ~ [checkbox] {
  position: relative;
  padding: 0em 0em 0em 1.5em;
  line-height: 1.5;
  display: inline;
  letter-spacing: -0.013em;
}
.zs-checkbox input ~ [checkbox]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  background: #ffffff;
  border: 1px solid #DEDCDE;
  transform: translateY(-50%);
}
.zs-checkbox input:hover ~ [checkbox]::before {
  border-color: #27A6A4;
}
.zs-checkbox input:checked ~ [checkbox]::before {
  border-color: #27A6A4;
  background: #27A6A4;
}
.zs-checkbox input:checked ~ [checkbox]::after {
  transform: translateY(-50%);
  content: "\ea2e";
  position: absolute;
  display: inline;
  color: #ffffff;
  font-family: 'zsIcons';
  top: 50%;
  left: 0;
}
.zs-checkbox input:indeterminate ~ [checkbox]::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 1em;
  height: 1em;
  background: #ffffff;
  border: 1px solid #DEDCDE;
  transform: translateY(-50%);
}
.zs-checkbox input:indeterminate ~ [checkbox]::after {
  display: inline;
  content: '';
  height: 0.5em;
  width: 0.5em;
  background: #27A6A4;
  top: 50%;
  position: absolute;
  left: 0;
  transform: translate(50%, -50%);
}
.zs-checkbox input:focus ~ [checkbox]::before {
  border-color: #2F6F7B;
  background: #F3FCFE;
}
.zs-checkbox input:checked:focus ~ [checkbox]::before {
  background: #2F6F7B;
}
.zs-checkbox input:focus:indeterminate ~ [checkbox]::before {
  background: #ffffff;
}
.zs-checkbox input:focus:indeterminate ~ [checkbox]::after {
  background: #2F6F7B;
}
.zs-checkbox[disabled],
.zs-checkbox.zs-disabled,
.zs-checkbox.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-checkbox[disabled] input ~ [checkbox],
.zs-checkbox.zs-disabled input ~ [checkbox],
.zs-checkbox.zs-calendar > table td[disabled] input ~ [checkbox] {
  color: #B2B0B6;
}
.zs-checkbox[disabled] input ~ [checkbox]::before,
.zs-checkbox.zs-disabled input ~ [checkbox]::before,
.zs-checkbox.zs-calendar > table td[disabled] input ~ [checkbox]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-checkbox[disabled] input:checked ~ [checkbox]::before,
.zs-checkbox.zs-disabled input:checked ~ [checkbox]::before,
.zs-checkbox.zs-calendar > table td[disabled] input:checked ~ [checkbox]::before {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border-color: #B2B0B6;
}
.zs-checkbox[disabled] input:checked ~ [checkbox]::after,
.zs-checkbox.zs-disabled input:checked ~ [checkbox]::after,
.zs-checkbox.zs-calendar > table td[disabled] input:checked ~ [checkbox]::after {
  color: #B2B0B6;
}
.zs-checkbox[disabled] input:indeterminate ~ [checkbox]::after,
.zs-checkbox.zs-disabled input:indeterminate ~ [checkbox]::after,
.zs-checkbox.zs-calendar > table td[disabled] input:indeterminate ~ [checkbox]::after {
  background: #B2B0B6;
}
.zs-field [field].zs-input-icon[class*='zs-icon-'] {
  font-size: 1.5em;
}
.zs-field [field].zs-input-icon[class*='zs-icon-'] [input],
.zs-field [field].zs-input-icon[class*='zs-icon-'] .zs-input {
  font-size: 0.66666667em;
}
.zs-field [field].zs-input-icon[class*='zs-icon-']::before {
  font-size: inherit;
}
.zs-field [field].zs-input-icon[class*='zs-icon-'] [clear] {
  font-size: inherit;
}
.zs-field.zs-select::after {
  font-size: 24px;
}
[hidden] {
  display: none !important;
}
[disabled] [input][disabled] {
  opacity: 1 !important;
}
.zs-field-shadow {
  display: block;
}
.zs-field {
  display: block;
}
.zs-field > label {
  display: inline-block;
}
.zs-field [input],
.zs-field [textarea] {
  outline: none;
  font-weight: normal;
  color: #2F2C3C;
  font-size: 1em;
  border-radius: 0;
  padding: 0.75em 0.75em 0.75em 0.75em;
  /**
	  * @todo a little hack to fix ZSUI-358
	  */
  margin-bottom: 0.5px;
  display: block;
}
.zs-field [input][type="number"],
.zs-field [textarea][type="number"] {
  text-align: right;
}
.zs-field [input][type="checkbox"],
.zs-field [textarea][type="checkbox"],
.zs-field [input][type="radio"],
.zs-field [textarea][type="radio"] {
  border: none;
}
.zs-field [input]:focus,
.zs-field [textarea]:focus,
.zs-field [input] :invalid:focus,
.zs-field [textarea] :invalid:focus {
  outline: none;
  box-shadow: none;
}
.zs-field [label] {
  display: block;
  font-size: 0.75em;
  padding: 0em 0em 0.66666667em 0em;
  font-weight: 700;
  letter-spacing: -0.013em;
  line-height: 1.37;
  color: #1A1628;
}
.zs-field [field].zs-input-icon {
  display: block;
  position: relative;
  z-index: 1;
}
.zs-field [field].zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-field [field].zs-input-icon [input] {
  padding-right: 3em !important;
}
.zs-field [field].zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-field [field].zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-field [field].zs-input-icon [input] {
  padding-right: 3em !important;
}
.zs-field [field].zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-field [clear] {
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
}
.zs-field [input]:not([size]):not([width]):not([type="image"]):not([type="checkbox"]):not([type="radio"]):not([type="submit"]):not([type="reset"]) {
  width: 100%;
}
.zs-field [field] {
  position: relative;
  display: block;
}
.zs-field textarea + [clear] {
  top: 0.66666667em;
  transform: none;
}
.zs-field [clear] {
  position: absolute;
  display: inline-block;
  top: 50%;
  transform: translate(0, -50%);
  border: 0;
  z-index: 2;
  text-align: center;
}
.zs-field [input][type="text"],
.zs-field [input][type="password"],
.zs-field [input][type="number"],
.zs-field [input][type="tel"],
.zs-field [input][type="url"],
.zs-field [input][type="email"],
.zs-field [input][type="search"],
.zs-field [input][type="time"],
.zs-field [input][type="week"],
.zs-field [input][type="date"] {
  height: 3.125em;
  line-height: 1.5;
  border: 1px solid #DEDCDE;
  background: #FAFAFA;
}
.zs-field [input][type="text"]::-webkit-input-placeholder,
.zs-field [input][type="password"]::-webkit-input-placeholder,
.zs-field [input][type="number"]::-webkit-input-placeholder,
.zs-field [input][type="tel"]::-webkit-input-placeholder,
.zs-field [input][type="url"]::-webkit-input-placeholder,
.zs-field [input][type="email"]::-webkit-input-placeholder,
.zs-field [input][type="search"]::-webkit-input-placeholder,
.zs-field [input][type="time"]::-webkit-input-placeholder,
.zs-field [input][type="week"]::-webkit-input-placeholder,
.zs-field [input][type="date"]::-webkit-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]:-moz-placeholder,
.zs-field [input][type="password"]:-moz-placeholder,
.zs-field [input][type="number"]:-moz-placeholder,
.zs-field [input][type="tel"]:-moz-placeholder,
.zs-field [input][type="url"]:-moz-placeholder,
.zs-field [input][type="email"]:-moz-placeholder,
.zs-field [input][type="search"]:-moz-placeholder,
.zs-field [input][type="time"]:-moz-placeholder,
.zs-field [input][type="week"]:-moz-placeholder,
.zs-field [input][type="date"]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]::-moz-placeholder,
.zs-field [input][type="password"]::-moz-placeholder,
.zs-field [input][type="number"]::-moz-placeholder,
.zs-field [input][type="tel"]::-moz-placeholder,
.zs-field [input][type="url"]::-moz-placeholder,
.zs-field [input][type="email"]::-moz-placeholder,
.zs-field [input][type="search"]::-moz-placeholder,
.zs-field [input][type="time"]::-moz-placeholder,
.zs-field [input][type="week"]::-moz-placeholder,
.zs-field [input][type="date"]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]:-ms-input-placeholder,
.zs-field [input][type="password"]:-ms-input-placeholder,
.zs-field [input][type="number"]:-ms-input-placeholder,
.zs-field [input][type="tel"]:-ms-input-placeholder,
.zs-field [input][type="url"]:-ms-input-placeholder,
.zs-field [input][type="email"]:-ms-input-placeholder,
.zs-field [input][type="search"]:-ms-input-placeholder,
.zs-field [input][type="time"]:-ms-input-placeholder,
.zs-field [input][type="week"]:-ms-input-placeholder,
.zs-field [input][type="date"]:-ms-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field [input][type="text"]:focus,
.zs-field [input][type="password"]:focus,
.zs-field [input][type="number"]:focus,
.zs-field [input][type="tel"]:focus,
.zs-field [input][type="url"]:focus,
.zs-field [input][type="email"]:focus,
.zs-field [input][type="search"]:focus,
.zs-field [input][type="time"]:focus,
.zs-field [input][type="week"]:focus,
.zs-field [input][type="date"]:focus {
  border: 1px solid #27A6A4;
}
.zs-field [input][type="text"]:hover,
.zs-field [input][type="password"]:hover,
.zs-field [input][type="number"]:hover,
.zs-field [input][type="tel"]:hover,
.zs-field [input][type="url"]:hover,
.zs-field [input][type="email"]:hover,
.zs-field [input][type="search"]:hover,
.zs-field [input][type="time"]:hover,
.zs-field [input][type="week"]:hover,
.zs-field [input][type="date"]:hover {
  border: 1px solid #9C9AA1;
}
.zs-field [input][type="text"]:active,
.zs-field [input][type="password"]:active,
.zs-field [input][type="number"]:active,
.zs-field [input][type="tel"]:active,
.zs-field [input][type="url"]:active,
.zs-field [input][type="email"]:active,
.zs-field [input][type="search"]:active,
.zs-field [input][type="time"]:active,
.zs-field [input][type="week"]:active,
.zs-field [input][type="date"]:active {
  border: 1px solid #27A6A4;
}
.zs-field [input][type="text"]:focus,
.zs-field [input][type="password"]:focus,
.zs-field [input][type="number"]:focus,
.zs-field [input][type="tel"]:focus,
.zs-field [input][type="url"]:focus,
.zs-field [input][type="email"]:focus,
.zs-field [input][type="search"]:focus,
.zs-field [input][type="time"]:focus,
.zs-field [input][type="week"]:focus,
.zs-field [input][type="date"]:focus {
  background: #F4F3F3;
}
.zs-field [input][type="text"]:hover,
.zs-field [input][type="password"]:hover,
.zs-field [input][type="number"]:hover,
.zs-field [input][type="tel"]:hover,
.zs-field [input][type="url"]:hover,
.zs-field [input][type="email"]:hover,
.zs-field [input][type="search"]:hover,
.zs-field [input][type="time"]:hover,
.zs-field [input][type="week"]:hover,
.zs-field [input][type="date"]:hover {
  background: #FAFAFA;
}
.zs-field [input][type="text"]:active,
.zs-field [input][type="password"]:active,
.zs-field [input][type="number"]:active,
.zs-field [input][type="tel"]:active,
.zs-field [input][type="url"]:active,
.zs-field [input][type="email"]:active,
.zs-field [input][type="search"]:active,
.zs-field [input][type="time"]:active,
.zs-field [input][type="week"]:active,
.zs-field [input][type="date"]:active {
  background: #F4F3F3;
}
.zs-field [input][type="text"]:focus:hover,
.zs-field [input][type="password"]:focus:hover,
.zs-field [input][type="number"]:focus:hover,
.zs-field [input][type="tel"]:focus:hover,
.zs-field [input][type="url"]:focus:hover,
.zs-field [input][type="email"]:focus:hover,
.zs-field [input][type="search"]:focus:hover,
.zs-field [input][type="time"]:focus:hover,
.zs-field [input][type="week"]:focus:hover,
.zs-field [input][type="date"]:focus:hover {
  border: 1px solid #27A6A4;
  background: #F4F3F3;
}
.zs-field [input][type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="password"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="number"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="tel"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="url"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="email"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="search"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="time"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="week"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="date"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field [input][type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="password"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="number"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="tel"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="url"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="email"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="search"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="time"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="week"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled),
.zs-field [input][type="date"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-field textarea[input] {
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  font-size: 1em;
  border-radius: 0;
  padding: 1em 1em 1em 1em;
  letter-spacing: 0.013em;
  outline: none;
  border: 1px solid #DEDCDE;
  background: #FAFAFA;
}
.zs-field textarea[input]::selection {
  background-color: #FFD680;
}
.zs-field textarea[input]::-moz-selection {
  background-color: #FFD680;
}
.zs-field textarea[input]::-webkit-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]:-ms-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-field textarea[input]:focus {
  border: 1px solid #27A6A4;
}
.zs-field textarea[input]:hover {
  border: 1px solid #9C9AA1;
}
.zs-field textarea[input]:active {
  border: 1px solid #27A6A4;
}
.zs-field textarea[input]:focus {
  background: #F4F3F3;
}
.zs-field textarea[input]:hover {
  background: #FAFAFA;
}
.zs-field textarea[input]:active {
  background: #F4F3F3;
}
.zs-field textarea[input]:focus:hover {
  border: 1px solid #27A6A4;
  background: #F4F3F3;
}
.zs-field textarea[input]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-field textarea[input]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-field.zs-error [input][type="text"],
.zs-field.zs-danger [input][type="text"],
.zs-field[invalid] [input][type="text"],
.zs-field.zs-error [input][type="password"],
.zs-field.zs-danger [input][type="password"],
.zs-field[invalid] [input][type="password"],
.zs-field.zs-error [input][type="number"],
.zs-field.zs-danger [input][type="number"],
.zs-field[invalid] [input][type="number"],
.zs-field.zs-error [input][type="tel"],
.zs-field.zs-danger [input][type="tel"],
.zs-field[invalid] [input][type="tel"],
.zs-field.zs-error [input][type="url"],
.zs-field.zs-danger [input][type="url"],
.zs-field[invalid] [input][type="url"],
.zs-field.zs-error [input][type="email"],
.zs-field.zs-danger [input][type="email"],
.zs-field[invalid] [input][type="email"],
.zs-field.zs-error [input][type="search"],
.zs-field.zs-danger [input][type="search"],
.zs-field[invalid] [input][type="search"],
.zs-field.zs-error [input][type="time"],
.zs-field.zs-danger [input][type="time"],
.zs-field[invalid] [input][type="time"],
.zs-field.zs-error [input][type="week"],
.zs-field.zs-danger [input][type="week"],
.zs-field[invalid] [input][type="week"],
.zs-field.zs-error [input][type="date"],
.zs-field.zs-danger [input][type="date"],
.zs-field[invalid] [input][type="date"] {
  border: 1px solid #B21111;
}
.zs-field.zs-error [input][type="text"]:focus,
.zs-field.zs-danger [input][type="text"]:focus,
.zs-field[invalid] [input][type="text"]:focus,
.zs-field.zs-error [input][type="password"]:focus,
.zs-field.zs-danger [input][type="password"]:focus,
.zs-field[invalid] [input][type="password"]:focus,
.zs-field.zs-error [input][type="number"]:focus,
.zs-field.zs-danger [input][type="number"]:focus,
.zs-field[invalid] [input][type="number"]:focus,
.zs-field.zs-error [input][type="tel"]:focus,
.zs-field.zs-danger [input][type="tel"]:focus,
.zs-field[invalid] [input][type="tel"]:focus,
.zs-field.zs-error [input][type="url"]:focus,
.zs-field.zs-danger [input][type="url"]:focus,
.zs-field[invalid] [input][type="url"]:focus,
.zs-field.zs-error [input][type="email"]:focus,
.zs-field.zs-danger [input][type="email"]:focus,
.zs-field[invalid] [input][type="email"]:focus,
.zs-field.zs-error [input][type="search"]:focus,
.zs-field.zs-danger [input][type="search"]:focus,
.zs-field[invalid] [input][type="search"]:focus,
.zs-field.zs-error [input][type="time"]:focus,
.zs-field.zs-danger [input][type="time"]:focus,
.zs-field[invalid] [input][type="time"]:focus,
.zs-field.zs-error [input][type="week"]:focus,
.zs-field.zs-danger [input][type="week"]:focus,
.zs-field[invalid] [input][type="week"]:focus,
.zs-field.zs-error [input][type="date"]:focus,
.zs-field.zs-danger [input][type="date"]:focus,
.zs-field[invalid] [input][type="date"]:focus {
  border: 1px solid #892208;
}
.zs-field.zs-error [input][type="text"]:hover,
.zs-field.zs-danger [input][type="text"]:hover,
.zs-field[invalid] [input][type="text"]:hover,
.zs-field.zs-error [input][type="password"]:hover,
.zs-field.zs-danger [input][type="password"]:hover,
.zs-field[invalid] [input][type="password"]:hover,
.zs-field.zs-error [input][type="number"]:hover,
.zs-field.zs-danger [input][type="number"]:hover,
.zs-field[invalid] [input][type="number"]:hover,
.zs-field.zs-error [input][type="tel"]:hover,
.zs-field.zs-danger [input][type="tel"]:hover,
.zs-field[invalid] [input][type="tel"]:hover,
.zs-field.zs-error [input][type="url"]:hover,
.zs-field.zs-danger [input][type="url"]:hover,
.zs-field[invalid] [input][type="url"]:hover,
.zs-field.zs-error [input][type="email"]:hover,
.zs-field.zs-danger [input][type="email"]:hover,
.zs-field[invalid] [input][type="email"]:hover,
.zs-field.zs-error [input][type="search"]:hover,
.zs-field.zs-danger [input][type="search"]:hover,
.zs-field[invalid] [input][type="search"]:hover,
.zs-field.zs-error [input][type="time"]:hover,
.zs-field.zs-danger [input][type="time"]:hover,
.zs-field[invalid] [input][type="time"]:hover,
.zs-field.zs-error [input][type="week"]:hover,
.zs-field.zs-danger [input][type="week"]:hover,
.zs-field[invalid] [input][type="week"]:hover,
.zs-field.zs-error [input][type="date"]:hover,
.zs-field.zs-danger [input][type="date"]:hover,
.zs-field[invalid] [input][type="date"]:hover {
  border: 1px solid #F27755;
}
.zs-field.zs-error [input][type="text"]:active,
.zs-field.zs-danger [input][type="text"]:active,
.zs-field[invalid] [input][type="text"]:active,
.zs-field.zs-error [input][type="password"]:active,
.zs-field.zs-danger [input][type="password"]:active,
.zs-field[invalid] [input][type="password"]:active,
.zs-field.zs-error [input][type="number"]:active,
.zs-field.zs-danger [input][type="number"]:active,
.zs-field[invalid] [input][type="number"]:active,
.zs-field.zs-error [input][type="tel"]:active,
.zs-field.zs-danger [input][type="tel"]:active,
.zs-field[invalid] [input][type="tel"]:active,
.zs-field.zs-error [input][type="url"]:active,
.zs-field.zs-danger [input][type="url"]:active,
.zs-field[invalid] [input][type="url"]:active,
.zs-field.zs-error [input][type="email"]:active,
.zs-field.zs-danger [input][type="email"]:active,
.zs-field[invalid] [input][type="email"]:active,
.zs-field.zs-error [input][type="search"]:active,
.zs-field.zs-danger [input][type="search"]:active,
.zs-field[invalid] [input][type="search"]:active,
.zs-field.zs-error [input][type="time"]:active,
.zs-field.zs-danger [input][type="time"]:active,
.zs-field[invalid] [input][type="time"]:active,
.zs-field.zs-error [input][type="week"]:active,
.zs-field.zs-danger [input][type="week"]:active,
.zs-field[invalid] [input][type="week"]:active,
.zs-field.zs-error [input][type="date"]:active,
.zs-field.zs-danger [input][type="date"]:active,
.zs-field[invalid] [input][type="date"]:active {
  border: 1px solid #5C1A0B;
}
.zs-field.zs-error textarea,
.zs-field.zs-danger textarea,
.zs-field[invalid] textarea,
.zs-field.zs-error textarea[input],
.zs-field.zs-danger textarea[input],
.zs-field[invalid] textarea[input] {
  border: 1px solid #B21111;
}
.zs-field.zs-error textarea:focus,
.zs-field.zs-danger textarea:focus,
.zs-field[invalid] textarea:focus,
.zs-field.zs-error textarea[input]:focus,
.zs-field.zs-danger textarea[input]:focus,
.zs-field[invalid] textarea[input]:focus {
  border: 1px solid #892208;
}
.zs-field.zs-error textarea:hover,
.zs-field.zs-danger textarea:hover,
.zs-field[invalid] textarea:hover,
.zs-field.zs-error textarea[input]:hover,
.zs-field.zs-danger textarea[input]:hover,
.zs-field[invalid] textarea[input]:hover {
  border: 1px solid #F27755;
}
.zs-field.zs-error textarea:active,
.zs-field.zs-danger textarea:active,
.zs-field[invalid] textarea:active,
.zs-field.zs-error textarea[input]:active,
.zs-field.zs-danger textarea[input]:active,
.zs-field[invalid] textarea[input]:active {
  border: 1px solid #5C1A0B;
}
.zs-field.zs-error [clear],
.zs-field.zs-danger [clear],
.zs-field[invalid] [clear] {
  color: #B21111;
}
.zs-field.zs-error [clear]:visited,
.zs-field.zs-danger [clear]:visited,
.zs-field[invalid] [clear]:visited {
  color: #B21111;
}
.zs-field.zs-error [clear]:focus,
.zs-field.zs-danger [clear]:focus,
.zs-field[invalid] [clear]:focus {
  color: #892208;
}
.zs-field.zs-error [clear]:hover,
.zs-field.zs-danger [clear]:hover,
.zs-field[invalid] [clear]:hover {
  color: #F27755;
}
.zs-field.zs-error [clear]:active,
.zs-field.zs-danger [clear]:active,
.zs-field[invalid] [clear]:active {
  color: #5C1A0B;
}
.zs-field.zs-success [input][type="text"],
.zs-field.zs-success [input][type="password"],
.zs-field.zs-success [input][type="number"],
.zs-field.zs-success [input][type="tel"],
.zs-field.zs-success [input][type="url"],
.zs-field.zs-success [input][type="email"],
.zs-field.zs-success [input][type="search"],
.zs-field.zs-success [input][type="time"],
.zs-field.zs-success [input][type="week"],
.zs-field.zs-success [input][type="date"] {
  border: 1px solid #00AA67;
}
.zs-field.zs-success [input][type="text"]:focus,
.zs-field.zs-success [input][type="password"]:focus,
.zs-field.zs-success [input][type="number"]:focus,
.zs-field.zs-success [input][type="tel"]:focus,
.zs-field.zs-success [input][type="url"]:focus,
.zs-field.zs-success [input][type="email"]:focus,
.zs-field.zs-success [input][type="search"]:focus,
.zs-field.zs-success [input][type="time"]:focus,
.zs-field.zs-success [input][type="week"]:focus,
.zs-field.zs-success [input][type="date"]:focus {
  border: 1px solid #0A6E5E;
}
.zs-field.zs-success [input][type="text"]:hover,
.zs-field.zs-success [input][type="password"]:hover,
.zs-field.zs-success [input][type="number"]:hover,
.zs-field.zs-success [input][type="tel"]:hover,
.zs-field.zs-success [input][type="url"]:hover,
.zs-field.zs-success [input][type="email"]:hover,
.zs-field.zs-success [input][type="search"]:hover,
.zs-field.zs-success [input][type="time"]:hover,
.zs-field.zs-success [input][type="week"]:hover,
.zs-field.zs-success [input][type="date"]:hover {
  border: 1px solid #54D7AF;
}
.zs-field.zs-success [input][type="text"]:active,
.zs-field.zs-success [input][type="password"]:active,
.zs-field.zs-success [input][type="number"]:active,
.zs-field.zs-success [input][type="tel"]:active,
.zs-field.zs-success [input][type="url"]:active,
.zs-field.zs-success [input][type="email"]:active,
.zs-field.zs-success [input][type="search"]:active,
.zs-field.zs-success [input][type="time"]:active,
.zs-field.zs-success [input][type="week"]:active,
.zs-field.zs-success [input][type="date"]:active {
  border: 1px solid #0C4846;
}
.zs-field.zs-success textarea,
.zs-field.zs-success textarea[input] {
  border: 1px solid #00AA67;
}
.zs-field.zs-success textarea:focus,
.zs-field.zs-success textarea[input]:focus {
  border: 1px solid #0A6E5E;
}
.zs-field.zs-success textarea:hover,
.zs-field.zs-success textarea[input]:hover {
  border: 1px solid #54D7AF;
}
.zs-field.zs-success textarea:active,
.zs-field.zs-success textarea[input]:active {
  border: 1px solid #0C4846;
}
.zs-field.zs-success [clear] {
  color: #00AA67;
}
.zs-field.zs-success [clear]:visited {
  color: #00AA67;
}
.zs-field.zs-success [clear]:focus {
  color: #0A6E5E;
}
.zs-field.zs-success [clear]:hover {
  color: #54D7AF;
}
.zs-field.zs-success [clear]:active {
  color: #0C4846;
}
.zs-field.zs-warning [input][type="text"],
.zs-field.zs-warning [input][type="password"],
.zs-field.zs-warning [input][type="number"],
.zs-field.zs-warning [input][type="tel"],
.zs-field.zs-warning [input][type="url"],
.zs-field.zs-warning [input][type="email"],
.zs-field.zs-warning [input][type="search"],
.zs-field.zs-warning [input][type="time"],
.zs-field.zs-warning [input][type="week"],
.zs-field.zs-warning [input][type="date"] {
  border: 1px solid #E3A900;
}
.zs-field.zs-warning [input][type="text"]:focus,
.zs-field.zs-warning [input][type="password"]:focus,
.zs-field.zs-warning [input][type="number"]:focus,
.zs-field.zs-warning [input][type="tel"]:focus,
.zs-field.zs-warning [input][type="url"]:focus,
.zs-field.zs-warning [input][type="email"]:focus,
.zs-field.zs-warning [input][type="search"]:focus,
.zs-field.zs-warning [input][type="time"]:focus,
.zs-field.zs-warning [input][type="week"]:focus,
.zs-field.zs-warning [input][type="date"]:focus {
  border: 1px solid #8A640C;
}
.zs-field.zs-warning [input][type="text"]:hover,
.zs-field.zs-warning [input][type="password"]:hover,
.zs-field.zs-warning [input][type="number"]:hover,
.zs-field.zs-warning [input][type="tel"]:hover,
.zs-field.zs-warning [input][type="url"]:hover,
.zs-field.zs-warning [input][type="email"]:hover,
.zs-field.zs-warning [input][type="search"]:hover,
.zs-field.zs-warning [input][type="time"]:hover,
.zs-field.zs-warning [input][type="week"]:hover,
.zs-field.zs-warning [input][type="date"]:hover {
  border: 1px solid #FDCA63;
}
.zs-field.zs-warning [input][type="text"]:active,
.zs-field.zs-warning [input][type="password"]:active,
.zs-field.zs-warning [input][type="number"]:active,
.zs-field.zs-warning [input][type="tel"]:active,
.zs-field.zs-warning [input][type="url"]:active,
.zs-field.zs-warning [input][type="email"]:active,
.zs-field.zs-warning [input][type="search"]:active,
.zs-field.zs-warning [input][type="time"]:active,
.zs-field.zs-warning [input][type="week"]:active,
.zs-field.zs-warning [input][type="date"]:active {
  border: 1px solid #563F0E;
}
.zs-field.zs-warning textarea,
.zs-field.zs-warning textarea[input] {
  border: 1px solid #E3A900;
}
.zs-field.zs-warning textarea:focus,
.zs-field.zs-warning textarea[input]:focus {
  border: 1px solid #8A640C;
}
.zs-field.zs-warning textarea:hover,
.zs-field.zs-warning textarea[input]:hover {
  border: 1px solid #FDCA63;
}
.zs-field.zs-warning textarea:active,
.zs-field.zs-warning textarea[input]:active {
  border: 1px solid #563F0E;
}
.zs-field.zs-warning [clear] {
  color: #E3A900;
}
.zs-field.zs-warning [clear]:visited {
  color: #E3A900;
}
.zs-field.zs-warning [clear]:focus {
  color: #8A640C;
}
.zs-field.zs-warning [clear]:hover {
  color: #FDCA63;
}
.zs-field.zs-warning [clear]:active {
  color: #563F0E;
}
.zs-field.zs-info [input][type="text"],
.zs-field.zs-info [input][type="password"],
.zs-field.zs-info [input][type="number"],
.zs-field.zs-info [input][type="tel"],
.zs-field.zs-info [input][type="url"],
.zs-field.zs-info [input][type="email"],
.zs-field.zs-info [input][type="search"],
.zs-field.zs-info [input][type="time"],
.zs-field.zs-info [input][type="week"],
.zs-field.zs-info [input][type="date"] {
  border: 1px solid #2B44C7;
}
.zs-field.zs-info [input][type="text"]:focus,
.zs-field.zs-info [input][type="password"]:focus,
.zs-field.zs-info [input][type="number"]:focus,
.zs-field.zs-info [input][type="tel"]:focus,
.zs-field.zs-info [input][type="url"]:focus,
.zs-field.zs-info [input][type="email"]:focus,
.zs-field.zs-info [input][type="search"]:focus,
.zs-field.zs-info [input][type="time"]:focus,
.zs-field.zs-info [input][type="week"]:focus,
.zs-field.zs-info [input][type="date"]:focus {
  border: 1px solid #141187;
}
.zs-field.zs-info [input][type="text"]:hover,
.zs-field.zs-info [input][type="password"]:hover,
.zs-field.zs-info [input][type="number"]:hover,
.zs-field.zs-info [input][type="tel"]:hover,
.zs-field.zs-info [input][type="url"]:hover,
.zs-field.zs-info [input][type="email"]:hover,
.zs-field.zs-info [input][type="search"]:hover,
.zs-field.zs-info [input][type="time"]:hover,
.zs-field.zs-info [input][type="week"]:hover,
.zs-field.zs-info [input][type="date"]:hover {
  border: 1px solid #7E92E9;
}
.zs-field.zs-info [input][type="text"]:active,
.zs-field.zs-info [input][type="password"]:active,
.zs-field.zs-info [input][type="number"]:active,
.zs-field.zs-info [input][type="tel"]:active,
.zs-field.zs-info [input][type="url"]:active,
.zs-field.zs-info [input][type="email"]:active,
.zs-field.zs-info [input][type="search"]:active,
.zs-field.zs-info [input][type="time"]:active,
.zs-field.zs-info [input][type="week"]:active,
.zs-field.zs-info [input][type="date"]:active {
  border: 1px solid #160C60;
}
.zs-field.zs-info textarea,
.zs-field.zs-info textarea[input] {
  border: 1px solid #2B44C7;
}
.zs-field.zs-info textarea:focus,
.zs-field.zs-info textarea[input]:focus {
  border: 1px solid #141187;
}
.zs-field.zs-info textarea:hover,
.zs-field.zs-info textarea[input]:hover {
  border: 1px solid #7E92E9;
}
.zs-field.zs-info textarea:active,
.zs-field.zs-info textarea[input]:active {
  border: 1px solid #160C60;
}
.zs-field.zs-info [clear] {
  color: #2B44C7;
}
.zs-field.zs-info [clear]:visited {
  color: #2B44C7;
}
.zs-field.zs-info [clear]:focus {
  color: #141187;
}
.zs-field.zs-info [clear]:hover {
  color: #7E92E9;
}
.zs-field.zs-info [clear]:active {
  color: #160C60;
}
.zs-field[type="radio"] [label],
.zs-field[type="checkbox"] [label] {
  padding: 0;
  font-size: 1em;
  line-height: 1.5;
  letter-spacing: -0.013em;
}
.zs-field[disabled],
.zs-field.zs-disabled,
.zs-field.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-field[disabled] [input][type="text"],
.zs-field.zs-disabled [input][type="text"],
.zs-field[disabled] [input][type="password"],
.zs-field.zs-disabled [input][type="password"],
.zs-field[disabled] [input][type="number"],
.zs-field.zs-disabled [input][type="number"],
.zs-field[disabled] [input][type="tel"],
.zs-field.zs-disabled [input][type="tel"],
.zs-field[disabled] [input][type="url"],
.zs-field.zs-disabled [input][type="url"],
.zs-field[disabled] [input][type="email"],
.zs-field.zs-disabled [input][type="email"],
.zs-field[disabled] [input][type="search"],
.zs-field.zs-disabled [input][type="search"],
.zs-field[disabled] [input][type="time"],
.zs-field.zs-disabled [input][type="time"],
.zs-field[disabled] [input][type="week"],
.zs-field.zs-disabled [input][type="week"],
.zs-field[disabled] [input][type="date"],
.zs-field.zs-disabled [input][type="date"],
.zs-field.zs-calendar > table td[disabled] [input][type="text"],
.zs-field.zs-calendar > table td[disabled] [input][type="password"],
.zs-field.zs-calendar > table td[disabled] [input][type="number"],
.zs-field.zs-calendar > table td[disabled] [input][type="tel"],
.zs-field.zs-calendar > table td[disabled] [input][type="url"],
.zs-field.zs-calendar > table td[disabled] [input][type="email"],
.zs-field.zs-calendar > table td[disabled] [input][type="search"],
.zs-field.zs-calendar > table td[disabled] [input][type="time"],
.zs-field.zs-calendar > table td[disabled] [input][type="week"],
.zs-field.zs-calendar > table td[disabled] [input][type="date"] {
  border: 1px solid #B2B0B6;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-field[disabled] [input][type="text"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="text"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="password"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="password"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="number"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="number"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="tel"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="tel"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="url"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="url"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="email"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="email"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="search"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="search"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="time"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="time"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="week"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="week"]::-webkit-input-placeholder,
.zs-field[disabled] [input][type="date"]::-webkit-input-placeholder,
.zs-field.zs-disabled [input][type="date"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]::-webkit-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] [input][type="text"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="text"]:-moz-placeholder,
.zs-field[disabled] [input][type="password"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="password"]:-moz-placeholder,
.zs-field[disabled] [input][type="number"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="number"]:-moz-placeholder,
.zs-field[disabled] [input][type="tel"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="tel"]:-moz-placeholder,
.zs-field[disabled] [input][type="url"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="url"]:-moz-placeholder,
.zs-field[disabled] [input][type="email"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="email"]:-moz-placeholder,
.zs-field[disabled] [input][type="search"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="search"]:-moz-placeholder,
.zs-field[disabled] [input][type="time"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="time"]:-moz-placeholder,
.zs-field[disabled] [input][type="week"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="week"]:-moz-placeholder,
.zs-field[disabled] [input][type="date"]:-moz-placeholder,
.zs-field.zs-disabled [input][type="date"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]:-moz-placeholder {
  /* Firefox 18- */
  color: #B2B0B6;
}
.zs-field[disabled] [input][type="text"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="text"]::-moz-placeholder,
.zs-field[disabled] [input][type="password"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="password"]::-moz-placeholder,
.zs-field[disabled] [input][type="number"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="number"]::-moz-placeholder,
.zs-field[disabled] [input][type="tel"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="tel"]::-moz-placeholder,
.zs-field[disabled] [input][type="url"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="url"]::-moz-placeholder,
.zs-field[disabled] [input][type="email"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="email"]::-moz-placeholder,
.zs-field[disabled] [input][type="search"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="search"]::-moz-placeholder,
.zs-field[disabled] [input][type="time"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="time"]::-moz-placeholder,
.zs-field[disabled] [input][type="week"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="week"]::-moz-placeholder,
.zs-field[disabled] [input][type="date"]::-moz-placeholder,
.zs-field.zs-disabled [input][type="date"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #B2B0B6;
}
.zs-field[disabled] [input][type="text"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="text"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="password"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="password"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="number"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="number"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="tel"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="tel"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="url"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="url"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="email"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="email"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="search"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="search"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="time"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="time"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="week"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="week"]:-ms-input-placeholder,
.zs-field[disabled] [input][type="date"]:-ms-input-placeholder,
.zs-field.zs-disabled [input][type="date"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="text"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="password"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="number"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="tel"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="url"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="email"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="search"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="time"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="week"]:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] [input][type="date"]:-ms-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] textarea,
.zs-field.zs-disabled textarea,
.zs-field.zs-calendar > table td[disabled] textarea {
  border: 1px solid #B2B0B6;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-field[disabled] textarea::-webkit-input-placeholder,
.zs-field.zs-disabled textarea::-webkit-input-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea::-webkit-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] textarea:-moz-placeholder,
.zs-field.zs-disabled textarea:-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #B2B0B6;
}
.zs-field[disabled] textarea::-moz-placeholder,
.zs-field.zs-disabled textarea::-moz-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #B2B0B6;
}
.zs-field[disabled] textarea:-ms-input-placeholder,
.zs-field.zs-disabled textarea:-ms-input-placeholder,
.zs-field.zs-calendar > table td[disabled] textarea:-ms-input-placeholder {
  color: #B2B0B6;
}
.zs-field[disabled] [clear],
.zs-field.zs-disabled [clear],
.zs-field[disabled] [field].zs-input-icon::before,
.zs-field.zs-disabled [field].zs-input-icon::before,
.zs-field.zs-calendar > table td[disabled] [clear],
.zs-field.zs-calendar > table td[disabled] [field].zs-input-icon::before {
  color: #B2B0B6;
}
.zs-field[type="checkbox"] > label,
.zs-field[type="radio"] > label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  font-weight: normal;
  cursor: pointer;
}
.zs-field[type="checkbox"] > label [label],
.zs-field[type="radio"] > label [label] {
  font-weight: normal;
}
.zs-field[type="checkbox"][disabled],
.zs-field[type="radio"][disabled],
.zs-field[type="checkbox"].zs-disabled,
.zs-field[type="radio"].zs-disabled,
.zs-field[type="checkbox"].zs-calendar > table td[disabled],
.zs-field[type="radio"].zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-field[type="checkbox"][disabled] [input],
.zs-field[type="radio"][disabled] [input],
.zs-field[type="checkbox"].zs-disabled [input],
.zs-field[type="radio"].zs-disabled [input],
.zs-field[type="checkbox"].zs-calendar > table td[disabled] [input],
.zs-field[type="radio"].zs-calendar > table td[disabled] [input] {
  opacity: 0 !important;
}
.zs-field[type="checkbox"][disabled] [label],
.zs-field[type="radio"][disabled] [label],
.zs-field[type="checkbox"].zs-disabled [label],
.zs-field[type="radio"].zs-disabled [label],
.zs-field[type="checkbox"].zs-calendar > table td[disabled] [label],
.zs-field[type="radio"].zs-calendar > table td[disabled] [label] {
  color: #B2B0B6;
}
.zs-field[type="checkbox"] .zs-input-icon::before,
.zs-field[type="radio"] .zs-input-icon::before {
  display: none;
}
.zs-field[type="checkbox"] [clear],
.zs-field[type="radio"] [clear] {
  display: none;
}
.zs-field[type="checkbox"] [input],
.zs-field[type="radio"] [input] {
  width: auto;
}
.zs-field[type="color"] [input],
.zs-field[type="image"] [input] {
  padding: 0;
  border: 0;
}
.zs-field[clear][icon]:not([value=""]) [field]::before {
  display: none;
}
.zs-field[clear][icon]:not([value]) [field]::before {
  display: inline-block;
}
.zs-field-fluid,
.zs-master-style p.zs-field-fluid {
  margin: 0;
}
.zs-field-fluid:not([type="checkbox"]):not([type="radio"]) > label,
.zs-master-style p.zs-field-fluid:not([type="checkbox"]):not([type="radio"]) > label {
  display: block;
}
.zs-action-field {
  font-size: 1em;
  display: inline-block;
}
.zs-action-field > label,
.zs-action-field [field-container] {
  display: grid;
  display: -ms-grid;
  grid-template-rows: auto auto auto;
  -ms-grid-rows: auto auto auto;
  grid-template-columns: auto 1fr auto auto;
  -ms-grid-columns: auto 1fr auto auto;
}
.zs-action-field [label] {
  display: inline-block;
  -ms-grid-row: 1;
  grid-row-start: 1;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  font-size: 0.75em;
  padding: 0em 0em 0.66666667em 0em;
  font-weight: 700;
  letter-spacing: -0.013em;
  line-height: 1.37;
  color: #1A1628;
}
.zs-action-field [label]:empty {
  padding: 0;
}
.zs-action-field [helper] {
  display: inline-block;
  -ms-grid-row: 3;
  grid-row-start: 3;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  font-size: 0.75em;
  padding: 0.66666667em 0em 0em 0em;
  letter-spacing: -0.013em;
  line-height: 1.5;
  color: #716E79;
}
.zs-action-field [helper]:empty {
  padding: 0;
}
.zs-action-field [input] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  grid-column-end: span 4;
  -ms-grid-column-span: 4;
  line-height: 1.5;
  font-size: 1em;
  border-radius: 0;
  padding: 0.75em 0.75em 0.75em 0.75em;
  height: calc((1.5 + 2*0.75)*1em + 2px);
  outline: none;
  border: 1px solid #DEDCDE;
  background: #FAFAFA;
}
.zs-action-field [input]::-webkit-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]:-moz-placeholder {
  /* Firefox 18- */
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]::-moz-placeholder {
  /* Firefox 19+ */
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]:-ms-input-placeholder {
  font-style: italic;
  color: #716E79;
}
.zs-action-field [input]:focus {
  border: 1px solid #27A6A4;
}
.zs-action-field [input]:hover {
  border: 1px solid #9C9AA1;
}
.zs-action-field [input]:active {
  border: 1px solid #27A6A4;
}
.zs-action-field [input]:focus {
  background: #F4F3F3;
}
.zs-action-field [input]:hover {
  background: #FAFAFA;
}
.zs-action-field [input]:active {
  background: #F4F3F3;
}
.zs-action-field [input]:focus:hover {
  border: 1px solid #27A6A4;
  background: #F4F3F3;
}
.zs-action-field [input]:hover ~ [signifier] {
  color: #716E79;
}
.zs-action-field [input][value]:not([value=""]):not(:focus):not(:hover):not(:active) ~ [signifier] {
  color: #1A1628;
}
.zs-action-field [input]:focus:hover ~ [signifier] {
  color: #27A6A4;
}
.zs-action-field [input]:focus ~ [signifier] {
  color: #27A6A4;
}
.zs-action-field [input]:active ~ [signifier] {
  color: #27A6A4;
}
.zs-action-field input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-action-field [action] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 4;
  grid-column-start: 4;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  font-size: 1.5em;
  margin: 0em 0.5em 0em 0em;
}
.zs-action-field [signifier] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  color: #716E79;
  font-size: 1.5em;
  margin: 0em 0.5em 0em 0.5em;
}
.zs-action-field [hint] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 3;
  grid-column-start: 3;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  font-size: 1.5em;
  margin: 0em 0.33333333em 0em 0em;
}
.zs-action-field [clear] {
  -ms-grid-row: 2;
  grid-row-start: 2;
  -ms-grid-column: 3;
  grid-column-start: 3;
  align-self: center;
  justify-self: center;
  -ms-grid-row-align: center;
  font-size: 1.5em;
  margin: 0em 0.33333333em 0em 0em;
}
.zs-action-field[clear]:not([action-icon]) [clear] {
  margin: 0em 0.5em 0em 0em;
}
.zs-action-field[hint-icon]:not([action-icon]) [hint] {
  margin: 0em 0.5em 0em 0em;
}
.zs-action-field.zs-error [input],
.zs-action-field.zs-danger [input],
.zs-action-field[invalid] [input] {
  border: 1px solid #B21111;
}
.zs-action-field.zs-error [input]:focus,
.zs-action-field.zs-danger [input]:focus,
.zs-action-field[invalid] [input]:focus {
  border: 1px solid #892208;
}
.zs-action-field.zs-error [input]:hover,
.zs-action-field.zs-danger [input]:hover,
.zs-action-field[invalid] [input]:hover {
  border: 1px solid #F27755;
}
.zs-action-field.zs-error [input]:active,
.zs-action-field.zs-danger [input]:active,
.zs-action-field[invalid] [input]:active {
  border: 1px solid #5C1A0B;
}
.zs-action-field.zs-error [action],
.zs-action-field.zs-danger [action],
.zs-action-field[invalid] [action],
.zs-action-field.zs-error [hint],
.zs-action-field.zs-danger [hint],
.zs-action-field[invalid] [hint],
.zs-action-field.zs-error [clear],
.zs-action-field.zs-danger [clear],
.zs-action-field[invalid] [clear] {
  color: #B21111;
}
.zs-action-field.zs-error [action]:visited,
.zs-action-field.zs-danger [action]:visited,
.zs-action-field[invalid] [action]:visited,
.zs-action-field.zs-error [hint]:visited,
.zs-action-field.zs-danger [hint]:visited,
.zs-action-field[invalid] [hint]:visited,
.zs-action-field.zs-error [clear]:visited,
.zs-action-field.zs-danger [clear]:visited,
.zs-action-field[invalid] [clear]:visited {
  color: #B21111;
}
.zs-action-field.zs-error [action]:focus,
.zs-action-field.zs-danger [action]:focus,
.zs-action-field[invalid] [action]:focus,
.zs-action-field.zs-error [hint]:focus,
.zs-action-field.zs-danger [hint]:focus,
.zs-action-field[invalid] [hint]:focus,
.zs-action-field.zs-error [clear]:focus,
.zs-action-field.zs-danger [clear]:focus,
.zs-action-field[invalid] [clear]:focus {
  color: #892208;
}
.zs-action-field.zs-error [action]:hover,
.zs-action-field.zs-danger [action]:hover,
.zs-action-field[invalid] [action]:hover,
.zs-action-field.zs-error [hint]:hover,
.zs-action-field.zs-danger [hint]:hover,
.zs-action-field[invalid] [hint]:hover,
.zs-action-field.zs-error [clear]:hover,
.zs-action-field.zs-danger [clear]:hover,
.zs-action-field[invalid] [clear]:hover {
  color: #F27755;
}
.zs-action-field.zs-error [action]:active,
.zs-action-field.zs-danger [action]:active,
.zs-action-field[invalid] [action]:active,
.zs-action-field.zs-error [hint]:active,
.zs-action-field.zs-danger [hint]:active,
.zs-action-field[invalid] [hint]:active,
.zs-action-field.zs-error [clear]:active,
.zs-action-field.zs-danger [clear]:active,
.zs-action-field[invalid] [clear]:active {
  color: #5C1A0B;
}
.zs-action-field.zs-error [helper],
.zs-action-field.zs-danger [helper],
.zs-action-field[invalid] [helper] {
  color: #B21111;
}
.zs-action-field.zs-success [input] {
  border: 1px solid #00AA67;
}
.zs-action-field.zs-success [input]:focus {
  border: 1px solid #0A6E5E;
}
.zs-action-field.zs-success [input]:hover {
  border: 1px solid #54D7AF;
}
.zs-action-field.zs-success [input]:active {
  border: 1px solid #0C4846;
}
.zs-action-field.zs-success [action],
.zs-action-field.zs-success [hint],
.zs-action-field.zs-success [clear] {
  color: #00AA67;
}
.zs-action-field.zs-success [action]:visited,
.zs-action-field.zs-success [hint]:visited,
.zs-action-field.zs-success [clear]:visited {
  color: #00AA67;
}
.zs-action-field.zs-success [action]:focus,
.zs-action-field.zs-success [hint]:focus,
.zs-action-field.zs-success [clear]:focus {
  color: #0A6E5E;
}
.zs-action-field.zs-success [action]:hover,
.zs-action-field.zs-success [hint]:hover,
.zs-action-field.zs-success [clear]:hover {
  color: #54D7AF;
}
.zs-action-field.zs-success [action]:active,
.zs-action-field.zs-success [hint]:active,
.zs-action-field.zs-success [clear]:active {
  color: #0C4846;
}
.zs-action-field.zs-success [helper] {
  color: #00AA67;
}
.zs-action-field.zs-warning [input] {
  border: 1px solid #E3A900;
}
.zs-action-field.zs-warning [input]:focus {
  border: 1px solid #8A640C;
}
.zs-action-field.zs-warning [input]:hover {
  border: 1px solid #FDCA63;
}
.zs-action-field.zs-warning [input]:active {
  border: 1px solid #563F0E;
}
.zs-action-field.zs-warning [action],
.zs-action-field.zs-warning [hint],
.zs-action-field.zs-warning [clear] {
  color: #E3A900;
}
.zs-action-field.zs-warning [action]:visited,
.zs-action-field.zs-warning [hint]:visited,
.zs-action-field.zs-warning [clear]:visited {
  color: #E3A900;
}
.zs-action-field.zs-warning [action]:focus,
.zs-action-field.zs-warning [hint]:focus,
.zs-action-field.zs-warning [clear]:focus {
  color: #8A640C;
}
.zs-action-field.zs-warning [action]:hover,
.zs-action-field.zs-warning [hint]:hover,
.zs-action-field.zs-warning [clear]:hover {
  color: #FDCA63;
}
.zs-action-field.zs-warning [action]:active,
.zs-action-field.zs-warning [hint]:active,
.zs-action-field.zs-warning [clear]:active {
  color: #563F0E;
}
.zs-action-field.zs-warning [helper] {
  color: #E3A900;
}
.zs-action-field.zs-info [input] {
  border: 1px solid #2B44C7;
}
.zs-action-field.zs-info [input]:focus {
  border: 1px solid #141187;
}
.zs-action-field.zs-info [input]:hover {
  border: 1px solid #7E92E9;
}
.zs-action-field.zs-info [input]:active {
  border: 1px solid #160C60;
}
.zs-action-field.zs-info [action],
.zs-action-field.zs-info [hint],
.zs-action-field.zs-info [clear] {
  color: #2B44C7;
}
.zs-action-field.zs-info [action]:visited,
.zs-action-field.zs-info [hint]:visited,
.zs-action-field.zs-info [clear]:visited {
  color: #2B44C7;
}
.zs-action-field.zs-info [action]:focus,
.zs-action-field.zs-info [hint]:focus,
.zs-action-field.zs-info [clear]:focus {
  color: #141187;
}
.zs-action-field.zs-info [action]:hover,
.zs-action-field.zs-info [hint]:hover,
.zs-action-field.zs-info [clear]:hover {
  color: #7E92E9;
}
.zs-action-field.zs-info [action]:active,
.zs-action-field.zs-info [hint]:active,
.zs-action-field.zs-info [clear]:active {
  color: #160C60;
}
.zs-action-field.zs-info [helper] {
  color: #2B44C7;
}
.zs-action-field[disabled],
.zs-action-field.zs-disabled,
.zs-action-field.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-action-field[disabled] [input],
.zs-action-field.zs-disabled [input],
.zs-action-field.zs-calendar > table td[disabled] [input] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  border: 1px solid #B2B0B6;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-action-field[disabled] [input]::-webkit-input-placeholder,
.zs-action-field.zs-disabled [input]::-webkit-input-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]::-webkit-input-placeholder {
  color: #B2B0B6;
}
.zs-action-field[disabled] [input]:-moz-placeholder,
.zs-action-field.zs-disabled [input]:-moz-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]:-moz-placeholder {
  /* Firefox 18- */
  color: #B2B0B6;
}
.zs-action-field[disabled] [input]::-moz-placeholder,
.zs-action-field.zs-disabled [input]::-moz-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]::-moz-placeholder {
  /* Firefox 19+ */
  color: #B2B0B6;
}
.zs-action-field[disabled] [input]:-ms-input-placeholder,
.zs-action-field.zs-disabled [input]:-ms-input-placeholder,
.zs-action-field.zs-calendar > table td[disabled] [input]:-ms-input-placeholder {
  color: #B2B0B6;
}
.zs-action-field[disabled] [clear],
.zs-action-field.zs-disabled [clear],
.zs-action-field[disabled] [hint],
.zs-action-field.zs-disabled [hint],
.zs-action-field[disabled] [action],
.zs-action-field.zs-disabled [action],
.zs-action-field[disabled] [signifier],
.zs-action-field.zs-disabled [signifier],
.zs-action-field.zs-calendar > table td[disabled] [clear],
.zs-action-field.zs-calendar > table td[disabled] [hint],
.zs-action-field.zs-calendar > table td[disabled] [action],
.zs-action-field.zs-calendar > table td[disabled] [signifier] {
  color: #B2B0B6;
}
.zs-action-field.zs-action-field-solid [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #DEDCDE;
  background: #FAFAFA;
}
.zs-action-field.zs-action-field-solid [input]:focus {
  box-shadow: inset 0 -2px 0 0 #27A6A4;
}
.zs-action-field.zs-action-field-solid [input]:hover {
  box-shadow: inset 0 -2px 0 0 #9C9AA1;
}
.zs-action-field.zs-action-field-solid [input]:active {
  box-shadow: inset 0 -2px 0 0 #27A6A4;
}
.zs-action-field.zs-action-field-solid [input]:focus {
  background: #F4F3F3;
}
.zs-action-field.zs-action-field-solid [input]:hover {
  background: #F4F3F3;
}
.zs-action-field.zs-action-field-solid [input]:active {
  background: #F4F3F3;
}
.zs-action-field.zs-action-field-solid [input]:focus:hover {
  border: none;
  box-shadow: inset 0 -2px 0 0 #27A6A4;
}
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]),
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""] {
  box-shadow: none;
}
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #FAFAFA;
}
.zs-action-field.zs-action-field-solid.zs-error [input],
.zs-action-field.zs-action-field-solid.zs-danger [input],
.zs-action-field.zs-action-field-solid[invalid] [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #B21111;
}
.zs-action-field.zs-action-field-solid.zs-error [input]:focus,
.zs-action-field.zs-action-field-solid.zs-danger [input]:focus,
.zs-action-field.zs-action-field-solid[invalid] [input]:focus {
  box-shadow: inset 0 -2px 0 0 #892208;
}
.zs-action-field.zs-action-field-solid.zs-error [input]:hover,
.zs-action-field.zs-action-field-solid.zs-danger [input]:hover,
.zs-action-field.zs-action-field-solid[invalid] [input]:hover {
  box-shadow: inset 0 -2px 0 0 #F27755;
}
.zs-action-field.zs-action-field-solid.zs-error [input]:active,
.zs-action-field.zs-action-field-solid.zs-danger [input]:active,
.zs-action-field.zs-action-field-solid[invalid] [input]:active {
  box-shadow: inset 0 -2px 0 0 #5C1A0B;
}
.zs-action-field.zs-action-field-solid.zs-success [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #00AA67;
}
.zs-action-field.zs-action-field-solid.zs-success [input]:focus {
  box-shadow: inset 0 -2px 0 0 #0A6E5E;
}
.zs-action-field.zs-action-field-solid.zs-success [input]:hover {
  box-shadow: inset 0 -2px 0 0 #54D7AF;
}
.zs-action-field.zs-action-field-solid.zs-success [input]:active {
  box-shadow: inset 0 -2px 0 0 #0C4846;
}
.zs-action-field.zs-action-field-solid.zs-warning [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #E3A900;
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:focus {
  box-shadow: inset 0 -2px 0 0 #8A640C;
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:hover {
  box-shadow: inset 0 -2px 0 0 #FDCA63;
}
.zs-action-field.zs-action-field-solid.zs-warning [input]:active {
  box-shadow: inset 0 -2px 0 0 #563F0E;
}
.zs-action-field.zs-action-field-solid.zs-info [input] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 -2px 0 0 #2B44C7;
}
.zs-action-field.zs-action-field-solid.zs-info [input]:focus {
  box-shadow: inset 0 -2px 0 0 #141187;
}
.zs-action-field.zs-action-field-solid.zs-info [input]:hover {
  box-shadow: inset 0 -2px 0 0 #7E92E9;
}
.zs-action-field.zs-action-field-solid.zs-info [input]:active {
  box-shadow: inset 0 -2px 0 0 #160C60;
}
.zs-action-field.zs-action-field-secondary [input] {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary [input]:focus {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary [input]:hover {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary [input]:active {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary [input]:focus:hover {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field.zs-action-field-secondary input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input] {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:focus {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:hover {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:active {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid [input]:focus:hover {
  background: #ffffff;
}
.zs-action-field.zs-action-field-secondary.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-action-field.zs-action-field-secondary.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-action-field[icon] [input] {
  padding: 0.75em 0.75em 0.75em 3em;
}
.zs-action-field[action-icon] [input],
.zs-action-field[hint-icon] [input],
.zs-action-field[clear] [input] {
  padding-right: 3em;
}
.zs-action-field[action-icon][hint-icon] [input],
.zs-action-field[clear][action-icon] [input] {
  padding-right: 5em;
}
.zs-display-none {
  display: none;
}
[hidden] {
  display: none !important;
}
.zs-message {
  padding: 0.25em 0.25em 0.25em 0.25em;
  position: relative;
  font-size: 1em;
  line-height: 1.5;
  color: #2F2C3C;
  margin: 0.5em 0em 0.5em 0em;
}
.zs-message:first-child {
  margin: 1em 0em 0.5em 0em;
}
.zs-action-field .zs-message {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-column-end: span 3;
  -ms-grid-column-span: 3;
  grid-row-start: 4;
  -ms-grid-row: 4;
}
.zs-message.zs-error,
.zs-error > .zs-message {
  border: 1px solid #FFB6A5;
  color: #2F2C3C;
  background: #FFEDE9;
  padding: 0.5em 1em 0.5em 2.5em;
}
.zs-message.zs-error:before,
.zs-error > .zs-message:before {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 0em;
  font-family: 'zsIcons';
  font-size: 1em;
  left: 1em;
  color: #B21111;
  content: "\eaa8";
}
.zs-message.zs-warning,
.zs-warning > .zs-message {
  border: 1px solid #E3A900;
  color: #2F2C3C;
  background: #FFF9F1;
  padding: 0.5em 1em 0.5em 2.5em;
}
.zs-message.zs-warning:before,
.zs-warning > .zs-message:before {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 0em;
  font-family: 'zsIcons';
  font-size: 1em;
  left: 1em;
  color: #E3A900;
  content: "\eaa2";
}
.zs-message.zs-success,
.zs-success > .zs-message {
  border: 1px solid #00AA67;
  color: #2F2C3C;
  background: #D2FAF7;
  padding: 0.5em 1em 0.5em 2.5em;
}
.zs-message.zs-success:before,
.zs-success > .zs-message:before {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 0em;
  font-family: 'zsIcons';
  font-size: 1em;
  left: 1em;
  color: #00AA67;
  content: "\ea30";
}
.zs-message.zs-info,
.zs-info > .zs-message {
  border: 1px solid #A6B2F3;
  color: #2F2C3C;
  background: #EEEEFF;
  padding: 0.5em 1em 0.5em 2.5em;
}
.zs-message.zs-info:before,
.zs-info > .zs-message:before {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  line-height: 0em;
  font-family: 'zsIcons';
  font-size: 1em;
  left: 1em;
  color: #2B44C7;
  content: "\eabb";
}
.zs-select {
  position: relative;
  padding: 0;
  display: inline-block;
  min-width: 15.625em;
}
.zs-select select,
.zs-select select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #1A1628;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-select select::-ms-expand {
  display: none;
}
.zs-select:after {
  content: "\ea26";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-select:focus.zs-select:after {
  color: #454250;
}
.zs-select:hover.zs-select:after {
  color: #9C9AA1;
}
.zs-select:active.zs-select:after {
  color: #2F2C3C;
}
.zs-select[disabled] select,
.zs-select.zs-disabled select,
.zs-select.zs-calendar > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-select[disabled]:after,
.zs-select.zs-disabled:after,
.zs-select.zs-calendar > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-select.zs-danger select,
.zs-select[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-select.zs-danger select:focus,
.zs-select[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-select.zs-danger select:hover,
.zs-select[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-select.zs-danger select:active,
.zs-select[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-select.zs-danger:after,
.zs-select[invalid]:after {
  color: #B21111;
}
.zs-select.zs-danger:focus:after,
.zs-select[invalid]:focus:after {
  color: #892208;
}
.zs-select.zs-danger:hover:after,
.zs-select[invalid]:hover:after {
  color: #F27755;
}
.zs-select.zs-danger:active:after,
.zs-select[invalid]:active:after {
  color: #5C1A0B;
}
.zs-select.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-select.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-select.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-select.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-select.zs-success:after {
  color: #00AA67;
}
.zs-select.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-select.zs-success:hover:after {
  color: #54D7AF;
}
.zs-select.zs-success:active:after {
  color: #0C4846;
}
.zs-select.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-select.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-select.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-select.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-select.zs-warning:after {
  color: #E3A900;
}
.zs-select.zs-warning:focus:after {
  color: #8A640C;
}
.zs-select.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-select.zs-warning:active:after {
  color: #563F0E;
}
.zs-select.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-select.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-select.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-select.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-select.zs-info:after {
  color: #2B44C7;
}
.zs-select.zs-info:focus:after {
  color: #141187;
}
.zs-select.zs-info:hover:after {
  color: #7E92E9;
}
.zs-select.zs-info:active:after {
  color: #160C60;
}
.zs-select > [overlay] {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 0;
  line-height: 1.5;
  letter-spacing: -0.2px;
  outline: 0;
  font-weight: 400;
  border: 1px solid #DEDCDE;
  color: #1A1628;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*
        * Deprecated `placeholder-active` class since v5.4.0 and will be removed in v6.0. Use `zs-placeholder-active` class instead.
        */
  padding: 0.75em 2.75em 0.75em 0.75em;
  max-width: none;
}
.zs-select > [overlay].zs-placeholder-active,
.zs-select > [overlay].placeholder-active {
  color: #716E79;
  font-style: italic;
}
.zs-select > [overlay]:focus {
  border: 1px solid #27A6A4;
}
.zs-select.zs-field[label]:after,
.zs-select.zs-action-field[label]:after {
  top: auto;
  bottom: 0.5em;
}
.zs-select.zs-action-field > select {
  display: none;
}
.zs-select.zs-action-field > select [label] {
  display: block;
}
.zs-select.zs-action-field.zs-danger > [overlay],
.zs-select.zs-action-field[invalid] > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-select.zs-action-field.zs-danger > [overlay]:focus,
.zs-select.zs-action-field[invalid] > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-select.zs-action-field.zs-danger > [overlay]:hover,
.zs-select.zs-action-field[invalid] > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-select.zs-action-field.zs-danger > [overlay]:active,
.zs-select.zs-action-field[invalid] > [overlay]:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-select.zs-action-field.zs-success > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-select.zs-action-field.zs-success > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-select.zs-action-field.zs-success > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-select.zs-action-field.zs-success > [overlay]:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-select.zs-action-field.zs-warning > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-select.zs-action-field.zs-warning > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-select.zs-action-field.zs-warning > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-select.zs-action-field.zs-warning > [overlay]:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-select.zs-action-field.zs-info > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-select.zs-action-field.zs-info > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-select.zs-action-field.zs-info > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-select.zs-action-field.zs-info > [overlay]:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-select[disabled] > [overlay],
.zs-select.zs-disabled > [overlay],
.zs-select.zs-calendar > table td[disabled] > [overlay] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-select.zs-field {
  display: inline-block;
}
.zs-select.zs-field > select {
  display: none;
}
.zs-select.zs-field > select [label] {
  display: block;
}
.zs-select.zs-field[label]:after {
  top: 66%;
}
.zs-select.zs-field[invalid] [overlay] {
  background: #B21111;
  border: 1px solid #B21111;
  outline: none;
}
.zs-select.zs-field[invalid] [overlay]:focus {
  background: #892208;
}
.zs-select.zs-field[invalid] [overlay]:hover {
  background: #F27755;
}
.zs-select.zs-field[invalid] [overlay]:active {
  background: #5C1A0B;
}
.zs-select.zs-field[invalid] [overlay]:focus {
  border: 1px solid #892208;
}
.zs-select.zs-field[invalid] [overlay]:hover {
  border: 1px solid #F27755;
}
.zs-select.zs-field[invalid] [overlay]:active {
  border: 1px solid #5C1A0B;
}
.zs-select.zs-field[clear][value]:not([value=""]) p.zs-icon-search::before {
  display: none;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown [input] {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown [input]:focus {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown [input]:hover {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown [input]:active {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown [input]:focus:hover {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown.zs-action-field-solid [input] {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown.zs-action-field-solid [input]:focus {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown.zs-action-field-solid [input]:hover {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown.zs-action-field-solid [input]:active {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown.zs-action-field-solid [input]:focus:hover {
  background: #ffffff;
}
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown.zs-action-field-solid input[type="text"]:not(:focus):not(:hover):not([value]):not(:disabled):not(.zs-disabled),
.zs-select.zs-action-field.zs-action-field-secondary .zs-search-dropdown.zs-action-field-solid input[type="text"]:not(:focus):not(:hover)[value=""]:not(:disabled):not(.zs-disabled) {
  background: #ffffff;
}
.zs-search-dropdown {
  margin: -1px;
  display: block;
  background: #FAFAFA;
  border-radius: 0em;
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
  min-width: auto;
  max-width: none;
  background: #ffffff;
  border-radius: 0.3em;
  border: 1px solid #e1e9ef;
  box-shadow: 0 3px 6px #dee3e3;
  box-sizing: border-box;
  display: inline-block;
  min-width: 150px;
  max-width: 400px;
  z-index: 2000;
  border: 1px solid #DEDCDE;
}
.zs-search-dropdown > a,
.zs-search-dropdown > li,
.zs-search-dropdown > li > a,
.zs-search-dropdown > ul > li > a,
.zs-search-dropdown > nav > a {
  color: #2F2C3C;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 1.5em 0.5em 1.5em;
  line-height: 1.5;
}
.zs-search-dropdown > a span,
.zs-search-dropdown > li span,
.zs-search-dropdown > li > a span,
.zs-search-dropdown > ul > li > a span,
.zs-search-dropdown > nav > a span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.zs-search-dropdown > a.zs-active,
.zs-search-dropdown > li.zs-active,
.zs-search-dropdown > li > a.zs-active,
.zs-search-dropdown > ul > li > a.zs-active,
.zs-search-dropdown > nav > a.zs-active {
  background: #27A6A4;
}
.zs-search-dropdown > a:hover,
.zs-search-dropdown > li:hover,
.zs-search-dropdown > li > a:hover,
.zs-search-dropdown > ul > li > a:hover,
.zs-search-dropdown > nav > a:hover {
  background: rgba(39, 166, 164, 0.1);
  color: inherit;
}
.zs-search-dropdown > a:first-child,
.zs-search-dropdown > li:first-child,
.zs-search-dropdown > li > a:first-child,
.zs-search-dropdown > ul > li > a:first-child,
.zs-search-dropdown > nav > a:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.zs-search-dropdown > a:last-child,
.zs-search-dropdown > li:last-child,
.zs-search-dropdown > li > a:last-child,
.zs-search-dropdown > ul > li > a:last-child,
.zs-search-dropdown > nav > a:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.zs-search-dropdown > a:active,
.zs-search-dropdown > li:active,
.zs-search-dropdown > li > a:active,
.zs-search-dropdown > ul > li > a:active,
.zs-search-dropdown > nav > a:active {
  background: #2D8B93;
  color: inherit;
}
.zs-search-dropdown > a > *:not(.zs-icon),
.zs-search-dropdown > li > *:not(.zs-icon),
.zs-search-dropdown > li > a > *:not(.zs-icon),
.zs-search-dropdown > ul > li > a > *:not(.zs-icon),
.zs-search-dropdown > nav > a > *:not(.zs-icon) {
  vertical-align: middle;
}
.zs-search-dropdown > li > a {
  padding: 0;
  color: inherit;
}
.zs-search-dropdown > li > a:focus,
.zs-search-dropdown > li > a:hover,
.zs-search-dropdown > li > a:active,
.zs-search-dropdown > li > a.zs-active {
  color: inherit;
  background: transparent;
}
.zs-search-dropdown > li > a:focus {
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-search-dropdown > a,
.zs-search-dropdown > li,
.zs-search-dropdown > li > a,
.zs-search-dropdown > ul > li > a,
.zs-search-dropdown > nav > a {
  color: #2F2C3C;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 1.5em 0.5em 1.5em;
  line-height: 1.5;
}
.zs-search-dropdown > a span,
.zs-search-dropdown > li span,
.zs-search-dropdown > li > a span,
.zs-search-dropdown > ul > li > a span,
.zs-search-dropdown > nav > a span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.zs-search-dropdown > a.zs-active,
.zs-search-dropdown > li.zs-active,
.zs-search-dropdown > li > a.zs-active,
.zs-search-dropdown > ul > li > a.zs-active,
.zs-search-dropdown > nav > a.zs-active {
  background: #27A6A4;
}
.zs-search-dropdown > a:hover,
.zs-search-dropdown > li:hover,
.zs-search-dropdown > li > a:hover,
.zs-search-dropdown > ul > li > a:hover,
.zs-search-dropdown > nav > a:hover {
  background: rgba(39, 166, 164, 0.1);
  color: inherit;
}
.zs-search-dropdown > a:first-child,
.zs-search-dropdown > li:first-child,
.zs-search-dropdown > li > a:first-child,
.zs-search-dropdown > ul > li > a:first-child,
.zs-search-dropdown > nav > a:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.zs-search-dropdown > a:last-child,
.zs-search-dropdown > li:last-child,
.zs-search-dropdown > li > a:last-child,
.zs-search-dropdown > ul > li > a:last-child,
.zs-search-dropdown > nav > a:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.zs-search-dropdown > a:active,
.zs-search-dropdown > li:active,
.zs-search-dropdown > li > a:active,
.zs-search-dropdown > ul > li > a:active,
.zs-search-dropdown > nav > a:active {
  background: #2D8B93;
  color: inherit;
}
.zs-search-dropdown > a > *:not(.zs-icon),
.zs-search-dropdown > li > *:not(.zs-icon),
.zs-search-dropdown > li > a > *:not(.zs-icon),
.zs-search-dropdown > ul > li > a > *:not(.zs-icon),
.zs-search-dropdown > nav > a > *:not(.zs-icon) {
  vertical-align: middle;
}
.zs-search-dropdown > li > a {
  padding: 0;
  color: inherit;
}
.zs-search-dropdown > li > a:focus,
.zs-search-dropdown > li > a:hover,
.zs-search-dropdown > li > a:active,
.zs-search-dropdown > li > a.zs-active {
  color: inherit;
  background: transparent;
}
.zs-search-dropdown > li > a:focus {
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-search-dropdown > nav {
  max-height: 12em;
  overflow: auto;
}
.zs-search-dropdown > nav > a[optgroup-link-parent] {
  padding-left: 2.5em;
}
.zs-search-dropdown > nav > a[hover] {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-search-dropdown > nav > a[active] {
  color: #ffffff;
  background: #27A6A4;
}
.zs-search-dropdown > nav > a[hover]:visited {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-search-dropdown > nav > a[active]:visited {
  color: #ffffff;
  background: #27A6A4;
}
.zs-search-dropdown > nav > a[active][checkbox-link]:not([hover]) {
  color: #2F2C3C;
  background: transparent;
}
.zs-search-dropdown > nav > a[active][checkbox-link][hover],
.zs-search-dropdown > nav > a[active][checkbox-link]:hover {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
.zs-search-dropdown > nav > a[active][checkbox-link]:active {
  color: #ffffff;
  background: #2D8B93;
}
.zs-search-dropdown > nav > a:not([index]):not(.select-all) {
  color: #716E79;
  background: transparent;
}
.zs-search-dropdown > nav > a:not([index]):not(.select-all):focus,
.zs-search-dropdown > nav > a:not([index]):not(.select-all):active,
.zs-search-dropdown > nav > a:not([index]):not(.select-all):hover {
  color: #716E79;
  background: transparent;
}
.zs-search-dropdown.zs-action-field [hidden] {
  display: none !important;
}
.zs-search-dropdown.zs-action-field [input] {
  width: 100%;
  height: calc((1.5 + 1.5)*1em + 2px);
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  padding-left: 3em;
}
.zs-search-dropdown.zs-action-field[action-icon] [clear] {
  margin-right: 0.33333333em;
}
.zs-search-dropdown.zs-action-field [signifier] {
  font-size: 1.5em;
  margin: 0em 0.5em 0em 0.5em;
}
.zs-search-dropdown.zs-action-field [action] {
  margin: 0em 0.5em 0em 0em;
}
.zs-search-dropdown > p {
  margin: 0.5em 0.5em 0.5em 0.5em !important;
  margin-top: 0.5em !important;
  display: block;
  position: relative;
}
.zs-search-dropdown > p.zs-icon-search::before {
  margin-top: 0;
  right: 0;
}
.zs-search-dropdown > p > input.zs-input {
  width: 100%;
  min-width: 100%;
}
.zs-search-dropdown > p .zs-input-icon {
  display: block;
  position: relative;
  z-index: 1;
}
.zs-search-dropdown > p .zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-search-dropdown > p .zs-input-icon [input] {
  padding-right: 3em !important;
}
.zs-search-dropdown > p .zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-search-dropdown > p .zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-search-dropdown > p .zs-input-icon [input] {
  padding-right: 3em !important;
}
.zs-search-dropdown > p .zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-search-dropdown [highlight] {
  background-color: #FFD680;
}
.zs-search-dropdown .select-all {
  border-bottom: 1px solid #DEDCDE;
}
.zs-search-dropdown input[type="checkbox"] {
  margin: 0em 0.5em 0em 0em;
  vertical-align: middle;
}
.zs-search-dropdown .no-search-results {
  text-align: center;
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.zs-open-dropdown.zs-select:after {
  content: '';
}
.zs-open-dropdown .zs-menu {
  box-shadow: none;
}
.zs-open-dropdown p .zs-input {
  min-width: 100%;
}
.zs-open-dropdown a:focus {
  outline: 0;
}
.zs-input {
  /**
	  * @todo a little hack to fix ZSUI-358
	  */
  margin-bottom: 0.5px;
}
.zs-table {
  font-size: 1em;
  border-collapse: collapse;
  border-spacing: 0;
  color: #454250;
  margin: 0.5em 1em 0.5em 1em;
}
.zs-table caption {
  text-align: left;
  margin: 0em 0em 1em 0em;
  font-size: 0.75em;
  color: #9C9AA1;
}
.zs-table:first-child {
  margin-top: 0;
}
.zs-table:first-child > caption {
  margin-top: 0;
}
.zs-table tr > td,
.zs-table tr > th {
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  border: 1px solid #DEDCDE;
  border-left: 0px;
  border-right: 0px;
  text-align: left;
  vertical-align: top;
}
.zs-table tr > td.zs-action-icons,
.zs-table tr > th.zs-action-icons {
  padding-top: 0.3em;
  padding-bottom: 0;
}
.zs-table tr > td.zs-actions,
.zs-table tr > th.zs-actions {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  vertical-align: middle;
}
.zs-table tr > td:focus,
.zs-table tr > th:focus {
  outline: 1px solid #27A6A4;
}
.zs-table tr > th {
  min-height: auto;
  height: auto;
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid #716E79;
  vertical-align: bottom;
}
.zs-table tr > th:focus {
  outline: 1px solid #27A6A4;
}
.zs-table tr > th a[sort] {
  color: #454250;
}
.zs-table tr > th a[sort]:visited {
  color: #454250;
}
.zs-table tr > th a[sort]:focus {
  color: #454250;
}
.zs-table tr > th a[sort]:hover {
  color: #454250;
}
.zs-table tr > th a[sort]:active {
  color: #454250;
}
.zs-table tr > th[sort],
.zs-table tr > th [sort] {
  padding-right: 1.75em;
  position: relative;
}
.zs-table tr > th[sort]::after,
.zs-table tr > th [sort]::after {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1.25em;
  font-family: 'zsIcons';
  content: "\ea1e";
  color: #716E79;
}
.zs-table tr > th[sort]:hover::after,
.zs-table tr > th [sort]:hover::after {
  color: #9C9AA1;
}
.zs-table tr > th[sort="asc"]::after,
.zs-table tr > th [sort="asc"]::after {
  color: #27A6A4;
  content: "\eb57";
}
.zs-table tr > th[sort="desc"]::after,
.zs-table tr > th [sort="desc"]::after {
  color: #27A6A4;
  content: "\eb56";
}
.zs-table tr > th[sort="asc"]:hover::after,
.zs-table tr > th [sort="asc"]:hover::after,
.zs-table tr > th[sort="desc"]:hover::after,
.zs-table tr > th [sort="desc"]:hover::after {
  color: #27A6A4;
}
.zs-table tr > th [sort] {
  cursor: pointer;
}
.zs-table tr > th [sort]::after {
  font-size: 1.25em;
}
.zs-table tr:focus {
  outline: 1px solid #27A6A4;
}
.zs-table td,
.zs-table th,
.zs-table .zs-td {
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  border: 1px solid #DEDCDE;
  border-left: 0px;
  border-right: 0px;
  text-align: left;
  vertical-align: top;
}
.zs-table td.zs-action-icons,
.zs-table th.zs-action-icons,
.zs-table .zs-td.zs-action-icons {
  padding-top: 0.3em;
  padding-bottom: 0;
}
.zs-table td.zs-actions,
.zs-table th.zs-actions,
.zs-table .zs-td.zs-actions {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  vertical-align: middle;
}
.zs-table td:focus,
.zs-table th:focus,
.zs-table .zs-td:focus {
  outline: 1px solid #27A6A4;
}
.zs-table > thead td,
.zs-table > thead th,
.zs-table > tr > th,
.zs-table > th,
.zs-table > tbody > tr > th,
.zs-table .zs-th,
.zs-table > tfoot td,
.zs-table > tfoot th {
  min-height: auto;
  height: auto;
  padding: 1em 1em 1em 1em;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  background: none;
  border-bottom: 1px solid #716E79;
  vertical-align: bottom;
}
.zs-table > thead td:focus,
.zs-table > thead th:focus,
.zs-table > tr > th:focus,
.zs-table > th:focus,
.zs-table > tbody > tr > th:focus,
.zs-table .zs-th:focus,
.zs-table > tfoot td:focus,
.zs-table > tfoot th:focus {
  outline: 1px solid #27A6A4;
}
.zs-table > thead td a[sort],
.zs-table > thead th a[sort],
.zs-table > tr > th a[sort],
.zs-table > th a[sort],
.zs-table > tbody > tr > th a[sort],
.zs-table .zs-th a[sort],
.zs-table > tfoot td a[sort],
.zs-table > tfoot th a[sort] {
  color: #454250;
}
.zs-table > thead td a[sort]:visited,
.zs-table > thead th a[sort]:visited,
.zs-table > tr > th a[sort]:visited,
.zs-table > th a[sort]:visited,
.zs-table > tbody > tr > th a[sort]:visited,
.zs-table .zs-th a[sort]:visited,
.zs-table > tfoot td a[sort]:visited,
.zs-table > tfoot th a[sort]:visited {
  color: #454250;
}
.zs-table > thead td a[sort]:focus,
.zs-table > thead th a[sort]:focus,
.zs-table > tr > th a[sort]:focus,
.zs-table > th a[sort]:focus,
.zs-table > tbody > tr > th a[sort]:focus,
.zs-table .zs-th a[sort]:focus,
.zs-table > tfoot td a[sort]:focus,
.zs-table > tfoot th a[sort]:focus {
  color: #454250;
}
.zs-table > thead td a[sort]:hover,
.zs-table > thead th a[sort]:hover,
.zs-table > tr > th a[sort]:hover,
.zs-table > th a[sort]:hover,
.zs-table > tbody > tr > th a[sort]:hover,
.zs-table .zs-th a[sort]:hover,
.zs-table > tfoot td a[sort]:hover,
.zs-table > tfoot th a[sort]:hover {
  color: #454250;
}
.zs-table > thead td a[sort]:active,
.zs-table > thead th a[sort]:active,
.zs-table > tr > th a[sort]:active,
.zs-table > th a[sort]:active,
.zs-table > tbody > tr > th a[sort]:active,
.zs-table .zs-th a[sort]:active,
.zs-table > tfoot td a[sort]:active,
.zs-table > tfoot th a[sort]:active {
  color: #454250;
}
.zs-table > thead td[sort],
.zs-table > thead th[sort],
.zs-table > tr > th[sort],
.zs-table > th[sort],
.zs-table > tbody > tr > th[sort],
.zs-table .zs-th[sort],
.zs-table > tfoot td[sort],
.zs-table > tfoot th[sort],
.zs-table > thead td [sort],
.zs-table > thead th [sort],
.zs-table > tr > th [sort],
.zs-table > th [sort],
.zs-table > tbody > tr > th [sort],
.zs-table .zs-th [sort],
.zs-table > tfoot td [sort],
.zs-table > tfoot th [sort] {
  padding-right: 1.75em;
  position: relative;
}
.zs-table > thead td[sort]::after,
.zs-table > thead th[sort]::after,
.zs-table > tr > th[sort]::after,
.zs-table > th[sort]::after,
.zs-table > tbody > tr > th[sort]::after,
.zs-table .zs-th[sort]::after,
.zs-table > tfoot td[sort]::after,
.zs-table > tfoot th[sort]::after,
.zs-table > thead td [sort]::after,
.zs-table > thead th [sort]::after,
.zs-table > tr > th [sort]::after,
.zs-table > th [sort]::after,
.zs-table > tbody > tr > th [sort]::after,
.zs-table .zs-th [sort]::after,
.zs-table > tfoot td [sort]::after,
.zs-table > tfoot th [sort]::after {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 1.25em;
  font-family: 'zsIcons';
  content: "\ea1e";
  color: #716E79;
}
.zs-table > thead td[sort]:hover::after,
.zs-table > thead th[sort]:hover::after,
.zs-table > tr > th[sort]:hover::after,
.zs-table > th[sort]:hover::after,
.zs-table > tbody > tr > th[sort]:hover::after,
.zs-table .zs-th[sort]:hover::after,
.zs-table > tfoot td[sort]:hover::after,
.zs-table > tfoot th[sort]:hover::after,
.zs-table > thead td [sort]:hover::after,
.zs-table > thead th [sort]:hover::after,
.zs-table > tr > th [sort]:hover::after,
.zs-table > th [sort]:hover::after,
.zs-table > tbody > tr > th [sort]:hover::after,
.zs-table .zs-th [sort]:hover::after,
.zs-table > tfoot td [sort]:hover::after,
.zs-table > tfoot th [sort]:hover::after {
  color: #9C9AA1;
}
.zs-table > thead td[sort="asc"]::after,
.zs-table > thead th[sort="asc"]::after,
.zs-table > tr > th[sort="asc"]::after,
.zs-table > th[sort="asc"]::after,
.zs-table > tbody > tr > th[sort="asc"]::after,
.zs-table .zs-th[sort="asc"]::after,
.zs-table > tfoot td[sort="asc"]::after,
.zs-table > tfoot th[sort="asc"]::after,
.zs-table > thead td [sort="asc"]::after,
.zs-table > thead th [sort="asc"]::after,
.zs-table > tr > th [sort="asc"]::after,
.zs-table > th [sort="asc"]::after,
.zs-table > tbody > tr > th [sort="asc"]::after,
.zs-table .zs-th [sort="asc"]::after,
.zs-table > tfoot td [sort="asc"]::after,
.zs-table > tfoot th [sort="asc"]::after {
  color: #27A6A4;
  content: "\eb57";
}
.zs-table > thead td[sort="desc"]::after,
.zs-table > thead th[sort="desc"]::after,
.zs-table > tr > th[sort="desc"]::after,
.zs-table > th[sort="desc"]::after,
.zs-table > tbody > tr > th[sort="desc"]::after,
.zs-table .zs-th[sort="desc"]::after,
.zs-table > tfoot td[sort="desc"]::after,
.zs-table > tfoot th[sort="desc"]::after,
.zs-table > thead td [sort="desc"]::after,
.zs-table > thead th [sort="desc"]::after,
.zs-table > tr > th [sort="desc"]::after,
.zs-table > th [sort="desc"]::after,
.zs-table > tbody > tr > th [sort="desc"]::after,
.zs-table .zs-th [sort="desc"]::after,
.zs-table > tfoot td [sort="desc"]::after,
.zs-table > tfoot th [sort="desc"]::after {
  color: #27A6A4;
  content: "\eb56";
}
.zs-table > thead td[sort="asc"]:hover::after,
.zs-table > thead th[sort="asc"]:hover::after,
.zs-table > tr > th[sort="asc"]:hover::after,
.zs-table > th[sort="asc"]:hover::after,
.zs-table > tbody > tr > th[sort="asc"]:hover::after,
.zs-table .zs-th[sort="asc"]:hover::after,
.zs-table > tfoot td[sort="asc"]:hover::after,
.zs-table > tfoot th[sort="asc"]:hover::after,
.zs-table > thead td [sort="asc"]:hover::after,
.zs-table > thead th [sort="asc"]:hover::after,
.zs-table > tr > th [sort="asc"]:hover::after,
.zs-table > th [sort="asc"]:hover::after,
.zs-table > tbody > tr > th [sort="asc"]:hover::after,
.zs-table .zs-th [sort="asc"]:hover::after,
.zs-table > tfoot td [sort="asc"]:hover::after,
.zs-table > tfoot th [sort="asc"]:hover::after,
.zs-table > thead td[sort="desc"]:hover::after,
.zs-table > thead th[sort="desc"]:hover::after,
.zs-table > tr > th[sort="desc"]:hover::after,
.zs-table > th[sort="desc"]:hover::after,
.zs-table > tbody > tr > th[sort="desc"]:hover::after,
.zs-table .zs-th[sort="desc"]:hover::after,
.zs-table > tfoot td[sort="desc"]:hover::after,
.zs-table > tfoot th[sort="desc"]:hover::after,
.zs-table > thead td [sort="desc"]:hover::after,
.zs-table > thead th [sort="desc"]:hover::after,
.zs-table > tr > th [sort="desc"]:hover::after,
.zs-table > th [sort="desc"]:hover::after,
.zs-table > tbody > tr > th [sort="desc"]:hover::after,
.zs-table .zs-th [sort="desc"]:hover::after,
.zs-table > tfoot td [sort="desc"]:hover::after,
.zs-table > tfoot th [sort="desc"]:hover::after {
  color: #27A6A4;
}
.zs-table > thead td [sort],
.zs-table > thead th [sort],
.zs-table > tr > th [sort],
.zs-table > th [sort],
.zs-table > tbody > tr > th [sort],
.zs-table .zs-th [sort],
.zs-table > tfoot td [sort],
.zs-table > tfoot th [sort] {
  cursor: pointer;
}
.zs-table > thead td [sort]::after,
.zs-table > thead th [sort]::after,
.zs-table > tr > th [sort]::after,
.zs-table > th [sort]::after,
.zs-table > tbody > tr > th [sort]::after,
.zs-table .zs-th [sort]::after,
.zs-table > tfoot td [sort]::after,
.zs-table > tfoot th [sort]::after {
  font-size: 1.25em;
}
.zs-table > tfoot td,
.zs-table > tfoot th {
  background: transparent;
  vertical-align: top;
}
.zs-table > tfoot > tr > td {
  border-bottom: 1px solid #DEDCDE;
}
.zs-table.zs-table-condense > thead > tr > td,
.zs-table.zs-table-condense > tfoot > tr > td,
.zs-table.zs-table-condense > tbody > tr > td,
.zs-table.zs-table-condense > thead > tr > th,
.zs-table.zs-table-condense > tfoot > tr > th,
.zs-table.zs-table-condense > tbody > tr > th {
  padding: 0.5em 0.5em 0.5em 0.5em;
}
.zs-table.zs-table-generous > thead > tr > td,
.zs-table.zs-table-generous > tfoot > tr > td,
.zs-table.zs-table-generous > tbody > tr > td,
.zs-table.zs-table-generous > thead > tr > th,
.zs-table.zs-table-generous > tfoot > tr > th,
.zs-table.zs-table-generous > tbody > tr > th {
  padding: 1.5em 1em 1.5em 1em;
}
.zs-table .zs-danger {
  background: #FFB6A5;
}
.zs-table .zs-warning {
  background: #FFDB93;
}
.zs-table .zs-success {
  background: #ADF2E7;
}
.zs-table .zs-active {
  background-color: rgba(236, 114, 0, 0.1);
}
.zs-table tr[border*="none"] > * {
  border: none;
}
.zs-table tr[border*="right"] > * {
  border-right: 1px solid #DEDCDE;
}
.zs-table tr[border*="left"] > * {
  border-left: 1px solid #DEDCDE;
}
.zs-table tr[border*="top"] > * {
  border-top: 1px solid #DEDCDE;
}
.zs-table tr[border*="bottom"] > * {
  border-bottom: 1px solid #DEDCDE;
}
.zs-table [border*="none"] {
  border: 0 !important;
}
.zs-table [border*="right"] {
  border-right: 1px solid #DEDCDE !important;
}
.zs-table [border*="left"] {
  border-left: 1px solid #DEDCDE !important;
}
.zs-table [border*="top"] {
  border-top: 1px solid #DEDCDE !important;
}
.zs-table [border*="bottom"] {
  border-bottom: 1px solid #DEDCDE !important;
}
.zs-table [scope="colgroup"],
.zs-table [scope="rowgroup"] {
  vertical-align: middle;
  text-align: center;
}
.zs-table tr[align="right"] > td,
.zs-table tr[align="right"] > th {
  text-align: right;
}
.zs-table tr[align="left"] > td,
.zs-table tr[align="left"] > th {
  text-align: left;
}
.zs-table tr[align="center"] > td,
.zs-table tr[align="center"] > th {
  text-align: center;
}
.zs-table tr[valign="top"] > td,
.zs-table tr[valign="top"] > th {
  vertical-align: top;
}
.zs-table tr[valign="middle"] > td,
.zs-table tr[valign="middle"] > th {
  vertical-align: middle;
}
.zs-table tr[valign="bottom"] > td,
.zs-table tr[valign="bottom"] > th {
  vertical-align: bottom;
}
.zs-table tr[valign="baseline"] > td,
.zs-table tr[valign="baseline"] > th {
  vertical-align: baseline;
}
.zs-table [align="right"] {
  text-align: right !important;
}
.zs-table [align="left"] {
  text-align: left !important;
}
.zs-table [align="center"] {
  text-align: center !important;
}
.zs-table [valign="top"] {
  vertical-align: top !important;
}
.zs-table [valign="middle"] {
  vertical-align: middle !important;
}
.zs-table [valign="bottom"] {
  vertical-align: bottom !important;
}
.zs-table [valign="baseline"] {
  vertical-align: baseline !important;
}
.zs-table [mark] {
  background-color: rgba(39, 166, 164, 0.1) !important;
}
.zs-table:focus {
  outline: 1px solid #27A6A4;
}
.zs-table a:focus {
  outline: 1px solid #27A6A4;
}
.zs-table > thead > tr > th a[sort]::after,
.zs-table > thead > tr > td a[sort]::after,
.zs-table > thead > tr > th a[sort="asc"]::after,
.zs-table > thead > tr > td a[sort="asc"]::after,
.zs-table > thead > tr > th a[sort="desc"]::after,
.zs-table > thead > tr > td a[sort="desc"]::after,
.zs-table > thead > tr > th [sort]::after,
.zs-table > thead > tr > td [sort]::after,
.zs-table > thead > tr > th [sort="asc"]::after,
.zs-table > thead > tr > td [sort="asc"]::after,
.zs-table > thead > tr > th [sort="desc"]::after,
.zs-table > thead > tr > td [sort="desc"]::after {
  font-size: 20px;
}
.zs-table tr.zs-table-row-group-divider > td,
.zs-table tr.zs-table-row-group-divider > th {
  border-top: 1px #9C9AA1 solid;
}
.zs-toggle-component {
  position: relative;
  display: flex;
  align-items: center;
}
.zs-toggle-component [label]:not(:empty) {
  margin-right: 1em;
}
.zs-toggle-component input,
.zs-toggle-component input[disabled] {
  position: absolute;
  opacity: 0;
}
.toggle-container {
  height: 1em;
  min-width: 2em;
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  min-width: 4em;
}
.toggle-outer-container {
  display: inline-block;
  position: relative;
  height: 1em;
  width: 2em;
}
.zs-toggle {
  overflow: hidden;
  cursor: pointer;
  border-radius: 9999px;
  height: 1em;
  width: 50%;
}
.toggle-inner {
  margin-left: -100%;
  display: inline-block;
  white-space: nowrap;
  transition: margin 300ms;
}
.toggle-inner .zs-toggle-on {
  pointer-events: none;
}
.toggle-inner .zs-toggle-button {
  left: 0.25em;
}
.toggle-inner.active {
  margin-left: 0px;
}
.toggle-inner.active .zs-toggle-button {
  left: 1.25em;
}
.toggle-inner.active .zs-toggle-off {
  pointer-events: none;
}
.zs-toggle .zs-toggle-on,
.zs-toggle .zs-toggle-off,
.zs-toggle .zs-toggle-button {
  display: inline-block;
  vertical-align: top;
}
.zs-toggle .zs-toggle-on,
.zs-toggle .zs-toggle-off {
  min-width: 2em;
  height: 1em;
  text-align: center;
}
.zs-toggle-on {
  background: #27A6A4;
  color: #2F2C3C;
  padding: 0.125em 0.25em 0.125em 0.25em;
  padding-right: 1em;
}
.zs-toggle-off {
  background: #716E79;
  color: #2F2C3C;
  padding: 0.125em 0.25em 0.125em 0.25em;
  padding-left: 1em;
}
.zs-toggle-button {
  width: 0.75em;
  height: 0.75em;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(26, 22, 40, 0.12), 0px 2px 4px rgba(26, 22, 40, 0.12);
  border: none;
  position: absolute;
  z-index: 99;
  margin: 0em 0em 0em -0.125em;
  transition: left 200ms;
  top: 50%;
  transform: translateY(-50%);
}
.zs-toggle-component[focussed] .toggle-container::before {
  content: ' ';
  position: absolute;
  border: 2px solid #27A6A4;
  left: -0.25em;
  top: -0.25em;
  right: 1.75em;
  bottom: -0.25em;
  border-radius: 0.75em;
  z-index: -1;
}
.zs-toggle-component[focussed] .zs-toggle-on {
  background: #2F6F7B;
}
.zs-toggle-component[focussed] .zs-toggle-off {
  background: #454250;
}
.zs-toggle-component[disabled],
.zs-toggle-component.zs-disabled,
.zs-toggle-component.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  opacity: 0.5;
}
/* Flexbox mixins */
@-ms-viewport {
  width: device-width;
}
.zs-visible-xs,
.zs-visible-sm,
.zs-visible-md,
.zs-visible-lg {
  display: none !important;
}
.zs-visible-xs-block,
.zs-visible-xs-inline,
.zs-visible-xs-inline-block,
.zs-visible-sm-block,
.zs-visible-sm-inline,
.zs-visible-sm-inline-block,
.zs-visible-md-block,
.zs-visible-md-inline,
.zs-visible-md-inline-block,
.zs-visible-lg-block,
.zs-visible-lg-inline,
.zs-visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 47.93em) {
  .zs-visible-xs {
    display: block !important;
  }
  table.zs-visible-xs {
    display: table !important;
  }
  tr.zs-visible-xs {
    display: table-row !important;
  }
  th.zs-visible-xs,
  td.zs-visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 47.93em) {
  .zs-visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 47.93em) {
  .zs-visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 47.93em) {
  .zs-visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm {
    display: block !important;
  }
  table.zs-visible-sm {
    display: table !important;
  }
  tr.zs-visible-sm {
    display: table-row !important;
  }
  th.zs-visible-sm,
  td.zs-visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md {
    display: block !important;
  }
  table.zs-visible-md {
    display: table !important;
  }
  tr.zs-visible-md {
    display: table-row !important;
  }
  th.zs-visible-md,
  td.zs-visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md-block {
    display: block !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg {
    display: block !important;
  }
  table.zs-visible-lg {
    display: table !important;
  }
  tr.zs-visible-lg {
    display: table-row !important;
  }
  th.zs-visible-lg,
  td.zs-visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 75em) {
  .zs-visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 47.93em) {
  .zs-hidden-xs {
    display: none !important;
  }
}
@media (min-width: 48em) and (max-width: 61.93em) {
  .zs-hidden-sm {
    display: none !important;
  }
}
@media (min-width: 62em) and (max-width: 74.93em) {
  .zs-hidden-md {
    display: none !important;
  }
}
@media (min-width: 75em) {
  .zs-hidden-lg {
    display: none !important;
  }
}
/*
Popup menu

Popup menu

Markup:
<div class="zs-menu">
	<a href="#">Link 1</a>
	<a href="#">link 2 </a>
	<a href="#" class="zs-active">Link 3</a>
	<a href="#">Link 4</a>		
</div>

Styleguide Components.PopupMenu
*/
.zs-menu {
  background: #ffffff;
  border-radius: 0.3em;
  border: 1px solid #e1e9ef;
  box-shadow: 0 3px 6px #dee3e3;
  box-sizing: border-box;
  display: inline-block;
  min-width: 150px;
  max-width: 400px;
  z-index: 2000;
}
.zs-menu > a,
.zs-menu > a:visited,
.zs-menu > a:hover,
.zs-menu > li,
.zs-menu > ul > li > a,
.zs-menu > nav > a,
.zs-menu > nav > a:visited {
  color: #53565a;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.71em 0.71em 0.71em 0.71em;
}
.zs-menu > a.zs-active,
.zs-menu > a:visited.zs-active,
.zs-menu > a:hover.zs-active,
.zs-menu > li.zs-active,
.zs-menu > ul > li > a.zs-active,
.zs-menu > nav > a.zs-active,
.zs-menu > nav > a:visited.zs-active {
  background: #e5eaef;
}
.zs-menu > a:hover,
.zs-menu > a:visited:hover,
.zs-menu > a:hover:hover,
.zs-menu > li:hover,
.zs-menu > ul > li > a:hover,
.zs-menu > nav > a:hover,
.zs-menu > nav > a:visited:hover {
  background: #f5f7f9;
}
.zs-menu > ali a,
.zs-menu > a:visitedli a,
.zs-menu > a:hoverli a,
.zs-menu > lili a,
.zs-menu > ul > li > ali a,
.zs-menu > nav > ali a,
.zs-menu > nav > a:visitedli a {
  color: #53565a;
  display: block;
  white-space: nowrap;
}
#zs-lbr {
  display: none;
}
.zs-user-bar {
  display: inline-block;
  position: absolute;
  right: 0em;
  margin-right: 2.5em;
  top: 50%;
  margin-top: -1.56714286em;
}
.zs-user-bar > div[role="profile"] > nav {
  min-width: 120%;
}
.zs-user-bar > div {
  position: relative;
  display: inline-block;
  padding: 0em 0.5325em 0em 0.5325em;
  margin: 0em 0em 0em 0.355em;
  min-height: 3.13428571em;
  max-height: 3.13428571em;
  height: 3.13428571em;
}
.zs-user-bar > div:first-child {
  margin: 0em 0em 0em 0em;
  margin-left: 0;
}
.zs-user-bar > div > a {
  cursor: pointer;
  position: relative;
  margin: 0em 0em 0em 0em;
  display: inline;
  line-height: 3.13428571em;
}
.zs-user-bar > div > a > .zs-icon {
  margin: 0em 0em 0em 0em;
}
.zs-user-bar > div > a > .zs-icon:before {
  margin: 0em 0em 0em 0em;
}
.zs-user-bar > div > a > .zs-icon.zs-icon-large:before {
  margin: 0em 0em 0em 0em;
}
.zs-user-bar > div > a > .zs-icon .zs-badge {
  font-size: 0.75em;
  top: 0.75em;
  left: 0.75em;
}
.zs-user-bar > div > a > label {
  line-height: 1.14285714em;
  cursor: pointer;
  padding: 0em 0em 0em 0.71em;
}
.zs-user-bar > div > nav {
  min-width: 10em;
  position: absolute;
  right: -0.0625;
  top: 100%;
  border-top-right-radius: 0;
}
.zs-user-bar > div > nav > a {
  z-index: 3000;
}
.zs-user-bar .zs-message-container {
  position: relative;
}
.zs-user-bar .zs-message-container .zs-message.zs-error {
  min-height: 0;
  z-index: 1000;
  position: absolute;
  top: 50%;
  right: 0;
  margin-left: -47em;
  margin-top: -1.35em;
}
.zs-user-bar .zs-menu ul li:nth-child(1) a.lbar-link {
  color: #722f20;
}
.zs-user-bar .zs-menu #lbar-notifications-menu-popup ul {
  list-style: none;
}
.zs-user-bar .zs-menu #lbar-notifications-menu-link {
  display: none;
}
.zs-hide,
.zs-menu.zs-hide {
  display: none;
}
.zs-menu .zs-message {
  border: none;
  min-width: 18em;
}
.zs-menu .zs-message a {
  display: block;
}
.zs-header .zs-brand-bar .zs-logo {
  display: inline-block;
  float: inherit;
  vertical-align: middle;
}
.zs-header .zs-brand-bar .zs-brand-name {
  display: inline-block;
  float: inherit;
  line-height: 100%;
  vertical-align: middle;
}
.zs-app-message {
  margin: 0em 0em 0em 12.5em;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  font-size: 0.875em;
  background: #fffbeb;
  display: block;
  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;
  position: relative;
  line-height: 2.2em;
  padding: 0.71em 1.42em 0.71em 1.42em;
  border: 1px solid #e1e9ef;
  display: none;
  position: fixed;
  bottom: 0;
  width: 90%;
  left: 50%;
  margin-left: -45%;
}
.zs-app-message button {
  margin: 0em 0em 0em 2.13em;
  line-height: 1.25em;
}
.zs-app-message:before,
.zs-app-message:after {
  content: "";
  display: table;
}
.zs-app-message:after {
  clear: both;
}
.zs-app-message .zs-icon.zs-icon-rejected-approval {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -0.57142857em;
  margin-right: 1.42em;
}
/*** Old Launchbar styles ***/
#lbr {
  background: #575757 url("../assets/zsui/zsui-auth/src/user/launch-bar.gif") 0 -9px repeat-x;
  font-size: 8pt;
  font-family: Tahoma, Helvetica, Sans-Serif;
  height: 2.18em;
  line-height: 2.09em;
}
div#lbr * {
  font-size: 8pt;
  font-family: Tahoma, Helvetica, Sans-Serif;
}
#lbr #lbar-apps {
  float: left;
  font-weight: bold;
}
#lbr #lbar-app-more {
  position: relative;
  z-index: 1500;
}
#lbr #lbar-logout {
  float: right;
}
#lbr #lbar-user {
  float: right;
  position: relative;
  z-index: 1500;
}
#lbr #lbar-user .lbar-menu-popup {
  right: -1px;
  min-width: 10em;
}
#lbr ul,
#lbr ul li,
#lbr a.lbar-link,
#lbr a.lbar-menu-dropdown {
  display: inline-block;
  height: 100%;
  /* For IE */
}
#lbr a {
  color: #B2B0B6;
}
#lbr ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#lbr a.lbar-link {
  text-decoration: none;
  padding: 0em 0.905em 0em 0.905em;
}
#lbr a.lbar-link:hover {
  color: white;
}
#lbr .lbar-selected {
  background: url("../assets/zsui/zsui-auth/src/user/launch-bar.gif") no-repeat;
  background-position: 50% -5.81em;
}
#lbr .lbar-selected a {
  color: white;
}
#lbr a.lbar-menu-dropdown {
  text-decoration: none;
  padding: 0em 2.36em 0em 1.9em;
  background: url("../assets/zsui/zsui-auth/src/user/launch-bar.gif") 100% -9.374em no-repeat;
}
#lbr .lbar-menu-on a.lbar-menu-dropdown {
  color: white;
  background-position: 100% -12.374em;
}
#lbr .lbar-menu-off {
  border-left: solid 0.09em transparent;
  border-right: solid 0.09em transparent;
}
#lbr .lbar-menu-on {
  background: url("../assets/zsui/zsui-auth/src/user/launch-bar.gif") 0 -3.09em repeat-x;
  border-left: solid 0.09em #909090;
  border-right: solid 0.09em #909090;
}
#lbr .lbar-menu-on .lbar-menu-popup {
  display: block;
}
#lbr .lbar-menu-popup {
  display: none;
  position: absolute;
  top: 2.09em;
  left: -0.09em;
  background-color: #626262;
  border-left: solid 0.09em #909090;
  border-right: solid 0.09em #909090;
  border-bottom: solid 0.09em #909090;
  font-weight: bold;
  line-height: 2.72em;
}
#lbr .lbar-menu-popup a {
  display: block;
  white-space: nowrap;
}
#lbr .lbar-menu-popup li {
  width: 100%;
}
#lbr .lbar-menu-popup ul {
  width: 100%;
}
#lbr #lbar-notifications {
  position: relative;
  float: left;
  background: #cc0000 url("../assets/zsui/zsui-auth/src/user/launch-bar-notifications.gif") 0.18em -0.36em no-repeat;
  border-right: none;
  color: white;
  font-weight: bold;
  z-index: 10000;
}
#lbr #lbar-notifications a.lbar-menu-dropdown {
  color: white;
  background-position: 100% -12.4em;
  padding-left: 3em;
}
#lbr #lbar-notifications .lbar-menu-popup {
  background-color: white;
  font-weight: normal;
  border: solid 0.37em #cc0000;
  -webkit-box-shadow: 0.093em 0.093em 0.281em #000000 inset, 0.093em 0.093em 0.18em rgba(40, 40, 40, 0.6), 0.18em 0.18em 0.468em #575757;
}
#lbr #lbar-notifications .lbar-menu-popup em {
  font-weight: bold;
  font-style: normal;
}
#lbr #lbar-notifications .lbar-menu-popup a.note-link {
  text-decoration: none;
  color: #3577C9;
}
#lbr #lbar-notifications .lbar-menu-popup a.note-link:hover {
  text-decoration: underline;
}
#lbr #lbar-notifications.lbar-menu-on {
  border-left: solid 0.093em #cc0000;
}
#lbr #lbar-notifications .lbar-link {
  color: black;
}
#lbr #lbar-not-prod {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #7e7e7e;
  color: white;
  font-weight: bold;
  height: 100%;
  padding: 0em 0.465em 0em 0.465em;
  margin: 0em 0.465em 0em 0em;
}
#lbr #lbar-user .user-name {
  font-weight: bold;
  padding: 0em 0em 0em 0.655em;
}
.zs-submenu > a:first-child + a,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > a:first-child + a,
.zs-user-bar > div > a:first-child + a {
  position: absolute;
  top: 50%;
  margin-top: -0.57142857em;
  right: 0;
}
.zs-submenu.zs-selected:before,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu.zs-selected:before,
.zs-user-bar > div.zs-selected:before {
  content: '';
  background: transparent;
  border-radius: 0.3em;
  border: 0.093em solid #e1e9ef;
  box-shadow: 0 0.281em 0.56em #dee3e3;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  top: 0px;
  position: absolute;
  right: -0.093em;
  bottom: -0.093em;
  left: -0.093em;
  z-index: 0;
}
.zs-submenu.zs-selected:after,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu.zs-selected:after,
.zs-user-bar > div.zs-selected:after {
  margin-top: -0.093em;
  left: 0;
  top: 100%;
  content: '';
  right: 0;
  height: 0.187em;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  z-index: 2;
}
.zs-submenu > nav,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > nav,
.zs-user-bar > div > nav {
  position: absolute;
  right: -0.093em;
  top: 100%;
  border-top-right-radius: 0;
  z-index: 1;
  min-width: 120%;
}
.zs-submenu > nav > ul > li,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > nav > ul > li,
.zs-user-bar > div > nav > ul > li {
  display: block;
  position: relative;
}
.zs-submenu > nav > ul > li.zs-active,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu > nav > ul > li.zs-active,
.zs-user-bar > div > nav > ul > li.zs-active {
  background: #e5eaef;
}
.zs-submenu.zs-selected.zs-to-left > nav,
.zs-mobile-header .zs-top-navigation > ul > li.zs-submenu.zs-selected.zs-to-left > nav,
.zs-user-bar > div.zs-selected.zs-to-left > nav {
  left: -0.093em;
  right: auto;
  border-top-right-radius: 0.3em;
  border-top-left-radius: 0;
}
.zs-user-bar .zs-icon-large {
  display: none;
}
@media (min-width: 0) {
  .zs-user-bar label {
    display: none;
  }
  .zs-user-bar .zs-icon {
    display: none;
  }
  .zs-user-bar .zs-icon.zs-icon-large {
    display: inline;
  }
}
@media (min-width: 72.09em) {
  .zs-user-bar [role="profile"] label {
    display: inline-block;
    max-width: 7em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (min-width: 96.09em) {
  .zs-user-bar .zs-icon {
    display: inline;
  }
  .zs-user-bar .zs-icon.zs-icon-large {
    display: none;
  }
  .zs-user-bar label,
  .zs-user-bar [role="profile"] label {
    display: inline;
  }
}
.zs-master-style zs-tooltip,
.zs-master-style .zs-tooltip {
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
  background-color: #1A1628;
  cursor: default;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  text-align: left;
  white-space: normal;
  visibility: visible;
  z-index: 1060;
  line-height: 1.5;
  padding: 0.25em 0.5em 0.25em 0.5em;
  min-width: 0.375em;
  max-width: 50vw;
  opacity: 0;
}
.zs-master-style zs-tooltip > zs-tooltip-arrow,
.zs-master-style .zs-tooltip > zs-tooltip-arrow,
.zs-master-style zs-tooltip > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip > zs-tooltip-arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.zs-master-style zs-tooltip > zs-tooltip-arrow,
.zs-master-style .zs-tooltip > zs-tooltip-arrow {
  border-width: 0.375em;
}
.zs-master-style zs-tooltip > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip > zs-tooltip-arrow:after {
  content: "";
  border-width: 0.375em;
}
.zs-master-style zs-tooltip.top > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.top > zs-tooltip-arrow {
  bottom: -0.375em;
  border-top-color: #DEDCDE;
  border-bottom-width: 0;
  left: 50%;
  margin-left: -0.375em;
}
.zs-master-style zs-tooltip.top > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.top > zs-tooltip-arrow:after {
  bottom: 1px;
  content: " ";
  border-top-color: #1A1628;
  border-bottom-width: 0;
  margin-left: -0.375em;
}
.zs-master-style zs-tooltip.right > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.right > zs-tooltip-arrow {
  border-right-color: #DEDCDE;
  border-left-width: 0;
  top: 50%;
  left: -0.375em;
  margin-top: -0.375em;
}
.zs-master-style zs-tooltip.right > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.right > zs-tooltip-arrow:after {
  border-right-color: #1A1628;
  border-left-width: 0;
  bottom: -0.375em;
  content: " ";
  left: 1px;
}
.zs-master-style zs-tooltip.bottom > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.bottom > zs-tooltip-arrow {
  top: -0.375em;
  left: 50%;
  margin-left: -0.375em;
  border-top-width: 0;
  border-bottom-color: #DEDCDE;
}
.zs-master-style zs-tooltip.bottom > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.bottom > zs-tooltip-arrow:after {
  top: 1px;
  margin-left: -0.375em;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #1A1628;
}
.zs-master-style zs-tooltip.left > zs-tooltip-arrow,
.zs-master-style .zs-tooltip.left > zs-tooltip-arrow {
  top: 50%;
  right: -0.375em;
  margin-top: -0.375em;
  border-right-width: 0;
  border-left-color: #DEDCDE;
}
.zs-master-style zs-tooltip.left > zs-tooltip-arrow:after,
.zs-master-style .zs-tooltip.left > zs-tooltip-arrow:after {
  right: 1px;
  bottom: -0.375em;
  content: " ";
  border-right-width: 0;
  border-left-color: #1A1628;
}
.zs-timepicker-element span > input[type='text'] {
  min-width: 150px;
  margin-bottom: -1px;
}
.zs-timepicker-element .zs-input {
  outline: none;
  font-weight: normal;
  color: #2F2C3C;
  font-size: 1em;
  border-radius: 0;
  padding: 0.75em 0.75em 0.75em 0.75em;
  /**
	  * @todo a little hack to fix ZSUI-358
	  */
  margin-bottom: 0.5px;
}
.zs-timepicker-element .zs-input[type="number"] {
  text-align: right;
}
.zs-timepicker-element .zs-input[type="checkbox"],
.zs-timepicker-element .zs-input[type="radio"] {
  border: none;
}
.zs-timepicker-element .zs-input:focus,
.zs-timepicker-element .zs-input :invalid:focus {
  outline: none;
  box-shadow: none;
}
.zs-timepicker-element.zs-action-field [input] {
  padding: 0.75em 0.75em 0.75em 3em;
}
.zs-timepicker-element[open] [input] {
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
}
.zs-timepicker-element.zs-action-field.zs-action-field-solid[open] [input] {
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15), inset 0 -2px 0 0 #27A6A4;
}
.zs-timepicker-element.zs-action-field.zs-action-field-solid.zs-success[open] [input] {
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15), inset 0 -2px 0 0 #00AA67;
}
.zs-timepicker-element.zs-action-field.zs-action-field-solid.zs-warning[open] [input] {
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15), inset 0 -2px 0 0 #E3A900;
}
.zs-timepicker-element.zs-action-field.zs-action-field-solid.zs-info[open] [input] {
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15), inset 0 -2px 0 0 #2B44C7;
}
.zs-timepicker-element.zs-action-field.zs-action-field-solid.zs-error[open] [input],
.zs-timepicker-element.zs-action-field.zs-action-field-solid.zs-danger[open] [input],
.zs-timepicker-element.zs-action-field.zs-action-field-solid[invalid][open] [input] {
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15), inset 0 -2px 0 0 #B21111;
}
.zs-input-icon {
  position: relative;
}
.zs-input-icon textarea[input] {
  padding-right: 3em !important;
}
.zs-input-icon [input] {
  padding-right: 3em !important;
}
.zs-input-icon::before {
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: inline-block;
  pointer-events: none;
  top: 50%;
  font-size: 1.5em;
  width: auto;
  right: 0.5em;
  padding: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: center;
  background: transparent;
  position: absolute;
}
.zs-timepicker {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  display: none;
  width: 100%;
  position: absolute;
  display: block;
  background: #FAFAFA;
  border-radius: 0em;
  border: 1px solid #DEDCDE;
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
  min-width: auto;
  max-width: none;
  background: #ffffff;
  border-radius: 0.3em;
  border: 1px solid #e1e9ef;
  box-shadow: 0 3px 6px #dee3e3;
  box-sizing: border-box;
  display: inline-block;
  min-width: 150px;
  max-width: 400px;
  z-index: 2000;
}
.zs-timepicker > a,
.zs-timepicker > li,
.zs-timepicker > li > a,
.zs-timepicker > ul > li > a,
.zs-timepicker > nav > a {
  color: #2F2C3C;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 1.5em 0.5em 1.5em;
  line-height: 1.5;
}
.zs-timepicker > a span,
.zs-timepicker > li span,
.zs-timepicker > li > a span,
.zs-timepicker > ul > li > a span,
.zs-timepicker > nav > a span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.zs-timepicker > a.zs-active,
.zs-timepicker > li.zs-active,
.zs-timepicker > li > a.zs-active,
.zs-timepicker > ul > li > a.zs-active,
.zs-timepicker > nav > a.zs-active {
  background: #27A6A4;
}
.zs-timepicker > a:hover,
.zs-timepicker > li:hover,
.zs-timepicker > li > a:hover,
.zs-timepicker > ul > li > a:hover,
.zs-timepicker > nav > a:hover {
  background: rgba(39, 166, 164, 0.1);
  color: inherit;
}
.zs-timepicker > a:first-child,
.zs-timepicker > li:first-child,
.zs-timepicker > li > a:first-child,
.zs-timepicker > ul > li > a:first-child,
.zs-timepicker > nav > a:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.zs-timepicker > a:last-child,
.zs-timepicker > li:last-child,
.zs-timepicker > li > a:last-child,
.zs-timepicker > ul > li > a:last-child,
.zs-timepicker > nav > a:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.zs-timepicker > a:active,
.zs-timepicker > li:active,
.zs-timepicker > li > a:active,
.zs-timepicker > ul > li > a:active,
.zs-timepicker > nav > a:active {
  background: #2D8B93;
  color: inherit;
}
.zs-timepicker > a > *:not(.zs-icon),
.zs-timepicker > li > *:not(.zs-icon),
.zs-timepicker > li > a > *:not(.zs-icon),
.zs-timepicker > ul > li > a > *:not(.zs-icon),
.zs-timepicker > nav > a > *:not(.zs-icon) {
  vertical-align: middle;
}
.zs-timepicker > li > a {
  padding: 0;
  color: inherit;
}
.zs-timepicker > li > a:focus,
.zs-timepicker > li > a:hover,
.zs-timepicker > li > a:active,
.zs-timepicker > li > a.zs-active {
  color: inherit;
  background: transparent;
}
.zs-timepicker > li > a:focus {
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-timepicker > a,
.zs-timepicker > li,
.zs-timepicker > li > a,
.zs-timepicker > ul > li > a,
.zs-timepicker > nav > a {
  color: #2F2C3C;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 1.5em 0.5em 1.5em;
  line-height: 1.5;
}
.zs-timepicker > a span,
.zs-timepicker > li span,
.zs-timepicker > li > a span,
.zs-timepicker > ul > li > a span,
.zs-timepicker > nav > a span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.zs-timepicker > a.zs-active,
.zs-timepicker > li.zs-active,
.zs-timepicker > li > a.zs-active,
.zs-timepicker > ul > li > a.zs-active,
.zs-timepicker > nav > a.zs-active {
  background: #27A6A4;
}
.zs-timepicker > a:hover,
.zs-timepicker > li:hover,
.zs-timepicker > li > a:hover,
.zs-timepicker > ul > li > a:hover,
.zs-timepicker > nav > a:hover {
  background: rgba(39, 166, 164, 0.1);
  color: inherit;
}
.zs-timepicker > a:first-child,
.zs-timepicker > li:first-child,
.zs-timepicker > li > a:first-child,
.zs-timepicker > ul > li > a:first-child,
.zs-timepicker > nav > a:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.zs-timepicker > a:last-child,
.zs-timepicker > li:last-child,
.zs-timepicker > li > a:last-child,
.zs-timepicker > ul > li > a:last-child,
.zs-timepicker > nav > a:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.zs-timepicker > a:active,
.zs-timepicker > li:active,
.zs-timepicker > li > a:active,
.zs-timepicker > ul > li > a:active,
.zs-timepicker > nav > a:active {
  background: #2D8B93;
  color: inherit;
}
.zs-timepicker > a > *:not(.zs-icon),
.zs-timepicker > li > *:not(.zs-icon),
.zs-timepicker > li > a > *:not(.zs-icon),
.zs-timepicker > ul > li > a > *:not(.zs-icon),
.zs-timepicker > nav > a > *:not(.zs-icon) {
  vertical-align: middle;
}
.zs-timepicker > li > a {
  padding: 0;
  color: inherit;
}
.zs-timepicker > li > a:focus,
.zs-timepicker > li > a:hover,
.zs-timepicker > li > a:active,
.zs-timepicker > li > a.zs-active {
  color: inherit;
  background: transparent;
}
.zs-timepicker > li > a:focus {
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-timepicker > nav {
  max-height: 10em;
  overflow-y: scroll;
}
.zs-timepicker > nav > a[active],
.zs-timepicker > nav > a[active][hover],
.zs-timepicker > nav > a[active][hover]:hover {
  color: #ffffff;
  background: #27A6A4;
}
.zs-timepicker > nav > a[hover] {
  color: #27A6A4;
  background: rgba(39, 166, 164, 0.1);
}
/**
* Popup Menu styles.
*/
.zs-menu {
  display: block;
  background: #FAFAFA;
  border-radius: 0em;
  border: 1px solid #DEDCDE;
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
  min-width: auto;
  max-width: none;
  background: #ffffff;
  border-radius: 0.3em;
  border: 1px solid #e1e9ef;
  box-shadow: 0 3px 6px #dee3e3;
  box-sizing: border-box;
  display: inline-block;
  min-width: 150px;
  max-width: 400px;
  z-index: 2000;
}
.zs-menu > a,
.zs-menu > li,
.zs-menu > li > a,
.zs-menu > ul > li > a,
.zs-menu > nav > a {
  color: #2F2C3C;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5em 1.5em 0.5em 1.5em;
  line-height: 1.5;
}
.zs-menu > a span,
.zs-menu > li span,
.zs-menu > li > a span,
.zs-menu > ul > li > a span,
.zs-menu > nav > a span {
  overflow: hidden;
  text-overflow: ellipsis;
}
.zs-menu > a.zs-active,
.zs-menu > li.zs-active,
.zs-menu > li > a.zs-active,
.zs-menu > ul > li > a.zs-active,
.zs-menu > nav > a.zs-active {
  background: #27A6A4;
}
.zs-menu > a:hover,
.zs-menu > li:hover,
.zs-menu > li > a:hover,
.zs-menu > ul > li > a:hover,
.zs-menu > nav > a:hover {
  background: rgba(39, 166, 164, 0.1);
  color: inherit;
}
.zs-menu > a:first-child,
.zs-menu > li:first-child,
.zs-menu > li > a:first-child,
.zs-menu > ul > li > a:first-child,
.zs-menu > nav > a:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.zs-menu > a:last-child,
.zs-menu > li:last-child,
.zs-menu > li > a:last-child,
.zs-menu > ul > li > a:last-child,
.zs-menu > nav > a:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.zs-menu > a:active,
.zs-menu > li:active,
.zs-menu > li > a:active,
.zs-menu > ul > li > a:active,
.zs-menu > nav > a:active {
  background: #2D8B93;
  color: inherit;
}
.zs-menu > a > *:not(.zs-icon),
.zs-menu > li > *:not(.zs-icon),
.zs-menu > li > a > *:not(.zs-icon),
.zs-menu > ul > li > a > *:not(.zs-icon),
.zs-menu > nav > a > *:not(.zs-icon) {
  vertical-align: middle;
}
.zs-menu > li > a {
  padding: 0;
  color: inherit;
}
.zs-menu > li > a:focus,
.zs-menu > li > a:hover,
.zs-menu > li > a:active,
.zs-menu > li > a.zs-active {
  color: inherit;
  background: transparent;
}
.zs-menu > li > a:focus {
  outline: 2px solid #27A6A4;
  outline-offset: -2px;
  z-index: 1;
}
.zs-date-picker {
  position: relative;
}
.zs-date-picker > label {
  vertical-align: bottom;
}
.zs-date-picker [input] {
  width: 100%;
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
}
.zs-date-picker [input][readonly] {
  cursor: default;
}
.zs-calendar {
  display: block;
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #DEDCDE;
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
  position: absolute;
  z-index: 2000;
  font-size: 1em;
  min-width: 100%;
}
.zs-calendar > header {
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0.5em 0.25em 0.5em 0.25em;
  background: #022D42;
}
.zs-calendar > header [role="prev"] ~ span {
  margin: 0em 0.25em 0em 1.5em;
}
.zs-calendar > header [role="prev"] ~ span select:focus {
  width: inherit;
}
.zs-calendar > header [role="prev"] ~ span ~ span {
  margin: 0em 0.25em 0em 0.5em;
}
.zs-calendar > header span {
  position: relative;
  padding: 0;
  display: inline-block;
  min-width: 15.625em;
  width: inherit;
  min-width: inherit;
}
.zs-calendar > header span select,
.zs-calendar > header span select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #1A1628;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-calendar > header span select::-ms-expand {
  display: none;
}
.zs-calendar > header span:after {
  content: "\ea26";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-calendar > header span:focus.zs-calendar > header span:after {
  color: #454250;
}
.zs-calendar > header span:hover.zs-calendar > header span:after {
  color: #9C9AA1;
}
.zs-calendar > header span:active.zs-calendar > header span:after {
  color: #2F2C3C;
}
.zs-calendar > header span[disabled] select,
.zs-calendar > header span.zs-disabled select,
.zs-calendar > header span.zs-calendar > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-calendar > header span[disabled]:after,
.zs-calendar > header span.zs-disabled:after,
.zs-calendar > header span.zs-calendar > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-calendar > header span.zs-danger select,
.zs-calendar > header span[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-calendar > header span.zs-danger select:focus,
.zs-calendar > header span[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-calendar > header span.zs-danger select:hover,
.zs-calendar > header span[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-calendar > header span.zs-danger select:active,
.zs-calendar > header span[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-calendar > header span.zs-danger:after,
.zs-calendar > header span[invalid]:after {
  color: #B21111;
}
.zs-calendar > header span.zs-danger:focus:after,
.zs-calendar > header span[invalid]:focus:after {
  color: #892208;
}
.zs-calendar > header span.zs-danger:hover:after,
.zs-calendar > header span[invalid]:hover:after {
  color: #F27755;
}
.zs-calendar > header span.zs-danger:active:after,
.zs-calendar > header span[invalid]:active:after {
  color: #5C1A0B;
}
.zs-calendar > header span.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-calendar > header span.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-calendar > header span.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-calendar > header span.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-calendar > header span.zs-success:after {
  color: #00AA67;
}
.zs-calendar > header span.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-calendar > header span.zs-success:hover:after {
  color: #54D7AF;
}
.zs-calendar > header span.zs-success:active:after {
  color: #0C4846;
}
.zs-calendar > header span.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-calendar > header span.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-calendar > header span.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-calendar > header span.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-calendar > header span.zs-warning:after {
  color: #E3A900;
}
.zs-calendar > header span.zs-warning:focus:after {
  color: #8A640C;
}
.zs-calendar > header span.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-calendar > header span.zs-warning:active:after {
  color: #563F0E;
}
.zs-calendar > header span.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-calendar > header span.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-calendar > header span.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-calendar > header span.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-calendar > header span.zs-info:after {
  color: #2B44C7;
}
.zs-calendar > header span.zs-info:focus:after {
  color: #141187;
}
.zs-calendar > header span.zs-info:hover:after {
  color: #7E92E9;
}
.zs-calendar > header span.zs-info:active:after {
  color: #160C60;
}
.zs-calendar > header span select,
.zs-calendar > header span select:focus {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  border: 1px solid #DEDCDE;
  color: #1A1628;
  cursor: pointer;
  width: 100%;
  padding: 0.5em 2.25em 0.5em 0.75em;
}
.zs-calendar > header span select::-ms-expand {
  display: none;
}
.zs-calendar > header span:after {
  content: "\ea26";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  font-family: 'zsIcons';
  font-size: 1.5em;
  color: #716E79;
  right: 0.5em;
  top: 50%;
  margin-top: -0.5em;
  position: absolute;
  pointer-events: none;
  display: inline-block;
}
.zs-calendar > header span:focus.zs-calendar > header span:after {
  color: #454250;
}
.zs-calendar > header span:hover.zs-calendar > header span:after {
  color: #9C9AA1;
}
.zs-calendar > header span:active.zs-calendar > header span:after {
  color: #2F2C3C;
}
.zs-calendar > header span[disabled] select,
.zs-calendar > header span.zs-disabled select,
.zs-calendar > header span.zs-calendar > table td[disabled] select {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-calendar > header span[disabled]:after,
.zs-calendar > header span.zs-disabled:after,
.zs-calendar > header span.zs-calendar > table td[disabled]:after {
  color: #B2B0B6;
}
.zs-calendar > header span.zs-danger select,
.zs-calendar > header span[invalid] select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-calendar > header span.zs-danger select:focus,
.zs-calendar > header span[invalid] select:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-calendar > header span.zs-danger select:hover,
.zs-calendar > header span[invalid] select:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-calendar > header span.zs-danger select:active,
.zs-calendar > header span[invalid] select:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-calendar > header span.zs-danger:after,
.zs-calendar > header span[invalid]:after {
  color: #B21111;
}
.zs-calendar > header span.zs-danger:focus:after,
.zs-calendar > header span[invalid]:focus:after {
  color: #892208;
}
.zs-calendar > header span.zs-danger:hover:after,
.zs-calendar > header span[invalid]:hover:after {
  color: #F27755;
}
.zs-calendar > header span.zs-danger:active:after,
.zs-calendar > header span[invalid]:active:after {
  color: #5C1A0B;
}
.zs-calendar > header span.zs-success select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-calendar > header span.zs-success select:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-calendar > header span.zs-success select:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-calendar > header span.zs-success select:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-calendar > header span.zs-success:after {
  color: #00AA67;
}
.zs-calendar > header span.zs-success:focus:after {
  color: #0A6E5E;
}
.zs-calendar > header span.zs-success:hover:after {
  color: #54D7AF;
}
.zs-calendar > header span.zs-success:active:after {
  color: #0C4846;
}
.zs-calendar > header span.zs-warning select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-calendar > header span.zs-warning select:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-calendar > header span.zs-warning select:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-calendar > header span.zs-warning select:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-calendar > header span.zs-warning:after {
  color: #E3A900;
}
.zs-calendar > header span.zs-warning:focus:after {
  color: #8A640C;
}
.zs-calendar > header span.zs-warning:hover:after {
  color: #FDCA63;
}
.zs-calendar > header span.zs-warning:active:after {
  color: #563F0E;
}
.zs-calendar > header span.zs-info select {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-calendar > header span.zs-info select:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-calendar > header span.zs-info select:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-calendar > header span.zs-info select:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-calendar > header span.zs-info:after {
  color: #2B44C7;
}
.zs-calendar > header span.zs-info:focus:after {
  color: #141187;
}
.zs-calendar > header span.zs-info:hover:after {
  color: #7E92E9;
}
.zs-calendar > header span.zs-info:active:after {
  color: #160C60;
}
.zs-calendar > header span > [overlay] {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 0;
  line-height: 1.5;
  letter-spacing: -0.2px;
  outline: 0;
  font-weight: 400;
  border: 1px solid #DEDCDE;
  color: #1A1628;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*
        * Deprecated `placeholder-active` class since v5.4.0 and will be removed in v6.0. Use `zs-placeholder-active` class instead.
        */
  padding: 0.75em 2.75em 0.75em 0.75em;
  max-width: none;
}
.zs-calendar > header span > [overlay].zs-placeholder-active,
.zs-calendar > header span > [overlay].placeholder-active {
  color: #716E79;
  font-style: italic;
}
.zs-calendar > header span > [overlay]:focus {
  border: 1px solid #27A6A4;
}
.zs-calendar > header span.zs-field[label]:after,
.zs-calendar > header span.zs-action-field[label]:after {
  top: auto;
  bottom: 0.5em;
}
.zs-calendar > header span.zs-action-field > select {
  display: none;
}
.zs-calendar > header span.zs-action-field > select [label] {
  display: block;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay],
.zs-calendar > header span.zs-action-field[invalid] > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #B21111;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay]:focus,
.zs-calendar > header span.zs-action-field[invalid] > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay]:hover,
.zs-calendar > header span.zs-action-field[invalid] > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
.zs-calendar > header span.zs-action-field.zs-danger > [overlay]:active,
.zs-calendar > header span.zs-action-field[invalid] > [overlay]:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #00AA67;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
.zs-calendar > header span.zs-action-field.zs-success > [overlay]:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #E3A900;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
.zs-calendar > header span.zs-action-field.zs-warning > [overlay]:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay] {
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px #2B44C7;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay]:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay]:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
.zs-calendar > header span.zs-action-field.zs-info > [overlay]:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-calendar > header span[disabled] > [overlay],
.zs-calendar > header span.zs-disabled > [overlay],
.zs-calendar > header span.zs-calendar > table td[disabled] > [overlay] {
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-calendar > header span > select[name] {
  font-weight: 600;
  border-color: transparent;
  color: #ffffff;
}
.zs-calendar > header span > select[name] option {
  font-weight: normal;
  color: #2F2C3C;
  color: initial;
}
.zs-calendar > header span:after {
  color: #ffffff;
}
.zs-calendar > header span:focus:after {
  color: #454250;
}
.zs-calendar > header span:hover:after {
  color: #9C9AA1;
}
.zs-calendar > header span:active:after {
  color: #2F2C3C;
}
.zs-calendar > header span ~ span {
  margin: 0em 0em 0em -1em;
}
.zs-calendar > header a:hover:before {
  color: inherit;
}
.zs-calendar > header [name="year"],
.zs-calendar > header [name="month"] {
  width: inherit;
  min-width: inherit;
}
.zs-calendar > table {
  width: 100%;
  padding: 1em 1em 0em 1em;
}
.zs-calendar > table th {
  padding-bottom: 0.5em;
  font-weight: normal;
  font-size: 0.625em;
  color: #716E79;
}
.zs-calendar > table td {
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #ffffff;
  font-size: 0.875em;
  width: 2.28571429em;
  height: 2em;
  padding: 0.25em 0.5em 0.25em 0.5em;
}
.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  background: none;
  border-radius: 0;
  color: #B2B0B6;
  border: 1px solid #ffffff;
}
.zs-calendar > table td[notinmonth] {
  opacity: 1;
  color: #B2B0B6;
  background: none;
  border-color: #ffffff;
}
.zs-calendar > table td[notinmonth][current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #716E79;
}
.zs-calendar > table td[notinmonth][today] {
  background: #FFF1D6;
  color: #716E79;
}
.zs-calendar > table td[today] {
  background: #FFD680;
  border: 1px solid #FFD680;
  border-radius: 0;
  font-weight: normal;
}
.zs-calendar > table td[current] {
  border: 1px solid #DEDCDE;
  background: #DEDCDE;
  color: #2F2C3C;
  border-radius: 0;
}
.zs-calendar > table td[current]:hover {
  color: #2F2C3C;
}
.zs-calendar > table td[current]:active {
  color: #ffffff;
  background: #022D42;
}
.zs-calendar > table td:hover {
  color: inherit;
  border: solid 1px #716E79;
  border-radius: 0;
}
.zs-calendar > table td:active {
  color: #ffffff;
  background: #022D42;
}
.zs-calendar > table td.zs-disabled,
.zs-calendar > table td.zs-calendar > table td[disabled] {
  background: none;
  border-radius: 0;
  color: #B2B0B6;
  border: 1px solid #ffffff;
  pointer-events: all;
}
.zs-calendar > table td.zs-disabled:hover,
.zs-calendar > table td.zs-calendar > table td[disabled]:hover {
  color: #B2B0B6;
  border: none;
  cursor: default;
}
.zs-calendar > table td.zs-disabled:active,
.zs-calendar > table td.zs-calendar > table td[disabled]:active {
  background: none;
}
.zs-calendar > footer {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 1em 1em 1em 1em;
  justify-content: space-between;
}
.zs-calendar > footer .zs-fab[role='next'],
.zs-calendar > footer .zs-fab[role='prev'] {
  padding: 0.625em 0.625em 0.625em 0.625em;
}
.zs-calendar > footer .zs-fab[role='next'] .zs-icon,
.zs-calendar > footer .zs-fab[role='prev'] .zs-icon {
  font-size: 0.75em;
  border-radius: inherit;
}
.zs-calendar > footer [role='today'] {
  font-size: 0.875em;
}
.zs-card {
  background: #ffffff;
  border-radius: 0;
  border: 1px solid #DEDCDE;
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
  display: inline-block;
}
.zs-card:hover,
.zs-card:focus {
  outline: none;
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
  border: 1px solid #27A6A4;
}
.zs-card[active] {
  border: 1px solid #27A6A4;
}
.zs-card[flippable] {
  perspective: 800px;
  border: 0px;
  box-shadow: none;
}
.zs-card[flippable]:hover,
.zs-card[flippable]:focus {
  outline: none;
  box-shadow: none;
  border: 0px;
}
.zs-card[flippable][flipped] [flip-card-container] [back] {
  transform: rotateY(0deg);
}
.zs-card[flippable][flipped] [flip-card-container] [front] {
  transform: rotateY(180deg);
}
.zs-card[flippable] [flip-card-container] {
  width: 100%;
  transform-style: preserve-3d;
  position: relative;
}
.zs-card[flippable] [flip-card-container] [front],
.zs-card[flippable] [flip-card-container] [back] {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 100%;
  border-radius: 0;
  border: 1px solid #DEDCDE;
}
.zs-card[flippable] [flip-card-container] [front] {
  position: absolute;
  transform: rotateY(0deg);
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
}
.zs-card[flippable] [flip-card-container] [back] {
  transform: rotateY(-180deg);
  box-shadow: 2px 2px 0px rgba(26, 22, 40, 0.12);
}
.zs-button {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
}
.zs-button:focus {
  border: 1px solid #2F6F7B;
}
.zs-button:hover {
  border: 1px solid #62D2D1;
}
.zs-button:active {
  border: 1px solid #2D535F;
}
.zs-button span {
  vertical-align: middle;
}
.zs-button:focus {
  color: #2F6F7B;
  outline: none;
}
.zs-button:focus > * {
  outline: inherit;
}
.zs-button:focus > .zs-icon {
  color: inherit;
}
.zs-button:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
}
.zs-button:hover .zs-icon {
  color: inherit;
}
.zs-button:active {
  background: #ffffff;
  color: #2D535F;
}
.zs-button:active > .zs-icon {
  color: inherit;
}
.zs-button.zs-danger {
  color: #B21111;
  border: 1px solid #B21111;
  background: #ffffff;
}
.zs-button.zs-danger:visited {
  color: #B21111;
}
.zs-button.zs-danger:focus {
  color: #892208;
}
.zs-button.zs-danger:hover {
  color: #ffffff;
}
.zs-button.zs-danger:active {
  color: #5C1A0B;
}
.zs-button.zs-danger:focus {
  border: 1px solid #892208;
}
.zs-button.zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-button.zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-button.zs-danger:focus {
  background: #ffffff;
}
.zs-button.zs-danger:hover {
  background: #F27755;
}
.zs-button.zs-danger:active {
  background: #ffffff;
}
.zs-button.zs-success {
  color: #00AA67;
  border: 1px solid #00AA67;
  background: #ffffff;
}
.zs-button.zs-success:visited {
  color: #00AA67;
}
.zs-button.zs-success:focus {
  color: #0A6E5E;
}
.zs-button.zs-success:hover {
  color: #ffffff;
}
.zs-button.zs-success:active {
  color: #0C4846;
}
.zs-button.zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-button.zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-button.zs-success:active {
  border: 1px solid #0C4846;
}
.zs-button.zs-success:focus {
  background: #ffffff;
}
.zs-button.zs-success:hover {
  background: #54D7AF;
}
.zs-button.zs-success:active {
  background: #ffffff;
}
.zs-button.zs-warning {
  color: #E3A900;
  border: 1px solid #E3A900;
  background: #ffffff;
}
.zs-button.zs-warning:visited {
  color: #E3A900;
}
.zs-button.zs-warning:focus {
  color: #8A640C;
}
.zs-button.zs-warning:hover {
  color: #ffffff;
}
.zs-button.zs-warning:active {
  color: #563F0E;
}
.zs-button.zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-button.zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-button.zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-button.zs-warning:focus {
  background: #ffffff;
}
.zs-button.zs-warning:hover {
  background: #FDCA63;
}
.zs-button.zs-warning:active {
  background: #ffffff;
}
.zs-button.zs-info {
  color: #2B44C7;
  border: 1px solid #2B44C7;
  background: #ffffff;
}
.zs-button.zs-info:visited {
  color: #2B44C7;
}
.zs-button.zs-info:focus {
  color: #141187;
}
.zs-button.zs-info:hover {
  color: #ffffff;
}
.zs-button.zs-info:active {
  color: #160C60;
}
.zs-button.zs-info:focus {
  border: 1px solid #141187;
}
.zs-button.zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-button.zs-info:active {
  border: 1px solid #160C60;
}
.zs-button.zs-info:focus {
  background: #ffffff;
}
.zs-button.zs-info:hover {
  background: #7E92E9;
}
.zs-button.zs-info:active {
  background: #ffffff;
}
.zs-button::-moz-focus-inner {
  border: none;
}
.zs-button-action,
.zs-button-solid {
  background-color: #27A6A4;
  color: #ffffff;
  border: 1px solid #27A6A4;
}
.zs-button-action:focus,
.zs-button-solid:focus {
  border: 1px solid #2F6F7B;
}
.zs-button-action:hover,
.zs-button-solid:hover {
  border: 1px solid #62D2D1;
}
.zs-button-action:active,
.zs-button-solid:active {
  border: 1px solid #2D535F;
}
.zs-button-action:focus,
.zs-button-solid:focus {
  background: #2F6F7B;
  color: #ffffff;
}
.zs-button-action:hover,
.zs-button-solid:hover {
  color: #ffffff;
  background: #62D2D1;
}
.zs-button-action:active,
.zs-button-solid:active {
  background: #2D535F;
  color: #ffffff;
}
.zs-button-action.zs-danger,
.zs-button-solid.zs-danger {
  background: #B21111;
  border: 1px solid #B21111;
  color: #ffffff;
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  background: #892208;
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  background: #F27755;
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  background: #5C1A0B;
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  border: 1px solid #892208;
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-button-action.zs-danger:visited,
.zs-button-solid.zs-danger:visited {
  color: #ffffff;
}
.zs-button-action.zs-danger:focus,
.zs-button-solid.zs-danger:focus {
  color: #ffffff;
}
.zs-button-action.zs-danger:hover,
.zs-button-solid.zs-danger:hover {
  color: #ffffff;
}
.zs-button-action.zs-danger:active,
.zs-button-solid.zs-danger:active {
  color: #ffffff;
}
.zs-button-action.zs-success,
.zs-button-solid.zs-success {
  background: #00AA67;
  border: 1px solid #00AA67;
  color: #ffffff;
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  background: #0A6E5E;
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  background: #54D7AF;
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  background: #0C4846;
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  border: 1px solid #0C4846;
}
.zs-button-action.zs-success:visited,
.zs-button-solid.zs-success:visited {
  color: #ffffff;
}
.zs-button-action.zs-success:focus,
.zs-button-solid.zs-success:focus {
  color: #ffffff;
}
.zs-button-action.zs-success:hover,
.zs-button-solid.zs-success:hover {
  color: #ffffff;
}
.zs-button-action.zs-success:active,
.zs-button-solid.zs-success:active {
  color: #ffffff;
}
.zs-button-action.zs-warning,
.zs-button-solid.zs-warning {
  background: #E3A900;
  border: 1px solid #E3A900;
  color: #ffffff;
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  background: #8A640C;
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  background: #FDCA63;
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  background: #563F0E;
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-button-action.zs-warning:visited,
.zs-button-solid.zs-warning:visited {
  color: #ffffff;
}
.zs-button-action.zs-warning:focus,
.zs-button-solid.zs-warning:focus {
  color: #ffffff;
}
.zs-button-action.zs-warning:hover,
.zs-button-solid.zs-warning:hover {
  color: #ffffff;
}
.zs-button-action.zs-warning:active,
.zs-button-solid.zs-warning:active {
  color: #ffffff;
}
.zs-button-action.zs-info,
.zs-button-solid.zs-info {
  background: #2B44C7;
  border: 1px solid #2B44C7;
  color: #ffffff;
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  background: #141187;
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  background: #7E92E9;
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  background: #160C60;
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  border: 1px solid #141187;
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  border: 1px solid #160C60;
}
.zs-button-action.zs-info:visited,
.zs-button-solid.zs-info:visited {
  color: #ffffff;
}
.zs-button-action.zs-info:focus,
.zs-button-solid.zs-info:focus {
  color: #ffffff;
}
.zs-button-action.zs-info:hover,
.zs-button-solid.zs-info:hover {
  color: #ffffff;
}
.zs-button-action.zs-info:active,
.zs-button-solid.zs-info:active {
  color: #ffffff;
}
a.zs-button {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  text-decoration: none;
  border: 1px solid transparent;
  color: #2D8B93;
}
a.zs-button:focus {
  border: 1px solid transparent;
}
a.zs-button:hover {
  border: 1px solid transparent;
}
a.zs-button:active {
  border: 1px solid transparent;
}
a.zs-button:focus {
  color: #27A6A4;
  background: #F3FCFE;
  box-shadow: inset 0 0 0 2px #27A6A4;
  outline: none;
}
a.zs-button:hover {
  color: #27A6A4;
  background: #F3FCFE;
  box-shadow: none;
}
a.zs-button:active {
  color: #ffffff;
  background: #2F6F7B;
  box-shadow: none;
}
a.zs-button.zs-danger {
  color: #B21111;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-danger:visited {
  color: #B21111;
}
a.zs-button.zs-danger:focus {
  color: #ffffff;
}
a.zs-button.zs-danger:hover {
  color: #ffffff;
}
a.zs-button.zs-danger:active {
  color: #ffffff;
}
a.zs-button.zs-danger:focus {
  background: #892208;
}
a.zs-button.zs-danger:hover {
  background: #F27755;
}
a.zs-button.zs-danger:active {
  background: #5C1A0B;
}
a.zs-button.zs-danger:focus {
  box-shadow: inset 0 0 0 1px #892208;
}
a.zs-button.zs-danger:hover {
  box-shadow: inset 0 0 0 1px #F27755;
}
a.zs-button.zs-danger:active {
  box-shadow: inset 0 0 0 1px #5C1A0B;
}
a.zs-button.zs-success {
  color: #00AA67;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-success:visited {
  color: #00AA67;
}
a.zs-button.zs-success:focus {
  color: #ffffff;
}
a.zs-button.zs-success:hover {
  color: #ffffff;
}
a.zs-button.zs-success:active {
  color: #ffffff;
}
a.zs-button.zs-success:focus {
  background: #0A6E5E;
}
a.zs-button.zs-success:hover {
  background: #54D7AF;
}
a.zs-button.zs-success:active {
  background: #0C4846;
}
a.zs-button.zs-success:focus {
  box-shadow: inset 0 0 0 1px #0A6E5E;
}
a.zs-button.zs-success:hover {
  box-shadow: inset 0 0 0 1px #54D7AF;
}
a.zs-button.zs-success:active {
  box-shadow: inset 0 0 0 1px #0C4846;
}
a.zs-button.zs-warning {
  color: #E3A900;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-warning:visited {
  color: #E3A900;
}
a.zs-button.zs-warning:focus {
  color: #ffffff;
}
a.zs-button.zs-warning:hover {
  color: #ffffff;
}
a.zs-button.zs-warning:active {
  color: #ffffff;
}
a.zs-button.zs-warning:focus {
  background: #8A640C;
}
a.zs-button.zs-warning:hover {
  background: #FDCA63;
}
a.zs-button.zs-warning:active {
  background: #563F0E;
}
a.zs-button.zs-warning:focus {
  box-shadow: inset 0 0 0 1px #8A640C;
}
a.zs-button.zs-warning:hover {
  box-shadow: inset 0 0 0 1px #FDCA63;
}
a.zs-button.zs-warning:active {
  box-shadow: inset 0 0 0 1px #563F0E;
}
a.zs-button.zs-info {
  color: #2B44C7;
  background: #ffffff;
  border: 0px;
  box-shadow: none;
  box-shadow: inset 0 0 0 1px transparent;
  border: 1px solid transparent;
}
a.zs-button.zs-info:visited {
  color: #2B44C7;
}
a.zs-button.zs-info:focus {
  color: #ffffff;
}
a.zs-button.zs-info:hover {
  color: #ffffff;
}
a.zs-button.zs-info:active {
  color: #ffffff;
}
a.zs-button.zs-info:focus {
  background: #141187;
}
a.zs-button.zs-info:hover {
  background: #7E92E9;
}
a.zs-button.zs-info:active {
  background: #160C60;
}
a.zs-button.zs-info:focus {
  box-shadow: inset 0 0 0 1px #141187;
}
a.zs-button.zs-info:hover {
  box-shadow: inset 0 0 0 1px #7E92E9;
}
a.zs-button.zs-info:active {
  box-shadow: inset 0 0 0 1px #160C60;
}
.zs-button .zs-icon:hover {
  cursor: pointer;
  color: inherit;
}
.zs-button .zs-icon::before,
.zs-button .zs-icon::after {
  margin: 0;
  padding: 0;
  vertical-align: top;
}
.zs-button > .zs-icon + *,
.zs-button > * + .zs-icon {
  vertical-align: middle;
}
.zs-button + .zs-button {
  margin-left: 0.5em;
}
.zs-fab {
  position: relative;
  border-radius: 100%;
  min-height: auto;
  padding: 1.5em;
}
.zs-fab > .zs-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zs-button > .zs-icon {
  color: inherit;
  background: inherit;
  background-color: inherit;
  border-color: inherit;
}
.zs-button[disabled],
.zs-button.zs-disabled,
.zs-field input[type="submit"][disabled],
.zs-field input[type="submit"].zs-disabled,
.zs-field input[type="button"][disabled],
.zs-field input[type="button"].zs-disabled,
.zs-field input[type="reset"][disabled],
.zs-field input[type="reset"].zs-disabled,
.zs-button.zs-calendar > table td[disabled],
.zs-field input[type="submit"].zs-calendar > table td[disabled],
.zs-field input[type="button"].zs-calendar > table td[disabled],
.zs-field input[type="reset"].zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
  border: 1px solid #B2B0B6;
}
.zs-button > *:not(.zs-icon) {
  margin: 0em 0.25em 0em 0.25em;
}
.zs-button > *:first-child {
  margin-left: 0;
}
.zs-button > *:last-child {
  margin-right: 0;
}
.zs-field input[type="submit"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
  background-color: #27A6A4;
  color: #ffffff;
  border: 1px solid #27A6A4;
}
.zs-field input[type="submit"]:focus {
  border: 1px solid #2F6F7B;
}
.zs-field input[type="submit"]:hover {
  border: 1px solid #62D2D1;
}
.zs-field input[type="submit"]:active {
  border: 1px solid #2D535F;
}
.zs-field input[type="submit"] span {
  vertical-align: middle;
}
.zs-field input[type="submit"]:focus {
  color: #2F6F7B;
  outline: none;
}
.zs-field input[type="submit"]:focus > * {
  outline: inherit;
}
.zs-field input[type="submit"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"]:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
}
.zs-field input[type="submit"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"]:active {
  background: #ffffff;
  color: #2D535F;
}
.zs-field input[type="submit"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="submit"].zs-danger {
  color: #B21111;
  border: 1px solid #B21111;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-danger:visited {
  color: #B21111;
}
.zs-field input[type="submit"].zs-danger:focus {
  color: #892208;
}
.zs-field input[type="submit"].zs-danger:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-danger:active {
  color: #5C1A0B;
}
.zs-field input[type="submit"].zs-danger:focus {
  border: 1px solid #892208;
}
.zs-field input[type="submit"].zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-field input[type="submit"].zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-field input[type="submit"].zs-danger:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-danger:hover {
  background: #F27755;
}
.zs-field input[type="submit"].zs-danger:active {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-success {
  color: #00AA67;
  border: 1px solid #00AA67;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-success:visited {
  color: #00AA67;
}
.zs-field input[type="submit"].zs-success:focus {
  color: #0A6E5E;
}
.zs-field input[type="submit"].zs-success:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-success:active {
  color: #0C4846;
}
.zs-field input[type="submit"].zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-field input[type="submit"].zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-field input[type="submit"].zs-success:active {
  border: 1px solid #0C4846;
}
.zs-field input[type="submit"].zs-success:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-success:hover {
  background: #54D7AF;
}
.zs-field input[type="submit"].zs-success:active {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-warning {
  color: #E3A900;
  border: 1px solid #E3A900;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-warning:visited {
  color: #E3A900;
}
.zs-field input[type="submit"].zs-warning:focus {
  color: #8A640C;
}
.zs-field input[type="submit"].zs-warning:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-warning:active {
  color: #563F0E;
}
.zs-field input[type="submit"].zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-field input[type="submit"].zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-field input[type="submit"].zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-field input[type="submit"].zs-warning:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-warning:hover {
  background: #FDCA63;
}
.zs-field input[type="submit"].zs-warning:active {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-info {
  color: #2B44C7;
  border: 1px solid #2B44C7;
  background: #ffffff;
}
.zs-field input[type="submit"].zs-info:visited {
  color: #2B44C7;
}
.zs-field input[type="submit"].zs-info:focus {
  color: #141187;
}
.zs-field input[type="submit"].zs-info:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-info:active {
  color: #160C60;
}
.zs-field input[type="submit"].zs-info:focus {
  border: 1px solid #141187;
}
.zs-field input[type="submit"].zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-field input[type="submit"].zs-info:active {
  border: 1px solid #160C60;
}
.zs-field input[type="submit"].zs-info:focus {
  background: #ffffff;
}
.zs-field input[type="submit"].zs-info:hover {
  background: #7E92E9;
}
.zs-field input[type="submit"].zs-info:active {
  background: #ffffff;
}
.zs-field input[type="submit"]:focus {
  border: 1px solid #2F6F7B;
}
.zs-field input[type="submit"]:hover {
  border: 1px solid #62D2D1;
}
.zs-field input[type="submit"]:active {
  border: 1px solid #2D535F;
}
.zs-field input[type="submit"]:focus {
  background: #2F6F7B;
  color: #ffffff;
}
.zs-field input[type="submit"]:hover {
  color: #ffffff;
  background: #62D2D1;
}
.zs-field input[type="submit"]:active {
  background: #2D535F;
  color: #ffffff;
}
.zs-field input[type="submit"].zs-danger {
  background: #B21111;
  border: 1px solid #B21111;
  color: #ffffff;
}
.zs-field input[type="submit"].zs-danger:focus {
  background: #892208;
}
.zs-field input[type="submit"].zs-danger:hover {
  background: #F27755;
}
.zs-field input[type="submit"].zs-danger:active {
  background: #5C1A0B;
}
.zs-field input[type="submit"].zs-danger:focus {
  border: 1px solid #892208;
}
.zs-field input[type="submit"].zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-field input[type="submit"].zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-field input[type="submit"].zs-danger:visited {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-danger:focus {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-danger:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-danger:active {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-success {
  background: #00AA67;
  border: 1px solid #00AA67;
  color: #ffffff;
}
.zs-field input[type="submit"].zs-success:focus {
  background: #0A6E5E;
}
.zs-field input[type="submit"].zs-success:hover {
  background: #54D7AF;
}
.zs-field input[type="submit"].zs-success:active {
  background: #0C4846;
}
.zs-field input[type="submit"].zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-field input[type="submit"].zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-field input[type="submit"].zs-success:active {
  border: 1px solid #0C4846;
}
.zs-field input[type="submit"].zs-success:visited {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-success:focus {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-success:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-success:active {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-warning {
  background: #E3A900;
  border: 1px solid #E3A900;
  color: #ffffff;
}
.zs-field input[type="submit"].zs-warning:focus {
  background: #8A640C;
}
.zs-field input[type="submit"].zs-warning:hover {
  background: #FDCA63;
}
.zs-field input[type="submit"].zs-warning:active {
  background: #563F0E;
}
.zs-field input[type="submit"].zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-field input[type="submit"].zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-field input[type="submit"].zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-field input[type="submit"].zs-warning:visited {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-warning:focus {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-warning:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-warning:active {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-info {
  background: #2B44C7;
  border: 1px solid #2B44C7;
  color: #ffffff;
}
.zs-field input[type="submit"].zs-info:focus {
  background: #141187;
}
.zs-field input[type="submit"].zs-info:hover {
  background: #7E92E9;
}
.zs-field input[type="submit"].zs-info:active {
  background: #160C60;
}
.zs-field input[type="submit"].zs-info:focus {
  border: 1px solid #141187;
}
.zs-field input[type="submit"].zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-field input[type="submit"].zs-info:active {
  border: 1px solid #160C60;
}
.zs-field input[type="submit"].zs-info:visited {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-info:focus {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-info:hover {
  color: #ffffff;
}
.zs-field input[type="submit"].zs-info:active {
  color: #ffffff;
}
.zs-field input[type="button"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
}
.zs-field input[type="button"]:focus {
  border: 1px solid #2F6F7B;
}
.zs-field input[type="button"]:hover {
  border: 1px solid #62D2D1;
}
.zs-field input[type="button"]:active {
  border: 1px solid #2D535F;
}
.zs-field input[type="button"] span {
  vertical-align: middle;
}
.zs-field input[type="button"]:focus {
  color: #2F6F7B;
  outline: none;
}
.zs-field input[type="button"]:focus > * {
  outline: inherit;
}
.zs-field input[type="button"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="button"]:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
}
.zs-field input[type="button"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="button"]:active {
  background: #ffffff;
  color: #2D535F;
}
.zs-field input[type="button"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="button"].zs-danger {
  color: #B21111;
  border: 1px solid #B21111;
  background: #ffffff;
}
.zs-field input[type="button"].zs-danger:visited {
  color: #B21111;
}
.zs-field input[type="button"].zs-danger:focus {
  color: #892208;
}
.zs-field input[type="button"].zs-danger:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-danger:active {
  color: #5C1A0B;
}
.zs-field input[type="button"].zs-danger:focus {
  border: 1px solid #892208;
}
.zs-field input[type="button"].zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-field input[type="button"].zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-field input[type="button"].zs-danger:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-danger:hover {
  background: #F27755;
}
.zs-field input[type="button"].zs-danger:active {
  background: #ffffff;
}
.zs-field input[type="button"].zs-success {
  color: #00AA67;
  border: 1px solid #00AA67;
  background: #ffffff;
}
.zs-field input[type="button"].zs-success:visited {
  color: #00AA67;
}
.zs-field input[type="button"].zs-success:focus {
  color: #0A6E5E;
}
.zs-field input[type="button"].zs-success:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-success:active {
  color: #0C4846;
}
.zs-field input[type="button"].zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-field input[type="button"].zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-field input[type="button"].zs-success:active {
  border: 1px solid #0C4846;
}
.zs-field input[type="button"].zs-success:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-success:hover {
  background: #54D7AF;
}
.zs-field input[type="button"].zs-success:active {
  background: #ffffff;
}
.zs-field input[type="button"].zs-warning {
  color: #E3A900;
  border: 1px solid #E3A900;
  background: #ffffff;
}
.zs-field input[type="button"].zs-warning:visited {
  color: #E3A900;
}
.zs-field input[type="button"].zs-warning:focus {
  color: #8A640C;
}
.zs-field input[type="button"].zs-warning:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-warning:active {
  color: #563F0E;
}
.zs-field input[type="button"].zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-field input[type="button"].zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-field input[type="button"].zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-field input[type="button"].zs-warning:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-warning:hover {
  background: #FDCA63;
}
.zs-field input[type="button"].zs-warning:active {
  background: #ffffff;
}
.zs-field input[type="button"].zs-info {
  color: #2B44C7;
  border: 1px solid #2B44C7;
  background: #ffffff;
}
.zs-field input[type="button"].zs-info:visited {
  color: #2B44C7;
}
.zs-field input[type="button"].zs-info:focus {
  color: #141187;
}
.zs-field input[type="button"].zs-info:hover {
  color: #ffffff;
}
.zs-field input[type="button"].zs-info:active {
  color: #160C60;
}
.zs-field input[type="button"].zs-info:focus {
  border: 1px solid #141187;
}
.zs-field input[type="button"].zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-field input[type="button"].zs-info:active {
  border: 1px solid #160C60;
}
.zs-field input[type="button"].zs-info:focus {
  background: #ffffff;
}
.zs-field input[type="button"].zs-info:hover {
  background: #7E92E9;
}
.zs-field input[type="button"].zs-info:active {
  background: #ffffff;
}
.zs-field input[type="reset"] {
  text-decoration: none;
  vertical-align: middle;
  line-height: 1.5;
  font-size: 1em;
  font-weight: 600;
  box-shadow: none;
  border: 1px solid #B2B0B6;
  color: #454250;
  background-color: #ffffff;
  border-radius: 0.25em;
  padding: 0.75em 1em 0.75em 1em;
  white-space: nowrap;
  -webkit-appearance: none;
}
.zs-field input[type="reset"]:focus {
  border: 1px solid #2F6F7B;
}
.zs-field input[type="reset"]:hover {
  border: 1px solid #62D2D1;
}
.zs-field input[type="reset"]:active {
  border: 1px solid #2D535F;
}
.zs-field input[type="reset"] span {
  vertical-align: middle;
}
.zs-field input[type="reset"]:focus {
  color: #2F6F7B;
  outline: none;
}
.zs-field input[type="reset"]:focus > * {
  outline: inherit;
}
.zs-field input[type="reset"]:focus > .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"]:hover {
  cursor: pointer;
  background-color: #62D2D1;
  color: #ffffff;
}
.zs-field input[type="reset"]:hover .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"]:active {
  background: #ffffff;
  color: #2D535F;
}
.zs-field input[type="reset"]:active > .zs-icon {
  color: inherit;
}
.zs-field input[type="reset"].zs-danger {
  color: #B21111;
  border: 1px solid #B21111;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-danger:visited {
  color: #B21111;
}
.zs-field input[type="reset"].zs-danger:focus {
  color: #892208;
}
.zs-field input[type="reset"].zs-danger:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-danger:active {
  color: #5C1A0B;
}
.zs-field input[type="reset"].zs-danger:focus {
  border: 1px solid #892208;
}
.zs-field input[type="reset"].zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-field input[type="reset"].zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-field input[type="reset"].zs-danger:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-danger:hover {
  background: #F27755;
}
.zs-field input[type="reset"].zs-danger:active {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-success {
  color: #00AA67;
  border: 1px solid #00AA67;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-success:visited {
  color: #00AA67;
}
.zs-field input[type="reset"].zs-success:focus {
  color: #0A6E5E;
}
.zs-field input[type="reset"].zs-success:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-success:active {
  color: #0C4846;
}
.zs-field input[type="reset"].zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-field input[type="reset"].zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-field input[type="reset"].zs-success:active {
  border: 1px solid #0C4846;
}
.zs-field input[type="reset"].zs-success:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-success:hover {
  background: #54D7AF;
}
.zs-field input[type="reset"].zs-success:active {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-warning {
  color: #E3A900;
  border: 1px solid #E3A900;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-warning:visited {
  color: #E3A900;
}
.zs-field input[type="reset"].zs-warning:focus {
  color: #8A640C;
}
.zs-field input[type="reset"].zs-warning:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-warning:active {
  color: #563F0E;
}
.zs-field input[type="reset"].zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-field input[type="reset"].zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-field input[type="reset"].zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-field input[type="reset"].zs-warning:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-warning:hover {
  background: #FDCA63;
}
.zs-field input[type="reset"].zs-warning:active {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-info {
  color: #2B44C7;
  border: 1px solid #2B44C7;
  background: #ffffff;
}
.zs-field input[type="reset"].zs-info:visited {
  color: #2B44C7;
}
.zs-field input[type="reset"].zs-info:focus {
  color: #141187;
}
.zs-field input[type="reset"].zs-info:hover {
  color: #ffffff;
}
.zs-field input[type="reset"].zs-info:active {
  color: #160C60;
}
.zs-field input[type="reset"].zs-info:focus {
  border: 1px solid #141187;
}
.zs-field input[type="reset"].zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-field input[type="reset"].zs-info:active {
  border: 1px solid #160C60;
}
.zs-field input[type="reset"].zs-info:focus {
  background: #ffffff;
}
.zs-field input[type="reset"].zs-info:hover {
  background: #7E92E9;
}
.zs-field input[type="reset"].zs-info:active {
  background: #ffffff;
}
.zs-toggle-tabs {
  display: block;
}
.zs-toggle-tabs .tabs-container {
  display: flex;
  justify-content: center;
}
.zs-toggle-tabs .tabs-container .zs-button {
  border: 1px solid #B2B0B6;
  border-right: 1px solid transparent;
}
.zs-toggle-tabs .tabs-container .zs-tab {
  padding: 0;
  margin: 0;
  border: 0;
}
.zs-toggle-tabs .tabs-container .zs-tab:first-of-type .zs-button {
  border: 1px solid #B2B0B6;
  border-right: 1px solid transparent;
}
.zs-toggle-tabs .tabs-container .zs-tab:last-of-type .zs-button {
  border: 1px solid #B2B0B6;
}
.zs-toggle-tabs .tabs-container .zs-tab .zs-button:hover {
  border: 1px solid #62D2D1;
}
.zs-toggle-tabs .tabs-container .zs-tab.zs-active + .zs-tab .zs-button {
  border: 1px solid #B2B0B6;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}
.zs-toggle-tabs .tabs-container .zs-tab.zs-active + .zs-tab:last-of-type .zs-button {
  border: 1px solid #B2B0B6;
  border-left: 1px solid transparent;
}
.zs-toggle-tabs .tabs-container .zs-tab.zs-active .zs-button {
  background-color: #27A6A4;
  color: #FAFAFA;
  border: 1px solid #27A6A4;
}
.zs-toggle-tabs .tabs-container .zs-tab[active]:after {
  display: none;
}
.zs-toggle-tabs [source-id] {
  top: 0;
  border: 0;
  box-shadow: none;
}
.zs-toggle-tabs.zs-toggle-tabs-outline .tabs-container .zs-active .zs-button {
  color: #27A6A4;
  background-color: #FAFAFA;
}
.zs-carousel {
  position: relative;
  overflow: hidden;
}
.zs-carousel .zs-carousel-navigator {
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
  font-size: 0.75em;
  top: calc(50% - 1.5em);
}
.zs-carousel .zs-carousel-navigator[role="back"] {
  left: 5em;
}
.zs-carousel .zs-carousel-navigator[role="forward"] {
  right: 5em;
}
.zs-carousel .zs-carousel-indicator-container .zs-carousel-navigator {
  top: 50%;
  font-size: 2em;
}
.zs-carousel .zs-carousel-indicator-container .zs-carousel-navigator[role="back"] {
  left: 1.75em;
  left: 1.625em;
}
.zs-carousel .zs-carousel-indicator-container .zs-carousel-navigator[role="forward"] {
  right: 1.75em;
  right: 1.625em;
}
.zs-carousel[slider] .zs-carousel-container {
  white-space: nowrap;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.zs-carousel[slider] .zs-carousel-container::-webkit-scrollbar {
  display: none;
}
.zs-carousel[slider] .zs-carousel-container .zs-card[tile] {
  margin: 0.5em 0.5em 0.5em 0.5em;
}
.zs-carousel[slider] .zs-carousel-container ~ span.zs-carousel-sides-back,
.zs-carousel[slider] .zs-carousel-container ~ span.zs-carousel-sides-forward {
  height: 100%;
}
.zs-carousel:not([slider]) .zs-carousel-container {
  display: grid;
  grid-template-columns: calc(100% - 3em) calc(100% - 3em*2) calc(100% - 3em);
  display: -ms-grid;
  -ms-grid-columns: calc(100% - 3em) calc(100% - 3em*2) calc(100% - 3em);
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}
.zs-carousel:not([slider]) .zs-carousel-container > section {
  overflow: hidden;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(1) {
  grid-column: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  margin-left: 3em;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(2) {
  grid-column: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(3) {
  grid-column: 3;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  margin-right: 3em;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(4) {
  grid-column: 4;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
}
.zs-carousel .zs-carousel-indicator-container {
  width: 100%;
  text-align: center;
  line-height: 1;
  padding: 0.5em 0em 0.5em 0em;
}
.zs-carousel .zs-carousel-indicator {
  display: inline-block;
  z-index: 2;
  cursor: pointer;
  opacity: 0.2;
  height: auto;
  width: auto;
  font-size: 1.5em;
  color: #2D535F;
  margin: 0em 0em 0em 0em;
}
.zs-carousel .zs-carousel-indicator[active] {
  opacity: 1;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  color: #27A6A4;
}
.zs-carousel .zs-inverse .zs-carousel-indicator {
  color: #ffffff;
}
.zs-carousel .zs-inverse .zs-carousel-indicator[active] {
  color: #27A6A4;
}
.zs-carousel span.zs-carousel-sides-back,
.zs-carousel span.zs-carousel-sides-forward {
  position: absolute;
  text-decoration: none;
  width: 3em;
  text-align: center;
  height: calc(100% - 1.5em - 1em);
  padding: 0;
  opacity: 0.5;
}
.zs-carousel span.zs-carousel-sides-back:nth-of-type(1),
.zs-carousel span.zs-carousel-sides-forward:nth-of-type(1) {
  left: -0.25em;
  top: 0;
  bottom: 0;
}
.zs-carousel span.zs-carousel-sides-back:nth-of-type(2),
.zs-carousel span.zs-carousel-sides-forward:nth-of-type(2) {
  right: -0.25em;
  top: 0;
  bottom: 0;
}
.zs-carousel span.zs-carousel-sides-back,
.zs-carousel span.zs-carousel-sides-forward {
  opacity: 0;
}
.zs-carousel:not([slider]) .zs-carousel-container {
  display: grid;
  grid-template-columns: calc(100% - 3em) calc(100% - 3em*2) calc(100% - 3em*2) calc(100% - 3em*2) calc(100% - 3em);
  display: -ms-grid;
  -ms-grid-columns: calc(100% - 3em) calc(100% - 3em*2) calc(100% - 3em*2) calc(100% - 3em*2) calc(100% - 3em);
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}
.zs-carousel:not([slider]) .zs-carousel-container > section {
  overflow: hidden;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(1) {
  grid-column: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  margin-left: 3em;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(2) {
  grid-column: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(3) {
  grid-column: 3;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
}
.zs-carousel:not([slider]) .zs-carousel-container > section:nth-of-type(4) {
  grid-column: 4;
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
}
.zs-master-style .zs-drawer,
.zs-master-style zs-drawer,
.zs-drawer,
.zs-master-style [role="drawer"] {
  background: #ffffff;
  border: 1px solid #DEDCDE;
  box-shadow: none;
  display: inline-block;
  position: fixed;
  padding: 1em 1em 1em 1em;
  min-width: 20em;
  min-height: 10em;
  text-align: left;
  z-index: 5001;
}
.zs-master-style .zs-drawer[embeded],
.zs-master-style zs-drawer[embeded],
.zs-drawer[embeded],
.zs-master-style [role="drawer"][embeded] {
  z-index: auto;
}
.zs-master-style .zs-drawer > section,
.zs-master-style zs-drawer > section,
.zs-drawer > section,
.zs-master-style [role="drawer"] > section {
  position: absolute;
  top: 0px;
  padding: 1em 1em 1em 1em;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  z-index: 2;
}
.zs-master-style .zs-drawer > tag,
.zs-master-style zs-drawer > tag,
.zs-drawer > tag,
.zs-master-style [role="drawer"] > tag,
.zs-master-style .zs-drawer > toggle,
.zs-master-style zs-drawer > toggle,
.zs-drawer > toggle,
.zs-master-style [role="drawer"] > toggle,
.zs-master-style .zs-drawer [role="toggle"],
.zs-master-style zs-drawer [role="toggle"],
.zs-drawer [role="toggle"],
.zs-master-style [role="drawer"] [role="toggle"] {
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  position: absolute;
  background: inherit;
  border-radius: 0.25em;
  border: 1px solid #DEDCDE;
  box-shadow: none;
  transform: rotate(0deg);
  cursor: pointer;
  text-align: center;
  height: 24px;
  border-bottom: 0px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.zs-master-style .zs-drawer > tag > span,
.zs-master-style zs-drawer > tag > span,
.zs-drawer > tag > span,
.zs-master-style [role="drawer"] > tag > span,
.zs-master-style .zs-drawer > toggle > span,
.zs-master-style zs-drawer > toggle > span,
.zs-drawer > toggle > span,
.zs-master-style [role="drawer"] > toggle > span,
.zs-master-style .zs-drawer [role="toggle"] > span,
.zs-master-style zs-drawer [role="toggle"] > span,
.zs-drawer [role="toggle"] > span,
.zs-master-style [role="drawer"] [role="toggle"] > span,
.zs-master-style .zs-drawer > tag > a,
.zs-master-style zs-drawer > tag > a,
.zs-drawer > tag > a,
.zs-master-style [role="drawer"] > tag > a,
.zs-master-style .zs-drawer > toggle > a,
.zs-master-style zs-drawer > toggle > a,
.zs-drawer > toggle > a,
.zs-master-style [role="drawer"] > toggle > a,
.zs-master-style .zs-drawer [role="toggle"] > a,
.zs-master-style zs-drawer [role="toggle"] > a,
.zs-drawer [role="toggle"] > a,
.zs-master-style [role="drawer"] [role="toggle"] > a {
  display: inline-block;
  width: 120px;
  white-space: nowrap;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0em 0em 0em 0em;
  background-color: transparent;
}
.zs-master-style .zs-drawer > tag > span.zs-icon,
.zs-master-style zs-drawer > tag > span.zs-icon,
.zs-drawer > tag > span.zs-icon,
.zs-master-style [role="drawer"] > tag > span.zs-icon,
.zs-master-style .zs-drawer > toggle > span.zs-icon,
.zs-master-style zs-drawer > toggle > span.zs-icon,
.zs-drawer > toggle > span.zs-icon,
.zs-master-style [role="drawer"] > toggle > span.zs-icon,
.zs-master-style .zs-drawer [role="toggle"] > span.zs-icon,
.zs-master-style zs-drawer [role="toggle"] > span.zs-icon,
.zs-drawer [role="toggle"] > span.zs-icon,
.zs-master-style [role="drawer"] [role="toggle"] > span.zs-icon,
.zs-master-style .zs-drawer > tag > a.zs-icon,
.zs-master-style zs-drawer > tag > a.zs-icon,
.zs-drawer > tag > a.zs-icon,
.zs-master-style [role="drawer"] > tag > a.zs-icon,
.zs-master-style .zs-drawer > toggle > a.zs-icon,
.zs-master-style zs-drawer > toggle > a.zs-icon,
.zs-drawer > toggle > a.zs-icon,
.zs-master-style [role="drawer"] > toggle > a.zs-icon,
.zs-master-style .zs-drawer [role="toggle"] > a.zs-icon,
.zs-master-style zs-drawer [role="toggle"] > a.zs-icon,
.zs-drawer [role="toggle"] > a.zs-icon,
.zs-master-style [role="drawer"] [role="toggle"] > a.zs-icon {
  width: 48px;
}
.zs-master-style .zs-drawer > tag > a,
.zs-master-style zs-drawer > tag > a,
.zs-drawer > tag > a,
.zs-master-style [role="drawer"] > tag > a,
.zs-master-style .zs-drawer > toggle > a,
.zs-master-style zs-drawer > toggle > a,
.zs-drawer > toggle > a,
.zs-master-style [role="drawer"] > toggle > a,
.zs-master-style .zs-drawer [role="toggle"] > a,
.zs-master-style zs-drawer [role="toggle"] > a,
.zs-drawer [role="toggle"] > a,
.zs-master-style [role="drawer"] [role="toggle"] > a {
  color: inherit;
}
.zs-master-style .zs-drawer > tag::after,
.zs-master-style zs-drawer > tag::after,
.zs-drawer > tag::after,
.zs-master-style [role="drawer"] > tag::after,
.zs-master-style .zs-drawer > toggle::after,
.zs-master-style zs-drawer > toggle::after,
.zs-drawer > toggle::after,
.zs-master-style [role="drawer"] > toggle::after,
.zs-master-style .zs-drawer [role="toggle"]::after,
.zs-master-style zs-drawer [role="toggle"]::after,
.zs-drawer [role="toggle"]::after,
.zs-master-style [role="drawer"] [role="toggle"]::after {
  content: '';
  position: absolute;
  display: block;
  left: -7px;
  bottom: -7px;
  right: -7px;
  background: inherit;
  z-index: 1;
  overflow: hidden;
  height: 7px;
}
.zs-master-style .zs-drawer[align="top"],
.zs-master-style zs-drawer[align="top"],
.zs-drawer[align="top"],
.zs-master-style [role="drawer"][align="top"] {
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}
.zs-master-style .zs-drawer[align="top"] > tag,
.zs-master-style zs-drawer[align="top"] > tag,
.zs-drawer[align="top"] > tag,
.zs-master-style [role="drawer"][align="top"] > tag,
.zs-master-style .zs-drawer[align="top"] > toggle,
.zs-master-style zs-drawer[align="top"] > toggle,
.zs-drawer[align="top"] > toggle,
.zs-master-style [role="drawer"][align="top"] > toggle,
.zs-master-style .zs-drawer[align="top"] [role="toggle"],
.zs-master-style zs-drawer[align="top"] [role="toggle"],
.zs-drawer[align="top"] [role="toggle"],
.zs-master-style [role="drawer"][align="top"] [role="toggle"] {
  left: 50%;
  margin-left: -24px;
  bottom: 0;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom: 1px solid #DEDCDE;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  margin-bottom: -24px;
  border-top: 0px;
}
.zs-master-style .zs-drawer[align="top"] > tag > .zs-icon,
.zs-master-style zs-drawer[align="top"] > tag > .zs-icon,
.zs-drawer[align="top"] > tag > .zs-icon,
.zs-master-style [role="drawer"][align="top"] > tag > .zs-icon,
.zs-master-style .zs-drawer[align="top"] > toggle > .zs-icon,
.zs-master-style zs-drawer[align="top"] > toggle > .zs-icon,
.zs-drawer[align="top"] > toggle > .zs-icon,
.zs-master-style [role="drawer"][align="top"] > toggle > .zs-icon,
.zs-master-style .zs-drawer[align="top"] [role="toggle"] > .zs-icon,
.zs-master-style zs-drawer[align="top"] [role="toggle"] > .zs-icon,
.zs-drawer[align="top"] [role="toggle"] > .zs-icon,
.zs-master-style [role="drawer"][align="top"] [role="toggle"] > .zs-icon {
  transform: rotate(180deg);
}
.zs-master-style .zs-drawer[align="top"] > tag::after,
.zs-master-style zs-drawer[align="top"] > tag::after,
.zs-drawer[align="top"] > tag::after,
.zs-master-style [role="drawer"][align="top"] > tag::after,
.zs-master-style .zs-drawer[align="top"] > toggle::after,
.zs-master-style zs-drawer[align="top"] > toggle::after,
.zs-drawer[align="top"] > toggle::after,
.zs-master-style [role="drawer"][align="top"] > toggle::after,
.zs-master-style .zs-drawer[align="top"] [role="toggle"]::after,
.zs-master-style zs-drawer[align="top"] [role="toggle"]::after,
.zs-drawer[align="top"] [role="toggle"]::after,
.zs-master-style [role="drawer"][align="top"] [role="toggle"]::after {
  top: -7px;
  bottom: auto;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="right"],
.zs-master-style zs-drawer[align="top"] > tag[align="right"],
.zs-drawer[align="top"] > tag[align="right"],
.zs-master-style [role="drawer"][align="top"] > tag[align="right"],
.zs-master-style .zs-drawer[align="top"] > toggle[align="right"],
.zs-master-style zs-drawer[align="top"] > toggle[align="right"],
.zs-drawer[align="top"] > toggle[align="right"],
.zs-master-style [role="drawer"][align="top"] > toggle[align="right"],
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="right"],
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="right"],
.zs-drawer[align="top"] [role="toggle"][align="right"],
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="right"] {
  left: auto;
  right: -1px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="right"]::after,
.zs-master-style zs-drawer[align="top"] > tag[align="right"]::after,
.zs-drawer[align="top"] > tag[align="right"]::after,
.zs-master-style [role="drawer"][align="top"] > tag[align="right"]::after,
.zs-master-style .zs-drawer[align="top"] > toggle[align="right"]::after,
.zs-master-style zs-drawer[align="top"] > toggle[align="right"]::after,
.zs-drawer[align="top"] > toggle[align="right"]::after,
.zs-master-style [role="drawer"][align="top"] > toggle[align="right"]::after,
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="right"]::after,
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="right"]::after,
.zs-drawer[align="top"] [role="toggle"][align="right"]::after,
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="right"]::after {
  left: -14px;
  right: 0;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="left"],
.zs-master-style zs-drawer[align="top"] > tag[align="left"],
.zs-drawer[align="top"] > tag[align="left"],
.zs-master-style [role="drawer"][align="top"] > tag[align="left"],
.zs-master-style .zs-drawer[align="top"] > toggle[align="left"],
.zs-master-style zs-drawer[align="top"] > toggle[align="left"],
.zs-drawer[align="top"] > toggle[align="left"],
.zs-master-style [role="drawer"][align="top"] > toggle[align="left"],
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="left"],
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="left"],
.zs-drawer[align="top"] [role="toggle"][align="left"],
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="left"] {
  left: -1px;
  right: auto;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="top"] > tag[align="left"]::after,
.zs-master-style zs-drawer[align="top"] > tag[align="left"]::after,
.zs-drawer[align="top"] > tag[align="left"]::after,
.zs-master-style [role="drawer"][align="top"] > tag[align="left"]::after,
.zs-master-style .zs-drawer[align="top"] > toggle[align="left"]::after,
.zs-master-style zs-drawer[align="top"] > toggle[align="left"]::after,
.zs-drawer[align="top"] > toggle[align="left"]::after,
.zs-master-style [role="drawer"][align="top"] > toggle[align="left"]::after,
.zs-master-style .zs-drawer[align="top"] [role="toggle"][align="left"]::after,
.zs-master-style zs-drawer[align="top"] [role="toggle"][align="left"]::after,
.zs-drawer[align="top"] [role="toggle"][align="left"]::after,
.zs-master-style [role="drawer"][align="top"] [role="toggle"][align="left"]::after {
  left: 0;
  right: -14px;
}
.zs-master-style .zs-drawer[align="right"],
.zs-master-style zs-drawer[align="right"],
.zs-drawer[align="right"],
.zs-master-style [role="drawer"][align="right"] {
  transform: translate(100%, 0);
  top: 0;
  bottom: 0;
  right: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag,
.zs-master-style zs-drawer[align="right"] > tag,
.zs-drawer[align="right"] > tag,
.zs-master-style [role="drawer"][align="right"] > tag,
.zs-master-style .zs-drawer[align="right"] > toggle,
.zs-master-style zs-drawer[align="right"] > toggle,
.zs-drawer[align="right"] > toggle,
.zs-master-style [role="drawer"][align="right"] > toggle,
.zs-master-style .zs-drawer[align="right"] [role="toggle"],
.zs-master-style zs-drawer[align="right"] [role="toggle"],
.zs-drawer[align="right"] [role="toggle"],
.zs-master-style [role="drawer"][align="right"] [role="toggle"] {
  transform-origin: left bottom;
  transform: rotate(270deg);
  top: 50%;
  bottom: auto;
  left: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="top"],
.zs-master-style zs-drawer[align="right"] > tag[valign="top"],
.zs-drawer[align="right"] > tag[valign="top"],
.zs-master-style [role="drawer"][align="right"] > tag[valign="top"],
.zs-master-style .zs-drawer[align="right"] > toggle[valign="top"],
.zs-master-style zs-drawer[align="right"] > toggle[valign="top"],
.zs-drawer[align="right"] > toggle[valign="top"],
.zs-master-style [role="drawer"][align="right"] > toggle[valign="top"],
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="top"],
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="top"],
.zs-drawer[align="right"] [role="toggle"][valign="top"],
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="top"] {
  bottom: auto;
  top: 8px;
  margin-top: 60px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="top"]::after,
.zs-master-style zs-drawer[align="right"] > tag[valign="top"]::after,
.zs-drawer[align="right"] > tag[valign="top"]::after,
.zs-master-style [role="drawer"][align="right"] > tag[valign="top"]::after,
.zs-master-style .zs-drawer[align="right"] > toggle[valign="top"]::after,
.zs-master-style zs-drawer[align="right"] > toggle[valign="top"]::after,
.zs-drawer[align="right"] > toggle[valign="top"]::after,
.zs-master-style [role="drawer"][align="right"] > toggle[valign="top"]::after,
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="top"]::after,
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="top"]::after,
.zs-drawer[align="right"] [role="toggle"][valign="top"]::after,
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="top"]::after {
  right: 0px;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="bottom"],
.zs-master-style zs-drawer[align="right"] > tag[valign="bottom"],
.zs-drawer[align="right"] > tag[valign="bottom"],
.zs-master-style [role="drawer"][align="right"] > tag[valign="bottom"],
.zs-master-style .zs-drawer[align="right"] > toggle[valign="bottom"],
.zs-master-style zs-drawer[align="right"] > toggle[valign="bottom"],
.zs-drawer[align="right"] > toggle[valign="bottom"],
.zs-master-style [role="drawer"][align="right"] > toggle[valign="bottom"],
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="bottom"],
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="bottom"],
.zs-drawer[align="right"] [role="toggle"][valign="bottom"],
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="bottom"] {
  bottom: -1px;
  top: auto;
  margin-top: 0;
  margin-bottom: 0px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="right"] > tag[valign="bottom"]::after,
.zs-master-style zs-drawer[align="right"] > tag[valign="bottom"]::after,
.zs-drawer[align="right"] > tag[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="right"] > tag[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="right"] > toggle[valign="bottom"]::after,
.zs-master-style zs-drawer[align="right"] > toggle[valign="bottom"]::after,
.zs-drawer[align="right"] > toggle[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="right"] > toggle[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="right"] [role="toggle"][valign="bottom"]::after,
.zs-master-style zs-drawer[align="right"] [role="toggle"][valign="bottom"]::after,
.zs-drawer[align="right"] [role="toggle"][valign="bottom"]::after,
.zs-master-style [role="drawer"][align="right"] [role="toggle"][valign="bottom"]::after {
  left: 0px;
}
.zs-master-style .zs-drawer[align="bottom"],
.zs-master-style zs-drawer[align="bottom"],
.zs-drawer[align="bottom"],
.zs-master-style [role="drawer"][align="bottom"] {
  transform: translate(0, 100%);
  bottom: 0;
  right: 0;
  left: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag,
.zs-master-style zs-drawer[align="bottom"] > tag,
.zs-drawer[align="bottom"] > tag,
.zs-master-style [role="drawer"][align="bottom"] > tag,
.zs-master-style .zs-drawer[align="bottom"] > toggle,
.zs-master-style zs-drawer[align="bottom"] > toggle,
.zs-drawer[align="bottom"] > toggle,
.zs-master-style [role="drawer"][align="bottom"] > toggle,
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"],
.zs-master-style zs-drawer[align="bottom"] [role="toggle"],
.zs-drawer[align="bottom"] [role="toggle"],
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"] {
  transform: rotate(0deg);
  top: 0;
  margin-top: -24px;
  left: 50%;
  margin-left: -24px;
  border-bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="right"],
.zs-master-style zs-drawer[align="bottom"] > tag[align="right"],
.zs-drawer[align="bottom"] > tag[align="right"],
.zs-master-style [role="drawer"][align="bottom"] > tag[align="right"],
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="right"],
.zs-master-style zs-drawer[align="bottom"] > toggle[align="right"],
.zs-drawer[align="bottom"] > toggle[align="right"],
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="right"],
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="right"],
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="right"],
.zs-drawer[align="bottom"] [role="toggle"][align="right"],
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="right"] {
  left: auto;
  right: -1px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="right"]::after,
.zs-master-style zs-drawer[align="bottom"] > tag[align="right"]::after,
.zs-drawer[align="bottom"] > tag[align="right"]::after,
.zs-master-style [role="drawer"][align="bottom"] > tag[align="right"]::after,
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="right"]::after,
.zs-master-style zs-drawer[align="bottom"] > toggle[align="right"]::after,
.zs-drawer[align="bottom"] > toggle[align="right"]::after,
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="right"]::after,
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="right"]::after,
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="right"]::after,
.zs-drawer[align="bottom"] [role="toggle"][align="right"]::after,
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="right"]::after {
  left: -14px;
  right: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="left"],
.zs-master-style zs-drawer[align="bottom"] > tag[align="left"],
.zs-drawer[align="bottom"] > tag[align="left"],
.zs-master-style [role="drawer"][align="bottom"] > tag[align="left"],
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="left"],
.zs-master-style zs-drawer[align="bottom"] > toggle[align="left"],
.zs-drawer[align="bottom"] > toggle[align="left"],
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="left"],
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="left"],
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="left"],
.zs-drawer[align="bottom"] [role="toggle"][align="left"],
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="left"] {
  left: -1px;
  right: auto;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="bottom"] > tag[align="left"]::after,
.zs-master-style zs-drawer[align="bottom"] > tag[align="left"]::after,
.zs-drawer[align="bottom"] > tag[align="left"]::after,
.zs-master-style [role="drawer"][align="bottom"] > tag[align="left"]::after,
.zs-master-style .zs-drawer[align="bottom"] > toggle[align="left"]::after,
.zs-master-style zs-drawer[align="bottom"] > toggle[align="left"]::after,
.zs-drawer[align="bottom"] > toggle[align="left"]::after,
.zs-master-style [role="drawer"][align="bottom"] > toggle[align="left"]::after,
.zs-master-style .zs-drawer[align="bottom"] [role="toggle"][align="left"]::after,
.zs-master-style zs-drawer[align="bottom"] [role="toggle"][align="left"]::after,
.zs-drawer[align="bottom"] [role="toggle"][align="left"]::after,
.zs-master-style [role="drawer"][align="bottom"] [role="toggle"][align="left"]::after {
  left: 0;
  right: -14px;
}
.zs-master-style .zs-drawer[align="left"],
.zs-master-style zs-drawer[align="left"],
.zs-drawer[align="left"],
.zs-master-style [role="drawer"][align="left"] {
  transform: translate(-100%, 0);
  top: 0;
  bottom: 0;
  left: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag,
.zs-master-style zs-drawer[align="left"] > tag,
.zs-drawer[align="left"] > tag,
.zs-master-style [role="drawer"][align="left"] > tag,
.zs-master-style .zs-drawer[align="left"] > toggle,
.zs-master-style zs-drawer[align="left"] > toggle,
.zs-drawer[align="left"] > toggle,
.zs-master-style [role="drawer"][align="left"] > toggle,
.zs-master-style .zs-drawer[align="left"] [role="toggle"],
.zs-master-style zs-drawer[align="left"] [role="toggle"],
.zs-drawer[align="left"] [role="toggle"],
.zs-master-style [role="drawer"][align="left"] [role="toggle"] {
  transform-origin: right bottom;
  transform: rotate(90deg);
  top: 50%;
  bottom: auto;
  right: 0;
  border-bottom: 0px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="top"],
.zs-master-style zs-drawer[align="left"] > tag[valign="top"],
.zs-drawer[align="left"] > tag[valign="top"],
.zs-master-style [role="drawer"][align="left"] > tag[valign="top"],
.zs-master-style .zs-drawer[align="left"] > toggle[valign="top"],
.zs-master-style zs-drawer[align="left"] > toggle[valign="top"],
.zs-drawer[align="left"] > toggle[valign="top"],
.zs-master-style [role="drawer"][align="left"] > toggle[valign="top"],
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="top"],
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="top"],
.zs-drawer[align="left"] [role="toggle"][valign="top"],
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="top"] {
  bottom: auto;
  top: 1px;
  margin-top: 96px;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="top"]::after,
.zs-master-style zs-drawer[align="left"] > tag[valign="top"]::after,
.zs-drawer[align="left"] > tag[valign="top"]::after,
.zs-master-style [role="drawer"][align="left"] > tag[valign="top"]::after,
.zs-master-style .zs-drawer[align="left"] > toggle[valign="top"]::after,
.zs-master-style zs-drawer[align="left"] > toggle[valign="top"]::after,
.zs-drawer[align="left"] > toggle[valign="top"]::after,
.zs-master-style [role="drawer"][align="left"] > toggle[valign="top"]::after,
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="top"]::after,
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="top"]::after,
.zs-drawer[align="left"] [role="toggle"][valign="top"]::after,
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="top"]::after {
  left: 0px;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="bottom"],
.zs-master-style zs-drawer[align="left"] > tag[valign="bottom"],
.zs-drawer[align="left"] > tag[valign="bottom"],
.zs-master-style [role="drawer"][align="left"] > tag[valign="bottom"],
.zs-master-style .zs-drawer[align="left"] > toggle[valign="bottom"],
.zs-master-style zs-drawer[align="left"] > toggle[valign="bottom"],
.zs-drawer[align="left"] > toggle[valign="bottom"],
.zs-master-style [role="drawer"][align="left"] > toggle[valign="bottom"],
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="bottom"],
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="bottom"],
.zs-drawer[align="left"] [role="toggle"][valign="bottom"],
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="bottom"] {
  bottom: -1px;
  top: auto;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.zs-master-style .zs-drawer[align="left"] > tag[valign="bottom"]::after,
.zs-master-style zs-drawer[align="left"] > tag[valign="bottom"]::after,
.zs-drawer[align="left"] > tag[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="left"] > tag[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="left"] > toggle[valign="bottom"]::after,
.zs-master-style zs-drawer[align="left"] > toggle[valign="bottom"]::after,
.zs-drawer[align="left"] > toggle[valign="bottom"]::after,
.zs-master-style [role="drawer"][align="left"] > toggle[valign="bottom"]::after,
.zs-master-style .zs-drawer[align="left"] [role="toggle"][valign="bottom"]::after,
.zs-master-style zs-drawer[align="left"] [role="toggle"][valign="bottom"]::after,
.zs-drawer[align="left"] [role="toggle"][valign="bottom"]::after,
.zs-master-style [role="drawer"][align="left"] [role="toggle"][valign="bottom"]::after {
  margin-right: 7px;
}
.zs-master-style .zs-drawer.zs-notag > tag,
.zs-master-style zs-drawer.zs-notag > tag,
.zs-drawer.zs-notag > tag,
.zs-master-style [role="drawer"].zs-notag > tag,
.zs-master-style .zs-drawer.zs-notag > toggle,
.zs-master-style zs-drawer.zs-notag > toggle,
.zs-drawer.zs-notag > toggle,
.zs-master-style [role="drawer"].zs-notag > toggle,
.zs-master-style .zs-drawer.zs-notag [role="toggle"],
.zs-master-style zs-drawer.zs-notag [role="toggle"],
.zs-drawer.zs-notag [role="toggle"],
.zs-master-style [role="drawer"].zs-notag [role="toggle"] {
  display: none;
}
.zs-tag {
  background-color: #ECF8FF;
  border-radius: 1.5em;
  padding: 0.5em 1em 0.5em 1em;
  white-space: nowrap;
  display: inline-block;
  border: 1px solid transparent;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  cursor: pointer;
  position: relative;
  color: #0952CA;
  border: 1px solid 'transparent';
  background: #ECF8FF;
}
.zs-tag > span {
  line-height: 1.5;
  vertical-align: middle;
}
.zs-tag[dismissable] {
  padding: 0.5em 1em 0.5em 1em;
  position: relative;
}
.zs-tag[dismissable] [close] {
  margin: 0em 0em 0em 0.25em;
  padding: 0;
  right: 0;
  top: 0;
  position: static;
  line-height: 1;
  vertical-align: middle;
  display: inline-flex;
}
.zs-tag[dismissable] [close]:focus {
  outline: 2px solid #27A6A4;
}
.zs-tag[nonclickable] {
  cursor: default;
  pointer-events: none;
  background-color: #ECF8FF;
  border-color: transparent;
  color: #0952CA;
}
.zs-tag[nonclickable]:focus::before {
  content: '';
}
.zs-tag[nonclickable] [close] {
  pointer-events: auto;
}
.zs-tag:hover,
.zs-tag:focus {
  outline: none;
}
.zs-tag:focus::before {
  content: ' ';
  position: absolute;
  border: 2px solid #27A6A4;
  left: calc(-1 * (0.5 * 0.5em + 1px));
  top: calc(-1 * (0.5 * 0.5em + 1px));
  right: calc(-1 * (0.5 * 0.5em + 1px));
  bottom: calc(-1 * (0.5 * 0.5em + 1px));
  border-radius: 1.5em;
  z-index: -1;
}
.zs-tag.zs-highlight {
  background-color: #FFD680;
  color: inherit;
}
.zs-tag .zs-interactive-primary {
  color: #0952CA;
}
.zs-tag .zs-interactive-primary:visited {
  color: #0952CA;
}
.zs-tag .zs-interactive-primary:focus {
  color: #0524A4;
}
.zs-tag .zs-interactive-primary:hover {
  color: #7CC0FF;
}
.zs-tag .zs-interactive-primary:active {
  color: #000077;
}
.zs-tag:visited {
  color: #0952CA;
}
.zs-tag:focus {
  color: #0524A4;
}
.zs-tag:hover {
  color: #0952CA;
}
.zs-tag:active {
  color: #000077;
}
.zs-tag:focus {
  border: 1px solid #0952CA;
}
.zs-tag:hover {
  border: 1px solid #0952CA;
}
.zs-tag:active {
  border: 1px solid #000077;
}
.zs-tag:focus {
  background: #ECF8FF;
}
.zs-tag:hover {
  background: #ECF8FF;
}
.zs-tag:active {
  background: #ECF8FF;
}
.zs-tag[active] {
  color: #000077;
  border: 1px solid #000077;
}
.zs-tag.zs-danger {
  color: #B21111;
  border: 1px solid 'transparent';
  background: #FFEDE9;
}
.zs-tag.zs-danger:visited {
  color: #B21111;
}
.zs-tag.zs-danger:focus {
  color: #892208;
}
.zs-tag.zs-danger:hover {
  color: #F27755;
}
.zs-tag.zs-danger:active {
  color: #5C1A0B;
}
.zs-tag.zs-danger:focus {
  border: 1px solid #892208;
}
.zs-tag.zs-danger:hover {
  border: 1px solid #F27755;
}
.zs-tag.zs-danger:active {
  border: 1px solid #5C1A0B;
}
.zs-tag.zs-danger:focus {
  background: #FFEDE9;
}
.zs-tag.zs-danger:hover {
  background: #FFEDE9;
}
.zs-tag.zs-danger:active {
  background: #FFEDE9;
}
.zs-tag.zs-danger[active] {
  color: #5C1A0B;
  border: 1px solid #5C1A0B;
}
.zs-tag.zs-success {
  color: #00AA67;
  border: 1px solid 'transparent';
  background: #D2FAF7;
}
.zs-tag.zs-success:visited {
  color: #00AA67;
}
.zs-tag.zs-success:focus {
  color: #0A6E5E;
}
.zs-tag.zs-success:hover {
  color: #54D7AF;
}
.zs-tag.zs-success:active {
  color: #0C4846;
}
.zs-tag.zs-success:focus {
  border: 1px solid #0A6E5E;
}
.zs-tag.zs-success:hover {
  border: 1px solid #54D7AF;
}
.zs-tag.zs-success:active {
  border: 1px solid #0C4846;
}
.zs-tag.zs-success:focus {
  background: #D2FAF7;
}
.zs-tag.zs-success:hover {
  background: #D2FAF7;
}
.zs-tag.zs-success:active {
  background: #D2FAF7;
}
.zs-tag.zs-success[active] {
  color: #0C4846;
  border: 1px solid #0C4846;
}
.zs-tag.zs-warning {
  color: #E3A900;
  border: 1px solid 'transparent';
  background: #FFF9F1;
}
.zs-tag.zs-warning:visited {
  color: #E3A900;
}
.zs-tag.zs-warning:focus {
  color: #8A640C;
}
.zs-tag.zs-warning:hover {
  color: #FDCA63;
}
.zs-tag.zs-warning:active {
  color: #563F0E;
}
.zs-tag.zs-warning:focus {
  border: 1px solid #8A640C;
}
.zs-tag.zs-warning:hover {
  border: 1px solid #FDCA63;
}
.zs-tag.zs-warning:active {
  border: 1px solid #563F0E;
}
.zs-tag.zs-warning:focus {
  background: #FFF9F1;
}
.zs-tag.zs-warning:hover {
  background: #FFF9F1;
}
.zs-tag.zs-warning:active {
  background: #FFF9F1;
}
.zs-tag.zs-warning[active] {
  color: #563F0E;
  border: 1px solid #563F0E;
}
.zs-tag.zs-info {
  color: #2B44C7;
  border: 1px solid 'transparent';
  background: #EEEEFF;
}
.zs-tag.zs-info:visited {
  color: #2B44C7;
}
.zs-tag.zs-info:focus {
  color: #141187;
}
.zs-tag.zs-info:hover {
  color: #7E92E9;
}
.zs-tag.zs-info:active {
  color: #160C60;
}
.zs-tag.zs-info:focus {
  border: 1px solid #141187;
}
.zs-tag.zs-info:hover {
  border: 1px solid #7E92E9;
}
.zs-tag.zs-info:active {
  border: 1px solid #160C60;
}
.zs-tag.zs-info:focus {
  background: #EEEEFF;
}
.zs-tag.zs-info:hover {
  background: #EEEEFF;
}
.zs-tag.zs-info:active {
  background: #EEEEFF;
}
.zs-tag.zs-info[active] {
  color: #160C60;
  border: 1px solid #160C60;
}
.zs-tag.zs-disabled,
.zs-tag [disabled],
.zs-tag.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-tag.zs-disabled [close],
.zs-tag [disabled] [close],
.zs-tag.zs-calendar > table td[disabled] [close] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
}
.zs-tag.zs-disabled:focus::before,
.zs-tag [disabled]:focus::before,
.zs-tag.zs-calendar > table td[disabled]:focus::before {
  content: '';
  border: none;
}
.zs-tag.zs-disabled[dismissable] [close]:focus,
.zs-tag [disabled][dismissable] [close]:focus,
.zs-tag.zs-calendar > table td[disabled][dismissable] [close]:focus {
  outline: none;
}
.zs-tabs {
  *zoom: 1;
  display: block !important;
}
.zs-tabs:before,
.zs-tabs:after {
  content: "";
  display: table;
}
.zs-tabs:after {
  clear: both;
}
.zs-tabs .zs-tab {
  color: #716E79;
  padding: 0.5em 1em 0.5em 1em;
  box-shadow: none;
  border: 1px solid #DEDCDE;
  border-bottom: 0;
  border-right: 0;
  border-radius: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  vertical-align: top;
  float: left;
  position: relative;
  cursor: pointer;
  background: #FAFAFA;
}
.zs-tabs .zs-tab:last-of-type {
  border-right: 1px solid #DEDCDE;
}
.zs-tabs .zs-tab:hover {
  color: #2F2C3C;
  background: #F4F3F3;
}
.zs-tabs .zs-tab:active {
  color: #716E79;
}
.zs-tabs .zs-tab.zs-active,
.zs-tabs .zs-tab[active] {
  color: #27A6A4;
  background: #ffffff;
  box-shadow: none;
  border-bottom-color: #ffffff;
}
.zs-tabs .zs-tab.zs-active .zs-tab-title-text,
.zs-tabs .zs-tab[active] .zs-tab-title-text {
  color: #27A6A4;
  font-weight: 600;
}
.zs-tabs .zs-tab.zs-active:hover,
.zs-tabs .zs-tab[active]:hover {
  color: #27A6A4;
}
.zs-tabs .zs-tab.zs-active:after,
.zs-tabs .zs-tab[active]:after {
  margin-top: -1px;
  left: 0px;
  top: 100%;
  content: '';
  right: 0px;
  height: 2px;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.zs-tabs .zs-tab[disabled],
.zs-tabs .zs-tab.zs-disabled,
.zs-tabs .zs-tab.zs-calendar > table td[disabled] {
  cursor: default;
  pointer-events: none;
  opacity: 1;
  color: #B2B0B6;
  background: none;
  background-color: #F4F3F3;
}
.zs-tabs .zs-tab[disabled] > .zs-icon,
.zs-tabs .zs-tab.zs-disabled > .zs-icon,
.zs-tabs .zs-tab.zs-calendar > table td[disabled] > .zs-icon {
  color: #B2B0B6;
}
.zs-tabs.zs-tabs-solid .zs-tab {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding: 1em 0em 1em 0em;
  background: #ffffff;
  margin: 0em 1.5em 0em 0em;
}
.zs-tabs.zs-tabs-solid .zs-tab:last-of-type {
  margin: 0em 0em 0em 0em;
}
.zs-tabs.zs-tabs-solid .zs-tab:hover::after,
.zs-tabs.zs-tabs-solid .zs-tab:active::after {
  height: 0.25em;
  margin-top: -0.25em;
  left: 0px;
  top: 100%;
  content: '';
  right: 0px;
  display: inline-block;
  position: absolute;
  z-index: 1;
}
.zs-tabs.zs-tabs-solid .zs-tab:hover::after {
  background: #62D2D1;
}
.zs-tabs.zs-tabs-solid .zs-tab:active {
  color: #716E79;
}
.zs-tabs.zs-tabs-solid .zs-tab:active::after {
  background: #2F6F7B;
}
.zs-tabs.zs-tabs-solid .zs-tab.zs-active:active,
.zs-tabs.zs-tabs-solid .zs-tab[active]:active {
  color: #2F6F7B;
}
.zs-tabs.zs-tabs-solid .zs-tab.zs-active:active .zs-tab-title-text,
.zs-tabs.zs-tabs-solid .zs-tab[active]:active .zs-tab-title-text {
  color: #2F6F7B;
}
.zs-tabs.zs-tabs-solid .zs-tab.zs-active:active::after,
.zs-tabs.zs-tabs-solid .zs-tab[active]:active::after {
  background: #2F6F7B;
  height: 0.25em;
  margin-top: -0.25em;
}
.zs-tabs.zs-tabs-solid .zs-tab.zs-active::after,
.zs-tabs.zs-tabs-solid .zs-tab[active]::after {
  height: 0.25em;
  background: #27A6A4;
  margin-top: -0.25em;
}
.zs-tabs.zs-tabs-solid [source-id] {
  border: none;
}
.zs-tabs.zs-tabs-vertical .zs-tab {
  color: #454250;
  display: block;
  float: none;
  padding: 0.5em 2em 0.5em 2em;
  border: 0;
  background: #F4F3F3;
}
.zs-tabs.zs-tabs-vertical .zs-tab:hover {
  background: #FAFAFA;
}
.zs-tabs.zs-tabs-vertical .zs-tab:hover .zs-tab-title-text {
  color: #2F2C3C;
}
.zs-tabs.zs-tabs-vertical .zs-tab:active {
  background: #F4F3F3;
}
.zs-tabs.zs-tabs-vertical .zs-tab:active .zs-tab-title-text {
  color: #716E79;
}
.zs-tabs.zs-tabs-vertical .zs-tab.zs-active .zs-tab-title-text,
.zs-tabs.zs-tabs-vertical .zs-tab[active] .zs-tab-title-text {
  color: #27A6A4;
  font-weight: 600;
}
.zs-tabs.zs-tabs-vertical .zs-tab.zs-active:hover,
.zs-tabs.zs-tabs-vertical .zs-tab[active]:hover {
  background: #F4F3F3;
}
.zs-tabs.zs-tabs-vertical .zs-tab.zs-active::after,
.zs-tabs.zs-tabs-vertical .zs-tab[active]::after {
  height: auto;
  width: 0.25em;
  background: #27A6A4;
  left: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
}
.zs-tabs.zs-tabs-vertical [source-id] {
  border: none;
}
.zs-tabs [source-id] {
  box-shadow: none;
  display: none;
  clear: left;
  border: 1px solid #DEDCDE;
  background: #ffffff;
  vertical-align: top;
  border-radius: 0;
  position: relative;
  top: -1px;
}
.zs-tabs [source-id][active] {
  display: block;
}
.zs-tab > *:not(.zs-icon) {
  margin: 0em 0.5em 0em 0.5em;
}
.zs-tab > *:first-child {
  margin-left: 0;
}
.zs-tab > *:last-child {
  margin-right: 0;
}
.zs-badge {
  font-size: 0.5em;
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  content: '';
  display: inline-block;
  position: absolute;
  top: 1.25em;
  left: 1.25em;
}
.zs-badge-counter {
  font-family: "Open Sans", sans-serif;
  font-size: 1em;
  font-weight: 600;
  text-align: center;
  position: relative;
  display: inline-block;
  background: #27A6A4;
  border-radius: 1.25em;
  color: #ffffff;
  min-width: 1.25em;
  line-height: 1;
  margin: 0em 0em 0em 0.25em;
  padding: 0.25em 0.5em 0.25em 0.5em;
  box-shadow: 0px 1px 4px #ffffff;
}
.zs-badge-counter .zs-badge-text-container {
  min-width: 1em;
  min-height: 1em;
  display: inline-flex;
  justify-content: center;
}
.zs-badge-counter.zs-badge:not([position*="bottom-"]),
.zs-badge-counter.zs-badge[position="top-right"] {
  position: absolute;
  top: 0;
  left: 100%;
  margin: 0;
  transform: translate(-50%, -50%);
}
.zs-badge-counter.zs-badge[position="bottom-right"] {
  position: absolute;
  left: 100%;
  margin: 0;
  transform: translate(-50%, 0);
}
.zs-badge-counter.zs-badge[position="top-left"] {
  left: 0;
  transform: translate(-50%, -50%);
}
.zs-badge-counter.zs-badge[position="bottom-left"] {
  position: absolute;
  margin: 0;
  left: 0;
  transform: translate(-50%, 0);
}
.zs-badge-counter.zs-success {
  background: #00AA67;
}
.zs-badge-counter.zs-error {
  background: #B21111;
}
.zs-badge-counter.zs-neutral {
  background: #1A1628;
}
.zs-badge-counter.zs-info {
  background: #2B44C7;
}
.zs-badge-counter.zs-warning {
  background: #E3A900;
}
.zs-icon > .zs-badge:not([position*="bottom-"]),
.zs-icon > .zs-badge[position="top-right"] {
  top: -0.5em;
  right: -0.5em;
  left: auto;
  transform: none;
  margin: 0;
  box-shadow: 0 0 0 1px #ffffff;
}
.zs-icon > .zs-badge:not([position*="bottom-"]).zs-badge-indicator,
.zs-icon > .zs-badge[position="top-right"].zs-badge-indicator {
  top: -0.5em;
  left: 1.25em;
  right: auto;
}
.zs-icon > .zs-badge:not([position*="bottom-"]).zs-badge-counter,
.zs-icon > .zs-badge[position="top-right"].zs-badge-counter {
  top: -0.75em;
  left: 1.25em;
  right: auto;
}
.zs-icon > .zs-badge:not([position*="bottom-"]).zs-badge-text,
.zs-icon > .zs-badge[position="top-right"].zs-badge-text {
  top: -1.25em;
  left: 1em;
  right: auto;
}
.zs-icon > .zs-badge.zs-badge[position="top-left"] {
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
}
.zs-badge-indicator {
  padding: 0.5em 0.5em 0.5em 0.5em;
  margin-left: 0.625em;
  border-radius: 100%;
  border: 0;
  background: #27A6A4;
  display: inline-block;
  vertical-align: middle;
  content: '';
}
.zs-badge-indicator.zs-success {
  background: #00AA67;
}
.zs-badge-indicator.zs-error {
  background: #B21111;
}
.zs-badge-indicator.zs-neutral {
  background: #1A1628;
}
.zs-badge-indicator.zs-info {
  background: #2B44C7;
}
.zs-badge-indicator.zs-warning {
  background: #E3A900;
}
.zs-counter {
  color: #27A6A4;
}
.zs-badge-text {
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  background: #27A6A4;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 1.35;
  letter-spacing: 0.013em;
  border-radius: 1.25em;
  padding: 0.25em 0.5em 0.25em 0.5em;
}
.zs-badge-text.zs-success {
  color: #00AA67;
  background: #F1FEFF;
}
.zs-badge-text.zs-error {
  color: #B21111;
  background: #FFEDE9;
}
.zs-badge-text.zs-neutral {
  color: #1A1628;
  background: #FAFAFA;
}
.zs-badge-text.zs-info {
  color: #2B44C7;
  background: #EEEEFF;
}
.zs-badge-text.zs-warning {
  color: #E3A900;
  background: #FFF9F1;
}
.zs-progress-bar {
  width: 100%;
  display: block;
  position: relative;
  text-align: center;
  height: auto;
  min-height: 0.5em;
  background-color: rgba(43, 68, 199, 0.4);
  border: 0;
  border-radius: 0;
  line-height: 0.5em;
  color: #ffffff;
}
.zs-progress-bar > [progress-indicator] {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  color: #ffffff;
  background-color: rgba(43, 68, 199, 0.9);
}
.zs-progress-bar > [progress-indicator] > [label] {
  position: absolute;
  left: 0%;
  top: 0%;
  white-space: nowrap;
  color: #ffffff;
  padding: 0.5em 0em 0.5em 0em;
  line-height: 1.5;
  font-weight: 600;
}
.zs-progress-bar > [text-container] {
  padding: 0.5em 0em 0.5em 0em;
  line-height: 1.5;
  display: inline-block;
  font-weight: 600;
}
.zs-progress-bar.zs-danger {
  background-color: rgba(178, 17, 17, 0.4);
}
.zs-progress-bar.zs-danger > [progress-indicator] {
  background-color: rgba(178, 17, 17, 0.9);
}
.zs-progress-bar.zs-success {
  background-color: rgba(0, 170, 103, 0.4);
}
.zs-progress-bar.zs-success > [progress-indicator] {
  background-color: rgba(0, 170, 103, 0.9);
}
.zs-progress-bar.zs-warning {
  background-color: rgba(227, 169, 0, 0.4);
}
.zs-progress-bar.zs-warning > [progress-indicator] {
  background-color: rgba(227, 169, 0, 0.9);
}
.zs-progress-bar[animate] [progress-indicator] {
  width: 0%;
}
/**
* Toolbar component styles
*/
.zs-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.zs-toolbar > a {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 1em 1em 1em;
}
.zs-toolbar > a > .zs-icon {
  padding: 0em 0.125em 0em 0.125em;
  color: inherit;
}
.zs-toolbar > a > .zs-icon::before {
  margin: 0;
  display: block;
  vertical-align: initial;
}
.zs-toolbar > a > .zs-icon + *,
.zs-toolbar > a > * {
  padding: 0em 0.33333333em 0em 0.33333333em;
  cursor: pointer;
}
.zs-toolbar > a > *:last-child {
  padding-right: 0;
}
.zs-toolbar > a > *:first-child {
  padding-left: 0;
}
.zs-toolbar > a > .zs-icon {
  padding: 0em 0em 0em 0em;
}
.zs-toolbar > a.zs-toolbar-partial-separator {
  position: relative;
}
.zs-toolbar > a.zs-toolbar-partial-separator::after {
  content: '\00a0';
  border: 1px solid #DEDCDE;
  border-left: none;
  position: absolute;
  right: 0;
  width: 0;
  top: 50%;
  transform: translateY(-50%);
}
.zs-toolbar > a.zs-toolbar-separator {
  border-right: 1px solid #DEDCDE;
}
.zs-toolbar > a:focus.zs-toolbar-partial-separator::after,
.zs-toolbar > a:focus.zs-toolbar-separator {
  border-color: transparent;
}
.zs-toolbar > a.zs-active {
  color: #ffffff;
}
.zs-toolbar > a.zs-active > .zs-icon::before {
  color: inherit;
}
.zs-toolbar > a.zs-active:hover {
  color: #ffffff;
}
.zs-toolbar.zs-toolbar-vertical {
  flex-direction: column;
  height: 100%;
}
.zs-toolbar.zs-toolbar-vertical > a {
  padding: 0.75em 0.5em 0.75em 0.5em;
}
.zs-toolbar.zs-toolbar-vertical > a.zs-toolbar-separator {
  border-right: none;
  border-bottom: 1px solid #DEDCDE;
}
.zs-toolbar.zs-toolbar-labels-below > a {
  flex-direction: column;
}
.zs-toolbar.zs-toolbar-labels-below > a > * {
  padding: 0em 0em 0em 0em;
}
.zs-toolbar.zs-toolbar-vertical.zs-toolbar.zs-toolbar-labels-below > a {
  text-align: center;
}
.zs-toolbar.zs-toolbar-align-left > a {
  justify-content: flex-start;
}
.zs-toolbar-target > a:target,
.zs-toolbar-target > a:active {
  color: #ffffff;
}
.zs-toolbar-target > a:target > .zs-icon::before,
.zs-toolbar-target > a:active > .zs-icon::before {
  color: inherit;
}
.zs-toolbar-target > a:target:hover,
.zs-toolbar-target > a:active:hover {
  color: #ffffff;
}
.zs-layout-full-sidenav {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto minmax(min-content, 1fr) auto;
  -ms-grid-columns: auto minmax(min-content, 1fr) auto;
  grid-template-rows: auto minmax(min-content, 1fr) auto;
  -ms-grid-rows: auto minmax(min-content, 1fr) auto;
}
.zs-layout-full-sidenav > header,
.zs-layout-full-sidenav > .zs-header {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-row-start: 1;
  -ms-grid-row: 1;
}
.zs-layout-full-sidenav > .zs-nav-container {
  grid-column-start: 1;
  -ms-grid-column: 1;
  grid-row-start: 1;
  -ms-grid-row: 1;
  grid-row-end: span 3;
  -ms-grid-row-span: 3;
  background: #1A1628;
}
.zs-layout-full-sidenav > .zs-nav-container > nav,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav {
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto;
  -ms-grid-columns: auto;
  grid-template-rows: auto auto 1fr;
  -ms-grid-rows: auto auto 1fr;
  background: linear-gradient(180deg, #1A1628 -1.83%, #1A1628 -1.82%, rgba(2, 45, 66, 0.1) 6.39%, rgba(113, 110, 121, 0.4) 50.47%, #2F2C3C 111%);
  padding: 0.5em 0em 1.5em 0em;
  overflow: hidden;
}
.zs-layout-full-sidenav > .zs-nav-container > nav :nth-of-type(1n+1),
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav :nth-of-type(1n+1) {
  grid-column-start: 1;
  -ms-grid-column: 1;
}
.zs-layout-full-sidenav > .zs-nav-container > nav :nth-of-type(n + 1),
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav :nth-of-type(n + 1) {
  grid-row-start: 1;
  -ms-grid-row: 1;
}
.zs-layout-full-sidenav > .zs-nav-container > nav :nth-of-type(n + 2),
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav :nth-of-type(n + 2) {
  grid-row-start: 2;
  -ms-grid-row: 2;
}
.zs-layout-full-sidenav > .zs-nav-container > nav :nth-of-type(n + 3),
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav :nth-of-type(n + 3) {
  grid-row-start: 3;
  -ms-grid-row: 3;
}
.zs-layout-full-sidenav > .zs-nav-container > nav :nth-of-type(n + 4),
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav :nth-of-type(n + 4) {
  grid-row-start: 4;
  -ms-grid-row: 4;
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-side-nav-label {
  font-size: 0.6875em;
  font-weight: 600;
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action {
  width: 5em;
  background: transparent;
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action.zs-active,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action.zs-active {
  background: rgba(178, 176, 182, 0.4);
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action:focus,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action:focus {
  background: rgba(156, 154, 161, 0.4);
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action:hover,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action:hover {
  background: rgba(222, 220, 222, 0.4);
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action:active,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action:active {
  background: rgba(113, 110, 121, 0.4);
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action:focus,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action:focus {
  outline: none;
}
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action:focus .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action:focus .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action:hover .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action:hover .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action:active .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action:active .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > nav .zs-link-action.zs-active .zs-side-nav-label,
.zs-layout-full-sidenav > .zs-nav-container > .zs-nav .zs-link-action.zs-active .zs-side-nav-label {
  font-weight: 700;
}
.zs-layout-full-sidenav > main,
.zs-layout-full-sidenav > .zs-main {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-row-start: 2;
  -ms-grid-row: 2;
}
.zs-layout-full-sidenav > footer,
.zs-layout-full-sidenav .zs-footer {
  grid-column-start: 2;
  -ms-grid-column: 2;
  grid-row-start: 3;
  -ms-grid-row: 3;
}
.zs-pagination {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  font-size: 1em;
  display: inline-block;
}
.zs-pagination a {
  display: inline-block;
  border: none;
  border-radius: 0;
  padding: 0.5em 0.75em 0.5em 0.75em;
  cursor: pointer;
  font-size: 0.875em;
  min-width: auto;
  vertical-align: middle;
  text-align: center;
  color: #454250;
}
.zs-pagination a:hover {
  border-color: transparent;
  text-decoration: underline;
}
.zs-pagination a:first-of-type {
  margin: 0em 0em 0em 0.75em;
}
.zs-pagination a:last-of-type {
  margin: 0em 0.75em 0em 0em;
}
.zs-pagination a:last-of-type + span[current] {
  margin: 0em 1.5em 0em 0em;
}
.zs-pagination a:visited {
  color: #454250;
}
.zs-pagination a:focus {
  color: inherit;
}
.zs-pagination a:hover {
  color: inherit;
}
.zs-pagination a:active {
  color: inherit;
}
.zs-pagination a[current],
.zs-pagination a[current]:visited {
  color: #1A1628;
  background: transparent;
  border: none;
  font-weight: 700;
  text-decoration: underline;
}
.zs-pagination a[current]:hover,
.zs-pagination a[current]:visited:hover {
  color: #1A1628;
}
.zs-pagination a:first-child {
  margin-left: 0;
}
.zs-pagination .zs-icon {
  border: 0px;
  cursor: pointer;
}
.zs-pagination .zs-fab {
  font-size: 0.875em;
}
.zs-pagination .zs-fab .zs-icon {
  font-size: 1.5em;
  pointer-events: none;
}
.zs-pagination .zs-fab + .zs-fab {
  margin: 0em 0em 0em 1em;
}
.zs-pagination .zs-fab + span[current] {
  margin-left: 1.5em;
}
.zs-pagination span[current] {
  vertical-align: middle;
  color: #1A1628;
  border-radius: 0;
  background: #F4F3F3;
  border: 1.5px solid #DEDCDE;
  padding: 0.5em 0em 0.5em 0em;
  margin: 0em 0.75em 0em 0.75em;
  position: relative;
  cursor: pointer;
  font-size: 0.875em;
}
.zs-pagination span[current] select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-weight: normal;
  color: #1A1628;
  padding: 0em 2.5em 0em 1em;
  font-size: inherit;
  cursor: pointer;
  text-align: center;
  text-align-last: center;
}
.zs-pagination span[current] select:focus {
  color: #454250;
}
.zs-pagination span[current] select::-ms-expand {
  display: none;
}
.zs-pagination span[current]:first-child {
  margin-left: 0;
}
.zs-pagination span[current]:after {
  content: "\ea26";
  font-size: 1.5em;
  right: 0.5em;
  font-family: 'zsIcons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  -o-font-feature-settings: "liga";
  font-feature-settings: "liga";
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  transform: translateY(-50%);
  color: #1A1628;
  top: 50%;
  position: absolute;
  pointer-events: none;
}
.zs-pagination span[current] + a:first-of-type {
  margin-left: 0;
}
.zs-drag-card-list-container {
  /* draggable icon */
  /* Element being dragged */
  /* Container on which element being dragged is expected to drop */
}
.zs-drag-card-list-container .zs-draggable > a.zs-icon-dragger {
  opacity: 1;
  color: #716E79;
  cursor: pointer;
}
.zs-drag-card-list-container .zs-draggable > a.zs-icon-dragger:active {
  color: #27A6A4;
}
.zs-drag-card-list-container .zs-dragged {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18) !important;
  border: 1px solid #27A6A4;
  opacity: 1 !important;
}
.zs-drag-card-list-container .zs-dragged > a.zs-icon-dragger {
  color: #27A6A4;
}
.zs-drag-card-list-container .zs-droppable {
  background: rgba(39, 166, 164, 0.1);
  border: 1px dashed #27A6A4 !important;
  box-shadow: none !important;
}
.zs-drag-card-list-container .zs-droppable > * {
  visibility: hidden;
}
.zs-drag-drop-table-container {
  /* Row on which element being dragged is expected to drop */
  /* draggable icon */
  /* Element being dragged */
}
.zs-drag-drop-table-container .zs-droppable {
  background: rgba(39, 166, 164, 0.1);
  outline: 1px dashed #27A6A4;
}
.zs-drag-drop-table-container .zs-droppable > td {
  opacity: 0;
}
.zs-drag-drop-table-container .zs-draggable a.zs-icon-dragger {
  color: #716E79;
  cursor: pointer;
}
.zs-drag-drop-table-container .zs-draggable a.zs-icon-dragger:active {
  color: #27A6A4;
}
.zs-drag-drop-table-container .zs-dragged {
  opacity: 1 !important;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18) !important;
  border: 1px solid #27A6A4;
}
.zs-drag-drop-table-container .zs-dragged a.zs-icon-dragger {
  color: #27A6A4;
}
.zs-drag-card-table-container {
  /* Draggable table row */
  /* Row on which element being dragged is expected to drop */
}
.zs-drag-card-table-container > table.zs-table {
  border-collapse: separate;
  border-spacing: 0 0.5em;
}
.zs-drag-card-table-container > table.zs-table > tbody td {
  border: 1px solid #DEDCDE;
}
.zs-drag-card-table-container > table.zs-table > tbody td:not(:first-child) {
  border-left: none;
}
.zs-drag-card-table-container > table.zs-table > tbody td:not(:last-child) {
  border-right: none;
}
.zs-drag-card-table-container > table.zs-table > tbody tr:hover {
  outline: 1px solid #27A6A4;
  box-shadow: 4px 4px 0px rgba(26, 22, 40, 0.15);
}
.zs-drag-card-table-container .zs-draggable .zs-icon-dragger {
  cursor: pointer;
  opacity: 0.5;
}
.zs-drag-card-table-container .zs-draggable .zs-icon-dragger:hover {
  opacity: 1;
}
.zs-drag-card-table-container .zs-droppable {
  background: rgba(39, 166, 164, 0.1);
  outline: 1px dashed #27A6A4;
}
.zs-drag-card-table-container .zs-droppable > td {
  opacity: 0;
}
.zs-dragging .zs-draggable,
.zs-dragging .zs-droppable,
.zs-dragging .zs-dragged {
  cursor: move !important;
}
.zs-overlay {
  position: fixed;
  top: 0;
  border: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
}
dialog {
  position: absolute;
  left: 0;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  margin: auto;
  border: solid;
  padding: 1em;
  background: white;
  color: black;
  display: block;
}
dialog:not([open]) {
  display: none;
}
dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
}
._dialog_overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
dialog.fixed {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}
.zs-dialog,
dialog.zs-dialog,
zs-dialog.zs-dialog {
  background: #ffffff;
  border-radius: 0;
  border: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18);
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
  width: intrinsic;
  min-width: 24em;
  min-height: 9.5em;
  left: 50%;
  margin: 0;
  right: auto;
  transform: translateX(-50%);
}
.zs-dialog [close],
dialog.zs-dialog [close],
zs-dialog.zs-dialog [close] {
  position: absolute;
  top: 1em;
  right: 1em;
  min-width: 1em;
  min-height: 1em;
}
.zs-dialog [close]::before,
dialog.zs-dialog [close]::before,
zs-dialog.zs-dialog [close]::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zs-dialog [close] ~ *,
dialog.zs-dialog [close] ~ *,
zs-dialog.zs-dialog [close] ~ * {
  margin-right: 1.5em;
}
.zs-dialog > header,
dialog.zs-dialog > header,
zs-dialog.zs-dialog > header,
.zs-dialog > form > header,
dialog.zs-dialog > form > header,
zs-dialog.zs-dialog > form > header {
  max-width: 80vw;
  padding: 1em 1em 1em 1em;
}
.zs-dialog > section,
dialog.zs-dialog > section,
zs-dialog.zs-dialog > section,
.zs-dialog > form > section,
dialog.zs-dialog > form > section,
zs-dialog.zs-dialog > form > section {
  max-width: 80vw;
  padding: 0em 1em 0em 1em;
}
.zs-dialog > footer,
dialog.zs-dialog > footer,
zs-dialog.zs-dialog > footer,
.zs-dialog > form > footer,
dialog.zs-dialog > form > footer,
zs-dialog.zs-dialog > form > footer {
  padding: 1em 1em 1em 1em;
  max-width: 80vw;
}
.backdrop[for="zs-dialog"]:not([class*="zs-bg-overlay-"]),
.zs-dialog ~ .backdrop:not([class*="zs-bg-overlay-"]) {
  background: rgba(255, 255, 255, 0.9) !important;
}
.zs-dialog::backdrop {
  background: rgba(255, 255, 255, 0.9) !important;
}
.zs-dialog:focus {
  outline: none;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(26, 22, 40, 0.12), 0px 10px 20px rgba(26, 22, 40, 0.18);
}
.zs-master-style .zs-stepper,
.zs-master-style.zs-stepper {
  display: flex;
  justify-content: space-between;
}
.zs-master-style .zs-stepper .zs-step,
.zs-master-style.zs-stepper .zs-step {
  flex-grow: 1;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}
.zs-master-style .zs-stepper .zs-step:last-child,
.zs-master-style.zs-stepper .zs-step:last-child {
  flex-grow: 0;
  flex-basis: auto;
  text-align: right;
}
.zs-master-style .zs-stepper .zs-step:last-child .zs-step-milestone::before,
.zs-master-style.zs-stepper .zs-step:last-child .zs-step-milestone::before {
  content: " ";
  background-color: #2D535F;
  width: 100%;
  height: 0.5em;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 2px;
  transform: translateY(-50%);
  z-index: -1;
}
.zs-master-style .zs-stepper .zs-step:last-child .zs-step-details,
.zs-master-style.zs-stepper .zs-step:last-child .zs-step-details {
  padding: 0em 0em 0em 0.5em;
}
.zs-master-style .zs-stepper .zs-step.zs-danger .zs-step-icon-container,
.zs-master-style.zs-stepper .zs-step.zs-danger .zs-step-icon-container {
  background: #B21111;
}
.zs-master-style .zs-stepper .zs-step.zs-warning .zs-step-icon-container,
.zs-master-style.zs-stepper .zs-step.zs-warning .zs-step-icon-container {
  background: #E3A900;
}
.zs-master-style .zs-stepper .zs-step.zs-info .zs-step-icon-container,
.zs-master-style.zs-stepper .zs-step.zs-info .zs-step-icon-container {
  background: #2B44C7;
}
.zs-master-style .zs-stepper .zs-step.zs-success .zs-step-icon-container,
.zs-master-style.zs-stepper .zs-step.zs-success .zs-step-icon-container {
  background: #00AA67;
}
.zs-master-style .zs-stepper .zs-step-milestone,
.zs-master-style.zs-stepper .zs-step-milestone {
  position: relative;
  display: inline-block;
  width: 100%;
}
.zs-master-style .zs-stepper .zs-step:not(:last-child) .zs-step-milestone::after,
.zs-master-style.zs-stepper .zs-step:not(:last-child) .zs-step-milestone::after {
  content: " ";
  background-color: #2D535F;
  width: 100%;
  height: 0.5em;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  z-index: -1;
}
.zs-master-style .zs-stepper .zs-step.zs-step-current .zs-step-milestone::after,
.zs-master-style.zs-stepper .zs-step.zs-step-current .zs-step-milestone::after,
.zs-master-style .zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-milestone::before,
.zs-master-style.zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-milestone::before,
.zs-master-style .zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-milestone::after,
.zs-master-style.zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-milestone::after,
.zs-master-style .zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-icon-container,
.zs-master-style.zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-icon-container {
  background-color: #F4F3F3;
}
.zs-master-style .zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-details,
.zs-master-style.zs-stepper .zs-step.zs-step-current ~ .zs-step .zs-step-details {
  color: #B2B0B6;
}
.zs-master-style .zs-stepper .zs-step-icon-container,
.zs-master-style.zs-stepper .zs-step-icon-container {
  color: #ffffff;
  display: inline-flex;
  border-radius: 50%;
  background-color: #2D535F;
  padding: 0.25em 0.25em 0.25em 0.25em;
}
.zs-master-style .zs-stepper .zs-step-details,
.zs-master-style.zs-stepper .zs-step-details {
  margin: 1em 0em 0em 0em;
  padding: 0em 0.5em 0em 0em;
}
.zs-master-style .zs-stepper .zs-highlighted-text,
.zs-master-style.zs-stepper .zs-highlighted-text {
  font-style: italic;
}
.zs-master-style .zs-stepper.zs-stepper-vertical,
.zs-master-style.zs-stepper.zs-stepper-vertical {
  flex-direction: column;
}
.zs-master-style .zs-stepper.zs-stepper-vertical .zs-step,
.zs-master-style.zs-stepper.zs-stepper-vertical .zs-step {
  flex-direction: row;
}
.zs-master-style .zs-stepper.zs-stepper-vertical .zs-step:last-child .zs-step-milestone::before,
.zs-master-style.zs-stepper.zs-stepper-vertical .zs-step:last-child .zs-step-milestone::before {
  content: none;
}
.zs-master-style .zs-stepper.zs-stepper-vertical .zs-step .zs-step-milestone,
.zs-master-style.zs-stepper.zs-stepper-vertical .zs-step .zs-step-milestone {
  width: auto;
  height: 100%;
}
.zs-master-style .zs-stepper.zs-stepper-vertical .zs-step:not(:last-child) .zs-step-milestone::after,
.zs-master-style.zs-stepper.zs-stepper-vertical .zs-step:not(:last-child) .zs-step-milestone::after {
  width: 0.5em;
  height: 100%;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
}
.zs-master-style .zs-stepper.zs-stepper-vertical .zs-step-details,
.zs-master-style.zs-stepper.zs-stepper-vertical .zs-step-details {
  margin: 0em 0em 0em 1em;
  padding: 0em 0em 0.5em 0em;
}
.zs-master-style .zs-highlighted-text,
.zs-master-style.zs-highlighted-text {
  padding: 0em 0.25em 0em 0.25em;
}
.zs-master-style .zs-highlighted-text.zs-success,
.zs-master-style.zs-highlighted-text.zs-success {
  background: #D2FAF7;
  color: #1A1628;
}
.zs-master-style .zs-highlighted-text.zs-info,
.zs-master-style.zs-highlighted-text.zs-info {
  background: #EEEEFF;
  color: #1A1628;
}
.zs-master-style .zs-highlighted-text.zs-warning,
.zs-master-style.zs-highlighted-text.zs-warning {
  background: #FFF9F1;
  color: #1A1628;
}
.zs-master-style .zs-highlighted-text.zs-danger,
.zs-master-style.zs-highlighted-text.zs-danger {
  background: #FFEDE9;
  color: #1A1628;
}
.zs-wizard {
  display: block;
  margin: 2em 2em 2em 2em;
}
.zs-wizard .zs-chevron {
  width: auto;
  color: #27A6A4;
  position: relative;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  padding: 0.5em 2em 0.5em 2em;
  margin: 0em 0.25em 0em 0em;
  display: inline-block;
}
.zs-wizard .zs-chevron:first-of-type {
  margin: 0em 0.75em 0em 0em;
  background: #D6F7FF;
}
.zs-wizard .zs-chevron:first-of-type::before,
.zs-wizard .zs-chevron:first-of-type::after {
  width: 1em;
  left: auto;
  right: 0;
}
.zs-wizard .zs-chevron:first-of-type::before {
  border-radius: 0 0 0 0;
  -webkit-transform: skew(30deg, 0deg) translateX(50%);
  -moz-transform: skew(30deg, 0deg) translateX(50%);
  -ms-transform: skew(30deg, 0deg) translateX(50%);
  -o-transform: skew(30deg, 0deg) translateX(50%);
  transform: skew(30deg, 0deg) translateX(50%);
}
.zs-wizard .zs-chevron:first-of-type::after {
  border-radius: 0 0 0 0;
  -webkit-transform: skew(-30deg, 0deg) translateX(50%);
  -moz-transform: skew(-30deg, 0deg) translateX(50%);
  -ms-transform: skew(-30deg, 0deg) translateX(50%);
  -o-transform: skew(-30deg, 0deg) translateX(50%);
  transform: skew(-30deg, 0deg) translateX(50%);
  top: 50%;
}
.zs-wizard .zs-chevron:first-of-type[active] {
  background: #2D535F;
}
.zs-wizard .zs-chevron[active] * {
  color: #ffffff;
}
.zs-wizard .zs-chevron[active] ~ .zs-chevron::before,
.zs-wizard .zs-chevron[active] ~ .zs-chevron::after {
  background: #F4F3F3;
}
.zs-wizard .zs-chevron[active]::before,
.zs-wizard .zs-chevron[active]::after {
  background: #2D535F;
}
.zs-wizard .zs-chevron[active] ~ .zs-chevron .zs-wizard-chevron-text {
  color: #716E79;
}
.zs-wizard .zs-chevron[active] ~ .zs-chevron .zs-wizard-chevron-text .zs-badge-counter {
  background: #716E79;
}
.zs-wizard .zs-chevron[active] .zs-badge-counter {
  color: #2D535F;
  background: #ffffff;
}
.zs-wizard .zs-chevron[active] [text-container] {
  font-weight: 700;
}
.zs-wizard .zs-chevron[active] .zs-badge-counter {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
}
.zs-wizard .zs-chevron::before,
.zs-wizard .zs-chevron::after {
  content: '';
  position: absolute;
  left: 0;
  height: 50%;
  width: 100%;
  border: 1px solid transparent;
  border-right-width: 0px;
  background: #D6F7FF;
  z-index: -1;
}
.zs-wizard .zs-chevron:last-of-type::before,
.zs-wizard .zs-chevron:last-of-type::after {
  border-right-width: 1px;
}
.zs-wizard .zs-chevron::before {
  top: 0;
  border-bottom: none;
  -webkit-transform: skew(30deg, 0deg);
  -moz-transform: skew(30deg, 0deg);
  -ms-transform: skew(30deg, 0deg);
  -o-transform: skew(30deg, 0deg);
  transform: skew(30deg, 0deg);
}
.zs-wizard .zs-chevron::after {
  border-top: none;
  -webkit-transform: skew(-30deg, 0deg);
  -moz-transform: skew(-30deg, 0deg);
  -ms-transform: skew(-30deg, 0deg);
  -o-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);
}
.zs-wizard .zs-chevron:not(:first-of-type)::after {
  top: calc(50% - 0.5px);
}
.zs-wizard .zs-chevron [text-container] {
  line-height: 1.5;
}
.zs-wizard .zs-chevron .zs-badge-counter {
  box-shadow: none;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text {
  color: #00AA67;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:visited {
  color: #00AA67;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:focus {
  color: #0A6E5E;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:hover {
  color: #54D7AF;
}
.zs-wizard .zs-chevron.zs-success .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-success .zs-wizard-chevron-text:active {
  color: #0C4846;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text {
  color: #B21111;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:visited {
  color: #B21111;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:focus {
  color: #892208;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:hover {
  color: #F27755;
}
.zs-wizard .zs-chevron.zs-danger .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-danger .zs-wizard-chevron-text:active {
  color: #5C1A0B;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text {
  color: #E3A900;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:visited {
  color: #E3A900;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:focus {
  color: #8A640C;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:hover {
  color: #FDCA63;
}
.zs-wizard .zs-chevron.zs-warning .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-warning .zs-wizard-chevron-text:active {
  color: #563F0E;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text {
  color: #2B44C7;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:visited,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:visited {
  color: #2B44C7;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:focus,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:focus {
  color: #141187;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:hover,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:hover {
  color: #7E92E9;
}
.zs-wizard .zs-chevron.zs-info .zs-wizard-chevron-text:active,
.zs-wizard .zs-chevron[active] ~ .zs-info .zs-wizard-chevron-text:active {
  color: #160C60;
}
.zs-wizard .zs-chevron.zs-success::before,
.zs-wizard .zs-chevron.zs-success::after,
.zs-wizard .zs-chevron.zs-success:first-of-type {
  background: #F1FEFF;
}
.zs-wizard .zs-chevron.zs-danger::before,
.zs-wizard .zs-chevron.zs-warning::before,
.zs-wizard .zs-chevron.zs-info::before,
.zs-wizard .zs-chevron.zs-danger::after,
.zs-wizard .zs-chevron.zs-warning::after,
.zs-wizard .zs-chevron.zs-info::after,
.zs-wizard .zs-chevron.zs-danger:first-of-type,
.zs-wizard .zs-chevron.zs-warning:first-of-type,
.zs-wizard .zs-chevron.zs-info:first-of-type {
  background: #F4F3F3;
}
.zs-wizard .zs-chevron.zs-success[active]::before,
.zs-wizard .zs-chevron.zs-success[active]::after,
.zs-wizard .zs-chevron.zs-success[active]:first-of-type {
  background: #00AA67;
}
.zs-wizard .zs-chevron.zs-danger[active]::before,
.zs-wizard .zs-chevron.zs-danger[active]::after,
.zs-wizard .zs-chevron.zs-danger[active]:first-of-type {
  background: #B21111;
}
.zs-wizard .zs-chevron.zs-warning[active]::before,
.zs-wizard .zs-chevron.zs-warning[active]::after,
.zs-wizard .zs-chevron.zs-warning[active]:first-of-type {
  background: #E3A900;
}
.zs-wizard .zs-chevron.zs-info[active]::before,
.zs-wizard .zs-chevron.zs-info[active]::after,
.zs-wizard .zs-chevron.zs-info[active]:first-of-type {
  background: #2B44C7;
}
.zs-wizard[nonclickable] .zs-chevron {
  pointer-events: none;
}
.zs-wizard [source-id] {
  display: none;
}
.zs-wizard [source-id][active] {
  display: block;
}
.zs-wizard.zs-wizard-stretch .zs-chevron {
  flex-grow: 1;
}
