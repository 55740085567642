@import "../../../../node_modules/zsui/src/core/relative.less";
 
.zs-accordion {
    .margin(0, 0, 0.25, 0)
  }
  .zs-accordion .zs-accordion-bar {
    border: solid 0.0625em @zs-accordion-border-color;
    box-shadow: 0.125em 0.125em 0.125em @zs-accordion-bar-box-shadow-color;
    cursor: pointer;
    .padding(1, 1, 1, 1)
  }
  .zs-accordion .zs-accordion-panel {
    background: @zs-accordion-panel-background;
    border: solid 0.0625em @zs-accordion-border-color;
    .padding(2, 2, 2, 2);
    .margin(0, 2, 0, 2)
  }
  @import "../../../themes/config.less";
